import styled from '@emotion/styled';
import {
  MIRA_PAYMENT_LAYOUT_PC_TITLE_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_PC_TITLE_MARGIN_BOTTOM,
  MIRA_PAYMENT_LAYOUT_TABLET_TITLE_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_TABLET_TITLE_MARGIN_BOTTOM,
  MIRA_PAYMENT_LAYOUT_SMART_TITLE_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_SMART_TITLE_MARGIN_BOTTOM,
  MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT,
  MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT,
  MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_SMART_TOTAL_FONT,
  MIRA_PAYMENT_LAYOUT_SMART_TOTAL_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_SMART_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_EXPLAN_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_EXPLAN_MARGIN_BOTTOM,
  MIRA_PAYMENT_LAYOUT_PC_EXPLAN_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_PC_TABLET_WIDTH,
  MAIN_FULL_WIDTH,
  MIRA_PAYMENT_LAYOUT_HISTORY_END_BORDER,
  MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT,
  MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT,
  MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_FONT,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_TABLET_SUBTOTAL_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT,
  MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTAL_FONT,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTAL_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_TABLET_TOTAL_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_TOTAL_END_BORDER,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_MARGIN_BOTTOM,
  MIRA_PAYMENT_LAYOUT_PC_SUBTOTALROW_HEIGHT,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTALROW_HEIGHT,
  MIRA_PAYMENT_LAYOUT_PC_TOTALROW_HEIGHT,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTALROW_HEIGHT,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT,
  MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT_HEIGHT,
  MIRA_PAYMENT_LAYOUT_TABLET_HISTORY_FONT_SPACING,
  MIRA_PAYMENT_LAYOUT_SUBTOTALrOW_PADDING_TOP,
  MIRA_PAYMENT_LAYOUT_PC_BUTTON_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_TABLET_BUTTON_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_SMART_BUTTON_MARGIN_BOTTOM,
  MIRA_PAYMENT_LAYOUT_TABLE_FIRST_COL_WIDTH,
  MAIN_SMART_LAYOUT_MARGIN_LEFT,
  MAIN_SMART_LAYOUT_MARGIN_RIGHT,
  MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT,
  MIRA_PAYMENT_LAYOUT_TABLE_FIRST_COL_TOP,
  MIRA_PAYMENT_LAYOUT_PC_MARGIN_BOTTOM,
  MIRA_PAYMENT_LAYOUT_SMART_BUTTON_IPHONE_MARGIN_BOTTOM,
} from '../../../styles/size';

import { TABLET_SIZE, SMART_SIZE, IPHONE_SIZE } from '../../../styles/common';

export const Layout = styled.div`
  display: inline-block;
  text-align: center;
  max-width: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_WIDTH};
  width: ${MAIN_FULL_WIDTH};
  // height: ${MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT};
  vertical-align: middle;
  margin-bottom: ${MIRA_PAYMENT_LAYOUT_PC_MARGIN_BOTTOM};
`;

export const Title = styled.p`
  margin-top: ${MIRA_PAYMENT_LAYOUT_PC_TITLE_MARGIN_TOP};
  margin-bottom: ${MIRA_PAYMENT_LAYOUT_PC_TITLE_MARGIN_BOTTOM};
  font-size: ${MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT};
  font-weight: 700;
  line-height: ${MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT_HEIGHT};
  letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT_SPACING};
  ${TABLET_SIZE} {
    margin-top: ${MIRA_PAYMENT_LAYOUT_TABLET_TITLE_MARGIN_TOP};
    margin-bottom: ${MIRA_PAYMENT_LAYOUT_TABLET_TITLE_MARGIN_BOTTOM};
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT_SPACING};
  }
  ${SMART_SIZE} {
    margin-top: ${MIRA_PAYMENT_LAYOUT_SMART_TITLE_MARGIN_TOP};
    margin-bottom: ${MIRA_PAYMENT_LAYOUT_SMART_TITLE_MARGIN_BOTTOM};
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT_SPACING};
  }
`;
export const Explan = styled.p`
  margin-top: ${MIRA_PAYMENT_LAYOUT_PC_EXPLAN_MARGIN_TOP};
  margin-bottom: ${MIRA_PAYMENT_LAYOUT_EXPLAN_MARGIN_BOTTOM};
  font-size: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT};
  line-height: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_HEIGHT};
  letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_SPACING};
  text-align: left;
  font-weight: 500;
  ${TABLET_SIZE} {
    margin-top: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_EXPLAN_MARGIN_TOP};
    font-size: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_SPACING};
  }
  ${SMART_SIZE} {
    margin-top: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_EXPLAN_MARGIN_TOP};
    font-size: ${MIRA_PAYMENT_LAYOUT_SMART_TOTAL_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_SMART_TOTAL_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_SMART_FONT_SPACING};
  }
`;

export const Explanation = styled.p`
  font-size: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT};
  line-height: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_HEIGHT};
  letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_SPACING};
  text-align: left;
  font-weight: 500;
  ${TABLET_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_SPACING};
  }
  ${SMART_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_SMART_TOTAL_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_SMART_TOTAL_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_SMART_FONT_SPACING};
  }
`;
export const Table = styled.table`
  border: none;
  width: ${MAIN_FULL_WIDTH};
  & > tbody > tr:last-of-type {
    // border-bottom: ${MIRA_PAYMENT_LAYOUT_HISTORY_END_BORDER} solid black;
  }
`;
export const Number = styled.td`
  text-align: left;
  width: ${MIRA_PAYMENT_LAYOUT_TABLE_FIRST_COL_WIDTH};
  top: ${MIRA_PAYMENT_LAYOUT_TABLE_FIRST_COL_TOP};
  position: relative;
`;
export const History = styled.td`
  text-align: left;
  font-size: ${MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT};
  line-height: ${MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT_HEIGHT};
  letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT_SPACING};
  font-weight: 500;
  ${TABLET_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_TABLET_HISTORY_FONT_SPACING};
  }
  ${SMART_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_TABLET_HISTORY_FONT_SPACING};
  }
`;
export const Costrow = styled.td`
  text-align: right;
  font-weight: 500;
`;

export const Subtotal = styled.tr`
  font-weight: 700;
  font-size: ${MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT};
  line-height: ${MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT_HEIGHT};
  letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT_SPACING};
  border-top: ${MIRA_PAYMENT_LAYOUT_HISTORY_END_BORDER} solid black;
  height: ${MIRA_PAYMENT_LAYOUT_PC_SUBTOTALROW_HEIGHT};
  ${TABLET_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_TABLET_SUBTOTAL_FONT_SPACING};
    padding-bottom: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_MARGIN_BOTTOM};
    height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTALROW_HEIGHT};
  }
  ${SMART_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_SMART_FONT_SPACING};
    height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTALROW_HEIGHT};
  }
`;
export const Subtotaltxt = styled.td`
  text-align: left;
  width: auto;
  display: flex;
`;
export const Subtotaltext = styled.div`
  text-align: left;
  padding-top: ${MIRA_PAYMENT_LAYOUT_SUBTOTALrOW_PADDING_TOP};
`;
export const Tax = styled.div`
  text-align: left;
  font-size: ${MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT};
  line-height: ${MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT_HEIGHT};
  letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT_SPACING};
  padding-top: ${MIRA_PAYMENT_LAYOUT_SUBTOTALrOW_PADDING_TOP};
  ${TABLET_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_TABLET_HISTORY_FONT_SPACING};
  }
  ${SMART_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_TABLET_HISTORY_FONT_SPACING};
  }
`;
export const Cost = styled.td`
  padding-top: ${MIRA_PAYMENT_LAYOUT_SUBTOTALrOW_PADDING_TOP};
  text-align: right;
  width: auto;
`;

export const Total = styled.tr`
  font-size: ${MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT};
  line-height: ${MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT_HEIGHT};
  letter-spacing: ${MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT_SPACING};
  border-bottom: ${MIRA_PAYMENT_LAYOUT_TOTAL_END_BORDER} solid black !important;
  border-top: ${MIRA_PAYMENT_LAYOUT_TOTAL_END_BORDER} solid black !important;
  font-weight: 700;
  height: ${MIRA_PAYMENT_LAYOUT_PC_TOTALROW_HEIGHT};
  ${TABLET_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTAL_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTAL_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_TABLET_TOTAL_FONT_SPACING};
    height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTALROW_HEIGHT};
  }
  ${SMART_SIZE} {
    font-size: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTAL_FONT};
    line-height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTAL_FONT_HEIGHT};
    letter-spacing: ${MIRA_PAYMENT_LAYOUT_SMART_FONT_SPACING};
    height: ${MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTALROW_HEIGHT};
  }
`;
export const Totaltxt = styled.td`
  text-align: left;
  vertical-align: middle;
`;
export const Totalcost = styled.td`
  text-align: right;
  vertical-align: middle;
`;
export const Buttonside = styled.div`
  margin-top: ${MIRA_PAYMENT_LAYOUT_PC_BUTTON_MARGIN_TOP};
  ${TABLET_SIZE} {
    margin-top: ${MIRA_PAYMENT_LAYOUT_TABLET_BUTTON_MARGIN_TOP};
  }
  ${SMART_SIZE} {
    position: absolute;
    bottom: ${MIRA_PAYMENT_LAYOUT_SMART_BUTTON_MARGIN_BOTTOM};
    width: calc(${MAIN_FULL_WIDTH} - ${MAIN_SMART_LAYOUT_MARGIN_LEFT} - ${MAIN_SMART_LAYOUT_MARGIN_RIGHT});
  }
  ${IPHONE_SIZE} {
    bottom: ${MIRA_PAYMENT_LAYOUT_SMART_BUTTON_IPHONE_MARGIN_BOTTOM};
  }
`;
