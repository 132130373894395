// COlOR
export const WHITE = '#ffffff' as const;
export const BLACK = '#000000' as const;
export const RED = '#D60035' as const;
export const RED_DISABLED = '#eb819b' as const;
export const LIGHT_GRAY = '#c4c4c4' as const;
export const LIGHT_GRAY_BORDER = '#c9caca' as const;
export const MEDIUM_GRAY = '#b5b5b6' as const;
export const LARGE_BUTTON_BOX_SHADOW_COLOR = '#00000026' as const;
export const BOX_BACKGROUND_PINK = '#f4f4f4' as const;
export const DESCRIPTION_TEXT_BLUE = '#1d2088' as const;
