import React, { FC } from 'react';
import styled from '@emotion/styled';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { TABLET_SIZE, SMART_SIZE } from '../../../../styles/common';

interface props {
  label: string;
  arrowLocation?: 'left' | 'right';
}

const ArrowLeft = styled(AiOutlineArrowLeft)`
  position: absolute;
  top: 22px;
  left: 30px;
  width: 21px;
  height: 21px;
  ${TABLET_SIZE} {
    position: absolute;
    top: 15px;
    left: 21px;
    width: 15px;
    height: 15px;
  }
  ${SMART_SIZE} {
    position: absolute;
    top: 15px;
    left: 21px;
    width: 15px;
    height: 15px;
  }
`;

const ArrowRight = styled(AiOutlineArrowRight)`
  position: absolute;
  top: 22px;
  right: 30px;
  width: 21px;
  height: 21px;
  ${TABLET_SIZE} {
    position: absolute;
    top: 15px;
    right: 21px;
    width: 15px;
    height: 15px;
  }
  ${SMART_SIZE} {
    position: absolute;
    top: 15px;
    right: 21px;
    width: 15px;
    height: 15px;
  }
`;

const CustomButtonLabel: FC<props> = ({ label, arrowLocation }) => {
  return (
    <>
      {arrowLocation === 'left' ? <ArrowLeft /> : ''}
      <span>{label}</span>
      {arrowLocation === 'right' ? <ArrowRight /> : ''}
    </>
  );
};

export default CustomButtonLabel;
