import produce from 'immer';
import { AnyAction } from 'redux';
import {
  GET_SHOP_BUTTON_REQUEST,
  GET_SHOP_BUTTON_SUCCESS,
  GET_SHOP_BUTTON_FAILURE,
  GET_SHOP_BUTTON_INITIAL,
} from '../../actions';

// タイプを設定
export type buttonStateType = {
  isGetButtonLoading: boolean;
  isGetButtonLoaded: boolean;
  getButtonFailureMessage: string;
  buttonData: any;
};

// ステイタスを初期化
const initialState: buttonStateType = {
  isGetButtonLoading: false,
  isGetButtonLoaded: false,
  getButtonFailureMessage: '',
  buttonData: [],
};

// APIの結果によってステイタスが更新される処理
const buttonReducer = (state = initialState, action: AnyAction): buttonStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case GET_SHOP_BUTTON_REQUEST:
        dataState.isGetButtonLoading = true;
        dataState.isGetButtonLoaded = false;
        dataState.getButtonFailureMessage = '';
        dataState.buttonData = [];
        break;
      case GET_SHOP_BUTTON_SUCCESS:
        dataState.isGetButtonLoading = false;
        dataState.isGetButtonLoaded = true;
        dataState.getButtonFailureMessage = '';
        dataState.buttonData = action.data;
        break;
      case GET_SHOP_BUTTON_FAILURE:
        dataState.isGetButtonLoading = false;
        dataState.isGetButtonLoaded = false;
        dataState.getButtonFailureMessage = '';
        dataState.buttonData = [];
        break;
      case GET_SHOP_BUTTON_INITIAL:
        dataState.isGetButtonLoading = false;
        dataState.isGetButtonLoaded = false;
        dataState.getButtonFailureMessage = '';
        dataState.buttonData = [];
        break;
      default:
        break;
    }
  });
};

export default buttonReducer;
