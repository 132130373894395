import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { paymentStateType } from '../../../store/reducers/payment/paymentReducer';
import {
  Title,
  Layout,
  Explan,
  Explanation,
  Number,
  History,
  Costrow,
  Subtotal,
  Tax,
  Cost,
  Total,
  Subtotaltxt,
  Subtotaltext,
  Totaltxt,
  Totalcost,
  Table,
  Buttonside,
} from './Payment.style';
import CustomButtonLabel from '../../../components/Common/Button/Label';
import CustomButton from '../../../components/Common/Button/style';
import MyPageLayout from '../../../components/Layouts/MyPage/MyPageLayout.index';

import {
  PAYMENT_REQUEST,
  UNPAID_REQUEST,
  CUSTOMER_ID_REQUEST,
  CUSTOMER_ID_INITIAL,
  UNPAID_OPERATION_CHECK_INITIAL,
} from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { unpaidStateType } from '../../../store/reducers/unpaid/unpaidReducer';
import { customerIdStateType } from '../../../store/reducers/customerId/customerIdReducer';

import FW from '../../../config/word.json';
import FS from '../../../config/sentence.json';

import useAuthorizationNormal from '../../../hooks/Auth/useAuthorizationNormal';

type unpaidType = {
  sub_total: number;
  sub_total_name: string;
  tax_rate: number;
  unpaid: UnpaidsPropsType[] | [];
};

type UnpaidsPropsType = {
  price: number;
  name: string;
};

/**
 * 未納金の支払い金額画面
 * @returns 未納金の支払い金額のHTML
 */
const UnpaidPayment: FC = () => {
  // ライブラリーを変数に設定する
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  // web決済APIのデータ
  const { isPaymentLoaded, paymentDatas } = useSelector<RootState, paymentStateType>((state) => state.payment);
  // 顧客ID払出APIのデータ
  const { isCustomerIdLoaded, customerIdData } = useSelector<RootState, customerIdStateType>(
    (state) => state.customerId,
  );
  // 未納金取得APIのデータ
  const { isUnpaidLoaded, unpaidLists } = useSelector<RootState, unpaidStateType>((state) => state.unpaid);

  // 顧客ID
  const [customerId, setCustomerId] = useState('');

  // ログイン認証を設定する
  useAuthorizationNormal();

  /**
   * 画面描画時の処理
   * 未納金取得API呼び出す
   */
  useEffect(() => {
    const memberID = sessionStorage.getItem('memberID') ?? '';
    dispatch({
      type: UNPAID_REQUEST,
      memberID,
      history: location,
    });
  }, [dispatch, location]);

  /**
   * 画面描画時の処理
   * 顧客ID払出APIが成功したら、下の処理を通る
   */
  useEffect(() => {
    if (isCustomerIdLoaded) {
      if (customerIdData !== null) {
        setCustomerId(customerIdData.customer_id);
        const receiptNumber = sessionStorage.getItem('memberID') ?? '';
        const shopId = sessionStorage.getItem('shopId');
        const amount = unpaidLists.total;
        const paymentType = 'unpaid';
        const unpaidId = unpaidLists.unpaid_id;
        sessionStorage.setItem('paymentType', paymentType);
        dispatch({
          type: CUSTOMER_ID_INITIAL,
          history,
        });
        // 未納金決済の処理を行う
        dispatch({
          type: PAYMENT_REQUEST,
          paymentType,
          customerId: customerIdData.customer_id,
          receiptNumber,
          shopId,
          unpaidId,
          amount,
          history,
        });
      }
    }
  }, [isCustomerIdLoaded, customerIdData, customerId, dispatch, history, unpaidLists.total, unpaidLists.unpaid_id]);

  /**
   * 画面描画時の処理
   * web決済APIが成功したら、下の処理を通る
   *   ・WEB決済APIが成功すると、以下のHTMLを生成して、SBPSページに遷移する
   */
  useEffect(() => {
    if (isPaymentLoaded) {
      if (paymentDatas !== null) {
        const html = `<html><head><meta name="robots" content="none"><meta charset="SHIFT_JIS"></head><body>${decodeURIComponent(
          paymentDatas.html,
        )}</body><script>document.forms[0].submit();</script></html>`;
        const x = window.open('', '_self');
        if (x != null) {
          x.document.open();
          x.document.write(html);
          x.document.close();
        }
      }
    }
  }, [isPaymentLoaded, paymentDatas]);

  /**
   * 戻るボタンを押下時の処理
   */
  const onClickBack = () => {
    const url = '/unpaid';
    dispatch({
      type: UNPAID_OPERATION_CHECK_INITIAL,
    });
    history.push(url);
  };

  /**
   * 次へボタンを押下時の処理
   */
  const onClickGo = () => {
    const memberID = sessionStorage.getItem('memberID') ?? '';
    dispatch({
      type: CUSTOMER_ID_REQUEST,
      memberID,
      history,
    });
  };

  return (
    <>
      <MyPageLayout>
        <Layout>
          {/* 未納金取得APIのロードが終わった時 */}
          {isUnpaidLoaded ? (
            <>
              {unpaidLists.result === 0 ? (
                // 未納金取得APIの処理結果が０の場合(未納金なし)
                <Title>{FS.F_S_UNPAID_PAYMENT_TOTAL_ZERO}</Title>
              ) : unpaidLists.result === 1 ? (
                // 未納金取得APIの処理結果が１の場合(未納金あり)
                <>
                  {unpaidLists.sub_total_list ? (
                    // 未納金取得APIの処理結果で、小計リストが存在している場合
                    <>
                      <Title>{FW.F_W_UNPAID_NAME}</Title>
                      <Table>
                        {unpaidLists.sub_total_list.map((rowItem: unpaidType, rowIndex) => {
                          return (
                            <tbody key={`unpaid-list-${rowIndex.toString()}-${rowItem.sub_total_name}`}>
                              {rowItem.unpaid.map((item: UnpaidsPropsType, cellIndex) => {
                                return (
                                  <tr key={`unpaid-price-${cellIndex.toString()}`}>
                                    {item.price < 0 ? (
                                      <>
                                        <Number>#</Number>
                                        <History>{item.name}</History>
                                        <Costrow>-¥{(item.price * -1).toLocaleString('en')}</Costrow>
                                      </>
                                    ) : (
                                      <>
                                        <Number />
                                        <History>{item.name}</History>
                                        <Costrow>¥{item.price.toLocaleString('en')}</Costrow>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                              <Subtotal>
                                <Number> </Number>
                                <Subtotaltxt>
                                  <Subtotaltext>
                                    {rowItem.sub_total_name.substring(0, rowItem.sub_total_name.indexOf('('))}
                                  </Subtotaltext>
                                  <Tax>{rowItem.sub_total_name.substring(rowItem.sub_total_name.indexOf('('))}</Tax>
                                </Subtotaltxt>
                                <Cost>¥{rowItem.sub_total.toLocaleString('en')}</Cost>
                              </Subtotal>
                            </tbody>
                          );
                        })}
                        <tbody>
                          <Total>
                            <Totaltxt colSpan={2}>合計</Totaltxt>
                            <Totalcost>¥{unpaidLists.total.toLocaleString('en')}</Totalcost>
                          </Total>
                        </tbody>
                      </Table>
                      <Explan>表示されている金額はすべて総額表示(税込)となります。</Explan>
                      <Explanation>*は軽減税率対象品目です。</Explanation>
                      <Explanation>#は該当月の表示価格から割り引かれる金額です。</Explanation>
                      <Explanation>
                        日割り等で割引金額が表示価格を上回った場合、割引金額の上限は表示価格となります。
                      </Explanation>
                      <Buttonside>
                        <CustomButton type="button" move="back" location="left" onClick={onClickBack}>
                          <CustomButtonLabel label="戻る" arrowLocation="left" />
                        </CustomButton>
                        <CustomButton type="button" move="go" location="right" onClick={onClickGo}>
                          <CustomButtonLabel label="次へ" arrowLocation="right" />
                        </CustomButton>
                      </Buttonside>
                    </>
                  ) : (
                    // 未納金取得APIの処理結果で、小計リストが存在していない場合
                    <>
                      <Title>{FS.F_S_UNPAID_PAYMENT_TOTAL_ZERO}</Title>
                    </>
                  )}
                </>
              ) : unpaidLists.result === 2 ? (
                // 未納金取得APIの処理結果が２の場合(2:未納金請求中)
                <Title>{FS.F_S_UNPAID_PAYMENT_RESULT_2}</Title>
              ) : (
                // その他の場合
                <Title>{FS.F_S_UNPAID_PAYMENT_RESULT_ELSE}</Title>
              )}
            </>
          ) : (
            <></>
          )}
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default UnpaidPayment;
