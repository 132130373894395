import produce from 'immer';
import { AnyAction } from 'redux';
import { UNPAID_SUCCESS, UNPAID_FAILURE, UNPAID_REQUEST } from '../../actions';

// タイプを設定
export type unpaidStateType = {
  isUnpaidLoading: boolean;
  isUnpaidLoaded: boolean;
  unpaidLists: unpaidType;
};

type unpaidType = {
  sub_total_list: UnpaidPropsType[] | null;
  total: number;
  result: number;
  unpaid_id: string;
};

type UnpaidPropsType = {
  sub_total: number;
  sub_total_name: string;
  tax_rate: number;
  unpaid: UnpaidsPropsType[] | [];
};

type UnpaidsPropsType = {
  price: number;
  name: string;
};

// ステイタスを初期化
const initialState: unpaidStateType = {
  isUnpaidLoading: false,
  isUnpaidLoaded: false,
  unpaidLists: {
    sub_total_list: [],
    total: 0,
    result: -1,
    unpaid_id: '',
  },
};

// APIの結果によってステイタスが更新される処理
const unpaidReducer = (state = initialState, action: AnyAction): unpaidStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case UNPAID_REQUEST:
        dataState.isUnpaidLoading = true;
        dataState.isUnpaidLoaded = false;
        dataState.unpaidLists.total = 0;
        dataState.unpaidLists.unpaid_id = '';
        dataState.unpaidLists.result = -1;
        dataState.unpaidLists.sub_total_list = [];
        break;
      case UNPAID_SUCCESS:
        dataState.isUnpaidLoading = false;
        dataState.isUnpaidLoaded = true;
        dataState.unpaidLists.total = action.res.data.total;
        dataState.unpaidLists.unpaid_id = action.res.data.unpaid_id;
        dataState.unpaidLists.result = action.res.data.result;
        dataState.unpaidLists.sub_total_list = action.res.data.sub_total_list;
        break;
      case UNPAID_FAILURE:
        dataState.isUnpaidLoading = false;
        dataState.isUnpaidLoaded = false;
        dataState.unpaidLists.total = 0;
        dataState.unpaidLists.unpaid_id = '';
        dataState.unpaidLists.result = -1;
        dataState.unpaidLists.sub_total_list = [];
        break;
      default:
        break;
    }
  });
};

export default unpaidReducer;
