import styled from '@emotion/styled';
import {
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_HEIGHT,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_WIDTH,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_WIDTH,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT,
  MIRA_CREDITCARDSWITCHING_LAYOUT_ERROR_TEXT_PC_TABLET_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_LAYOUT_ERROR_TEXT_SMART_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_PC_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_TABLET_SMART_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_TABLET_SMART_MIN_FONT_SIZE,
} from '../../styles/size';
import { BLACK, RED } from '../../styles/color';
import { IPHONE_SIZE, IPHONE_SIZE_MIN, SMART_SIZE, TABLET_SIZE } from '../../styles/common';

export const Layout = styled.div`
  margin-top: calc(33.33vh - 100px);
  & > div {
    & > p {
      font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_PC_FONT_SIZE};
      line-height: 45px;
      letter-spacing: 1.5px;
      color: ${BLACK};
      font-weight: 700;
      white-space: nowrap;
    }
    & > span > p {
      font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_ERROR_TEXT_PC_TABLET_FONT_SIZE};
      color: ${BLACK};
      font-weight: 700;
      line-height: 30px;
    }
    & > button {
      margin-top: 54px;
      width: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_WIDTH};
      height: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_HEIGHT};
      font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_FONT_SIZE};
      line-height: 29px;
      letter-spacing: 1px;
      color: ${RED};
      font-weight: 700;
      border-radius: 40px;
    }
  }
  ${TABLET_SIZE} {
    margin-top: calc(33.33vh - 79.5px);
    & > div {
      & > p {
        font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_TABLET_SMART_FONT_SIZE};
        line-height: 36px;
        letter-spacing: 0px;
        color: ${BLACK};
        font-weight: 700;
      }
      & > button {
        margin-top: 46px;
        width: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_WIDTH};
        height: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT};
        font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE};
        line-height: 20px;
        letter-spacing: 0.7px;
        color: ${RED};
        font-weight: 700;
      }
    }
  }
  ${SMART_SIZE} {
    margin-top: calc(33.33vh - 79.5px);
    text-align: center;
    height: calc(100vh - 200px);
    & > div {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      & > button {
        position: fixed;
        bottom: 89px;
        margin-left: auto;
        margin-right: auto;
      }
      & > span > p {
        font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_ERROR_TEXT_SMART_FONT_SIZE};
        color: ${BLACK};
        font-weight: 700;
        line-height: 26px;
      }
    }
  }
  ${IPHONE_SIZE} {
    & > div {
      & > button {
        position: fixed;
        bottom: 99px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  ${IPHONE_SIZE_MIN} {
    & > div {
      & > p {
        font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_TABLET_SMART_MIN_FONT_SIZE};
        line-height: 36px;
        letter-spacing: 0px;
        color: ${BLACK};
        font-weight: 700;
      }
      & > button {
        position: fixed;
        bottom: 99px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

export const DeactivatedText = styled.p`
  font-size: 18px;
  margin-top: 50px;
  ${SMART_SIZE} {
    font-size: 16px;
    text-align: left;
  }
`;
