import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import { PUT_PASSWORD_REQUEST, PUT_PASSWORD_SUCCESS, PUT_PASSWORD_FAILURE, COMMON_FAILURE } from '../../actions';
import { passwordService } from '../../services';
import { historyType } from '../../../utils/commonType';

export type putPasswordAction = {
  type: typeof PUT_PASSWORD_REQUEST;
  password: string;
  memberID: string;
  history: historyType;
};

function* putPassword(action: putPasswordAction): Generator<unknown> {
  try {
    const res: any = yield call(passwordService(action).putPassword);
    yield put({
      type: PUT_PASSWORD_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: PUT_PASSWORD_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchPutPassword() {
  yield takeLatest(PUT_PASSWORD_REQUEST, putPassword);
}
export default function* putPasswordSaga(): Generator<unknown> {
  yield all([fork(watchPutPassword)]);
}
