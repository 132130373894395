import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { API_BASE_ADDRESS } from '../api';

// APIの住所を設定します。
const unpaidOperationCheckServices = (memberId: string): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
    params: { member_id: memberId },
  };
  return {
    getUnpaidOperationCheck: () => axios.get(`${API_BASE_ADDRESS}/unpaid/operation/check`, config),
  };
};

export default unpaidOperationCheckServices;
