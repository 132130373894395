import React, { FC, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  MessageModal,
  MessageModalBodyLayout,
  MessageModalButton,
  MessageModalErrorMessage,
  MessageModalErrorMessageText,
} from '../Recess.style';
import MyPageLayout from '../../../components/Layouts/MyPage/MyPageLayout.index';

import { menuStateType } from '../../../store/reducers/menu/menuReducer';
import { buttonStateType } from '../../../store/reducers/button/buttonReducer';
import { recessStatusStateType } from '../../../store/reducers/recess/recessStatusReducer';
import { recessStateType } from '../../../store/reducers/recess/recessReducer';
import { memberTransferStatusStateType } from '../../../store/reducers/recess/memberTransferStatusReducer';
import CustomButton from '../../../components/Common/Button/style';
import CustomButtonLabel from '../../../components/Common/Button/Label';
import { Layout, Buttonside } from './Recess.agreement.style';

import FW from '../../../config/word.json';
import FS from '../../../config/sentence.json';

import useAuthorizationNormal from '../../../hooks/Auth/useAuthorizationNormal';

import { RootState } from '../../../store/reducers';

import {
  GET_MEMBER_MENU_INITIAL,
  GET_MEMBER_MENU_REQUEST,
  GET_SHOP_BUTTON_INITIAL,
  GET_SHOP_BUTTON_REQUEST,
  GET_MEMBER_TRANSFER_STATUS_INITIAL,
  GET_MEMBER_TRANSFER_STATUS_REQUEST,
  GET_RECESS_STATUS_INITIAL,
  GET_RECESS_STATUS_REQUEST,
  POST_RECESS_INITIAL,
  POST_RECESS_REQUEST,
} from '../../../store/actions';

/**
 * 休会選択月確認画面
 * @returns 休会選択月確認画面のHTML
 */
const Recess_agreement: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isClicked, setClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const startMonths = JSON.parse(sessionStorage.getItem('months') ?? '{}');
  const startMonth = sessionStorage.getItem('startMonth') ?? '';
  const endMonth = sessionStorage.getItem('endMonth') ?? '';
  let content = '';

  // メニュー取得API
  const { isGetMenuLoaded, menuData } = useSelector<RootState, menuStateType>((state) => state.menu);

  // 店舗ボタン取得API
  const { isGetButtonLoaded, buttonData } = useSelector<RootState, buttonStateType>((state) => state.button);

  // 休会状態取得API
  const { isGetRecessStatusLoaded, recessStatusDatas } = useSelector<RootState, recessStatusStateType>(
    (state) => state.recessStatus,
  );

  // 休会状態取得API
  const { isPostRecessLoaded, recessDatas } = useSelector<RootState, recessStateType>((state) => state.recess);

  // 会員管理移籍処理チェックAPI
  const { isGetMemberTransferStatusLoaded, memberTransferStatusDatas } = useSelector<
    RootState,
    memberTransferStatusStateType
  >((state) => state.memberTransferStatus);

  // ログイン認証を設定する
  useAuthorizationNormal();

  // 休会月を構築する
  if (startMonths.length > 0) {
    for (let i = 0; i < startMonths.length; i += 1) {
      if (startMonth === startMonths[i].start_month) {
        for (let j = 0; j < startMonths[i].range.length; j += 1) {
          const month = Number(startMonths[i].range[j].substring(4, 6));
          content = `${content}${month}月 `;
          if (endMonth === startMonths[i].range[j]) {
            break;
          }
        }
      }
    }
  }

  /**
   * 次へボタンを押下時の処理
   *   → 終了月を選択する画面へ遷移
   */
  const onClickGo = useCallback(() => {
    if (isClicked === false) {
      setClicked(true);
      // メニュー利用可否・休会可否時間帯・休会状態取得・会員管理移籍処理チェック・休会のAPIを初期化
      dispatch({
        type: GET_MEMBER_MENU_INITIAL,
      });
      dispatch({
        type: GET_SHOP_BUTTON_INITIAL,
      });
      dispatch({
        type: GET_RECESS_STATUS_INITIAL,
      });
      dispatch({
        type: GET_MEMBER_TRANSFER_STATUS_INITIAL,
      });
      dispatch({
        type: POST_RECESS_INITIAL,
      });
      // メニュー利用可否をチェックする
      // 暗号化された会員番号
      const memberId = sessionStorage.getItem('memberID') ?? '';
      // 店舗コード
      const shopId = sessionStorage.getItem('shopID') ?? '';
      // 会員メニューを取得する
      dispatch({
        type: GET_MEMBER_MENU_REQUEST,
        memberId,
        shopId,
        history,
      });
    }
  }, [dispatch, isClicked, history]);

  /**
   * 画面描画時の処理、休会メニュー利用可否取得APIが成功したら、下の処理を通る
   *   → 利用できれば、利用時間帯をチェックする
   *   → 利用できなければ、エラーメッセージを表示する
   */
  useEffect(() => {
    if (isGetMenuLoaded) {
      if (menuData.is_usable_recess === false) {
        // 休会メニュー利用不可の場合、エラーとなる
        setModalErrorMessage(FS.F_S_RECESS_FAILURE);
        setIsModalOpen(true);
        setClicked(false);
      } else {
        // 休会利用時間帯をチェックするAPI
        const shopId = sessionStorage.getItem('shopID') ?? '';
        dispatch({
          type: GET_SHOP_BUTTON_REQUEST,
          shopId,
          buttonId: '7', // 休会ボタンID
          history,
        });
      }
      dispatch({
        type: GET_MEMBER_MENU_INITIAL,
      });
    }
  }, [dispatch, history, isClicked, isGetMenuLoaded, menuData.is_usable_recess]);

  /**
   * 画面描画時の処理、休会メニュー利用時間帯取得APIが成功したら、下の処理を通る
   *   → 利用できる時間帯の場合、休会状態をチェックする
   *   → 利用できない時間帯の場合、、エラーメッセージを表示する
   */
  useEffect(() => {
    if (isGetButtonLoaded) {
      if (buttonData.is_available) {
        // 休会状態取得・休会取消のAPIを初期化
        // 休会状態を取得する
        const memberId = sessionStorage.getItem('member_id') ?? '';
        dispatch({
          type: GET_RECESS_STATUS_REQUEST,
          memberId,
          history,
        });
      } else {
        // 休会ボタン利用不可時間帯の場合、エラーとなる
        setModalErrorMessage(FS.F_S_RECESS_FAILURE);
        setIsModalOpen(true);
        setClicked(false);
      }
      dispatch({
        type: GET_SHOP_BUTTON_INITIAL,
      });
    }
  }, [buttonData.is_available, dispatch, history, isClicked, isGetButtonLoaded]);

  /**
   * 画面描画時の処理
   * 休会状態取得APIが成功したら、下の処理を通る
   */
  useEffect(() => {
    if (isGetRecessStatusLoaded) {
      if (recessStatusDatas.result === 1) {
        // 休会申込なしの場合、会員管理移籍処理チェックAPI
        dispatch({
          type: GET_MEMBER_TRANSFER_STATUS_REQUEST,
          memberId: sessionStorage.getItem('member_id') ?? '',
          history,
        });
      } else {
        // 休会申込ありの場合、再度休会不可、休会初期画面へ
        setModalErrorMessage(FS.F_S_RECESS_FAILURE);
        setIsModalOpen(true);
        setClicked(false);
        dispatch({
          type: GET_RECESS_STATUS_INITIAL,
        });
      }
    }
  }, [dispatch, history, isGetRecessStatusLoaded, recessStatusDatas.result]);

  /**
   * 画面描画時の処理
   * 休会状態取得APIが成功したら、下の処理を通る
   */
  useEffect(() => {
    if (isGetMemberTransferStatusLoaded) {
      if (memberTransferStatusDatas.result === 0) {
        if (
          recessStatusDatas.available_months === undefined ||
          recessStatusDatas.available_months === null ||
          recessStatusDatas.available_months.length === 0
        ) {
          // 休会可能月がなければ、エラーメッセージを表示する
          setModalErrorMessage(FS.F_S_RECESS_FAILURE);
          setIsModalOpen(true);
          setClicked(false);
        } else {
          let canRecess = false;
          // 休会可能月かどうかをチェックする
          for (let i = 0; i < recessStatusDatas.available_months.length; i += 1) {
            if (startMonth === recessStatusDatas.available_months[i].start_month) {
              for (let j = 0; j < recessStatusDatas.available_months[i].range.length; j += 1) {
                if (endMonth === recessStatusDatas.available_months[i].range[j]) {
                  canRecess = true;
                  break;
                }
              }
            }
          }
          if (canRecess) {
            // 休会申込なしの場合、休会をします
            dispatch({
              type: POST_RECESS_REQUEST,
              memberId: sessionStorage.getItem('member_id') ?? '',
              shopId: sessionStorage.getItem('shopID') ?? '',
              startMonth: sessionStorage.getItem('startMonth') ?? '',
              endMonth: sessionStorage.getItem('endMonth') ?? '',
              history,
            });
          } else {
            // 休会可能月ではなければ、エラーメッセージを表示する
            setModalErrorMessage(FS.F_S_RECESS_FAILURE);
            setIsModalOpen(true);
            setClicked(false);
          }
        }
      } else {
        // 休会申込ありの場合、再度休会不可、エラーメッセージを表示する
        setModalErrorMessage(FS.F_S_RECESS_FAILURE);
        setIsModalOpen(true);
        setClicked(false);
      }
      dispatch({
        type: GET_RECESS_STATUS_INITIAL,
      });
      dispatch({
        type: GET_MEMBER_TRANSFER_STATUS_INITIAL,
      });
    }
  }, [
    dispatch,
    endMonth,
    history,
    isGetMemberTransferStatusLoaded,
    isGetRecessStatusLoaded,
    memberTransferStatusDatas.result,
    recessStatusDatas.available_months,
    recessStatusDatas.result,
    startMonth,
  ]);

  /**
   * 画面描画時の処理
   * 休会状態取得APIが成功したら、下の処理を通る
   */
  useEffect(() => {
    if (isPostRecessLoaded) {
      setClicked(false);
      if (recessDatas.result === 0) {
        // キャッシュをクリアする
        sessionStorage.removeItem('months');
        sessionStorage.removeItem('startMonth');
        sessionStorage.removeItem('endMonth');
        sessionStorage.removeItem('transfer_month');
        // 成功 完了画面へ遷移
        history.push('/recess/recess-complete');
      } else {
        // 失敗 エラーとする
        setModalErrorMessage(FS.F_S_RECESS_FAILURE);
        setIsModalOpen(true);
      }
      dispatch({
        type: POST_RECESS_INITIAL,
      });
    }
  }, [dispatch, history, isPostRecessLoaded, recessDatas.result]);

  /**
   * ポップアップメッセージをクローズ処理
   */
  const onClickClose = useCallback(() => {
    setIsModalOpen(false);
    setModalErrorMessage('');
  }, []);

  /**
   * モダールが閉じた時の処理
   */
  const onHideModalClose = useCallback(() => {
    setIsModalOpen(false);
    setModalErrorMessage('');
  }, []);

  /**
   * 次へボタンを押下時の処理
   *   → 終了月を選択する画面へ遷移
   */
  const onClickBack = useCallback(() => {
    history.push('/recess/month-end');
  }, [history]);

  return (
    <>
      <MyPageLayout>
        <Layout>
          <div>
            <div>
              <h1>
                {content}
                <br />
                {FS.F_S_RECESS_CONFIRM_TEXT_1}
              </h1>
            </div>
            <Buttonside>
              <CustomButton type="button" move="back" location="left" onClick={onClickBack}>
                <CustomButtonLabel label={FW.F_W_GO_BEFORE} arrowLocation="left" />
              </CustomButton>
              <CustomButton type="button" move="go" location="right" onClick={onClickGo}>
                <CustomButtonLabel label={FW.F_W_GO_NEXT} arrowLocation="right" />
              </CustomButton>
            </Buttonside>
          </div>
          <MessageModal show={isModalOpen} onHide={onHideModalClose}>
            <MessageModalBodyLayout>
              <MessageModalErrorMessage>
                <MessageModalErrorMessageText>{modalErrorMessage}</MessageModalErrorMessageText>
              </MessageModalErrorMessage>
              <MessageModalButton onClick={onClickClose}>
                <>{FW.F_W_CLOSE}</>
              </MessageModalButton>
            </MessageModalBodyLayout>
          </MessageModal>
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Recess_agreement;
