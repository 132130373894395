import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import {
  PUT_MAIL_ADDRESS_REQUEST,
  PUT_MAIL_ADDRESS_SUCCESS,
  PUT_MAIL_ADDRESS_FAILURE,
  COMMON_FAILURE,
} from '../../actions';
import { mailService } from '../../services';
import { historyType } from '../../../utils/commonType';

export type putMailAddressAction = {
  type: typeof PUT_MAIL_ADDRESS_REQUEST;
  code: string;
  history: historyType;
};

function* putMailAddress(action: putMailAddressAction): Generator<unknown> {
  try {
    const res: any = yield call(mailService(action).putMailAddress);
    yield put({
      type: PUT_MAIL_ADDRESS_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: PUT_MAIL_ADDRESS_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
      if (error.response?.status === 400) {
        sessionStorage.clear();
        action.history.push('/not-found');
      } else {
        action.history.push('/systemError');
      }
    }
  }
}

function* watchPutMailAddress() {
  yield takeLatest(PUT_MAIL_ADDRESS_REQUEST, putMailAddress);
}
export default function* putMailAddressSaga(): Generator<unknown> {
  yield all([fork(watchPutMailAddress)]);
}
