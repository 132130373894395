import React, { FC, useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  MessageModal,
  MessageModalBodyLayout,
  MessageModalButton,
  MessageModalErrorMessage,
  MessageModalErrorMessageText,
} from '../Recess.style';
import MyPageLayout from '../../../components/Layouts/MyPage/MyPageLayout.index';

import CustomButton from '../../../components/Common/Button/style';
import CustomButtonLabel from '../../../components/Common/Button/Label';
import MonthInput from '../../../components/Common/Button/monthInput';
import { Layout, Buttonside } from './Month.end.style';

import { WHITE, RED } from '../../../styles/color';

import FW from '../../../config/word.json';
import FS from '../../../config/sentence.json';

import useAuthorizationNormal from '../../../hooks/Auth/useAuthorizationNormal';
import {
  GET_MEMBER_MENU_INITIAL,
  GET_MEMBER_TRANSFER_STATUS_INITIAL,
  GET_RECESS_STATUS_INITIAL,
  GET_SHOP_BUTTON_INITIAL,
  POST_RECESS_INITIAL,
} from '../../../store/actions';

/**
 * 休会終了月選択画面
 * @returns 休会終了月選択画面のHTML
 */
const Month_end: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const startMonths = JSON.parse(sessionStorage.getItem('months') ?? '{}');
  const startMonth = sessionStorage.getItem('startMonth') ?? '';
  const endMonth = sessionStorage.getItem('endMonth') ?? '';
  let content;
  let months: string[] = [];

  // ログイン認証を設定する
  useAuthorizationNormal();

  /**
   * カレンダーアイコンの表示・非表示を設定する
   * @param id タグID
   * @param option 表示オプション
   */
  const showDisplay = (id: string, option: boolean) => {
    const elem = document.getElementById(id);
    if (elem) elem.style.display = option ? 'block' : 'none';
  };

  /**
   * 開始月選択状態を設定する
   * @param id タグID
   * @param option 選択するかどうか
   */
  const setChecked = (id: string, option: boolean) => {
    const elem = document.getElementById(id);
    if (elem) {
      if (option) {
        elem.style.backgroundColor = RED;
        elem.style.color = WHITE;
      } else {
        elem.style.backgroundColor = WHITE;
        elem.style.color = RED;
      }
    }
  };

  // 選択できる終了月を設定する
  if (startMonths.length > 0) {
    for (let i = 0; i < startMonths.length; i += 1) {
      if (startMonths[i].start_month === startMonth) {
        months = startMonths[i].range;
        break;
      }
    }
  }

  // 終了月を変更して、対応するタグのスタイルを合わせて変更する。
  const onChangeEndMonth = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id } = event.target;
      for (let i = 0; i < months.length; i += 1) {
        if (i === Number(id)) {
          showDisplay(`calender${i.toString()}`, false);
          showDisplay(`calenderActive${i.toString()}`, true);
          setChecked(months[i], true);
        } else {
          showDisplay(`calender${i.toString()}`, true);
          showDisplay(`calenderActive${i.toString()}`, false);
          setChecked(months[i], false);
        }
      }
      sessionStorage.setItem('endMonth', event.target.value);
      setDisabled(false);
    },
    [months],
  );

  // 終了月選択エリアを構築する
  if (months.length > 0) {
    content = months.map((month: string, idx: number) => (
      <MonthInput key={month} htmlFor={idx.toString()} id={month}>
        <input type="radio" name="endMonth" value={month} id={idx.toString()} onChange={onChangeEndMonth} />
        <img src="/images/icons/calendar-alt.svg" alt="Calender" id={`calender${idx.toString()}`} />
        <img
          style={{ display: 'none' }}
          src="/images/icons/calendar-alt-active.svg"
          alt="Calender"
          id={`calenderActive${idx.toString()}`}
        />
        <span>{Number(month.substring(4, 6))}月</span>
      </MonthInput>
    ));
  }

  /**
   * 画面描画時の処理
   * 終了月が選択済であれば、選択状態を設定する
   */
  useEffect(() => {
    if (endMonth !== null && months.length > 0) {
      for (let i = 0; i < months.length; i += 1) {
        if (endMonth === months[i]) {
          showDisplay(`calender${i.toString()}`, false);
          showDisplay(`calenderActive${i.toString()}`, true);
          setChecked(months[i], true);
          setDisabled(false);
        } else {
          showDisplay(`calender${i.toString()}`, true);
          showDisplay(`calenderActive${i.toString()}`, false);
          setChecked(months[i], false);
        }
      }
    }
  }, [months, endMonth]);

  /**
   * 次へボタンを押下時の処理
   *   → 休会確認画面へ遷移
   */
  const onClickGo = useCallback(() => {
    const transferMonth = sessionStorage.getItem('transfer_month') ?? '';
    const selectedMonth = sessionStorage.getItem('endMonth') ?? '';
    if (transferMonth !== '' && transferMonth <= selectedMonth) {
      // 移籍確定月以降の休会手続きはできません。
      setModalErrorMessage(FS.F_S_RECESS_AFTER_TRANSFER);
      setIsModalOpen(true);
    } else {
      // メニュー取得・メニュー利用可否取得・休会状態取得・会員管理移籍処理チェック・休会のAPIを初期化
      dispatch({
        type: GET_MEMBER_MENU_INITIAL,
      });
      dispatch({
        type: GET_SHOP_BUTTON_INITIAL,
      });
      dispatch({
        type: GET_RECESS_STATUS_INITIAL,
      });
      dispatch({
        type: GET_MEMBER_TRANSFER_STATUS_INITIAL,
      });
      dispatch({
        type: POST_RECESS_INITIAL,
      });
      // 休会確認画面へ
      history.push('/recess/recess-aggrement');
    }
  }, [dispatch, history]);

  /**
   * 戻るボタンを押下時の処理
   *   → 開始月を選択する画面へ遷移
   */
  const onClickBack = useCallback(() => {
    history.push('/recess/month-start');
  }, [history]);

  /**
   * ポップアップメッセージをクローズ処理
   */
  const onClickClose = useCallback(() => {
    setIsModalOpen(false);
    setModalErrorMessage('');
  }, []);

  /**
   * モダールが閉じた時の処理
   */
  const onHideModalClose = useCallback(() => {
    setIsModalOpen(false);
    setModalErrorMessage('');
  }, []);

  return (
    <>
      <MyPageLayout>
        <Layout>
          <div>
            <div>
              <h1>{FS.F_S_RECESS_END_MONTH_TEXT_1}</h1>
            </div>
            <section>
              <div>
                <p>{FS.F_S_RECESS_END_MONTH_TEXT_2}</p>
                <div>{content}</div>
              </div>
            </section>

            <Buttonside>
              <CustomButton type="button" move="back" location="left" onClick={onClickBack}>
                <CustomButtonLabel label={FW.F_W_GO_BEFORE} arrowLocation="left" />
              </CustomButton>
              <CustomButton type="button" move="go" location="right" onClick={onClickGo} disabled={isDisabled}>
                <CustomButtonLabel label={FW.F_W_GO_NEXT} arrowLocation="right" />
              </CustomButton>
            </Buttonside>
          </div>
          <MessageModal show={isModalOpen} onHide={onHideModalClose}>
            <MessageModalBodyLayout>
              <MessageModalErrorMessage>
                <MessageModalErrorMessageText>{modalErrorMessage}</MessageModalErrorMessageText>
              </MessageModalErrorMessage>
              <MessageModalButton onClick={onClickClose}>
                <>{FW.F_W_CLOSE}</>
              </MessageModalButton>
            </MessageModalBodyLayout>
          </MessageModal>
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Month_end;
