import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router';

const useAuthorizationNormal = (): void => {
  const history = useHistory();

  useLayoutEffect(() => {
    const isLogin = JSON.parse(sessionStorage.getItem('member_info') || '{}');
    if (isLogin.result) {
      const currentPage = history.location.pathname;
      history.push(currentPage);
    } else {
      sessionStorage.clear();
      const url = '/';
      history.push(url);
    }
  }, [history]);
};

export default useAuthorizationNormal;
