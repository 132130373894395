/**
 * ReduxとRedux-sagaを設定する
 */
import { createStore, applyMiddleware, compose, Store } from 'redux';
// ReduxのDevToolsを確認するための、Middlewareを作成
import { composeWithDevTools } from 'redux-devtools-extension';
// Redux-sagaのMiddlewareを作成
import createSagaMiddleware from 'redux-saga';

import rootReducers from './reducers';
import rootSagas from './sagas';

export const ENV = (process.env.NODE_ENV as 'production' | 'test' | 'development') || 'development';
// depoly環境
export const isProduction = ENV === 'production';
// 開発環境
export const isDebug = ENV === 'development';
export const isClient = typeof window !== 'undefined';

export default function configureStore(): Store {
  // Redux-sagaのmiddleware作成
  const sagaMiddleware = createSagaMiddleware();
  // 配列を使用して、middlewareを配列に格納（もし、他のmiddlewareも使うことになったらここに格納する）
  const middlewares = [sagaMiddleware];

  // console.log(`isClient && isDebugがtrueなら開発環境 -> ${isClient && isDebug}`);

  let enhancer;
  // development環境実装
  if (isClient && isDebug) {
    // applyMiddlewareにredux-devtoolsモード表示させる
    enhancer = composeWithDevTools(applyMiddleware(...middlewares));
  } else {
    // deploy環境実装
    enhancer = compose(applyMiddleware(...middlewares));
  }

  // (store as SagaStore).sagaTask = sagaMiddleware.run(rootSagas);
  // (store as any).sagaTask = sagaMiddleware.run(rootSagas);

  const store = createStore(rootReducers, enhancer);
  // const store = createStore(()=>[], {}, applyMiddleware);

  // const store = createStore(rootReducers, {}, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSagas);
  return store;
}
