import styled from '@emotion/styled';
import {
  MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM,
  MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH,
  MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH,
  MAIN_FULL_WIDTH,
  MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LETTER_SPACING,
  MIRA_PAYMENT_LAYOUT_PC_BUTTON_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_TABLET_BUTTON_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_SMART_BUTTON_MARGIN_BOTTOM,
  MAIN_SMART_LAYOUT_MARGIN_RIGHT,
  MAIN_SMART_LAYOUT_MARGIN_LEFT,
  MIRA_PAYMENT_LAYOUT_SMART_BUTTON_IPHONE_MARGIN_BOTTOM,
  MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT,
  MIRA_RECESS_LAYOUT_BODY_FONT_WEIGHT,
  MIRA_RECESS_LAYOUT_SELECTION_PC_TABLET_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_SELECTION_SMART_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_SELECTION_DIV_PC_TABLET_MARGIN,
  MIRA_RECESS_LAYOUT_SELECTIONDIV_SMART_MARGIN,
} from '../../../styles/size';
import { IPHONE_SIZE, SMART_SIZE, TABLET_SIZE } from '../../../styles/common';
import { BLACK } from '../../../styles/color';

export const Layout = styled.div`
  margin-top: ${MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP};
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: ${MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM};
  & > div {
    & > div {
      max-width: ${MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH};
      margin-left: auto;
      margin-right: auto;
      & > h1 {
        font-size: ${MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE};
        line-height: ${MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING};
        color: ${BLACK};
        font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
      }
    }
    & > section {
      margin-top: ${MIRA_RECESS_LAYOUT_SELECTION_PC_TABLET_MARGIN_TOP};
      & > div {
        padding: 0;
        text-align: center;
        justify-content: start;
        & > p {
          font-size: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LETTER_SPACING};
          font-weight: ${MIRA_RECESS_LAYOUT_BODY_FONT_WEIGHT};
        }
        & > div {
          display: flex;
          justify-content: center;
          margin: ${MIRA_RECESS_LAYOUT_SELECTION_DIV_PC_TABLET_MARGIN};
        }
      }
    }
  }
  ${TABLET_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP};
    & > div {
      & > div {
        max-width: ${MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH};
        margin-left: auto;
        margin-right: auto;
        & > h1 {
          font-size: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING};
          color: ${BLACK};
          font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
        }
      }
      & > section {
        margin-top: ${MIRA_RECESS_LAYOUT_SELECTION_PC_TABLET_MARGIN_TOP};
        & > div {
          padding: 0;
          text-align: center;
          justify-content: start;
          & > p {
            font-size: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LETTER_SPACING};
            font-weight: ${MIRA_RECESS_LAYOUT_BODY_FONT_WEIGHT};
          }
          & > div {
            display: flex;
            justify-content: center;
            margin: ${MIRA_RECESS_LAYOUT_SELECTION_DIV_PC_TABLET_MARGIN};
          }
        }
      }
    }
  }
  ${SMART_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP};
    & > div {
      width: 100%;
      & div {
        & > h1 {
          width: calc(${MAIN_FULL_WIDTH} - ${MAIN_SMART_LAYOUT_MARGIN_LEFT} - ${MAIN_SMART_LAYOUT_MARGIN_RIGHT});
          font-size: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING};
          color: ${BLACK};
          font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
        }
      }
      & > section {
        margin-top: ${MIRA_RECESS_LAYOUT_SELECTION_SMART_MARGIN_TOP};
        & > div {
          & > p {
            font-size: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LETTER_SPACING};
            font-weight: ${MIRA_RECESS_LAYOUT_BODY_FONT_WEIGHT};
          }
          & > div {
            display: inline-block;
            justify-content: center;
            margin: ${MIRA_RECESS_LAYOUT_SELECTIONDIV_SMART_MARGIN};
          }
        }
      }
    }
  }
`;

export const Buttonside = styled.div`
  margin-top: ${MIRA_PAYMENT_LAYOUT_PC_BUTTON_MARGIN_TOP};
  ${TABLET_SIZE} {
    margin-top: ${MIRA_PAYMENT_LAYOUT_TABLET_BUTTON_MARGIN_TOP};
  }
  ${SMART_SIZE} {
    position: absolute;
    bottom: ${MIRA_PAYMENT_LAYOUT_SMART_BUTTON_MARGIN_BOTTOM};
    width: calc(${MAIN_FULL_WIDTH} - ${MAIN_SMART_LAYOUT_MARGIN_LEFT} - ${MAIN_SMART_LAYOUT_MARGIN_RIGHT});
  }
  ${IPHONE_SIZE} {
    bottom: ${MIRA_PAYMENT_LAYOUT_SMART_BUTTON_IPHONE_MARGIN_BOTTOM};
  }
`;
