import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { getRecessStatusAction } from '../sagas/recess/recessStatusSaga';
import { API_BASE_ADDRESS } from '../api';

// ユーザーメニューを取得します。
const recessStatusService = (action: getRecessStatusAction): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  return {
    getRecessStatus: () => axios.get(`${API_BASE_ADDRESS}/member/${action.memberId}/recess/status`, config),
  };
};

export default recessStatusService;
