import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import MyPageLayout from '../../components/Layouts/MyPage/MyPageLayout.index';

import CustomButton from '../../components/Common/Button/style';
import CustomButtonLabel from '../../components/Common/Button/Label';
import { ErrorMessageDescription, Layout } from './Unpaid.style';

import FW from '../../config/word.json';
import FS from '../../config/sentence.json';

import useAuthorizationNormal from '../../hooks/Auth/useAuthorizationNormal';
import useWindowSize from '../../hooks/useWindowSize';

import { UNPAID_OPERATION_CHECK_REQUEST } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { unpaidOperationCheckType } from '../../store/reducers/unpaidOperationCheck/unpaidOperationCheckReducer';

/**
 * 未納金の支払い説明欄画面
 * @returns 未納金の支払い説明欄のHTML
 */
const Unpaid: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // モバイルサイズを設定する
  const getIsMobile = () => window.innerWidth < 576;
  const isMobile = useWindowSize(getIsMobile);

  // ログイン認証を設定する
  useAuthorizationNormal();

  // 未納決済操作日チェックAPIのステータス
  const { unpaidOperationCheckResult } = useSelector<RootState, unpaidOperationCheckType>(
    (state) => state.unpaidOperationCheck,
  );

  const [errorMessage, setErrorMessage] = useState('');

  /**
   * 画面描画時の処理
   * 未納決済操作日チェックAPIを実行して以降の処理
   */
  useEffect(() => {
    if (unpaidOperationCheckResult.isLoaded) {
      // 未納決済操作日チェックAPIが成功の場合
      if (unpaidOperationCheckResult.result === -1) {
        // 初期化の場合
      } else if (unpaidOperationCheckResult.result === 0) {
        // 成功の場合
        history.push('/unpaid/payment');
      } else if (unpaidOperationCheckResult.result === 1) {
        // 異常の場合
        setErrorMessage(FS.F_S_UNPAID_OPERATION_RESULT_1);
      } else if (unpaidOperationCheckResult.result === 2) {
        // NG(ルミーズ、SBPS)の場合
        setErrorMessage(FS.F_S_UNPAID_OPERATION_RESULT_2);
      } else if (unpaidOperationCheckResult.result === 3) {
        // NG(JACCS)の場合
        setErrorMessage(FS.F_S_UNPAID_OPERATION_RESULT_3);
      } else if (unpaidOperationCheckResult.result === 4) {
        // NG(代位弁済)の場合
        setErrorMessage(FS.F_S_UNPAID_OPERATION_RESULT_4);
      } else if (unpaidOperationCheckResult.result === 5) {
        // NG(請求中)の場合
        setErrorMessage(FS.F_S_UNPAID_OPERATION_RESULT_5);
      } else {
        // その他の場合
        setErrorMessage(FS.F_S_UNPAID_OPERATION_RESULT_1);
      }
    }
  }, [unpaidOperationCheckResult.isLoaded, unpaidOperationCheckResult.result, history]);

  /**
   * 次へボタンを押下時の処理
   *   → 未納決済操作日チェックAPIを呼び出す
   */
  const onClickGo = useCallback(() => {
    const memberID = sessionStorage.getItem('memberID') ?? '';
    // 未納決済操作日チェックAPIを呼び出す
    dispatch({
      type: UNPAID_OPERATION_CHECK_REQUEST,
      memberID,
      history,
    });
  }, [dispatch, history]);

  return (
    <>
      <MyPageLayout>
        <Layout>
          {unpaidOperationCheckResult.result === -1 ? (
            <div>
              <div>
                {isMobile ? (
                  <h1>
                    {FS.F_S_UNPAID_TEXT_1}
                    <br /> {FS.F_S_UNPAID_TEXT_2}
                  </h1>
                ) : (
                  <h1>
                    {FS.F_S_UNPAID_TEXT_1} {FS.F_S_UNPAID_TEXT_2}
                  </h1>
                )}
                {isMobile ? (
                  <p>
                    {FS.F_S_UNPAID_TEXT_3}
                    <br /> {FS.F_S_UNPAID_TEXT_4}
                  </p>
                ) : (
                  <p>
                    {FS.F_S_UNPAID_TEXT_3}
                    {FS.F_S_UNPAID_TEXT_4}
                  </p>
                )}
                <div>
                  <h3>{FS.F_S_UNPAID_TEXT_5}</h3>
                  <span>{FS.F_S_UNPAID_TEXT_6}</span>
                </div>
                <ul>
                  <li>{FS.F_S_UNPAID_TEXT_7}</li>
                  <li>{FS.F_S_UNPAID_TEXT_8}</li>
                  <li>
                    {FS.F_S_UNPAID_TEXT_9}
                    <span>{FS.F_S_UNPAID_TEXT_10}</span>
                  </li>
                </ul>
                <section>
                  <div>
                    <div>
                      <ul>
                        <li>{FS.F_S_UNPAID_TEXT_11}</li>
                        <li>{FS.F_S_UNPAID_TEXT_12}</li>
                      </ul>
                    </div>
                    <div>
                      <p>{FS.F_S_UNPAID_TEXT_13}</p>
                      <p>{FS.F_S_UNPAID_TEXT_14}</p>
                    </div>
                  </div>
                  <ul>
                    <li>{FS.F_S_UNPAID_TEXT_15}</li>
                    <li>
                      <span>{FS.F_S_UNPAID_TEXT_16}</span>
                    </li>
                  </ul>
                </section>
              </div>

              <CustomButton type="button" move="go" location="left" onClick={onClickGo}>
                <CustomButtonLabel label={FW.F_W_GO_NEXT} arrowLocation="right" />
              </CustomButton>
            </div>
          ) : unpaidOperationCheckResult.result === 0 ? (
            <></>
          ) : (
            <>
              <ErrorMessageDescription>{errorMessage}</ErrorMessageDescription>
            </>
          )}
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Unpaid;
