import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';

import MyPageLayout from '../../../components/Layouts/MyPage/MyPageLayout.index';

import CustomButton from '../../../components/Common/Button/style';
import CustomButtonLabel from '../../../components/Common/Button/Label';
import { Layout } from './Recess.info.style';

import FW from '../../../config/word.json';
import FS from '../../../config/sentence.json';

import useAuthorizationNormal from '../../../hooks/Auth/useAuthorizationNormal';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * 休会説明欄画面
 * @returns 休会説明欄のHTML
 */
const Recess_Info: FC = () => {
  const history = useHistory();

  // モバイルサイズを設定する
  const getIsMobile = () => window.innerWidth < 576;
  const isMobile = useWindowSize(getIsMobile);

  // ログイン認証を設定する
  useAuthorizationNormal();

  /**
   * 次へボタンを押下時の処理
   *   → 未納決済操作日チェックAPIを呼び出す
   */
  const onClickGo = useCallback(() => {
    history.push('/recess/month-start');
  }, [history]);

  return (
    <>
      <MyPageLayout>
        <Layout>
          <div>
            <div>
              {isMobile ? (
                <h1>
                  {FS.F_S_RECESS_TEXT_2_1}
                  <br /> {FS.F_S_RECESS_TEXT_2_2}
                  <br /> {FS.F_S_RECESS_TEXT_2_3}
                </h1>
              ) : (
                <h1>
                  {FS.F_S_RECESS_TEXT_1_1}
                  <br /> {FS.F_S_RECESS_TEXT_1_2}
                </h1>
              )}
              <p>{FS.F_S_RECESS_TEXT_3}</p>
              <div>
                <h3>{FS.F_S_RECESS_TEXT_4}</h3>
                <span>{FS.F_S_RECESS_TEXT_5}</span>
              </div>
              <ul>
                <li>
                  <span>{FS.F_S_RECESS_TEXT_6}</span>
                  {FS.F_S_RECESS_TEXT_7}
                  <span>{FS.F_S_RECESS_TEXT_8}</span>
                  {FS.F_S_RECESS_TEXT_9}
                </li>
                <li>
                  <span>{FS.F_S_RECESS_TEXT_10}</span>
                  {FS.F_S_RECESS_TEXT_11}
                  <span>{FS.F_S_RECESS_TEXT_12}</span>
                </li>
                <li>{FS.F_S_RECESS_TEXT_13}</li>
                <li>
                  {FS.F_S_RECESS_TEXT_14}
                  <span>{FS.F_S_RECESS_TEXT_15}</span>
                  {FS.F_S_RECESS_TEXT_16}
                </li>
                <li>{FS.F_S_RECESS_TEXT_17}</li>
                <li>{FS.F_S_RECESS_TEXT_18}</li>
              </ul>
            </div>

            <CustomButton type="button" move="go" location="left" onClick={onClickGo}>
              <CustomButtonLabel label={FW.F_W_GO_NEXT} arrowLocation="right" />
            </CustomButton>
          </div>
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Recess_Info;
