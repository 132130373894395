import styled from '@emotion/styled/';
import {
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_LETTER_SPACING,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_LINE_HEIGHT,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_LETTER_SPACING,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_LINE_HEIGHT,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_PC_MARGIN_TOP_MINUS,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_PC_TABLET_MARGIN_TOP,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_SMART_HEIGHT,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_SMART_HEIGHT_MINUS,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TABLET_MARGIN_TOP_MINUS,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_LETTER_SPACING,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_LINE_HEIGHT,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_MARGIN_BOTTOM,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_LINE_HEIGHT,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_MARGIN_BOTTOM,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_LINE_HEIGHT,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_MARGIN_BOTTOM,
  MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_SMART_LETTER_SPACING,
} from '../../styles/size';
import { SMART_SIZE, TABLET_SIZE } from '../../styles/common';
import { BLACK } from '../../styles/color';

export const Layout = styled.div`
  margin-top: calc(
    ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_PC_TABLET_MARGIN_TOP} -
      ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_PC_MARGIN_TOP_MINUS}
  );
  display: flex;
  justify-content: center;
  text-align: center;
  ${TABLET_SIZE} {
    margin-top: calc(
      ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_PC_TABLET_MARGIN_TOP} -
        ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TABLET_MARGIN_TOP_MINUS}
    );
  }
  ${SMART_SIZE} {
    margin-top: auto;
    align-items: center;
    height: calc(
      ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_SMART_HEIGHT} -
        ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_SMART_HEIGHT_MINUS}
    );
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_FONT_SIZE};
  line-height: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_LINE_HEIGHT};
  letter-spacing: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_LETTER_SPACING};
  color: ${BLACK};
  font-weight: 700;
  margin-bottom: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_MARGIN_BOTTOM};
  ${TABLET_SIZE} {
    text-align: center;
    font-size: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_FONT_SIZE};
    line-height: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_LINE_HEIGHT};
    letter-spacing: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_SMART_LETTER_SPACING};
    margin-bottom: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_MARGIN_BOTTOM};
  }
  ${SMART_SIZE} {
    text-align: center;
    font-size: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_FONT_SIZE};
    line-height: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_LINE_HEIGHT};
    margin-bottom: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_MARGIN_BOTTOM};
  }
`;

export const Description = styled.div`
  font-size: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_FONT_SIZE};
  text-align: center;
  line-height: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_LINE_HEIGHT};
  letter-spacing: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_LETTER_SPACING};
  color: ${BLACK};
  ${TABLET_SIZE} {
    text-align: center;
    font-size: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_FONT_SIZE};
    line-height: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_LINE_HEIGHT};
    letter-spacing: ${MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_LETTER_SPACING};
    color: ${BLACK};
  }
`;
