import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';
import {
  COMMON_FAILURE,
  UNPAID_OPERATION_CHECK_FAILURE,
  UNPAID_OPERATION_CHECK_REQUEST,
  UNPAID_OPERATION_CHECK_SUCCESS,
} from '../../actions';

import { unpaidOperationCheckService } from '../../services';

export type unpaidActionType = {
  type: typeof UNPAID_OPERATION_CHECK_REQUEST;
  memberID: string;
  history: string[];
};

function* getUnpaidOperationCheck(action: unpaidActionType): Generator<unknown> {
  try {
    const res: any = yield call(unpaidOperationCheckService(action.memberID).getUnpaidOperationCheck);
    yield put({
      type: UNPAID_OPERATION_CHECK_SUCCESS,
      res,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: UNPAID_OPERATION_CHECK_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchGetUnpaid() {
  yield takeLatest(UNPAID_OPERATION_CHECK_REQUEST, getUnpaidOperationCheck);
}
export default function* unPaidOperationCheckSaga(): Generator<unknown> {
  yield all([fork(watchGetUnpaid)]);
}
