import produce from 'immer';
import { AnyAction } from 'redux';
import {
  GET_PASSWORD_STATUS_SUCCESS,
  GET_PASSWORD_STATUS_FAILURE,
  GET_PASSWORD_STATUS_REQUEST,
  GET_PASSWORD_STATUS_INITIAL,
} from '../../actions';

export type getPasswordStatusType = {
  getPasswordStatusResult: {
    isLoading: boolean;
    isLoaded: boolean;
    result: number;
    has_password: boolean;
  };
};

const initialState: getPasswordStatusType = {
  getPasswordStatusResult: {
    isLoading: false,
    isLoaded: false,
    result: -1,
    has_password: false,
  },
};

const getPasswordStatusReducer = (state = initialState, action: AnyAction): getPasswordStatusType => {
  return produce(state, (stateDraft) => {
    const draft = stateDraft;
    switch (action.type) {
      case GET_PASSWORD_STATUS_REQUEST:
        draft.getPasswordStatusResult.isLoading = true;
        draft.getPasswordStatusResult.isLoaded = false;
        draft.getPasswordStatusResult.result = -1;
        draft.getPasswordStatusResult.has_password = false;
        break;
      case GET_PASSWORD_STATUS_SUCCESS:
        draft.getPasswordStatusResult.isLoading = false;
        draft.getPasswordStatusResult.isLoaded = true;
        draft.getPasswordStatusResult.result = action.data.result;
        draft.getPasswordStatusResult.has_password = action.data.has_password;
        break;
      case GET_PASSWORD_STATUS_FAILURE:
        draft.getPasswordStatusResult.isLoading = false;
        draft.getPasswordStatusResult.isLoaded = false;
        draft.getPasswordStatusResult.result = -1;
        draft.getPasswordStatusResult.has_password = false;
        break;
      case GET_PASSWORD_STATUS_INITIAL:
        draft.getPasswordStatusResult.isLoading = false;
        draft.getPasswordStatusResult.isLoaded = false;
        draft.getPasswordStatusResult.result = -1;
        draft.getPasswordStatusResult.has_password = false;
        break;
      default:
        break;
    }
  });
};

export default getPasswordStatusReducer;
