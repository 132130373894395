import styled from '@emotion/styled';
import {
  MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM,
  MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH,
  MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH,
  MIRA_PAYMENT_LAYOUT_SMART_BUTTON_MARGIN_BOTTOM,
  MIRA_PAYMENT_LAYOUT_SMART_BUTTON_IPHONE_MARGIN_BOTTOM,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_WIDTH,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_HEIGHT,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_FONT_SIZE,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_WIDTH,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT,
  MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE,
  MIRA_HEADER_TABLET_PADDING_LEFT,
  MIRA_PAYMENT_LAYOUT_PC_BUTTON_MARGIN_TOP,
  MIRA_PAYMENT_LAYOUT_TABLET_BUTTON_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BUTTON_LINE_HEIGHT,
  MIRA_RECESS_LAYOUT_BUTTON_SMART_BOTTOM,
  MIRA_RECESS_LAYOUT_BUTTON_IPHONE_BOTTOM,
} from '../../../styles/size';
import { IPHONE_SIZE, SMART_SIZE, TABLET_SIZE } from '../../../styles/common';
import { BLACK } from '../../../styles/color';

export const Layout = styled.div`
  margin-top: ${MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP};
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: ${MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM};
  & > div {
    & > div {
      max-width: ${MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH};
      margin-left: auto;
      margin-right: auto;
      & > h1 {
        font-size: ${MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE};
        line-height: ${MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING};
        color: ${BLACK};
        font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
      }
    }
  }
  ${TABLET_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP};
    & > div {
      width: 100%;
      & > div {
        max-width: ${MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH};
        margin-left: auto;
        margin-right: auto;
        & > h1 {
          font-size: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING};
          color: ${BLACK};
          font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
        }
      }
    }
  }
  ${SMART_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP};
    & > div {
      width: 100%;
      & div {
        & > h1 {
          font-size: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING};
          color: ${BLACK};
          font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
        }
      }
    }
  }
`;

export const Buttonside = styled.div`
  & > button {
    margin-top: ${MIRA_PAYMENT_LAYOUT_PC_BUTTON_MARGIN_TOP};
    width: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_WIDTH};
    height: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_HEIGHT};
    font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_FONT_SIZE};
    line-height: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_LINE_HEIGHT};
    letter-spacing: 1px;
    font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
    border-radius: 40px;
    margin-left: ${MIRA_HEADER_TABLET_PADDING_LEFT};
  }
  ${TABLET_SIZE} {
    & > button {
      margin-top: ${MIRA_PAYMENT_LAYOUT_TABLET_BUTTON_MARGIN_TOP};
      width: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_WIDTH};
      height: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT};
      font-size: ${MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE};
      line-height: ${MIRA_UNPAID_LAYOUT_BUTTON_LINE_HEIGHT};
      letter-spacing: 0.7px;
      font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
    }
  }
  ${SMART_SIZE} {
    display: flex;
    -webkit-justify-content: center;
    bottom: ${MIRA_PAYMENT_LAYOUT_SMART_BUTTON_MARGIN_BOTTOM};
    & > button {
      position: fixed;
      bottom: ${MIRA_RECESS_LAYOUT_BUTTON_SMART_BOTTOM};
      margin-left: auto;
      margin-right: auto;
    }
  }
  ${IPHONE_SIZE} {
    bottom: ${MIRA_PAYMENT_LAYOUT_SMART_BUTTON_IPHONE_MARGIN_BOTTOM};
    & > button {
      position: fixed;
      bottom: ${MIRA_RECESS_LAYOUT_BUTTON_IPHONE_BOTTOM};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
