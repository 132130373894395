import React from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import FW from '../../../config/word.json';

export const SideBarItems = [
  {
    title: FW.F_W_HOME,
    path: '/home',
    label: 'home',
    icon: <img src="/images/icons/home_active.svg" alt="" />,
    clickedIcon: <img src="/images/icons/home.svg" alt="" />,
    exact: true,
    isSubTab: false,
    isCorporateMonthly: false,
    isCorporatePrivate: false,
    isActive: false,
    isBusinessType: false,
    isShowInSubTab: false,
  },
  {
    title: FW.F_W_REFERRAL_CODE,
    path: '/referralCode',
    label: 'referralCode',
    icon: <img src="/images/icons/add-user-active.svg" alt="" />,
    clickedIcon: <img src="/images/icons/add-user.svg" alt="" />,
    exact: true,
    isSubTab: false,
    isCorporateMonthly: true,
    isCorporatePrivate: true,
    isActive: true,
    isBusinessType: false,
    isShowInSubTab: false,
  },
  {
    title: FW.F_W_CREDIT_CARD_SWITCHING,
    path: '/creditcardSwitching',
    label: 'creditcardSwitching',
    icon: <img src="/images/icons/credit-card-active.svg" alt="" />,
    clickedIcon: <img src="/images/icons/credit-card.svg" alt="" />,
    exact: true,
    isSubTab: false,
    isCorporateMonthly: true,
    isCorporatePrivate: false,
    isActive: false,
    isBusinessType: false,
    isShowInSubTab: false,
  },
  {
    title: FW.F_W_UNPAID,
    path: '/unpaid',
    label: 'unpaid',
    icon: <img src="/images/icons/money-collect-active.svg" alt="" />,
    clickedIcon: <img src="/images/icons/money-collect.svg" alt="" />,
    exact: true,
    isSubTab: false,
    isCorporateMonthly: true,
    isCorporatePrivate: false,
    isActive: false,
    isBusinessType: false,
    isShowInSubTab: false,
  },
  {
    title: FW.F_W_OTHERS,
    smartTitle: FW.F_W_SUB_RESERVATION,
    path: '#',
    label: 'others',
    icon: <FiMoreHorizontal />,
    clickedIcon: <FiMoreHorizontal />,
    exact: true,
    isSubTab: true,
    isCorporateMonthly: false,
    isCorporatePrivate: false,
    isActive: false,
    isShowInSubTab: false,
    subItem: [
      {
        title: FW.F_W_SUB_RESERVATION,
        icon: <img src="/images/icons/calendar.svg" alt="" />,
        path: '#',
        exact: true,
        isSubBusinessType: true,
      },
      {
        title: FW.F_W_SUB_RECESS,
        icon: <img src="/images/icons/recess.svg" alt="" />,
        path: '/recess',
        exact: true,
        isSubBusinessType: false,
      },
    ],
  },
  {
    title: FW.F_W_SUB_RECESS,
    path: '/recess',
    label: 'recess',
    icon: <img src="/images/icons/recess.svg" alt="" />,
    clickedIcon: <img src="/images/icons/recess.svg" alt="" />,
    exact: true,
    isSubTab: false,
    isCorporateMonthly: false,
    isCorporatePrivate: false,
    isActive: false,
    isBusinessType: false,
    isShowInSubTab: true,
  },
  {
    title: FW.F_W_LOG_OUT,
    path: '/',
    label: 'logout',
    icon: <img src="/images/icons/log-out-active.svg" alt="" />,
    clickedIcon: <img src="/images/icons/log-out.svg" alt="" />,
    exact: true,
    isSubTab: false,
    isCorporateMonthly: false,
    isCorporatePrivate: false,
    isActive: false,
    isBusinessType: false,
    isShowInSubTab: false,
  },
];
