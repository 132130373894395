import produce from 'immer';
import { AnyAction } from 'redux';
import { GET_REFERRAL_CODE_REQUEST, GET_REFERRAL_CODE_SUCCESS, GET_REFERRAL_CODE_FAILURE } from '../../actions';

// タイプを設定
export type getReferralCodeType = {
  getReferralCodeResult: {
    isLoading: boolean;
    isLoaded: boolean;
    failureMessage: string;
    result: number;
    campaign_code: string;
  };
};

const initialState: getReferralCodeType = {
  getReferralCodeResult: {
    isLoading: false,
    isLoaded: false,
    failureMessage: '',
    result: -1,
    campaign_code: '',
  },
};

// APIの結果によってステイタスが更新される処理
const referralCodeReducer = (state = initialState, action: AnyAction): getReferralCodeType => {
  return produce(state, (dataState) => {
    const draft = dataState;
    switch (action.type) {
      case GET_REFERRAL_CODE_REQUEST:
        draft.getReferralCodeResult.isLoading = true;
        draft.getReferralCodeResult.isLoaded = false;
        draft.getReferralCodeResult.failureMessage = '';
        draft.getReferralCodeResult.campaign_code = '';
        draft.getReferralCodeResult.result = -1;
        break;
      case GET_REFERRAL_CODE_SUCCESS:
        draft.getReferralCodeResult.isLoading = false;
        draft.getReferralCodeResult.isLoaded = true;
        draft.getReferralCodeResult.failureMessage = action.data.message;
        draft.getReferralCodeResult.campaign_code = action.data.campaign_code;
        draft.getReferralCodeResult.result = action.data.result;
        break;
      case GET_REFERRAL_CODE_FAILURE:
        draft.getReferralCodeResult.isLoading = false;
        draft.getReferralCodeResult.isLoaded = false;
        draft.getReferralCodeResult.failureMessage = '';
        draft.getReferralCodeResult.campaign_code = '';
        draft.getReferralCodeResult.result = -1;
        break;
      default:
        break;
    }
  });
};

export default referralCodeReducer;
