import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { getShopButtonAction } from '../sagas/button/buttonSaga';
import { API_BASE_ADDRESS } from '../api';

// 店舗ボタンを取得します。
const shopButtonServices = (action: getShopButtonAction): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  return {
    getShopButton: () => axios.get(`${API_BASE_ADDRESS}/shop/${action.shopId}/button/${action.buttonId}`, config),
  };
};

export default shopButtonServices;
