import styled from '@emotion/styled';
import { SMART_SIZE } from '../../../styles/common';
import {
  MAIN_FULL_WIDTH,
  MAIN_LAYOUT_MIN_HEIGHT,
  MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT,
  MAIN_LAYOUT_PADDING_BOTTOM,
  MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT,
  MAIN_SMART_LAYOUT_MARGIN_LEFT,
  MAIN_SMART_LAYOUT_MARGIN_RIGHT,
} from '../../../styles/size';

export const MyPageLayoutWrapper = styled.div`
  height: ${MAIN_FULL_WIDTH};
  min-height: ${MAIN_LAYOUT_MIN_HEIGHT};
  position: relative;
`;

export const CommonLayout = styled.div`
  // padding-bottom: ${MAIN_LAYOUT_PADDING_BOTTOM};
  margin-left: ${MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT};
  margin-right: ${MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT};
  ${SMART_SIZE} {
    margin-left: ${MAIN_SMART_LAYOUT_MARGIN_LEFT};
    margin-right: ${MAIN_SMART_LAYOUT_MARGIN_RIGHT};
  }
`;

export const CommonBlockBox = styled.div`
  text-align: center;
`;
