import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import { HOME_REQUEST, HOME_SUCCESS, HOME_FAILURE, COMMON_FAILURE } from '../../actions';
import { homeService } from '../../services';

export type homeActionType = {
  type: typeof HOME_REQUEST;
  memberID: string;
  history: string[];
};

function* getHome(action: homeActionType): Generator<unknown> {
  try {
    const res: any = yield call(homeService(action.memberID).getHome);
    yield put({
      type: HOME_SUCCESS,
      res,
    });
    sessionStorage.setItem('shopId', res.data.shop_id.toString());
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: HOME_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchGetHome() {
  yield takeLatest(HOME_REQUEST, getHome);
}
export default function* homeSaga(): Generator<unknown> {
  yield all([fork(watchGetHome)]);
}
