export const COMMON_FAILURE = 'COMMON_FAILURE' as const;

export const LOG_IN_REQUEST = 'LOG_IN_REQUEST' as const;
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS' as const;
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE' as const;
export const LOG_IN_INITIAL = 'LOG_IN_INITIAL' as const;

export const HOME_REQUEST = 'HOME_REQUEST' as const;
export const HOME_SUCCESS = 'HOME_SUCCESS' as const;
export const HOME_FAILURE = 'HOME_FAILURE' as const;
export const HOME_INITIAL = 'HOME_INITIAL' as const;

// 87_コード払出API
export const GET_REFERRAL_CODE_REQUEST = 'GET_REFERRAL_CODE_REQUEST' as const;
export const GET_REFERRAL_CODE_SUCCESS = 'GET_REFERRAL_CODE_SUCCESS' as const;
export const GET_REFERRAL_CODE_FAILURE = 'GET_REFERRAL_CODE_FAILURE' as const;

export const REFERRAL_CODE_REQUEST = 'REFERRAL_CODE_REQUEST' as const;
export const REFERRAL_CODE_SUCCESS = 'REFERRAL_CODE_SUCCESS' as const;
export const REFERRAL_CODE_FAILURE = 'REFERRAL_CODE_FAILURE' as const;

export const UNPAID_REQUEST = 'UNPAID_REQUEST' as const;
export const UNPAID_SUCCESS = 'UNPAID_SUCCESS' as const;
export const UNPAID_FAILURE = 'UNPAID_FAILURE' as const;

export const UNPAID_OPERATION_CHECK_REQUEST = 'UNPAID_OPERATION_CHECK_REQUEST' as const;
export const UNPAID_OPERATION_CHECK_SUCCESS = 'UNPAID_OPERATION_CHECK_SUCCESS' as const;
export const UNPAID_OPERATION_CHECK_FAILURE = 'UNPAID_OPERATION_CHECK_FAILURE' as const;
export const UNPAID_OPERATION_CHECK_INITIAL = 'UNPAID_OPERATION_CHECK_INITIAL' as const;

export const PAYMENT_REQUEST = 'PAYMENT_REQUEST' as const;
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS' as const;
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE' as const;

export const CUSTOMER_ID_REQUEST = 'CUSTOMER_ID_REQUEST' as const;
export const CUSTOMER_ID_SUCCESS = 'CUSTOMER_ID_SUCCESS' as const;
export const CUSTOMER_ID_FAILURE = 'CUSTOMER_ID_FAILURE' as const;
export const CUSTOMER_ID_INITIAL = 'CUSTOMER_ID_INITIAL' as const;

// 30_クレカ切り替え操作日チェックAPI
export const GET_CREDIT_CHANGE_CHECK_REQUEST = 'GET_CREDIT_CHANGE_CHECK_REQUEST' as const;
export const GET_CREDIT_CHANGE_CHECK_SUCCESS = 'GET_CREDIT_CHANGE_CHECK_SUCCESS' as const;
export const GET_CREDIT_CHANGE_CHECK_FAILURE = 'GET_CREDIT_CHANGE_CHECK_FAILURE' as const;
export const GET_CREDIT_CHANGE_CHECK_INITIAL = 'GET_CREDIT_CHANGE_CHECK_INITIAL' as const;

// 89_パスワードの変更
export const PUT_PASSWORD_REQUEST = 'PUT_PASSWORD_REQUEST' as const;
export const PUT_PASSWORD_SUCCESS = 'PUT_PASSWORD_SUCCESS' as const;
export const PUT_PASSWORD_FAILURE = 'PUT_PASSWORD_FAILURE' as const;
export const PUT_PASSWORD_INITIAL = 'PUT_PASSWORD_INITIAL' as const;

// 90_メールアドレス変更メール送信
export const POST_SEND_MAIL_REQUEST = 'POST_SEND_MAIL_REQUEST' as const;
export const POST_SEND_MAIL_SUCCESS = 'POST_SEND_MAIL_SUCCESS' as const;
export const POST_SEND_MAIL_FAILURE = 'POST_SEND_MAIL_FAILURE' as const;
export const POST_SEND_MAIL_INITIAL = 'POST_SEND_MAIL_INITIAL' as const;

// 91_メールアドレス変更
export const PUT_MAIL_ADDRESS_REQUEST = 'PUT_MAIL_ADDRESS_REQUEST' as const;
export const PUT_MAIL_ADDRESS_SUCCESS = 'PUT_MAIL_ADDRESS_SUCCESS' as const;
export const PUT_MAIL_ADDRESS_FAILURE = 'PUT_MAIL_ADDRESS_FAILURE' as const;
export const PUT_MAIL_ADDRESS_INITIAL = 'PUT_MAIL_ADDRESS_INITIAL' as const;

// 106_マイページパスワード設定状態チェック
export const GET_PASSWORD_STATUS_REQUEST = 'GET_PASSWORD_STATUS_REQUEST' as const;
export const GET_PASSWORD_STATUS_SUCCESS = 'GET_PASSWORD_STATUS_SUCCESS' as const;
export const GET_PASSWORD_STATUS_FAILURE = 'GET_PASSWORD_STATUS_FAILURE' as const;
export const GET_PASSWORD_STATUS_INITIAL = 'GET_PASSWORD_STATUS_INITIAL' as const;

// 26_メニュー取得API
export const GET_MEMBER_MENU_REQUEST = 'GET_MEMBER_MENU_REQUEST' as const;
export const GET_MEMBER_MENU_SUCCESS = 'GET_MEMBER_MENU_SUCCESS' as const;
export const GET_MEMBER_MENU_FAILURE = 'GET_MEMBER_MENU_FAILURE' as const;
export const GET_MEMBER_MENU_INITIAL = 'GET_MEMBER_MENU_INITIAL' as const;

// 104.メニュー利用可否チェックAPI
export const GET_SHOP_BUTTON_REQUEST = 'GET_SHOP_BUTTON_REQUEST' as const;
export const GET_SHOP_BUTTON_SUCCESS = 'GET_SHOP_BUTTON_SUCCESS' as const;
export const GET_SHOP_BUTTON_FAILURE = 'GET_SHOP_BUTTON_FAILURE' as const;
export const GET_SHOP_BUTTON_INITIAL = 'GET_SHOP_BUTTON_INITIAL' as const;

// 29.休会申込状態取得API
export const GET_RECESS_STATUS_REQUEST = 'GET_RECESS_STATUS_REQUEST' as const;
export const GET_RECESS_STATUS_SUCCESS = 'GET_RECESS_STATUS_SUCCESS' as const;
export const GET_RECESS_STATUS_FAILURE = 'GET_RECESS_STATUS_FAILURE' as const;
export const GET_RECESS_STATUS_INITIAL = 'GET_RECESS_STATUS_INITIAL' as const;

// 105.会員管理移籍処理チェックAPI
export const GET_MEMBER_TRANSFER_STATUS_REQUEST = 'GET_MEMBER_TRANSFER_STATUS_REQUEST' as const;
export const GET_MEMBER_TRANSFER_STATUS_SUCCESS = 'GET_MEMBER_TRANSFER_STATUS_SUCCESS' as const;
export const GET_MEMBER_TRANSFER_STATUS_FAILURE = 'GET_MEMBER_TRANSFER_STATUS_FAILURE' as const;
export const GET_MEMBER_TRANSFER_STATUS_INITIAL = 'GET_MEMBER_TRANSFER_STATUS_INITIAL' as const;

// 38.休会申込API
export const POST_RECESS_REQUEST = 'POST_RECESS_REQUEST' as const;
export const POST_RECESS_SUCCESS = 'POST_RECESS_SUCCESS' as const;
export const POST_RECESS_FAILURE = 'POST_RECESS_FAILURE' as const;
export const POST_RECESS_INITIAL = 'POST_RECESS_INITIAL' as const;

// 39.休会申込取消API
export const POST_RECESS_CANCEL_REQUEST = 'POST_RECESS_CANCEL_REQUEST' as const;
export const POST_RECESS_CANCEL_SUCCESS = 'POST_RECESS_CANCEL_SUCCESS' as const;
export const POST_RECESS_CANCEL_FAILURE = 'POST_RECESS_CANCEL_FAILURE' as const;
export const POST_RECESS_CANCEL_INITIAL = 'POST_RECESS_CANCEL_INITIAL' as const;
