import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Cards, Layout } from './ReferralCode.style';

import FS from '../../config/sentence.json';

import MyPageLayout from '../../components/Layouts/MyPage/MyPageLayout.index';

import { RootState } from '../../store/reducers';
import { getReferralCodeType } from '../../store/reducers/referralCode/referralCodeReducer';
import { GET_REFERRAL_CODE_REQUEST } from '../../store/actions';

import useAuthorizationNormal from '../../hooks/Auth/useAuthorizationNormal';
import useWindowSize from '../../hooks/useWindowSize';

const ReferralCode: FC = () => {
  // カスタマイズのhooksを取得する
  const dispatch = useDispatch();
  const history = useHistory();
  const getIsMobile = () => window.innerWidth < 576;
  const isMobile = useWindowSize(getIsMobile);
  // APIの処理のデータを取得する
  const { getReferralCodeResult } = useSelector<RootState, getReferralCodeType>((state) => state.referralCode);
  // ローカルの変数を設定する
  const [code, setCode] = useState('');

  // ログイン認証を設定する
  useAuthorizationNormal();

  useEffect(() => {
    const introductionCode = sessionStorage.getItem('introductionCode') ?? '';
    /**
     * 紹介コードの値をなかったら、コード払出しAPIを呼出し
     */
    if (introductionCode === '' || introductionCode === 'null') {
      dispatch({
        type: GET_REFERRAL_CODE_REQUEST,
        memberID: sessionStorage.getItem('memberID'),
        history,
      });
    } else {
      setCode(introductionCode);
    }
  }, [dispatch, history, code]);

  return (
    <>
      <MyPageLayout>
        <Layout>
          {getReferralCodeResult.isLoaded && getReferralCodeResult.result === 0 && (
            <div>
              <div>
                <p>{FS.F_S_REFERRALCODE_TITLE}</p>
                {getReferralCodeResult.isLoaded ? (
                  <h1>
                    {getReferralCodeResult.campaign_code.substring(0, 4)}-
                    {getReferralCodeResult.campaign_code.substring(4, 8)}-
                    {getReferralCodeResult.campaign_code.substring(8, 12)}
                  </h1>
                ) : code !== '' && code !== 'null' ? (
                  <h1>
                    {code.substring(0, 4)}-{code.substring(4, 8)}-{code.substring(8, 12)}
                  </h1>
                ) : (
                  <h1>
                    {code.substring(0, 4)}-{code.substring(4, 8)}-{code.substring(8, 12)}
                  </h1>
                )}
              </div>
              {isMobile ? (
                <h1>
                  {FS.F_S_REFERRALCODE_TEXT_1} <br />
                  <span>{FS.F_S_REFERRALCODE_TEXT_2}</span>
                </h1>
              ) : (
                <h1>
                  {FS.F_S_REFERRALCODE_TEXT_1}
                  <span>{FS.F_S_REFERRALCODE_TEXT_2}</span>
                </h1>
              )}
              {isMobile ? (
                <p>
                  {FS.F_S_REFERRALCODE_DESCRIPTION_TEXT_1} <br /> {FS.F_S_REFERRALCODE_DESCRIPTION_TEXT_2}
                </p>
              ) : (
                <p>
                  {FS.F_S_REFERRALCODE_DESCRIPTION_TEXT_1}
                  {FS.F_S_REFERRALCODE_DESCRIPTION_TEXT_2}
                </p>
              )}
            </div>
          )}
          {getReferralCodeResult.isLoaded && getReferralCodeResult.result === 1 && (
            <span>
              <p>
                {FS.F_S_REFERRALCODE_ERROR_TEXT_1} <br /> {FS.F_S_REFERRALCODE_ERROR_TEXT_2}
              </p>
            </span>
          )}
          {getReferralCodeResult.isLoaded && getReferralCodeResult.result === 2 && (
            <span>
              <p>{FS.F_S_REFERRALCODE_ERROR_TEXT_3}</p>
            </span>
          )}
          {getReferralCodeResult.isLoaded && getReferralCodeResult.result === 3 && (
            <span>
              <p>{FS.F_S_REFERRALCODE_ERROR_TEXT_4}</p>
            </span>
          )}
          {!getReferralCodeResult.isLoaded && code !== '' && code !== 'null' && (
            <div>
              <div>
                <p>{FS.F_S_REFERRALCODE_TITLE}</p>
                {getReferralCodeResult.isLoaded ? (
                  <h1>
                    {getReferralCodeResult.campaign_code.substring(0, 4)}-
                    {getReferralCodeResult.campaign_code.substring(4, 8)}-
                    {getReferralCodeResult.campaign_code.substring(8, 12)}
                  </h1>
                ) : code !== '' && code !== 'null' ? (
                  <h1>
                    {code.substring(0, 4)}-{code.substring(4, 8)}-{code.substring(8, 12)}
                  </h1>
                ) : (
                  <h1>
                    {code.substring(0, 4)}-{code.substring(4, 8)}-{code.substring(8, 12)}
                  </h1>
                )}
              </div>
              {isMobile ? (
                <h1>
                  {FS.F_S_REFERRALCODE_TEXT_1} <br />
                  <span>{FS.F_S_REFERRALCODE_TEXT_2}</span>
                </h1>
              ) : (
                <h1>
                  {FS.F_S_REFERRALCODE_TEXT_1}
                  <span>{FS.F_S_REFERRALCODE_TEXT_2}</span>
                </h1>
              )}
              {isMobile ? (
                <p>
                  {FS.F_S_REFERRALCODE_DESCRIPTION_TEXT_1} <br /> {FS.F_S_REFERRALCODE_DESCRIPTION_TEXT_2}
                </p>
              ) : (
                <p>
                  {FS.F_S_REFERRALCODE_DESCRIPTION_TEXT_1}
                  {FS.F_S_REFERRALCODE_DESCRIPTION_TEXT_2}
                </p>
              )}
            </div>
          )}
        </Layout>
        {(getReferralCodeResult.isLoaded && getReferralCodeResult.result === 0) ||
        (!getReferralCodeResult.isLoaded && code !== '' && code !== 'null') ? (
          <Cards>
            {isMobile ? (
              <h3>
                {FS.F_S_REFERRALCODE_TITLE_TEXT_1} <br />
                {FS.F_S_REFERRALCODE_TITLE_TEXT_2} <br />
                {FS.F_S_REFERRALCODE_TITLE_TEXT_3}
              </h3>
            ) : (
              <h3>
                {FS.F_S_REFERRALCODE_TITLE_TEXT_1}
                {FS.F_S_REFERRALCODE_TITLE_TEXT_2}
                {FS.F_S_REFERRALCODE_TITLE_TEXT_3}
              </h3>
            )}
            <div>
              <div>
                <h3>{FS.F_S_REFERRALCODE_BOX_TITLE_TEXT_1}</h3>
                <h1>
                  {FS.F_S_REFERRALCODE_BOX_RED_TEXT_1}
                  <br /> {FS.F_S_REFERRALCODE_BOX_RED_TEXT_2}
                </h1>
                <p>
                  {FS.F_S_REFERRALCODE_BOX_DESCRIPTION_TEXT_1}
                  <br /> {FS.F_S_REFERRALCODE_BOX_DESCRIPTION_TEXT_2}
                </p>
              </div>
              <div>
                <h3>{FS.F_S_REFERRALCODE_BOX_TITLE_TEXT_2}</h3>
                <h1>
                  {FS.F_S_REFERRALCODE_BOX_RED_TEXT_3} <br /> {FS.F_S_REFERRALCODE_BOX_RED_TEXT_4}
                </h1>
                <p>
                  {FS.F_S_REFERRALCODE_BOX_DESCRIPTION_TEXT_3} <br /> {FS.F_S_REFERRALCODE_BOX_DESCRIPTION_TEXT_4}
                </p>
              </div>
            </div>
            <ul>
              <li>
                <span>{FS.F_S_REFERRALCODE_RECOMMENDATIONS_TEXT1}</span>
                {FS.F_S_REFERRALCODE_RECOMMENDATIONS_TEXT2} <br />
              </li>
              <li>
                {FS.F_S_REFERRALCODE_RECOMMENDATIONS_TEXT3} <span>{FS.F_S_REFERRALCODE_RECOMMENDATIONS_TEXT4}</span>
                {FS.F_S_REFERRALCODE_RECOMMENDATIONS_TEXT5}
              </li>
            </ul>
          </Cards>
        ) : (
          <></>
        )}
      </MyPageLayout>
    </>
  );
};

export default ReferralCode;
