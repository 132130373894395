import produce from 'immer';
import { AnyAction } from 'redux';
import { CUSTOMER_ID_SUCCESS, CUSTOMER_ID_FAILURE, CUSTOMER_ID_REQUEST, CUSTOMER_ID_INITIAL } from '../../actions';

// タイプを設定
export type customerIdStateType = {
  isCustomerIdLoading: boolean;
  isCustomerIdLoaded: boolean;
  customerIdData: customerIdType;
};

type customerIdType = {
  customer_id: string;
};

// ステイタスを初期化
const initialState: customerIdStateType = {
  isCustomerIdLoading: false,
  isCustomerIdLoaded: false,
  customerIdData: {
    customer_id: '',
  },
};

// APIの結果によってステイタスが更新される処理
const customerIdReducer = (state = initialState, action: AnyAction): customerIdStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case CUSTOMER_ID_REQUEST:
        dataState.isCustomerIdLoading = true;
        dataState.isCustomerIdLoaded = false;
        dataState.customerIdData.customer_id = '';
        break;
      case CUSTOMER_ID_SUCCESS:
        dataState.isCustomerIdLoading = false;
        dataState.isCustomerIdLoaded = true;
        dataState.customerIdData.customer_id = action.res.data.customer_id;
        break;
      case CUSTOMER_ID_FAILURE:
        dataState.isCustomerIdLoading = false;
        dataState.isCustomerIdLoaded = false;
        dataState.customerIdData.customer_id = '';
        break;
      case CUSTOMER_ID_INITIAL:
        dataState.isCustomerIdLoading = false;
        dataState.isCustomerIdLoaded = false;
        dataState.customerIdData.customer_id = '';
        break;
      default:
        break;
    }
  });
};

export default customerIdReducer;
