import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';
import { PAYMENT_REQUEST, PAYMENT_SUCCESS, PAYMENT_FAILURE, COMMON_FAILURE } from '../../actions';

import { paymentService } from '../../services';

export type paymentActionType = {
  type: typeof PAYMENT_REQUEST;
  paymentType: string;
  customerId: string;
  receiptNumber: string;
  shopId: string;
  unpaidId: string;
  amount: number;
  history: string[];
};

function* postPayment(action: paymentActionType): Generator<unknown> {
  try {
    const res = yield call(paymentService(action).postPayment);
    yield put({
      type: PAYMENT_SUCCESS,
      res,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400) {
        action.history.push('/unpaid/payment');
      } else {
        action.history.push('/systemError');
      }
      yield put({
        type: PAYMENT_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
  }
}

function* watchPostPayment() {
  yield takeLatest(PAYMENT_REQUEST, postPayment);
}
export default function* unPaidSaga(): Generator<unknown> {
  yield all([fork(watchPostPayment)]);
}
