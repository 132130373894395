import React, { FC, memo, useCallback, useState } from 'react';
import { Route } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ModalBody } from 'react-bootstrap';

import { SideBarItems } from '../../MyPageSideBarItems';
import {
  SubMenuItem,
  MenuLink,
  ScrollContainer,
  Layout,
  Content,
  SubItemTabLayout,
  SubItemTabBackground,
  SubItemTabCloseIcon,
  TabItem,
  ReservationFooterModal,
  ReservationFooterModalText,
  ReservationFooterModalButtonLayout,
  ReservationFooterModalButton,
  TabItemLayout,
  TabItemSVG,
} from './MyPageMenuBar.style';
import { CONTENT_REF } from '../../../../../index';

import {
  GET_CREDIT_CHANGE_CHECK_REQUEST,
  GET_MEMBER_MENU_INITIAL,
  GET_MEMBER_MENU_REQUEST,
  GET_MEMBER_TRANSFER_STATUS_INITIAL,
  GET_RECESS_STATUS_INITIAL,
  GET_SHOP_BUTTON_INITIAL,
  HOME_INITIAL,
  LOG_IN_INITIAL,
  UNPAID_OPERATION_CHECK_INITIAL,
} from '../../../../../store/actions';
import FS from '../../../../../config/sentence.json';

interface ISubItem {
  title: string;
  icon: JSX.Element;
  path: string;
  exact: boolean;
  isSubBusinessType: boolean;
}
interface Props {
  title: string;
  to: string;
  activeOnlyWhenExact: boolean;
  icon: JSX.Element;
  clickedIcon: JSX.Element;
  onClickShowSubItem: () => void;
  isSubTab: boolean;
  label: string;
  subItem: ISubItem[] | undefined;
}

const CustomMenuLink = memo(
  ({ title, to, activeOnlyWhenExact, icon, clickedIcon, onClickShowSubItem, isSubTab, label, subItem }: Props) => {
    const url = useLocation().pathname;
    const dispatch = useDispatch();
    const history = useHistory();
    // const businessType = sessionStorage.getItem('businessType') ?? '';

    // ログアウトのボタンを押下時にセッションストレージをクリアする
    const onClickMenuItem = (toPage: string) => () => {
      if (toPage === '/') {
        // ログアウトをクリックすると、セッションストレージをクリアする
        dispatch({
          type: LOG_IN_INITIAL,
        });
        dispatch({
          type: HOME_INITIAL,
        });
        sessionStorage.clear();
      } else if (toPage === '/home') {
        dispatch({
          type: HOME_INITIAL,
        });
      } else if (toPage === '/creditcardSwitching') {
        // クレカ切替操作日チェックAPIを呼出し
        dispatch({
          type: GET_CREDIT_CHANGE_CHECK_REQUEST,
          member_id: sessionStorage.getItem('memberID'),
          history,
        });
      } else if (toPage === '/unpaid') {
        dispatch({
          type: UNPAID_OPERATION_CHECK_INITIAL,
        });
      }
    };

    return (
      <Route path={to} exact={activeOnlyWhenExact}>
        {({ match }) => {
          const active = url.includes(to) && to !== '/' ? 'active' : '';
          return (
            <>
              {isSubTab ? (
                <>
                  {subItem && subItem.length > 0 ? (
                    <>
                      <SubMenuItem>
                        <MenuLink to={to} isclickedactive={active} onClick={onClickShowSubItem}>
                          <span onClick={onClickMenuItem(to)} aria-hidden="true">
                            {active ? icon : clickedIcon}
                            <span>{title}</span>
                          </span>
                        </MenuLink>
                      </SubMenuItem>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <SubMenuItem>
                  <MenuLink to={to} isclickedactive={active}>
                    <span onClick={onClickMenuItem(to)} aria-hidden="true">
                      {active ? icon : clickedIcon}
                      <span>{title}</span>
                    </span>
                  </MenuLink>
                </SubMenuItem>
              )}
            </>
          );
        }}
      </Route>
    );
  },
);

/**
 * フッターのメニューのコンポーネント
 * @returns フッターのメニューのコンポーネント
 */
const MenuBar: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const [isMobileModal, setIsMobileModal] = useState(false);

  const typeCode = sessionStorage.getItem('typeCode');
  const price = sessionStorage.getItem('price');
  const isSessionStorageActive = sessionStorage.getItem('isActive') ?? '';
  const businessType = sessionStorage.getItem('businessType') ?? '';
  let subItemCount = 0;

  const onClickShowSubItem = () => {
    setSidebar(!sidebar);
  };

  const onClickTabItem = useCallback(() => {
    setIsMobileModal(true);
    setSidebar(false);
  }, []);

  // ログアウトのボタンを押下時にセッションストレージをクリアする
  const onClickTabMenuItem = (toPage: string) => () => {
    setSidebar(false);
    if (toPage === '/recess') {
      // 休会をクリックすると、会員メニュー・店舗ボタン・休会状態・移籍状態APIを初期化
      dispatch({
        type: GET_MEMBER_MENU_INITIAL,
      });
      dispatch({
        type: GET_SHOP_BUTTON_INITIAL,
      });
      dispatch({
        type: GET_RECESS_STATUS_INITIAL,
      });
      dispatch({
        type: GET_MEMBER_TRANSFER_STATUS_INITIAL,
      });
      // 会員メニューを取得する
      dispatch({
        type: GET_MEMBER_MENU_REQUEST,
        memberId: sessionStorage.getItem('memberID') ?? '',
        shopId: sessionStorage.getItem('shopID') ?? '',
        history,
      });
      // 休会ページへ
      history.push(toPage);
    }
  };

  const onHideModalClose = useCallback(() => {
    setIsMobileModal(false);
  }, []);

  const onClickButtonModal = useCallback(
    (data: number) => () => {
      if (data === 0) {
        window.open('https://mirafitness.hacomono.jp');
        setIsMobileModal(false);
      } else {
        setIsMobileModal(false);
      }
    },
    [],
  );

  // サブメニューの数を算出する
  for (let i = 0; i < SideBarItems.length; i += 1) {
    if (
      SideBarItems[i].isSubTab === true &&
      SideBarItems[i].subItem !== undefined &&
      SideBarItems[i].subItem !== null
    ) {
      const subItems = SideBarItems[i].subItem ?? [];
      for (let j = 0; j < subItems.length; j += 1) {
        if (subItems[j].isSubBusinessType === true && businessType === '2') {
          subItemCount += 1;
        } else if (subItems[j].isSubBusinessType === false) {
          subItemCount += 1;
        }
      }
    }
  }

  return (
    <>
      {isMobileModal ? (
        <>
          <ReservationFooterModal show={isMobileModal} onHide={onHideModalClose}>
            <ModalBody>
              <ReservationFooterModalText>
                {FS.F_S_UNPAID_RESERVATION_TEXT_1}
                <br />
                {FS.F_S_UNPAID_RESERVATION_TEXT_2}
              </ReservationFooterModalText>
              <ReservationFooterModalButtonLayout>
                <ReservationFooterModalButton
                  type="button"
                  onClick={onClickButtonModal(0)}
                  style={{ marginRight: '3px' }}
                >
                  はい
                </ReservationFooterModalButton>
                <ReservationFooterModalButton
                  type="button"
                  onClick={onClickButtonModal(1)}
                  style={{ marginLeft: '3px' }}
                >
                  いいえ
                </ReservationFooterModalButton>
              </ReservationFooterModalButtonLayout>
            </ModalBody>
          </ReservationFooterModal>
        </>
      ) : (
        <></>
      )}
      <Layout sidebar={sidebar} ref={CONTENT_REF}>
        <Content>
          <ScrollContainer>
            {SideBarItems.map((item) => {
              return (
                <React.Fragment key={item.title}>
                  {/* 会員区分コードの値が【1】又は【7】ではなかったら、「isJoinedCorporation===true」であるメニューは表示しない */}
                  <>
                    {/* isJoinedCorporationの値がtrueだったら、空っぽにする */}
                    {((typeCode === '60' || (typeCode === '70' && price === '0')) && item.isCorporateMonthly) ||
                    (typeCode === '70' && price !== '0' && item.isCorporatePrivate) ||
                    (isSessionStorageActive === 'false' && item.isCorporatePrivate) ||
                    item.isShowInSubTab ? (
                      <></>
                    ) : (
                      <>
                        <CustomMenuLink
                          activeOnlyWhenExact={item.exact}
                          to={item.path}
                          title={item.title}
                          icon={item.icon}
                          clickedIcon={item.clickedIcon}
                          onClickShowSubItem={onClickShowSubItem}
                          isSubTab={item.isSubTab}
                          label={item.label}
                          subItem={item.subItem}
                        />
                        {/* Subのタブが次のリリースに追加したら、下のコメントを解除する */}
                        {sidebar ? (
                          item.subItem ? (
                            <>
                              <SubItemTabLayout subItemTabCount={subItemCount}>
                                <SubItemTabBackground subItemTabCount={subItemCount} subItemTabHeight={70} />
                                <SubItemTabCloseIcon onClick={onClickShowSubItem} />
                                {item.subItem?.map((subItem, index) => {
                                  return (
                                    <>
                                      {businessType === '2' && subItem.isSubBusinessType ? (
                                        <React.Fragment key={`subItemNewBusiness-${index.toString()}-${subItem}`}>
                                          <TabItemLayout onClick={onClickTabItem}>
                                            <TabItemSVG>{subItem.icon}</TabItemSVG>
                                            <TabItem>{subItem.title}</TabItem>
                                          </TabItemLayout>
                                        </React.Fragment>
                                      ) : (
                                        <>
                                          {subItem.isSubBusinessType === false ? (
                                            <React.Fragment key={`subItem-${index.toString()}-${subItem}`}>
                                              <TabItemLayout onClick={onClickTabMenuItem(subItem.path)}>
                                                <TabItemSVG>{subItem.icon}</TabItemSVG>
                                                <TabItem>{subItem.title}</TabItem>
                                              </TabItemLayout>
                                            </React.Fragment>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </SubItemTabLayout>
                            </>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                </React.Fragment>
              );
            })}
          </ScrollContainer>
        </Content>
      </Layout>
    </>
  );
};

export default memo(MenuBar);
