import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { postLoginAction } from '../sagas/login/loginSaga';
import { API_BASE_ADDRESS } from '../api';

// APIの住所を設定します。

const loginServices = (action: postLoginAction): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  let data = {};
  if (action.loginClass === 0) {
    data = {
      member_id: action.memberShip,
      mobile_number: action.mobileNumber,
      birthday: action.birthday,
      login_class: action.loginClass,
    };
  } else if (action.loginClass === 1) {
    data = {
      login_class: action.loginClass,
      mail_address: action.mail_address,
      password: action.password,
    };
  } else {
    data = {};
  }

  return {
    postLogin: () => axios.post(`${API_BASE_ADDRESS}/mypage/login`, data, config),
  };
};

export default loginServices;
