import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { postRecessAction } from '../sagas/recess/recessSaga';
import { API_BASE_ADDRESS } from '../api';

// 休会を取得実行します。
const recessService = (action: postRecessAction): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  const postData = {
    member_id: action.memberId,
    shop_id: action.shopId,
    start_month: action.startMonth,
    end_month: action.endMonth,
  };

  return {
    postRecess: () => axios.post(`${API_BASE_ADDRESS}/member/recess`, postData, config),
  };
};

export default recessService;
