import React, { FC, memo } from 'react';

import useWindowSize from '../../../../hooks/useWindowSize';
import PCHeader from './PCHeader/MyPagePCHeader.index';
import MobileHeader from './MobileHeader/MyPageMobileHeader.index';

const Header: FC = () => {
  const getIsMobile = () => window.innerWidth < 576;
  const isMobile = useWindowSize(getIsMobile);
  return <>{isMobile ? <MobileHeader /> : <PCHeader />}</>;
};

export default memo(Header);
