import { combineReducers } from 'redux';
import postSendMailReducer from './postSendMailReducer';
import putMailAddressReducer from './putMailAddressReducer';

const index = combineReducers({
  postSendMail: postSendMailReducer,
  putMailAddress: putMailAddressReducer,
});

export default index;
