import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { SMART_SIZE, TABLET_SIZE } from '../../../../styles/common';
import { BLACK, RED } from '../../../../styles/color';
import {
  FINTNESS_TABLET_SMART_LOGO_FONT_SIZE,
  MIRA_TABLET_SMART_LOGO_FONT_SIZE,
  FINTNESS_PC_LOGO_FONT_SIZE,
  MIRA_PC_LOGO_FONT_SIZE,
  MIRA_HEADER_LOGO_PC_HEIGHT,
  MIRA_HEADER_LOGO_TABLET_SMART_HEIGHT,
} from '../../../../styles/size';

export const Title = styled.div`
  height: ${MIRA_HEADER_LOGO_PC_HEIGHT};
  ${TABLET_SIZE} {
    height: ${MIRA_HEADER_LOGO_TABLET_SMART_HEIGHT};
  }
  > img {
    height: ${MIRA_HEADER_LOGO_PC_HEIGHT};
    ${TABLET_SIZE} {
      height: ${MIRA_HEADER_LOGO_TABLET_SMART_HEIGHT};
    }
  }
  > span:first-of-type {
    color: ${RED};
    font-size: ${MIRA_PC_LOGO_FONT_SIZE};
    ${TABLET_SIZE} {
      font-size: ${MIRA_TABLET_SMART_LOGO_FONT_SIZE};
    }
    ${SMART_SIZE} {
      font-size: ${MIRA_TABLET_SMART_LOGO_FONT_SIZE};
    }
  }
  > span {
    color: ${BLACK};
    font-size: ${FINTNESS_PC_LOGO_FONT_SIZE};
    ${TABLET_SIZE} {
      font-size: ${FINTNESS_TABLET_SMART_LOGO_FONT_SIZE};
    }
    ${SMART_SIZE} {
      font-size: ${FINTNESS_TABLET_SMART_LOGO_FONT_SIZE};
    }
  }
`;
const MyPageLogo: FC = () => {
  return (
    <>
      <Title>
        <img src="/images/logo-black.svg" alt="" />
      </Title>
    </>
  );
};

export default memo(MyPageLogo);
