import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { menuStateType } from '../../store/reducers/menu/menuReducer';
import { buttonStateType } from '../../store/reducers/button/buttonReducer';
import { recessStatusStateType } from '../../store/reducers/recess/recessStatusReducer';
import { memberTransferStatusStateType } from '../../store/reducers/recess/memberTransferStatusReducer';
import MyPageLayout from '../../components/Layouts/MyPage/MyPageLayout.index';
import { Layout, ErrorMessageDescription } from './Recess.style';
import useAuthorizationNormal from '../../hooks/Auth/useAuthorizationNormal';

import FS from '../../config/sentence.json';

import {
  GET_SHOP_BUTTON_REQUEST,
  GET_RECESS_STATUS_REQUEST,
  GET_MEMBER_TRANSFER_STATUS_REQUEST,
  GET_RECESS_STATUS_INITIAL,
  POST_RECESS_CANCEL_INITIAL,
  GET_MEMBER_MENU_INITIAL,
  GET_SHOP_BUTTON_INITIAL,
} from '../../store/actions';

import { RootState } from '../../store/reducers';

/**
 * 休会説明欄画面
 * @returns 休会説明欄のHTML
 */
const Recess: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCanRecess, setCanRecess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');
  const [errorMessage3, setErrorMessage3] = useState('');
  const [errorMessage4, setErrorMessage4] = useState('');
  const [isLoaded, setLoaded] = useState(false);

  // 休会データと選択情報を初期化
  sessionStorage.removeItem('months');
  sessionStorage.removeItem('startMonth');
  sessionStorage.removeItem('endMonth');

  // メニュー取得API
  const { isGetMenuLoaded, menuData } = useSelector<RootState, menuStateType>((state) => state.menu);

  // 店舗ボタン取得API
  const { isGetButtonLoaded, buttonData } = useSelector<RootState, buttonStateType>((state) => state.button);

  // 休会状態取得API
  const { isGetRecessStatusLoaded, recessStatusDatas } = useSelector<RootState, recessStatusStateType>(
    (state) => state.recessStatus,
  );

  // 会員管理移籍処理チェックAPI
  const { isGetMemberTransferStatusLoaded, memberTransferStatusDatas } = useSelector<
    RootState,
    memberTransferStatusStateType
  >((state) => state.memberTransferStatus);

  // ログイン認証を設定する
  useAuthorizationNormal();

  // 時刻変換
  function formatTime(time: string) {
    let HH = time.substring(0, 2);
    HH = HH.replace(/^0?/, '');
    const MM = time.substring(2, 4);
    return `${HH}時${MM}分`;
  }
  /**
   * 画面描画時の処理、休会利用可否をチェックする
   */
  useEffect(() => {
    // エラーメッセージ初期化
    setErrorMessage('');
    setErrorMessage2('');
    setErrorMessage3('');
    setErrorMessage4('');
  }, []);

  /**
   * 画面描画時の処理
   * 会員メニュー取得APIが成功したら、下の処理を通る
   *   → 利用できれば、利用時間帯をチェックする
   *   → 利用できなければ、エラーメッセージを表示する
   */
  useEffect(() => {
    if (isGetMenuLoaded) {
      if (menuData.is_usable_recess === false) {
        // 休会利用不可
        setErrorMessage(menuData.unuseable_recess_message);
        setCanRecess(false);
        setLoaded(true);
      } else {
        // 休会利用時間帯をチェックするAPI
        const shopId = sessionStorage.getItem('shopID') ?? '';
        dispatch({
          type: GET_SHOP_BUTTON_REQUEST,
          shopId,
          buttonId: '7', // 休会ボタンID
          history,
        });
      }
    }
  }, [menuData.is_usable_recess, dispatch, isGetMenuLoaded, history, menuData.unuseable_recess_message]);

  /**
   * 画面描画時の処理、休会メニュー利用時間帯取得APIが成功したら、下の処理を通る
   *   → 利用できる時間帯の場合、休会状態をチェックする
   *   → 利用できない時間帯の場合、、エラーメッセージを表示する
   */
  useEffect(() => {
    if (isGetButtonLoaded) {
      if (buttonData.is_available) {
        // 休会ボタン利用可能、休会状態をチェックする
        // 会員番号
        const memberId = sessionStorage.getItem('member_id') ?? '';
        dispatch({
          type: GET_RECESS_STATUS_REQUEST,
          memberId,
          history,
        });
      } else {
        // 休会ボタン利用不可時間帯
        setErrorMessage(FS.F_S_RECESS_CAN_NOT_USE);
        // 利用可能時間帯のメッセージを作る
        let hours = '';
        if (
          buttonData.available_hour &&
          buttonData.available_hour !== null &&
          buttonData.available_hour !== undefined
        ) {
          hours += FS.F_S_RECESS_CAN_USE_TIME;
          for (let i = 0; i < buttonData.available_hour.length; i += 1) {
            const startHour = formatTime(buttonData.available_hour[i].start_hour);
            const endHour = formatTime(buttonData.available_hour[i].end_hour);
            hours += `${startHour}～${endHour}`;
            if (i !== buttonData.available_hour.length - 1) {
              hours += '、';
            }
          }
        }
        setErrorMessage2(hours);
        // 利用可能曜日のメッセージを作る
        let weekdays = '';
        if (buttonData.weekdays && buttonData.weekdays !== null && buttonData.weekdays !== undefined) {
          weekdays += FS.F_S_RECESS_CAN_USE_WEEKDAY;
          for (let i = 0; i < buttonData.weekdays.length; i += 1) {
            weekdays += buttonData.weekdays[i];
            if (i !== buttonData.weekdays.length - 1) {
              weekdays += '/';
            }
          }
        }
        setErrorMessage3(weekdays);
        // 利用不可日のメッセージを作る
        let invalidDate = '';
        if (buttonData.invalid_date && buttonData.invalid_date !== null && buttonData.invalid_date !== undefined) {
          invalidDate += '（';
          for (let i = 0; i < buttonData.invalid_date.length; i += 1) {
            invalidDate += buttonData.invalid_date[i];
            if (i !== buttonData.invalid_date.length - 1) {
              invalidDate += '、';
            }
          }
          invalidDate += FS.F_S_RECESS_EXCEPT_DAY;
        }
        setErrorMessage4(invalidDate);

        setCanRecess(false);
        setLoaded(true);
      }
    }
  }, [
    dispatch,
    history,
    menuData.unuseable_recess_message,
    isGetButtonLoaded,
    buttonData.is_available,
    buttonData.available_hour,
    buttonData.weekdays,
    buttonData.invalid_date,
  ]);

  /**
   * 画面描画時の処理、休会状態取得APIが成功したら、下の処理を通る
   *   → 休会できれば、会員管理移籍状態をチェック
   *   → 休会取消でければ、休会取消画面へ遷移
   *   → 両方ともできなければ、エラーメッセージを表示
   */
  useEffect(() => {
    if (isGetRecessStatusLoaded) {
      if (recessStatusDatas.result === 0) {
        // 休会申込ありの場合
        if (
          recessStatusDatas.cancellable_months === null ||
          recessStatusDatas.cancellable_months === undefined ||
          recessStatusDatas.cancellable_months.length === 0
        ) {
          // 取消可能月がnullまたは配列数が0の場合、エラーとなる
          setErrorMessage(FS.F_S_RECESS_LOAD_FAILURE);
          setCanRecess(false);
        } else if (recessStatusDatas.is_cancellable) {
          // 取消可能フラグがオンの場合、セッションストレージに取消可能月を設定する
          sessionStorage.setItem('months', JSON.stringify(recessStatusDatas.cancellable_months));
          setCanRecess(true);
          // メニュー取得・メニュー利用可否取得・休会状態取得・休会取消のAPIを初期化
          dispatch({
            type: GET_MEMBER_MENU_INITIAL,
          });
          dispatch({
            type: GET_SHOP_BUTTON_INITIAL,
          });
          dispatch({
            type: GET_RECESS_STATUS_INITIAL,
          });
          dispatch({
            type: POST_RECESS_CANCEL_INITIAL,
          });
          // 休会取消確認ページへ
          history.push('/recess/recess-cancel-agreement');
        } else {
          // 取消可能フラグがオフ
          setErrorMessage(FS.F_S_RECESS_LOAD_FAILURE);
          setCanRecess(false);
        }
        // 画面ロード終了
        setLoaded(true);
      } else {
        // 休会申込なしの場合
        // 会員管理移籍処理チェックAPI
        const memberId = sessionStorage.getItem('member_id') ?? '';
        dispatch({
          type: GET_MEMBER_TRANSFER_STATUS_REQUEST,
          memberId,
          history,
        });
      }
    }
  }, [
    dispatch,
    history,
    isGetRecessStatusLoaded,
    recessStatusDatas.cancellable_months,
    recessStatusDatas.is_cancellable,
    recessStatusDatas.result,
  ]);

  /**
   * 画面描画時の処理、会員管理移籍状態取得APIが成功したら、下の処理を通る
   *   → 休会可能月があれば、休会画面へ遷移
   *   → 休会可能月がなければ或いは会員管理側移籍済みであれば、エラーメッセージを表示
   */
  useEffect(() => {
    if (isGetMemberTransferStatusLoaded) {
      if (memberTransferStatusDatas.result === 0) {
        if (recessStatusDatas.length === 0) {
          // 休会ボタンを連続で押している場合、チェックエラーメッセージが表示するのを防止する。
          // この場合、休会状態取得APIが初期化されたから、recessStatusDatas.available_monthsは常にundefinedです、後の処理は実行しないように
          return;
        }
        if (recessStatusDatas.available_months === undefined || recessStatusDatas.available_months === null) {
          setErrorMessage(FS.F_S_RECESS_LOAD_FAILURE);
          setCanRecess(false);
        } else if (recessStatusDatas.available_months.length === 0) {
          setErrorMessage(FS.F_S_RECESS_FAILURE_WITHDRAWALED);
          setCanRecess(false);
        } else {
          // 休会可能
          sessionStorage.setItem('months', JSON.stringify(recessStatusDatas.available_months));
          sessionStorage.setItem('transfer_month', recessStatusDatas.transfer_month);
          setCanRecess(true);
          history.push('/recess/recess-info');
        }
      } else {
        // 会員管理で移籍済み
        setErrorMessage(FS.F_S_RECESS_FAILURE_TRANSFERED);
        setCanRecess(false);
      }
      // 画面ロード終了
      setLoaded(true);
    }
  }, [
    dispatch,
    history,
    isGetMemberTransferStatusLoaded,
    memberTransferStatusDatas,
    memberTransferStatusDatas.result,
    recessStatusDatas.available_months,
    recessStatusDatas.length,
    recessStatusDatas.transfer_month,
  ]);

  return (
    <>
      <MyPageLayout>
        <Layout>
          {isLoaded && !isCanRecess ? (
            <>
              <ErrorMessageDescription>{errorMessage}</ErrorMessageDescription>
              <ErrorMessageDescription>{errorMessage2}</ErrorMessageDescription>
              <ErrorMessageDescription>{errorMessage3}</ErrorMessageDescription>
              <ErrorMessageDescription>{errorMessage4}</ErrorMessageDescription>
            </>
          ) : (
            <></>
          )}
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Recess;
