import React, { FC, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { HeaderLayout } from '../../components/Layouts/MyPage/Header/PCHeader/MyPagePCHeader.style';
import MyPageLogo from '../../components/Common/Logo/MyPage/index';
import { RootState } from '../../store/reducers';
import { commonApiStateType } from '../../store/reducers/common/commonReducer';
import {
  ERROR_DESCRIPTION,
  ERROR_SMART_DESCRIPTION,
  ERROR_TITLE,
  MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT,
  MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT,
  MAIN_SMART_LAYOUT_MARGIN_LEFT,
  MAIN_SMART_LAYOUT_MARGIN_RIGHT,
  LAYOUT_TOP,
  LAYOUT_LEFT,
  LAYOUT_TRANSFORM,
  LAYOUT_WIDTH,
  ERROR_CODE_MARGIN_BOTTOM,
  ERROR_DESCRIPTION_MARGIN_BOTTOM,
  MAIN_FULL_WIDTH,
} from '../../styles/size';
import FS from '../../config/sentence.json';
import CustomButton from '../../components/Common/Button/style';
import { SMART_SIZE } from '../../styles/common';

const Layout = styled.div`
  position: absolute;
  top: ${LAYOUT_TOP};
  // left: ${LAYOUT_LEFT};
  transform: translate(${LAYOUT_TRANSFORM} ${LAYOUT_TRANSFORM});
  font-weight: 700;
  width: ${LAYOUT_WIDTH};
  text-align: center;
  padding-left: ${MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT};
  padding-right: ${MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT};
  ${SMART_SIZE} {
    padding-left: ${MAIN_SMART_LAYOUT_MARGIN_LEFT};
    padding-right: ${MAIN_SMART_LAYOUT_MARGIN_RIGHT};
    & > button {
      width: ${MAIN_FULL_WIDTH};
    }
  }
`;

const ErrorCode = styled.p`
  font-size: ${ERROR_TITLE};
  margin-bottom: ${ERROR_CODE_MARGIN_BOTTOM};
`;

const ErrorDescription = styled.p`
  font-size: ${ERROR_DESCRIPTION};
  margin-bottom: ${ERROR_DESCRIPTION_MARGIN_BOTTOM};
  ${SMART_SIZE} {
    font-size: ${ERROR_SMART_DESCRIPTION};
  }
`;

const ErrorSystem: FC = () => {
  const history = useHistory();
  const [error, setError] = React.useState(false);
  const { apiStatus } = useSelector<RootState, commonApiStateType>((state) => state.common);

  useLayoutEffect(() => {
    const isLogin = JSON.parse(sessionStorage.getItem('member_info') || '{}');
    if (isLogin.result) {
      setError(true);
    }
    if ((apiStatus && apiStatus.status) === '' || null) {
      history.push('/');
    }
  }, [history, apiStatus]);

  const onClickLoginBack = () => {
    sessionStorage.clear();
    history.push('/');
  };

  const onClickHomeBack = () => {
    sessionStorage.clear();
    history.push('/');
  };

  return (
    <>
      <HeaderLayout>
        <MyPageLogo />
      </HeaderLayout>
      <Layout>
        {apiStatus && apiStatus.status ? (
          <>
            <ErrorCode>
              {apiStatus.status} {apiStatus.statusText}
            </ErrorCode>
          </>
        ) : (
          <></>
        )}
        <ErrorDescription>{FS.F_S_SYSTEM_ERROR}</ErrorDescription>
        {error ? (
          <CustomButton type="button" onClick={onClickHomeBack} move="go">
            {FS.F_S_HOME_BACK}
          </CustomButton>
        ) : (
          <CustomButton type="button" onClick={onClickLoginBack} move="go">
            {FS.F_S_LOGIN_BACK}
          </CustomButton>
        )}
      </Layout>
    </>
  );
};

export default ErrorSystem;
