import { combineReducers } from 'redux';

import loginReducer from './login/loginReducer';
import homeReducer from './home/homeReducer';
import referralCodeReducer from './referralCode/referralCodeReducer';
import unpaidReducer from './unpaid/unpaidReducer';
import unpaidOperationCheckReducer from './unpaidOperationCheck/unpaidOperationCheckReducer';
import paymentReducer from './payment/paymentReducer';
import commonReducer from './common/commonReducer';
import customerIdReducer from './customerId/customerIdReducer';
import creditChangeCheckReducer from './creditChangeCheck/creditChangeCheckReducer';
import mailReducer from './mail/index';
import passwordReducer from './password/index';
import menuReducer from './menu/menuReducer';
import memberTransferStatusReducer from './recess/memberTransferStatusReducer';
import recessCancelReducer from './recess/recessCancelReducer';
import recessReducer from './recess/recessReducer';
import recessStatusReducer from './recess/recessStatusReducer';
import buttonReducer from './button/buttonReducer';

// 他のReducerを組み立てる
const rootReducers = combineReducers({
  common: commonReducer,
  login: loginReducer,
  home: homeReducer,
  unpaid: unpaidReducer,
  unpaidOperationCheck: unpaidOperationCheckReducer,
  referralCode: referralCodeReducer,
  payment: paymentReducer,
  customerId: customerIdReducer,
  creditChangeCheck: creditChangeCheckReducer,
  mail: mailReducer,
  password: passwordReducer,
  menu: menuReducer,
  memberTransferStatus: memberTransferStatusReducer,
  recessCancel: recessCancelReducer,
  recess: recessReducer,
  recessStatus: recessStatusReducer,
  button: buttonReducer,
});

// Reducerをtype設定する
export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
