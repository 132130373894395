import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { getMemberTransferAction } from '../sagas/recess/memberTransferStatusSaga';
import { API_BASE_ADDRESS } from '../api';

// 会員管理側会員移籍状態を取得します。
const memberTransferStatusService = (action: getMemberTransferAction): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  return {
    getMemberTransferStatus: () =>
      axios.get(`${API_BASE_ADDRESS}/member/${action.memberId}/non-kiosk-transfer/status`, config),
  };
};

export default memberTransferStatusService;
