import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { API_BASE_ADDRESS } from '../api';

// APIの住所を設定します。

const homeServices = (memberId: string): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  return {
    getHome: () => axios.get(`${API_BASE_ADDRESS}/member/${memberId}/contract-information`, config),
  };
};

export default homeServices;
