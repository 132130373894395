import React, { FC, useCallback, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import {
  ERROR_DESCRIPTION,
  ERROR_SMART_DESCRIPTION,
  MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT,
  MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT,
  MAIN_SMART_LAYOUT_MARGIN_LEFT,
  MAIN_SMART_LAYOUT_MARGIN_RIGHT,
  LAYOUT_WIDTH,
  ERROR_DESCRIPTION_MARGIN_BOTTOM,
} from '../../styles/size';
import FS from '../../config/sentence.json';
import { SMART_SIZE, TABLET_SIZE } from '../../styles/common';
import { RED, WHITE } from '../../styles/color';

import { HeaderLayout } from '../../components/Layouts/MyPage/Header/PCHeader/MyPagePCHeader.style';
import MyPageLogo from '../../components/Common/Logo/MyPage/index';

import { LOG_IN_INITIAL, HOME_INITIAL } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { putMailAddressType } from '../../store/reducers/mail/putMailAddressReducer';

const Layout = styled.div`
  position: absolute;
  top: 43%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  width: ${LAYOUT_WIDTH};
  text-align: center;
  padding-left: ${MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT};
  padding-right: ${MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT};
  ${SMART_SIZE} {
    padding-left: ${MAIN_SMART_LAYOUT_MARGIN_LEFT};
    padding-right: ${MAIN_SMART_LAYOUT_MARGIN_RIGHT};
  }
`;

const ErrorDescription = styled.p`
  font-size: ${ERROR_DESCRIPTION};
  margin-bottom: ${ERROR_DESCRIPTION_MARGIN_BOTTOM};
  line-height: 1.5;
  white-space: pre-line;
  ${SMART_SIZE} {
    font-size: ${ERROR_SMART_DESCRIPTION};
  }
`;

const EmailCustomButton = styled.button`
  position: relative;
  box-shadow: 0px 5px 8px #00000026;
  width: 280px;
  height: 70px;
  border-radius: 40px;
  background-color: ${WHITE};
  color: ${RED};
  border: 3px solid ${RED};
  cursor: pointer;
  :hover {
    background-color: ${RED};
    color: ${WHITE};
  }
  & > span {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  ${TABLET_SIZE} {
    width: 45%;
    max-width: 255px;
    height: 50px;
    & > span {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.7px;
      font-weight: 700;
    }
  }
  ${SMART_SIZE} {
    width: 100%;
    height: 50px;
    & > span {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.7px;
      font-weight: 700;
    }
  }
`;

const ErrorPayment: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // メールアドレス更新APIのステータス
  const { putMailAddressResult } = useSelector<RootState, putMailAddressType>((state) => state.mail.putMailAddress);

  useLayoutEffect(() => {
    sessionStorage.clear();
  }, [history]);

  useEffect(() => {
    // メールアドレス更新APIのステータスが「-1(リロード), 6(該当レコードなし)」の場合、404ページに遷移
    if (putMailAddressResult.result === -1 || putMailAddressResult.result === 6) {
      history.push('/not-found');
    }
  }, [history, putMailAddressResult.result]);

  const onClickHomeBack = useCallback(() => {
    dispatch({
      type: LOG_IN_INITIAL,
    });
    dispatch({
      type: HOME_INITIAL,
    });
    history.push('/');
  }, [dispatch, history]);

  return (
    <>
      <HeaderLayout>
        <MyPageLogo />
      </HeaderLayout>
      <Layout>
        <ErrorDescription>
          {putMailAddressResult.result === 0 ? (
            <>{FS.F_S_PUT_MAIL_ADDRESS_RESULT_SUCCESS}</>
          ) : putMailAddressResult.result === 1 ? (
            <>{FS.F_S_PUT_MAIL_ADDRESS_RESULT_FAILURE}</>
          ) : putMailAddressResult.result === 2 ? (
            <>{FS.F_S_PUT_MAIL_ADDRESS_RESULT_2}</>
          ) : putMailAddressResult.result === 3 ? (
            <>{FS.F_S_PUT_MAIL_ADDRESS_RESULT_3}</>
          ) : putMailAddressResult.result === 4 ? (
            <>{FS.F_S_PUT_MAIL_ADDRESS_RESULT_4}</>
          ) : putMailAddressResult.result === 5 ? (
            <>{FS.F_S_PUT_MAIL_ADDRESS_RESULT_5}</>
          ) : putMailAddressResult.result === 6 ? (
            <>{/* 404ページに遷移するので、エラーメッセージを表示しない */}</>
          ) : (
            <>{FS.F_S_PUT_MAIL_ADDRESS_RESULT_FAILURE}</>
          )}
        </ErrorDescription>
        <br />
        <EmailCustomButton type="button" onClick={onClickHomeBack}>
          {FS.F_S_LOGIN_BACK}
        </EmailCustomButton>
      </Layout>
    </>
  );
};

export default ErrorPayment;
