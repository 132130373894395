import produce from 'immer';
import { AnyAction } from 'redux';
import { POST_RECESS_REQUEST, POST_RECESS_SUCCESS, POST_RECESS_FAILURE, POST_RECESS_INITIAL } from '../../actions';

// タイプを設定
export type recessStateType = {
  isPostRecessLoading: boolean;
  isPostRecessLoaded: boolean;
  postRecessFailureMessage: string;
  recessDatas: any;
};

// ステイタスを初期化
const initialState: recessStateType = {
  isPostRecessLoading: false,
  isPostRecessLoaded: false,
  postRecessFailureMessage: '',
  recessDatas: [],
};

// APIの結果によってステイタスが更新される処理
const recessReducer = (state = initialState, action: AnyAction): recessStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case POST_RECESS_REQUEST:
        dataState.isPostRecessLoading = true;
        dataState.isPostRecessLoaded = false;
        dataState.postRecessFailureMessage = '';
        dataState.recessDatas = [];
        break;
      case POST_RECESS_SUCCESS:
        dataState.isPostRecessLoading = false;
        dataState.isPostRecessLoaded = true;
        dataState.postRecessFailureMessage = '';
        dataState.recessDatas = action.data;
        break;
      case POST_RECESS_FAILURE:
        dataState.isPostRecessLoading = false;
        dataState.isPostRecessLoaded = false;
        dataState.postRecessFailureMessage = '';
        dataState.recessDatas = [];
        break;
      case POST_RECESS_INITIAL:
        dataState.isPostRecessLoading = false;
        dataState.isPostRecessLoaded = false;
        dataState.postRecessFailureMessage = '';
        dataState.recessDatas = [];
        break;
      default:
        break;
    }
  });
};

export default recessReducer;
