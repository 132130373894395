import produce from 'immer';
import { AnyAction } from 'redux';
import {
  POST_RECESS_CANCEL_REQUEST,
  POST_RECESS_CANCEL_SUCCESS,
  POST_RECESS_CANCEL_FAILURE,
  POST_RECESS_CANCEL_INITIAL,
} from '../../actions';

// タイプを設定
export type recessCancelStateType = {
  isPostRecessCancelLoading: boolean;
  isPostRecessCancelLoaded: boolean;
  postRecessCancelFailureMessage: string;
  recessCancelDatas: any;
};

// ステイタスを初期化
const initialState: recessCancelStateType = {
  isPostRecessCancelLoading: false,
  isPostRecessCancelLoaded: false,
  postRecessCancelFailureMessage: '',
  recessCancelDatas: [],
};

// APIの結果によってステイタスが更新される処理
const recessCancelReducer = (state = initialState, action: AnyAction): recessCancelStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case POST_RECESS_CANCEL_REQUEST:
        dataState.isPostRecessCancelLoading = true;
        dataState.isPostRecessCancelLoaded = false;
        dataState.postRecessCancelFailureMessage = '';
        dataState.recessCancelDatas = [];
        break;
      case POST_RECESS_CANCEL_SUCCESS:
        dataState.isPostRecessCancelLoading = false;
        dataState.isPostRecessCancelLoaded = true;
        dataState.postRecessCancelFailureMessage = '';
        dataState.recessCancelDatas = action.data;
        break;
      case POST_RECESS_CANCEL_FAILURE:
        dataState.isPostRecessCancelLoading = false;
        dataState.isPostRecessCancelLoaded = false;
        dataState.postRecessCancelFailureMessage = '';
        dataState.recessCancelDatas = [];
        break;
      case POST_RECESS_CANCEL_INITIAL:
        dataState.isPostRecessCancelLoading = false;
        dataState.isPostRecessCancelLoaded = false;
        dataState.postRecessCancelFailureMessage = '';
        dataState.recessCancelDatas = [];
        break;
      default:
        break;
    }
  });
};

export default recessCancelReducer;
