import styled from '@emotion/styled';
import { Tab, Tabs } from 'react-bootstrap';

import { TABLET_SIZE, SMART_SIZE } from '../../styles/common';
import { RED, WHITE, LIGHT_GRAY_BORDER, MEDIUM_GRAY } from '../../styles/color';
import {
  FORMLAYOUT_PC_TABLET_TITLE_BORDER_RADIUS,
  FORMLAYOUT_TABLET_SMART_WIDTH,
  PARA_PC_TABLET_TITLE_FONT_SIZE,
  PARA_SMART_TITLE_FONT_SIZE,
  PARA_PC_TABLET_TITLE_LINE_SIZE,
  PARA_SMART_TITLE_LINE_SIZE,
  PARA_PC_TABLET_TITLE_LETTER_SPACE_SIZE,
  PARA_SMART_TITLE_LETTER_SPACE_SIZE,
  FORM_TITLE_PC_TABLET_TITLE_FONT_SIZE,
  FORM_TITLE_SMART_TITLE_FONT_SIZE,
  FORM_TITLE_PC_TABLET_MARGIN_TOP,
  FORM_TITLE_PC_TABLET_MARGIN_LEFT,
  FORM_TITLE_PC_TABLET_MARGIN_RIGHT,
  FORM_TITLE_CODE_PC_TABLET_MARGIN_TOP,
  FORM_TITLE_CODE_PC_TABLET_MARGIN_LEFT,
  FORM_TITLE_CODE_PC_TABLET_MARGIN_RIGHT,
  FORM_TITLE_CODE_PC_TABLET_WIDTH,
  FORM_TITLE_CODE_PC_TABLET_TITLE_LINE_HEIGHT,
  FORM_TITLE_CODE_PC_TABLET_DIV_WIDTH,
  FORM_TITLE_EMAIL_PC_TABLET_MARGIN_TOP,
  FORM_TITLE_EMAIL_PC_TABLET_MARGIN_LEFT,
  FORM_TITLE_EMAIL_PC_TABLET_MARGIN_RIGHT,
  FORM_TITLE_EMAIL_PC_TABLET_WIDTH,
  FORM_TITLE_EMAIL_PC_TABLET_TITLE_LINE_HEIGHT,
  FORM_TITLE_EMAIL_PC_TABLET_DIV_WIDTH,
  FORM_TITLE_PC_TABLET_TITLE_LINE_HEIGHT,
  FORM_TITLE_SMART_TITLE_LINE_SIZE,
  FORM_TITLE_SMART_TITLE_LETTER_SPACE_SIZE,
  FORM_TITLE_FONT_SIZE,
  FORM_TITLE_LINE_SIZE,
  FORM_TITLE_LETTER_SPACE_SIZE,
  FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS,
  FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS_RIGHT,
  FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS_LEFT,
  FORM_TITLE_BORDER_RADIUS,
  FORM_TITLE_SMART_TITLE_MIN_WIDTH,
  FORM_TITLE_SMART_TITLE_MAX_WIDTH,
  FORMTITLE_PC_HEIGHT,
  FORM_PC_WIDTH,
  FORM_PC_HEIGHT,
  FORM_PC_BORDER_RADIUS,
  FORM_SMART_MAX_WIDTH,
  FORM_TABLET_HEIGHT,
  FORM_SMART_HEIGHT,
  FORM_TABLET_MIN_WIDTH,
  INPUT_PC_TABLET_SMART_MAX_WIDTH,
  INPUT_PC_WIDTH,
  INPUT_PC_HEIGHT,
  INPUT_BUTTON_PC_TABLET_SMART_WIDTH,
  INPUT_BUTTON_PC_TABLET_SMART_HEIGHT,
  MAIN_SMART_LAYOUT_MARGIN_LEFT,
  MAIN_SMART_LAYOUT_MARGIN_RIGHT,
  INPUT_BUTTON_PC_TABLET_FONT_SIZE,
  INPUT_BUTTON_PC_TABLET_LINE_HEIGHT,
  INPUT_VALIDATION_MESSAGE_SMART_FONT_SIZE,
  INPUT_VALIDATION_MESSAGE_SMART_LINE_HEIGHT,
  INPUT_VALIDATION_MESSAGE_SMART_LINE_SPACING,
  INPUT_VALIDATION_MESSAGE_SMART_HEIGHT,
  FORM_TITLE_SMART_TITLE_MIN_WIDTH_PLUS_ONE,
  FORM_TITLE_BACKGROUND_PADDING,
  FORM_TITLE_CODE_BORDER_BOTTOM,
  FORM_TITLE_PC_TABLET_SMART_TOP,
  FORM_TITLE_PC_TABLET_SMART_MARGIN_BOTTOM,
  FORM_PC_PADDING_TOP,
  FORM_PC_PADDING_LEFT,
  FORM_PC_PADDING_BOTTOM,
  FORM_PC_PADDING_RIGHT,
  INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_TOP,
  INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_LEFT,
  INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_BOTTOM,
  INPUT_VALIDATION_MESSAGE_PC_TABLET_FONT_SIZE,
} from '../../styles/size';

const TOP = '-20px';
const BOTTOM = '32px';

export const FormLayout = styled.div`
  border: ${FORM_TITLE_CODE_BORDER_BOTTOM} solid ${LIGHT_GRAY_BORDER};
  border-radius: ${FORMLAYOUT_PC_TABLET_TITLE_BORDER_RADIUS};
  width: ${FORM_PC_WIDTH};
  height: ${FORM_PC_HEIGHT};
  margin: auto;
  ${TABLET_SIZE} {
    width: ${FORMLAYOUT_TABLET_SMART_WIDTH};
    height: ${FORM_TABLET_HEIGHT};
    max-width: ${FORM_TABLET_MIN_WIDTH};
    margin-left: auto;
    margin-right: auto;
  }
  ${SMART_SIZE} {
    height: ${FORM_SMART_HEIGHT};
    width: ${FORMLAYOUT_TABLET_SMART_WIDTH};
    max-width: ${FORM_SMART_MAX_WIDTH};
  }
`;
export const Para = styled.p`
  margin-top: ${TOP};
  margin-bottom: ${BOTTOM};
  font-size: ${PARA_PC_TABLET_TITLE_FONT_SIZE};
  line-height: ${PARA_PC_TABLET_TITLE_LINE_SIZE};
  letter-spacing: ${PARA_PC_TABLET_TITLE_LETTER_SPACE_SIZE};
  color: ${WHITE};
  ${SMART_SIZE} {
    font-size: ${PARA_SMART_TITLE_FONT_SIZE};
    line-height: ${PARA_SMART_TITLE_LINE_SIZE};
    letter-spacing: ${PARA_SMART_TITLE_LETTER_SPACE_SIZE};
  }
`;
export const Formtitle = styled.div`
  height: ${FORMTITLE_PC_HEIGHT};
  margin-top: ${FORM_TITLE_PC_TABLET_MARGIN_TOP};
  margin-left: ${FORM_TITLE_PC_TABLET_MARGIN_LEFT};
  margin-right: ${FORM_TITLE_PC_TABLET_MARGIN_RIGHT};
  background: ${RED} ${FORM_TITLE_BACKGROUND_PADDING} ${FORM_TITLE_BACKGROUND_PADDING} no-repeat padding-box;
  border-radius: ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS} ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS} 0 0;
  display: grid;
  align-items: center;
  font-size: ${FORM_TITLE_PC_TABLET_TITLE_FONT_SIZE};
  line-height: ${FORM_TITLE_PC_TABLET_TITLE_LINE_HEIGHT};
  ${SMART_SIZE} {
    width: auto;
    font-size: ${FORM_TITLE_SMART_TITLE_FONT_SIZE};
    line-height: ${FORM_TITLE_SMART_TITLE_LINE_SIZE};
    letter-spacing: ${FORM_TITLE_SMART_TITLE_LETTER_SPACE_SIZE};
  }
`;
export const FormtitleCode = styled.div`
  margin-top: ${FORM_TITLE_CODE_PC_TABLET_MARGIN_TOP};
  margin-left: ${FORM_TITLE_CODE_PC_TABLET_MARGIN_LEFT};
  margin-right: ${FORM_TITLE_CODE_PC_TABLET_MARGIN_RIGHT};
  display: flex;
  align-items: center;
  width: ${FORM_TITLE_CODE_PC_TABLET_WIDTH};
  font-size: ${FORM_TITLE_PC_TABLET_TITLE_FONT_SIZE};
  line-height: ${FORM_TITLE_CODE_PC_TABLET_TITLE_LINE_HEIGHT};
  & > div {
    height: ${FORMTITLE_PC_HEIGHT};
    width: ${FORM_TITLE_CODE_PC_TABLET_DIV_WIDTH};
    display: grid;
    align-items: center;
    background: transparent ${FORM_TITLE_BACKGROUND_PADDING} ${FORM_TITLE_BACKGROUND_PADDING} no-repeat padding-box;
    border-radius: ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS_LEFT} ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS} 0 0;
    border-bottom: ${FORM_TITLE_CODE_BORDER_BOTTOM} solid ${LIGHT_GRAY_BORDER};
    color: ${LIGHT_GRAY_BORDER};
    cursor: pointer;
  }
  & > div:first-of-type {
    width: ${FORM_TITLE_CODE_PC_TABLET_DIV_WIDTH};
    display: grid;
    align-items: center;
    background: ${RED} ${FORM_TITLE_BACKGROUND_PADDING} ${FORM_TITLE_BACKGROUND_PADDING} no-repeat padding-box;
    border-radius: ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS} ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS_RIGHT} 0 0;
    border-bottom: ${FORM_TITLE_CODE_BORDER_BOTTOM} solid ${RED};
    color: ${WHITE};
  }
  ${SMART_SIZE} {
    width: auto;
    font-size: ${FORM_TITLE_SMART_TITLE_FONT_SIZE};
    line-height: ${FORM_TITLE_SMART_TITLE_LINE_SIZE};
    letter-spacing: ${FORM_TITLE_SMART_TITLE_LETTER_SPACE_SIZE};
     >div: first-of-type >p {
      text-align: center;
    }
    >div: last-of-type >p {
      text-align: center;
    }
`;
export const FormtitleEmail = styled.div`
  margin-top: ${FORM_TITLE_EMAIL_PC_TABLET_MARGIN_TOP};
  margin-left: ${FORM_TITLE_EMAIL_PC_TABLET_MARGIN_LEFT};
  margin-right: ${FORM_TITLE_EMAIL_PC_TABLET_MARGIN_RIGHT};
  display: flex;
  align-items: center;
  width: ${FORM_TITLE_EMAIL_PC_TABLET_WIDTH};
  font-size: ${FORM_TITLE_PC_TABLET_TITLE_FONT_SIZE};
  line-height: ${FORM_TITLE_EMAIL_PC_TABLET_TITLE_LINE_HEIGHT};
  & > div {
    height: ${FORMTITLE_PC_HEIGHT};
    width: ${FORM_TITLE_EMAIL_PC_TABLET_DIV_WIDTH};
    min-width: calc(${FORM_TITLE_SMART_TITLE_MIN_WIDTH} + ${FORM_TITLE_SMART_TITLE_MIN_WIDTH_PLUS_ONE});
    display: grid;
    align-items: center;
    background: transparent ${FORM_TITLE_BACKGROUND_PADDING} ${FORM_TITLE_BACKGROUND_PADDING} no-repeat padding-box;
    border-radius: ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS_LEFT} ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS} 0 0;
    border-bottom: ${FORM_TITLE_CODE_BORDER_BOTTOM} solid ${LIGHT_GRAY_BORDER};
    color: ${LIGHT_GRAY_BORDER};
    cursor: pointer;
  }
  & > div:last-of-type {
    width: ${FORM_TITLE_EMAIL_PC_TABLET_DIV_WIDTH};
    min-width: calc(${FORM_TITLE_SMART_TITLE_MIN_WIDTH} + ${FORM_TITLE_SMART_TITLE_MIN_WIDTH_PLUS_ONE});
    display: grid;
    align-items: center;
    background: ${RED} ${FORM_TITLE_BACKGROUND_PADDING} ${FORM_TITLE_BACKGROUND_PADDING} no-repeat padding-box;
    border-radius: ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS_LEFT} ${FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS} 0 0;
    border-bottom: ${FORM_TITLE_CODE_BORDER_BOTTOM} solid ${RED};
    color: ${WHITE};
  }
  ${SMART_SIZE} {
    width: auto;
    font-size: ${FORM_TITLE_SMART_TITLE_FONT_SIZE};
    line-height: ${FORM_TITLE_SMART_TITLE_LINE_SIZE};
    letter-spacing: ${FORM_TITLE_SMART_TITLE_LETTER_SPACE_SIZE};
    >div: first-of-type >p {
      text-align: center;
    }
    >div: last-of-type >p {
      text-align: center;
    }
  }
  & > div {
    min-width: ${FORM_TITLE_SMART_TITLE_MIN_WIDTH};
  }
  & > div:last-of-type {
    min-width: calc(${FORM_TITLE_SMART_TITLE_MIN_WIDTH} + ${FORM_TITLE_SMART_TITLE_MIN_WIDTH_PLUS_ONE});
  }
`;
export const Form = styled.form`
  top: ${FORM_TITLE_PC_TABLET_SMART_TOP};
  position: relative;
  > div {
    margin-bottom: ${FORM_TITLE_PC_TABLET_SMART_MARGIN_BOTTOM};
  }
  > div > input {
    max-width: ${INPUT_PC_TABLET_SMART_MAX_WIDTH};
    width: ${INPUT_PC_WIDTH};
    height: ${INPUT_PC_HEIGHT};
    border: none;
    border-radius: ${FORM_PC_BORDER_RADIUS};
    padding: ${FORM_PC_PADDING_TOP} ${FORM_PC_PADDING_LEFT} ${FORM_PC_PADDING_BOTTOM} ${FORM_PC_PADDING_RIGHT};
    ::placeholder {
      color: ${MEDIUM_GRAY};
      font-size: ${FORM_TITLE_FONT_SIZE};
      line-height: ${FORM_TITLE_LINE_SIZE};
      letter-spacing: ${FORM_TITLE_LETTER_SPACE_SIZE};
    }
  }
  > div > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  > div > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  > div > input[type='number'] {
    -moz-appearance: textfield;
  }
  > div > input[type='submit'] {
    margin-top: 12px;
    width: ${INPUT_BUTTON_PC_TABLET_SMART_WIDTH};
    height: ${INPUT_BUTTON_PC_TABLET_SMART_HEIGHT};
    color: ${WHITE};
    background: ${RED} ${FORM_TITLE_BACKGROUND_PADDING} ${FORM_TITLE_BACKGROUND_PADDING} no-repeat padding-box;
    box-shadow: ${INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_TOP} ${INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_LEFT}${INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_BOTTOM}#00000026;
    border: none;
    border-radius: ${FORM_TITLE_BORDER_RADIUS};
    font-size: ${INPUT_BUTTON_PC_TABLET_FONT_SIZE};
    line-height: ${INPUT_BUTTON_PC_TABLET_LINE_HEIGHT};
    font-weight: 500;
  }
  > p {
    max-width: ${FORM_TITLE_SMART_TITLE_MAX_WIDTH};
    text-align: center;
    margin: auto;
    font-size: ${INPUT_VALIDATION_MESSAGE_PC_TABLET_FONT_SIZE};
    line-height: ${INPUT_VALIDATION_MESSAGE_SMART_LINE_HEIGHT};
    letter-spacing: ${INPUT_VALIDATION_MESSAGE_SMART_LINE_SPACING};
    font-weight: 700;
    height: ${INPUT_VALIDATION_MESSAGE_SMART_HEIGHT};
  }
  ${SMART_SIZE} {
    padding-left: ${MAIN_SMART_LAYOUT_MARGIN_LEFT};
    padding-right: ${MAIN_SMART_LAYOUT_MARGIN_RIGHT};
    & > p {
      font-size: ${INPUT_VALIDATION_MESSAGE_SMART_FONT_SIZE};
    }
  }
`;

export const LoginTab = styled(Tab)`
  /* color: red; */
  border-bottom: 0px;
`;

export const LoginTabs = styled(Tabs)`
  & > a {
    width: calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: ${WHITE};
    padding: 20px 0px;
    ${SMART_SIZE} {
      font-size: 12px;
      padding: 20px 10px;
    }
    :first-of-type {
      border-top-left-radius: 10px !important;
    }
    :last-child {
      border-top-right-radius: 10px !important;
    }
  }
`;
