import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { API_BASE_ADDRESS } from '../api';

const passwordService = (action: any) => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  const putPassWordData = {
    member_id: action.memberID,
    password: action.password,
  };

  return {
    putPassword: () => axios.put(`${API_BASE_ADDRESS}/member/password`, putPassWordData, config),
    getPasswordStatus: () => axios.get(`${API_BASE_ADDRESS}/member/${action.memberID}/password/status`, config),
  };
};

export default passwordService;
