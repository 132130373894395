import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import { LOG_IN_REQUEST, LOG_IN_SUCCESS, LOG_IN_FAILURE, COMMON_FAILURE } from '../../actions';
import { loginService } from '../../services';
import { historyType } from '../../../utils/commonType';

export type postLoginAction = {
  type: typeof LOG_IN_REQUEST;
  memberShip: string;
  mobileNumber: string;
  birthday: string;
  loginClass: number;
  mail_address: string;
  password: string;
  history: historyType;
};

function* postLogin(action: postLoginAction): Generator<unknown> {
  try {
    const res: any = yield call(loginService(action).postLogin);
    yield put({
      type: LOG_IN_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: LOG_IN_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchPostLogin() {
  yield takeLatest(LOG_IN_REQUEST, postLogin);
}

export default function* loginSaga(): Generator<unknown> {
  yield all([fork(watchPostLogin)]);
}
