import React, { FC, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  MessageModal,
  MessageModalBodyLayout,
  MessageModalButton,
  MessageModalErrorMessage,
  MessageModalErrorMessageText,
} from '../Recess.style';
import MyPageLayout from '../../../components/Layouts/MyPage/MyPageLayout.index';
import { menuStateType } from '../../../store/reducers/menu/menuReducer';
import { buttonStateType } from '../../../store/reducers/button/buttonReducer';
import { recessStatusStateType } from '../../../store/reducers/recess/recessStatusReducer';
import { recessCancelStateType } from '../../../store/reducers/recess/recessCancelReducer';
import CustomButton from '../../../components/Common/Button/style';
import CustomButtonLabel from '../../../components/Common/Button/Label';
import { Layout, Buttonside } from './Recess.cancel.agreement.style';
import useAuthorizationNormal from '../../../hooks/Auth/useAuthorizationNormal';
import { RootState } from '../../../store/reducers';

import FW from '../../../config/word.json';
import FS from '../../../config/sentence.json';

import {
  GET_MEMBER_MENU_INITIAL,
  GET_MEMBER_MENU_REQUEST,
  GET_SHOP_BUTTON_INITIAL,
  GET_SHOP_BUTTON_REQUEST,
  GET_RECESS_STATUS_INITIAL,
  GET_RECESS_STATUS_REQUEST,
  POST_RECESS_CANCEL_INITIAL,
  POST_RECESS_CANCEL_REQUEST,
} from '../../../store/actions';

/**
 * 休会選択月確認画面
 * @returns 休会選択月確認画面のHTML
 */
const Recess_cancel_agreement: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isClicked, setClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const months = JSON.parse(sessionStorage.getItem('months') ?? '{}');
  let content = '';

  // メニュー取得API
  const { isGetMenuLoaded, menuData } = useSelector<RootState, menuStateType>((state) => state.menu);

  // 店舗ボタン取得API
  const { isGetButtonLoaded, buttonData } = useSelector<RootState, buttonStateType>((state) => state.button);

  // 休会状態取得API
  const { isGetRecessStatusLoaded, recessStatusDatas } = useSelector<RootState, recessStatusStateType>(
    (state) => state.recessStatus,
  );

  // 休会取消API
  const { isPostRecessCancelLoaded, recessCancelDatas } = useSelector<RootState, recessCancelStateType>(
    (state) => state.recessCancel,
  );

  // ログイン認証を設定する
  useAuthorizationNormal();

  // 休会取消可能月を構築する
  if (months.length > 0) {
    for (let i = 0; i < months.length; i += 1) {
      if (typeof months[i] === 'string') {
        const month = Number(months[i].substring(4, 6));
        content = `${content} ${month}月`;
      }
    }
  }

  /**
   * 次へボタンを押下時の処理
   *   → 休会取消可否チェックして、休会取消をすると、休会取消完了画面へ遷移
   */
  const onClickGo = useCallback(() => {
    if (isClicked === false) {
      setClicked(true);
      // メニュー利用可否・休会可否時間帯・休会状態取得・休会取消のAPIを初期化
      dispatch({
        type: GET_MEMBER_MENU_INITIAL,
      });
      dispatch({
        type: GET_SHOP_BUTTON_INITIAL,
      });
      dispatch({
        type: POST_RECESS_CANCEL_INITIAL,
      });
      dispatch({
        type: GET_RECESS_STATUS_INITIAL,
      });
      dispatch({
        type: POST_RECESS_CANCEL_INITIAL,
      });
      // メニュー利用可否をチェックする
      // 暗号化された会員番号
      const memberId = sessionStorage.getItem('memberID') ?? '';
      // 店舗コード
      const shopId = sessionStorage.getItem('shopID') ?? '';
      // 会員メニューを取得する
      dispatch({
        type: GET_MEMBER_MENU_REQUEST,
        memberId,
        shopId,
        history,
      });
    }
  }, [dispatch, history, isClicked]);

  /**
   * 画面描画時の処理、休会メニュー利用可否取得APIが成功したら、下の処理を通る
   *   → 利用できれば、利用時間帯をチェックする
   *   → 利用できなければ、エラーメッセージを表示する
   */
  useEffect(() => {
    if (isGetMenuLoaded && isClicked) {
      if (menuData.is_usable_recess === false) {
        // 休会メニュー利用不可の場合、エラーとなる
        setModalErrorMessage(FS.F_S_RECESS_CANCEL_FAILURE);
        setIsModalOpen(true);
        setClicked(false);
      } else {
        // 休会利用時間帯をチェックするAPI
        const shopId = sessionStorage.getItem('shopID') ?? '';
        dispatch({
          type: GET_SHOP_BUTTON_REQUEST,
          shopId,
          buttonId: '7', // 休会ボタンID
          history,
        });
      }
      // APIを初期化
      dispatch({
        type: GET_MEMBER_MENU_INITIAL,
      });
    }
  }, [dispatch, history, isClicked, isGetMenuLoaded, menuData.is_usable_recess]);

  /**
   * 画面描画時の処理、休会メニュー利用時間帯取得APIが成功したら、下の処理を通る
   *   → 利用できる時間帯の場合、休会状態をチェックする
   *   → 利用できない時間帯の場合、、エラーメッセージを表示する
   */
  useEffect(() => {
    if (isGetButtonLoaded && isClicked) {
      if (buttonData.is_available) {
        // 休会状態取得・休会取消のAPIを初期化
        // 休会状態を取得する
        const memberId = sessionStorage.getItem('member_id') ?? '';
        dispatch({
          type: GET_RECESS_STATUS_REQUEST,
          memberId,
          history,
        });
      } else {
        // 休会ボタン利用不可時間帯の場合、エラーとなる
        setModalErrorMessage(FS.F_S_RECESS_CANCEL_FAILURE);
        setIsModalOpen(true);
        setClicked(false);
      }
      // APIを初期化
      dispatch({
        type: GET_SHOP_BUTTON_INITIAL,
      });
    }
  }, [buttonData.is_available, dispatch, history, isClicked, isGetButtonLoaded]);

  /**
   * 画面描画時の処理、休会状態取得APIが成功したら、下の処理を通る
   *   → 休会取消可能月があれば、休会取消をする
   *   → 休会取消可能月がなければ、エラーメッセージを表示する
   */
  useEffect(() => {
    if (isGetRecessStatusLoaded && isClicked) {
      if (recessStatusDatas.result === 0) {
        if (
          recessStatusDatas.cancellable_months == null ||
          recessStatusDatas.cancellable_months === undefined ||
          recessStatusDatas.cancellable_months.length === 0
        ) {
          // 取消可能月がnullまたは配列数が0の場合、エラーとなる
          setModalErrorMessage(FS.F_S_RECESS_CANCEL_FAILURE);
          setIsModalOpen(true);
          setClicked(false);
        } else if (recessStatusDatas.is_cancellable) {
          // 休会申込ありの場合、休会取消をする
          dispatch({
            type: POST_RECESS_CANCEL_REQUEST,
            memberId: sessionStorage.getItem('member_id') ?? '',
            shopId: sessionStorage.getItem('shopID') ?? '',
            history,
          });
        } else {
          // 取消可能フラグがオフ
          setModalErrorMessage(FS.F_S_RECESS_CANCEL_FAILURE);
          setIsModalOpen(true);
          setClicked(false);
        }
      } else {
        // 休会申込なしの場合、休会取消不可、エラーメッセージを表示
        setModalErrorMessage(FS.F_S_RECESS_CANCEL_FAILURE);
        setIsModalOpen(true);
        setClicked(false);
      }
      // APIを初期化
      dispatch({
        type: GET_RECESS_STATUS_INITIAL,
      });
    }
  }, [
    dispatch,
    history,
    isClicked,
    isGetRecessStatusLoaded,
    recessStatusDatas.cancellable_months,
    recessStatusDatas.is_cancellable,
    recessStatusDatas.result,
  ]);

  /**
   * 画面描画時の処理、休会取消APIが成功したら、下の処理を通る
   *  → 休会取消成功の場合、休会取消完了画面へ遷移
   *  → 休会取消失敗の場合、エラーメッセージを表示する
   */
  useEffect(() => {
    if (isPostRecessCancelLoaded) {
      setClicked(false);
      if (recessCancelDatas.result === 0) {
        // キャッシュをクリアする
        sessionStorage.removeItem('months');
        // 成功 完了画面へ遷移
        history.push('/recess/recess-cancel-complete');
      } else {
        // 休会取消失敗の場合、エラーメッセージを表示
        setModalErrorMessage(FS.F_S_RECESS_CANCEL_FAILURE);
        setIsModalOpen(true);
      }
      // APIを初期化
      dispatch({
        type: POST_RECESS_CANCEL_INITIAL,
      });
    }
  }, [history, dispatch, isPostRecessCancelLoaded, recessCancelDatas.result]);

  /**
   * ポップアップメッセージをクローズ処理
   */
  const onClickClose = useCallback(() => {
    setIsModalOpen(false);
    setModalErrorMessage('');
  }, []);

  /**
   * ポップアップメッセージが閉じた時の処理
   */
  const onHideModalClose = useCallback(() => {
    setIsModalOpen(false);
    setModalErrorMessage('');
  }, []);

  return (
    <>
      <MyPageLayout>
        <Layout>
          <div>
            <div>
              <h1>
                {FS.F_S_CANCEL_RECESS_TEXT_1}
                <br />
                <br />
                {FS.F_S_CANCEL_RECESS_TEXT_2}
                {content}
              </h1>
            </div>
            <Buttonside>
              <CustomButton type="button" move="go" location="right" onClick={onClickGo}>
                <CustomButtonLabel label={FW.F_W_GO_NEXT} arrowLocation="right" />
              </CustomButton>
            </Buttonside>
          </div>
          <MessageModal show={isModalOpen} onHide={onHideModalClose}>
            <MessageModalBodyLayout>
              <MessageModalErrorMessage>
                <MessageModalErrorMessageText>{modalErrorMessage}</MessageModalErrorMessageText>
              </MessageModalErrorMessage>
              <MessageModalButton onClick={onClickClose}>
                <>{FW.F_W_CLOSE}</>
              </MessageModalButton>
            </MessageModalBodyLayout>
          </MessageModal>
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Recess_cancel_agreement;
