import styled from '@emotion/styled';
import { AiOutlineClose } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IPHONE_SIZE, SMART_SIZE } from '../../../../../styles/common';
import { BLACK, LARGE_BUTTON_BOX_SHADOW_COLOR, RED, WHITE } from '../../../../../styles/color';
import {
  MIRA_SMART_MENU_BOTTOM,
  MAIN_FULL_WIDTH,
  MIRA_SMART_MENU_MARGIN_TOP,
  MIRA_SMART_MENU_MARGIN_LEFT,
  MIRA_SMART_MENU_PADDING_TOP_BOTTOM,
  MIRA_SMART_MENU_PADDING_LEFT_RIGHT,
  MIRA_SMART_MENU_ICON_WIDTH,
  MIRA_SMART_MENU_ICON_HEIGHT,
  MIRA_SMART_MENU_LINK_FONT_SIZE,
  MIRA_SMART_MENU_LINK_MARGIN_TOP,
  MIRA_SMART_MENU_LINK_LINE_HEIGHT,
  MIRA_SMART_SUB_ITEM_LEFT,
  MIRA_SMART_SUB_ITEM_TOP,
  MIRA_SMART_SUB_ITEM_BOTTOM,
  MIRA_SMART_TAB_ITEM_CLOSE_ICON_RIGHT,
  MIRA_SMART_TAB_ITEM_CLOSE_FONT_SIZE,
  MIRA_SMART_TAB_ITEM_CLOSE_ICON_Z_INDEX,
  MIRA_SMART_MENU_MARGIN_BOTTOM,
  MIRA_SMART_MENU_MARGIN_RIGHT,
  MIRA_SMART_SCROLLBAR_HEIGHT,
  MIRA_SMART_SCROLLBAR_BORDER_RADIUS,
  MIRA_SMART_SUBMENU_BACKGROIND_OPACITY,
  MIRA_SMART_TAB_ITEM_CLOSE_TOP,
  MIRA_SMART_MENU_MIN_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE,
  MAIN_BUTTON_BORDER_WIDTH,
} from '../../../../../styles/size';

interface SubItemTabBackgroundProps {
  subItemTabHeight: number;
  subItemTabCount: number;
}

interface LayoutProps {
  sidebar: boolean;
}

interface ButtonProps {
  isclickedactive: string;
}

interface SubItemTabProps {
  subItemTabCount: number;
}

export const ScrollContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${IPHONE_SIZE} {
    display: flex;
    overflow-x: auto;
    background: ${RED};
    width: ${MAIN_FULL_WIDTH};
    ::-webkit-scrollbar {
      height: ${MIRA_SMART_SCROLLBAR_HEIGHT};
      background-color: ${RED};
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${WHITE};
      border-radius: ${MIRA_SMART_SCROLLBAR_BORDER_RADIUS};
    }
  }
`;

export const Layout = styled.div<LayoutProps>`
  /* position: absolute; */
  position: fixed;
  bottom: ${MIRA_SMART_MENU_BOTTOM};
  background-color: ${RED};
  width: ${MAIN_FULL_WIDTH};
  color: white;
  display: table;
  table-layout: fixed;
  z-index: ${({ sidebar }) => (sidebar === true ? '1000000' : '0')};
`;

export const Content = styled.div`
  margin: ${MIRA_SMART_MENU_MARGIN_TOP} ${MIRA_SMART_MENU_MARGIN_LEFT} ${MIRA_SMART_MENU_MARGIN_BOTTOM}
    ${MIRA_SMART_MENU_MARGIN_RIGHT};
  /*& > div:last-of-type {
    display: none;
  }
  & > div:nth-of-type(4) {
    display: none;
  }*/
`;

export const SubMenuItem = styled.div`
  display: table-cell;
  padding: ${MIRA_SMART_MENU_PADDING_TOP_BOTTOM} ${MIRA_SMART_MENU_PADDING_LEFT_RIGHT};
  min-width: ${MIRA_SMART_MENU_MIN_WIDTH};
  :nth-of-type(1) {
    border-left: transparent;
  }
  :nth-last-of-type(1) {
    border-right: transparent;
  }
  & > a {
    text-decoration: none;
    & > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${WHITE};
      text-decoration: none;
      word-break: break-all;
      & > img {
        width: ${MIRA_SMART_MENU_ICON_WIDTH};
        height: ${MIRA_SMART_MENU_ICON_HEIGHT};
      }
      & > svg {
        width: ${MIRA_SMART_MENU_ICON_WIDTH};
        height: ${MIRA_SMART_MENU_ICON_HEIGHT};
      }
      & > span {
        font-size: ${MIRA_SMART_MENU_LINK_FONT_SIZE};
        margin-top: ${MIRA_SMART_MENU_LINK_MARGIN_TOP} !important;
        display: inline-block;
        text-align: center;
        line-height: ${MIRA_SMART_MENU_LINK_LINE_HEIGHT};
      }
    }
  }
`;

export const MenuLink = styled(Link)<ButtonProps>`
  color: ${({ isclickedactive }) => (isclickedactive === 'active' ? `white !important` : ``)};
`;

export const SubItemTabLayout = styled.div<SubItemTabProps>`
  position: absolute;
  top: ${({ subItemTabCount }) => `-${subItemTabCount * 54}px`};
  left: ${MIRA_SMART_SUB_ITEM_LEFT};
  color: black;
  width: ${MAIN_FULL_WIDTH};
  background-color: ${WHITE};
`;

export const SubItemTabBackground = styled.div<SubItemTabBackgroundProps>`
  display: block;
  position: fixed;
  top: ${MIRA_SMART_SUB_ITEM_TOP};
  bottom: ${MIRA_SMART_SUB_ITEM_BOTTOM};
  ${({ subItemTabHeight, subItemTabCount }) =>
    subItemTabCount && `height: calc(100% - ${subItemTabHeight}px - ${subItemTabCount * 54}px);`};
  background-color: ${BLACK};
  opacity: ${MIRA_SMART_SUBMENU_BACKGROIND_OPACITY};
  width: ${MAIN_FULL_WIDTH};

  @media screen and (max-width: 475px) {
    /* none */
    ${({ subItemTabHeight, subItemTabCount }) =>
      subItemTabCount && `height: calc(100% - ${subItemTabHeight + 16}px - ${subItemTabCount * 54}px);`};
  }

  @media screen and (max-width: 374px) {
    ${({ subItemTabHeight, subItemTabCount }) =>
      subItemTabCount && `height: calc(100% - ${subItemTabHeight + 20}px - ${subItemTabCount * 54}px);`};
  }
`;
export const TabItemLayout = styled.div`
  padding-left: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const TabItem = styled.div`
  color: ${BLACK};
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const TabItemSVG = styled.div`
  width: 30px;
  height: 30px;
`;

export const SubItemTabCloseIcon = styled(AiOutlineClose)`
  z-index: ${MIRA_SMART_TAB_ITEM_CLOSE_ICON_Z_INDEX};
  color: ${WHITE};
  right: ${MIRA_SMART_TAB_ITEM_CLOSE_ICON_RIGHT};
  position: absolute;
  top: ${MIRA_SMART_TAB_ITEM_CLOSE_TOP};
  font-size: ${MIRA_SMART_TAB_ITEM_CLOSE_FONT_SIZE};
  cursor: pointer;
`;

export const ReservationFooterModal = styled(Modal)`
  padding-left: 40px !important;
  padding-right: 40px !important;
  & > div {
    max-width: ${MIRA_HOME_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH};
    display: flex;
    align-items: center;
    height: ${MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT};
    justify-content: center;
    & > div {
      height: 160px;
      width: 400px;
      border-radius: 30px;
    }
  }
  ${SMART_SIZE} {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: ${MAIN_FULL_WIDTH};
  }
`;

export const ReservationFooterModalText = styled.p`
  margin: 0px 21px;
  margin-top: 5px;
`;

export const ReservationFooterModalButton = styled.button`
  width: 150px;
  height: 50px;
  border: 3px solid ${RED};
  box-shadow: 0px 5px 8px ${LARGE_BUTTON_BOX_SHADOW_COLOR};ReservationFooterModalModal
  font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE};
  font-weight: 700;
  line-height: 29px;
  background: ${WHITE};
  border-radius: 50px;
  color: ${RED};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 34px;

  &:disabled {
    opacity: 0.6 !important;
    background: ${WHITE} !important;
    color: ${RED} !important;
  }

  &:hover {
    background: ${RED};
    color: ${WHITE};
    border: ${MAIN_BUTTON_BORDER_WIDTH} solid ${RED};
  }

  ${SMART_SIZE} {
    width: ${MAIN_FULL_WIDTH};
  }
`;

export const ReservationFooterModalButtonLayout = styled.div`
  display: flex;
`;
