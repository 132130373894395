import { css } from '@emotion/react';
import { RED, WHITE } from './color';
// import { BLACK } from './color';

export const GlOBAL_COMMON_CSS = css`
  * {
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    font-size: 1rem;
    font-family: 'Noto Sans JP', '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック',
      'Yu Gothic', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', sans-serif;
  }

  .modal-backdrop {
    width: 100%;
    height: 100%;
  }

  /* *************************************** */
  /* ログインのタブ */
  /* *************************************** */
  .nav-tabs .nav-link.active {
    background-color: ${RED} !important;
    border-color: ${RED} !important;
    color: ${WHITE};
  }

  .nav-link:focus,
  .nav-link:hover {
    color: ${WHITE};
  }

  .align-items-center {
    align-items: center !important;
  }
`;

export const PC_SIZE = '@media screen and (min-width: 992px)' as const;
export const TABLET_SIZE = '@media screen and (max-width: 991px)' as const;
export const SMART_SIZE = '@media screen and (max-width: 575px)' as const;
export const IPHONE_SIZE = '@media screen and (max-width: 375px)' as const;
export const IPHONE_SIZE_MIN = '@media screen and (max-width: 320px)' as const;
