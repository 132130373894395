import React, { FC, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import MyPageLayout from '../../../components/Layouts/MyPage/MyPageLayout.index';

import CustomButton from '../../../components/Common/Button/style';
import CustomButtonLabel from '../../../components/Common/Button/Label';
import MonthInput from '../../../components/Common/Button/monthInput';
import { Layout, Buttonside } from './Month.start.style';

import { WHITE, RED } from '../../../styles/color';

import FW from '../../../config/word.json';
import FS from '../../../config/sentence.json';

import useAuthorizationNormal from '../../../hooks/Auth/useAuthorizationNormal';

type RecessMonth = {
  start_month: string;
  range: string[] | [];
};

/**
 * 休会開始月選択画面
 * @returns 休会開始月選択のHTML
 */
const Month_start: FC = () => {
  const history = useHistory();
  const [isDisabled, setDisabled] = useState(true);
  const months = JSON.parse(sessionStorage.getItem('months') ?? '{}');
  const startMonth = sessionStorage.getItem('startMonth') ?? '';
  let content;

  // ログイン認証を設定する
  useAuthorizationNormal();

  /**
   * カレンダーアイコンの表示・非表示を設定する
   * @param id タグID
   * @param option 表示オプション
   */
  const showDisplay = (id: string, option: boolean) => {
    const elem = document.getElementById(id);
    if (elem) elem.style.display = option ? 'block' : 'none';
  };

  /**
   * 開始月選択状態を設定する
   * @param id タグID
   * @param option 選択するかどうか
   */
  const setChecked = (id: string, option: boolean) => {
    const elem = document.getElementById(id);
    if (elem) {
      if (option) {
        elem.style.backgroundColor = RED;
        elem.style.color = WHITE;
      } else {
        elem.style.backgroundColor = WHITE;
        elem.style.color = RED;
      }
    }
  };

  // 開始月を変更して、対応するタグのスタイルを合わせて変更する。
  const onChangeStartMonth = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id } = event.target;
      for (let i = 0; i < months.length; i += 1) {
        if (i === Number(id)) {
          showDisplay(`calender${i.toString()}`, false);
          showDisplay(`calenderActive${i.toString()}`, true);
          setChecked(months[i].start_month, true);
        } else {
          showDisplay(`calender${i.toString()}`, true);
          showDisplay(`calenderActive${i.toString()}`, false);
          setChecked(months[i].start_month, false);
        }
      }
      // 開始月を保存する
      sessionStorage.setItem('startMonth', event.target.value);
      // 終了月をクリアする
      sessionStorage.removeItem('endMonth');
      setDisabled(false);
    },
    [months],
  );

  // 開始月表示エリアを構築する
  if (months.length > 0) {
    content = months.map((month: RecessMonth, index: number) => (
      <MonthInput key={month.start_month} htmlFor={index.toString()} id={month.start_month}>
        <input
          type="radio"
          name="startMonth"
          value={month.start_month}
          id={index.toString()}
          onChange={onChangeStartMonth}
        />
        <img src="/images/icons/calendar-alt.svg" alt="Calender" id={`calender${index.toString()}`} />
        <img
          style={{ display: 'none' }}
          src="/images/icons/calendar-alt-active.svg"
          alt="Calender"
          id={`calenderActive${index.toString()}`}
        />
        <span>{Number(month.start_month.substring(4, 6))}月</span>
      </MonthInput>
    ));
  }

  /**
   * 画面描画時の処理
   * 開始月が選択済であれば、選択状態を設定する
   */
  useEffect(() => {
    if (startMonth !== null && months.length > 0) {
      for (let i = 0; i < months.length; i += 1) {
        if (startMonth === months[i].start_month) {
          showDisplay(`calender${i.toString()}`, false);
          showDisplay(`calenderActive${i.toString()}`, true);
          setChecked(months[i].start_month, true);
          setDisabled(false);
        } else {
          showDisplay(`calender${i.toString()}`, true);
          showDisplay(`calenderActive${i.toString()}`, false);
          setChecked(months[i].start_month, false);
        }
      }
    }
  }, [months, startMonth]);

  /**
   * 次へボタンを押下時の処理
   *   → 終了月を選択する画面へ遷移
   */
  const onClickGo = useCallback(() => {
    history.push('/recess/month-end');
  }, [history]);

  /**
   * 次へボタンを押下時の処理
   *   → 休会情報画面へ遷移
   */
  const onClickBack = useCallback(() => {
    history.push('/recess/recess-info');
  }, [history]);

  return (
    <>
      <MyPageLayout>
        <Layout>
          <div>
            <div>
              <h1>{FS.F_S_RECESS_START_MONTH_TEXT_1}</h1>
            </div>
            <section>
              <div>
                <p>{FS.F_S_RECESS_START_MONTH_TEXT_2}</p>
                <div>{content}</div>
              </div>
            </section>

            <Buttonside>
              <CustomButton type="button" move="back" location="left" onClick={onClickBack}>
                <CustomButtonLabel label={FW.F_W_GO_BEFORE} arrowLocation="left" />
              </CustomButton>
              <CustomButton type="button" move="go" location="right" onClick={onClickGo} disabled={isDisabled}>
                <CustomButtonLabel label={FW.F_W_GO_NEXT} arrowLocation="right" />
              </CustomButton>
            </Buttonside>
          </div>
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Month_start;
