import styled from '@emotion/styled';
import {
  MIRA_UNPAID_LAYOUT_BUTTON_BORDER_RADIUS,
  MIRA_UNPAID_LAYOUT_BUTTON_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_HEIGHT,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_WIDTH,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLE_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_WIDTH,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BUTTON_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM,
  MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH,
  MIRA_UNPAID_LAYOUT_TITLE_P_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TITLE_P_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TITLE_P_PC_TABLET_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH,
  MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TITLE_P_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TITLE_P_SMART_MARGIN_TOP,
  MAIN_FULL_WIDTH,
  MIRA_UNPAID_LAYOUT_BOX_BORDER_RADIUS,
  MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_TOP,
  MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_BOTTOM,
  MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_TOP,
  MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_BOTTOM,
  MIRA_UNPAID_LAYOUT_PC_BOX_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_BOX_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SMART_BOX_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BOX_H3_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_H3_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_H3_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_PC_BOX_H3_TEXT_UNDERLINE_OFFSET,
  MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_H3_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_PC_BOX_SPAN_PC_TABLET_MARGIN_LEFT,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_SMART_MARGIN_LEFT,
  MIRA_UNPAID_LAYOUT_BOX_UL_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BOX_UL_TABLET_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BOX_LI_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_LI_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_LI_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_LI_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BOX_LI_PADDING_LEFT,
  MIRA_UNPAID_LAYOUT_BOX_LI_TEXT_INDENT,
  MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TITLE_P_PC_LINE_HEIGHT,
  MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT,
  MIRA_RECESS_LAYOUT_BODY_FONT_WEIGHT,
} from '../../../styles/size';
import { SMART_SIZE, TABLET_SIZE } from '../../../styles/common';
import { BLACK, BOX_BACKGROUND_PINK, DESCRIPTION_TEXT_BLUE, RED } from '../../../styles/color';

export const Layout = styled.div`
  margin-top: ${MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP};
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: ${MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM};
  & > div {
    & > div {
      max-width: ${MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH};
      margin-left: auto;
      margin-right: auto;
      & > h1 {
        font-size: ${MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE};
        line-height: ${MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING};
        color: ${BLACK};
        font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
      }
      & > p {
        font-size: ${MIRA_UNPAID_LAYOUT_TITLE_P_PC_FONT_SIZE};
        line-height: ${MIRA_UNPAID_LAYOUT_TITLE_P_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_UNPAID_LAYOUT_TITLE_P_PC_LETTER_SPACING};
        font-weight: 500;
        color: ${DESCRIPTION_TEXT_BLUE};
        margin-top: ${MIRA_UNPAID_LAYOUT_TITLE_P_PC_TABLET_MARGIN_TOP};
      }
      & > div {
        width: ${MAIN_FULL_WIDTH};
        display: inline-block;
        background: ${BOX_BACKGROUND_PINK};
        border-radius: ${MIRA_UNPAID_LAYOUT_BOX_BORDER_RADIUS};
        padding-top: ${MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_TOP};
        padding-bottom: ${MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_BOTTOM};
        margin-top: ${MIRA_UNPAID_LAYOUT_PC_BOX_MARGIN_TOP};
        & > h3 {
          font-size: ${MIRA_UNPAID_LAYOUT_BOX_H3_PC_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_BOX_H3_PC_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_H3_PC_LETTER_SPACING};
          text-decoration: underline;
          text-underline-offset: ${MIRA_UNPAID_LAYOUT_PC_BOX_H3_TEXT_UNDERLINE_OFFSET};
          font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
          display: inline-block;
        }
        & > span {
          font-size: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LETTER_SPACING};
          font-weight: 500;
          text-decoration: none;
          color: black;
          margin-left: ${MIRA_UNPAID_LAYOUT_PC_BOX_SPAN_PC_TABLET_MARGIN_LEFT};
          display: inline-block;
        }
      }
      & > ul {
        list-style: none;
        margin-top: ${MIRA_UNPAID_LAYOUT_BOX_UL_PC_MARGIN_TOP} !important;
        margin-left: 0;
        padding-left: 0;
        & > li {
          text-align: left;
          font-size: ${MIRA_UNPAID_LAYOUT_BOX_LI_PC_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_BOX_LI_PC_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_LI_PC_LETTER_SPACING};
          font-weight: ${MIRA_RECESS_LAYOUT_BODY_FONT_WEIGHT};
          margin-top: ${MIRA_UNPAID_LAYOUT_BOX_LI_PC_MARGIN_TOP};
          padding-left: ${MIRA_UNPAID_LAYOUT_BOX_LI_PADDING_LEFT};
          text-indent: ${MIRA_UNPAID_LAYOUT_BOX_LI_TEXT_INDENT};
          & span {
            color: ${RED};
          }
        }
        & > li:before {
          content: '〇';
        }
      }
    }

    & > button {
      margin-top: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_MARGIN_TOP};
      width: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_WIDTH};
      height: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_HEIGHT};
      font-size: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_FONT_SIZE};
      line-height: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_LINE_HEIGHT};
      letter-spacing: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_LETTER_SPACING};
      color: ${RED};
      font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
      border-radius: ${MIRA_UNPAID_LAYOUT_BUTTON_BORDER_RADIUS};
    }
  }
  ${TABLET_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP};
    display: flex;
    justify-content: center;
    text-align: center;
    & > div {
      & > div {
        max-width: ${MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH};
        margin-left: auto;
        margin-right: auto;
        & > h1 {
          font-size: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING};
          color: ${BLACK};
          font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
        }
        & p {
          font-size: ${MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LETTER_SPACING};
        }
        & > div {
          margin-top: ${MIRA_UNPAID_LAYOUT_TABLET_BOX_MARGIN_TOP};
          & > h3 {
            font-size: ${MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LETTER_SPACING};
            display: inline-block;
          }
          & > span {
            font-size: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LETTER_SPACING};
            display: inline-block;
          }
        }
        & > ul {
          list-style: none;
          margin-top: ${MIRA_UNPAID_LAYOUT_BOX_UL_TABLET_SMART_MARGIN_TOP} !important;
          & > li {
            font-size: ${MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LETTER_SPACING};
            margin-top: ${MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_MARGIN_TOP};
          }
        }
      }
      & > button {
        margin-top: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLE_MARGIN_TOP};
        width: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_WIDTH};
        height: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT};
        font-size: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE};
        line-height: ${MIRA_UNPAID_LAYOUT_BUTTON_LINE_HEIGHT};
        letter-spacing: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_LETTER_SPACING};
        color: ${RED};
        font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
      }
    }
  }
  ${SMART_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP};
    & > div {
      & div {
        margin-left: auto;
        margin-right: auto;
        & > h1 {
          font-size: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING};
          color: ${BLACK};
          font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
        }
        & p {
          font-size: ${MIRA_UNPAID_LAYOUT_TITLE_P_SMART_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LETTER_SPACING};
          margin-top: ${MIRA_UNPAID_LAYOUT_TITLE_P_SMART_MARGIN_TOP};
        }
        & > div {
          display: grid;
          padding-top: ${MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_TOP};
          padding-bottom: ${MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_BOTTOM};
          margin-top: ${MIRA_UNPAID_LAYOUT_SMART_BOX_MARGIN_TOP};
          & > h3 {
            font-size: ${MIRA_UNPAID_LAYOUT_BOX_H3_SMART_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LETTER_SPACING};
          }
          & > span {
            margin-left: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_SMART_MARGIN_LEFT};
          }
        }
        & > ul {
          list-style: none;
        }
      }
      & > button {
        margin-top: ${MIRA_UNPAID_LAYOUT_BUTTON_SMART_MARGIN_TOP};
      }
    }
  }
`;
