import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { API_BASE_ADDRESS } from '../api';

const mailServices = (action: any) => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  const postSendMailData = {
    member_id: action.memberID,
    mail_address: action.mailAddress,
  };

  const putMailAddressData = {
    code: action.code,
  };

  return {
    postSendMail: () => axios.post(`${API_BASE_ADDRESS}/member/send-mail`, postSendMailData, config),
    putMailAddress: () => axios.put(`${API_BASE_ADDRESS}/member/mailaddress`, putMailAddressData, config),
  };
};

export default mailServices;
