import produce from 'immer';
import { AnyAction } from 'redux';
import {
  POST_SEND_MAIL_SUCCESS,
  POST_SEND_MAIL_FAILURE,
  POST_SEND_MAIL_REQUEST,
  POST_SEND_MAIL_INITIAL,
} from '../../actions';

export type postSendMailType = {
  postSendMailResult: {
    isLoading: boolean;
    isLoaded: boolean;
    result: number;
  };
};

const initialState: postSendMailType = {
  postSendMailResult: {
    isLoading: false,
    isLoaded: false,
    result: -1,
  },
};

const postSendMailReducer = (state = initialState, action: AnyAction): postSendMailType => {
  return produce(state, (stateDraft) => {
    const draft = stateDraft;
    switch (action.type) {
      case POST_SEND_MAIL_REQUEST:
        draft.postSendMailResult.isLoading = true;
        draft.postSendMailResult.isLoaded = false;
        draft.postSendMailResult.result = -1;
        break;
      case POST_SEND_MAIL_SUCCESS:
        draft.postSendMailResult.isLoading = false;
        draft.postSendMailResult.isLoaded = true;
        draft.postSendMailResult.result = action.data.result;
        break;
      case POST_SEND_MAIL_FAILURE:
        draft.postSendMailResult.isLoading = false;
        draft.postSendMailResult.isLoaded = false;
        draft.postSendMailResult.result = -1;
        break;
      case POST_SEND_MAIL_INITIAL:
        draft.postSendMailResult.isLoading = false;
        draft.postSendMailResult.isLoaded = false;
        draft.postSendMailResult.result = -1;
        break;
      default:
        break;
    }
  });
};

export default postSendMailReducer;
