import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

const useAuthorizationMailAddress = (): void => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const isLogin = JSON.parse(sessionStorage.getItem('member_info') || '{}');
    if (query.get('code')) {
      const currentPage = history.location.pathname;
      history.push(currentPage);
    } else if (isLogin.result) {
      const currentPage = history.location.pathname;
      history.push(currentPage);
    } else {
      sessionStorage.clear();
      const url = '/';
      history.push(url);
    }
  }, [history, search, dispatch]);
};

export default useAuthorizationMailAddress;
