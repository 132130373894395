import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { API_BASE_ADDRESS } from '../api';

const creditChangeCheckService = (action: any) => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  const memberId = action.member_id;

  return {
    getCreditChangeCheck: () => axios.get(`${API_BASE_ADDRESS}/credit/change/check?member_id=${memberId}`, config),
  };
};

export default creditChangeCheckService;
