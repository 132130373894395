import styled from '@emotion/styled';
import { Modal } from 'react-bootstrap';
import {
  MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM,
  ERROR_DESCRIPTION,
  ERROR_SMART_DESCRIPTION,
  MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_MAIN_TABLET_MAX_WIDTH,
  MAIN_FULL_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE,
  MIRA_RECESS_LAYOUT_MODAL_PC_CONTENT_HEIGHT,
  MIRA_RECESS_LAYOUT_MODAL_TABLET_SMART_CONTENT_HEIGHT,
  MIRA_RECESS_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH,
  MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_WIDTH,
  MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_HEIGHT,
  MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_MAX_WIDTH,
  MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_WIDTH,
  MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT,
  MIRA_RECESS_LAYOUT_MODAL_MESSAGE_PC_FONT_SIZE,
  MIRA_RECESS_LAYOUT_MODAL_MESSAGE_TABLET_FONT_SIZE,
  MIRA_RECESS_LAYOUT_MODAL_MESSAGE_SMART_FONT_SIZE,
  MIRA_RECESS_LAYOUT_MODAL_MESSAGE_PC_LAYOUT_HEIGHT,
  MIRA_RECESS_LAYOUT_MODAL_MESSAGE_TABLE_SMART_LAYOUT_HEIGHT,
  MIRA_RECESS_LAYOUT_MODAL_LAYOUT_BORDER_RADIUS,
  MIRA_RECESS_LAYOUT_MODAL_MESSAGE_LAYOUT_PADDING,
  MIRA_RECESS_LAYOUT_MODAL_MESSAGE_LAYOUT_PADDING_TOP,
  MAIN_BUTTON_BORDER_WIDTH,
} from '../../styles/size';
import { SMART_SIZE, TABLET_SIZE } from '../../styles/common';
import { LARGE_BUTTON_BOX_SHADOW_COLOR, LIGHT_GRAY, LIGHT_GRAY_BORDER, WHITE } from '../../styles/color';

export const Layout = styled.div`
  margin-top: ${MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP};
  justify-content: center;
  text-align: center;
  margin-bottom: ${MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM};

  ${TABLET_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP};
    justify-content: center;
    text-align: center;
  }

  ${SMART_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP};
  }
`;

export const ErrorMessageDescription = styled.p`
  font-size: ${ERROR_DESCRIPTION};
  line-height: 1.5;
  white-space: pre-line;
  ${SMART_SIZE}: {
    font-size: ${ERROR_SMART_DESCRIPTION};
  }
`;

export const MessageModal = styled(Modal)`
  padding-left: 40px !important;
  padding-right: 40px !important;
  & > div {
    max-width: ${MIRA_RECESS_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH};
    display: flex;
    align-items: center;
    height: ${MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT};
    justify-content: center;
    & > div {
      height: ${MIRA_RECESS_LAYOUT_MODAL_PC_CONTENT_HEIGHT};
      border-radius: ${MIRA_RECESS_LAYOUT_MODAL_LAYOUT_BORDER_RADIUS};
    }
  }
  ${TABLET_SIZE} {
    & > div {
      max-width: ${MIRA_HOME_LAYOUT_MODAL_MAIN_TABLET_MAX_WIDTH};
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        height: ${MIRA_RECESS_LAYOUT_MODAL_TABLET_SMART_CONTENT_HEIGHT};
      }
    }
  }
  ${SMART_SIZE} {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: ${MAIN_FULL_WIDTH};
  }
`;

export const MessageModalBodyLayout = styled.div`
  width: ${MAIN_FULL_WIDTH};
  height: ${MIRA_RECESS_LAYOUT_MODAL_PC_CONTENT_HEIGHT};
  text-align: center;
  padding: ${MIRA_RECESS_LAYOUT_MODAL_MESSAGE_LAYOUT_PADDING};
  padding-top: ${MIRA_RECESS_LAYOUT_MODAL_MESSAGE_LAYOUT_PADDING_TOP};
  ${TABLET_SIZE} {
    height: ${MIRA_RECESS_LAYOUT_MODAL_TABLET_SMART_CONTENT_HEIGHT};
  }
`;

export const MessageModalErrorMessage = styled.p`
  text-align: center;
  letter-spacing: -0.1px;
  height: ${MIRA_RECESS_LAYOUT_MODAL_MESSAGE_PC_LAYOUT_HEIGHT};
  ${TABLET_SIZE} {
    height: ${MIRA_RECESS_LAYOUT_MODAL_MESSAGE_TABLE_SMART_LAYOUT_HEIGHT};
  }
`;

export const MessageModalErrorMessageText = styled.span`
  margin-bottom: -10px;
  font-size: ${MIRA_RECESS_LAYOUT_MODAL_MESSAGE_PC_FONT_SIZE};

  ${TABLET_SIZE} {
    font-size: ${MIRA_RECESS_LAYOUT_MODAL_MESSAGE_TABLET_FONT_SIZE};
  }
  ${SMART_SIZE} {
    font-size: ${MIRA_RECESS_LAYOUT_MODAL_MESSAGE_SMART_FONT_SIZE};
  }
`;

export const MessageModalButton = styled.button`
  width: ${MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_WIDTH};
  height: ${MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_HEIGHT};
  border: ${MAIN_BUTTON_BORDER_WIDTH} solid ${LIGHT_GRAY_BORDER};
  box-shadow: 0px 5px 8px ${LARGE_BUTTON_BOX_SHADOW_COLOR};
  font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE};
  font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
  line-height: 29px;
  background: ${WHITE};
  border-radius: ${MIRA_RECESS_LAYOUT_MODAL_LAYOUT_BORDER_RADIUS};
  color: ${LIGHT_GRAY};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &:hover {
    background: ${LIGHT_GRAY};
    color: ${WHITE};
    border: ${MAIN_BUTTON_BORDER_WIDTH} solid ${LIGHT_GRAY_BORDER};
  }
  ${TABLET_SIZE} {
    max-width: ${MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_MAX_WIDTH};
    width: ${MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_WIDTH};
    height: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_HEIGHT};
    font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_FONT_SIZE};
  }

  ${SMART_SIZE} {
    width: ${MAIN_FULL_WIDTH};
  }
`;
