import styled from '@emotion/styled';
import {
  MIRA_SMART_HEADER_BORDER_WIDTH,
  MIRA_SMART_HEADER_PADDING_LEFT_RIGHT,
  MIRA_SMART_HEADER_PADDING_TOP_BOTTOM,
} from '../../../../../styles/size';
import { LIGHT_GRAY } from '../../../../../styles/color';

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${MIRA_SMART_HEADER_PADDING_TOP_BOTTOM} ${MIRA_SMART_HEADER_PADDING_LEFT_RIGHT};
  border-bottom: ${MIRA_SMART_HEADER_BORDER_WIDTH} solid ${LIGHT_GRAY};
`;
