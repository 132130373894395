import { useState, useEffect } from 'react';

const useWindowSize = (getIsMobile: () => boolean): boolean => {
  const [isMobile, setIsMobile] = useState(getIsMobile);
  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [getIsMobile]);

  return isMobile;
};

export default useWindowSize;
