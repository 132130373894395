import produce from 'immer';
import { AnyAction } from 'redux';
import { PAYMENT_SUCCESS, PAYMENT_FAILURE, PAYMENT_REQUEST } from '../../actions';

// タイプを設定
export type paymentStateType = {
  isPaymentLoading: boolean;
  isPaymentLoaded: boolean;
  paymentDatas: paymentType;
};

type paymentType = {
  html: string;
};

// ステイタスを初期化
const initialState: paymentStateType = {
  isPaymentLoading: false,
  isPaymentLoaded: false,
  paymentDatas: {
    html: '',
  },
};

// APIの結果によってステイタスが更新される処理
const paymentReducer = (state = initialState, action: AnyAction): paymentStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case PAYMENT_REQUEST:
        dataState.isPaymentLoading = true;
        dataState.isPaymentLoaded = false;
        dataState.paymentDatas.html = '';
        break;
      case PAYMENT_SUCCESS:
        dataState.isPaymentLoading = false;
        dataState.isPaymentLoaded = true;
        dataState.paymentDatas.html = action.res.data.html;
        break;
      case PAYMENT_FAILURE:
        dataState.isPaymentLoading = false;
        dataState.isPaymentLoaded = false;
        dataState.paymentDatas.html = '';
        break;
      default:
        break;
    }
  });
};

export default paymentReducer;
