import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import {
  GET_PASSWORD_STATUS_REQUEST,
  GET_PASSWORD_STATUS_SUCCESS,
  GET_PASSWORD_STATUS_FAILURE,
  COMMON_FAILURE,
} from '../../actions';
import { passwordService } from '../../services';
import { historyType } from '../../../utils/commonType';

export type getPasswordStatusAction = {
  type: typeof GET_PASSWORD_STATUS_REQUEST;
  memberID: string;
  history: historyType;
};

function* getPasswordStatus(action: getPasswordStatusAction): Generator<unknown> {
  try {
    const res: any = yield call(passwordService(action).getPasswordStatus);
    yield put({
      type: GET_PASSWORD_STATUS_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: GET_PASSWORD_STATUS_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchGetPasswordStatus() {
  yield takeLatest(GET_PASSWORD_STATUS_REQUEST, getPasswordStatus);
}
export default function* getPasswordStatusSaga(): Generator<unknown> {
  yield all([fork(watchGetPasswordStatus)]);
}
