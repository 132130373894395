/**
 * Redux-sagaのメイン
 * Redux-sagaはAPIの非同期処理を行う
 */
import { all, AllEffect } from '@redux-saga/core/effects';
import { call, Effect } from 'redux-saga/effects';
import loginSaga from './login/loginSaga';
import homeSaga from './home/homeSaga';
import unpaidSaga from './unpaid/unpaidSaga';
import unpaidOperationCheckSaga from './unpaidOperationCheck/unpaidOperationCheckSaga';
import paymentSaga from './payment/paymentSaga';
import referralCodeSaga from './referralCode/referralCodeSaga';
import customerIdSaga from './customerId/customerIdSaga';
import creditChangeCheckSaga from './creditChangeCheck/creditChangeCheckSaga';
import passwordSaga from './password/index';
import mailSaga from './mail/index';
import menuSaga from './menu/menuSaga';
import memberTransferStatusSaga from './recess/memberTransferStatusSaga';
import recessCancelSaga from './recess/recessCancelSaga';
import recessSaga from './recess/recessSaga';
import recessStatusSaga from './recess/recessStatusSaga';
import buttonSaga from './button/buttonSaga';

// 他のRedux-sagaを組み立てる
export default function* rootSagas(): Generator<AllEffect<Effect>> {
  yield all([
    call(loginSaga),
    call(homeSaga),
    call(unpaidSaga),
    call(unpaidOperationCheckSaga),
    call(referralCodeSaga),
    call(paymentSaga),
    call(customerIdSaga),
    call(creditChangeCheckSaga),
    call(passwordSaga),
    call(mailSaga),
    call(menuSaga),
    call(memberTransferStatusSaga),
    call(recessCancelSaga),
    call(recessSaga),
    call(recessStatusSaga),
    call(buttonSaga),
  ]);
}
