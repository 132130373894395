import React, { FC, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';

import {
  ERROR_DESCRIPTION,
  ERROR_SMART_DESCRIPTION,
  MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT,
  MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT,
  MAIN_SMART_LAYOUT_MARGIN_LEFT,
  MAIN_SMART_LAYOUT_MARGIN_RIGHT,
  LAYOUT_WIDTH,
  ERROR_DESCRIPTION_MARGIN_BOTTOM,
  MAIN_LAYOUT_MIN_HEIGHT,
  MAIN_LAYOUT_MIN_HEIGHT_MINUS,
} from '../../styles/size';
import FS from '../../config/sentence.json';
import { SMART_SIZE } from '../../styles/common';
import MyPageLayout from '../../components/Layouts/MyPage/MyPageLayout.index';

const Layout = styled.div`
  height: calc(${MAIN_LAYOUT_MIN_HEIGHT} - ${MAIN_LAYOUT_MIN_HEIGHT_MINUS});
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: ${LAYOUT_WIDTH};
  text-align: center;
  padding-left: ${MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT};
  padding-right: ${MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT};
  ${SMART_SIZE} {
    padding-left: ${MAIN_SMART_LAYOUT_MARGIN_LEFT};
    padding-right: ${MAIN_SMART_LAYOUT_MARGIN_RIGHT};
  }
`;

const ErrorDescription = styled.p`
  font-size: ${ERROR_DESCRIPTION};
  margin-bottom: ${ERROR_DESCRIPTION_MARGIN_BOTTOM};
  line-height: 1.5;
  white-space: pre-line;
  ${SMART_SIZE} {
    font-size: ${ERROR_SMART_DESCRIPTION};
  }
`;

const ErrorPayment: FC = () => {
  const history = useHistory();

  useLayoutEffect(() => {
    const memberID = sessionStorage.getItem('memberID') ?? '';
    if (memberID === '') {
      history.push('/');
    }
    const isLogin = JSON.parse(sessionStorage.getItem('member_info') || '{}');
    if (!isLogin.result) {
      history.push('/home');
    }
  }, [history]);

  return (
    <>
      <MyPageLayout>
        <Layout>
          {sessionStorage.getItem('paymentType') !== null && sessionStorage.getItem('paymentType') === 'creditcard' ? (
            <ErrorDescription>{FS.F_S_CREDIT_CARD_PAYMENT_ERROR}</ErrorDescription>
          ) : (
            <ErrorDescription>{FS.F_S_UNPAID_PAYMENT_ERROR}</ErrorDescription>
          )}
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default ErrorPayment;
