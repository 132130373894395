import produce from 'immer';
import { AnyAction } from 'redux';
import {
  UNPAID_OPERATION_CHECK_SUCCESS,
  UNPAID_OPERATION_CHECK_FAILURE,
  UNPAID_OPERATION_CHECK_REQUEST,
  UNPAID_OPERATION_CHECK_INITIAL,
} from '../../actions';

// タイプを設定
export type unpaidOperationCheckType = {
  unpaidOperationCheckResult: {
    isLoading: boolean;
    isLoaded: boolean;
    isLoadFailed: boolean;
    result: number;
  };
};

// ステイタスを初期化
const initialState: unpaidOperationCheckType = {
  unpaidOperationCheckResult: {
    isLoading: false,
    isLoaded: false,
    isLoadFailed: false,
    result: -1,
  },
};

// APIの結果によってステイタスが更新される処理
const unpaidOperationCheckReducer = (state = initialState, action: AnyAction): unpaidOperationCheckType => {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case UNPAID_OPERATION_CHECK_REQUEST:
        draft.unpaidOperationCheckResult.isLoading = true;
        draft.unpaidOperationCheckResult.isLoaded = false;
        draft.unpaidOperationCheckResult.isLoadFailed = false;
        draft.unpaidOperationCheckResult.result = -1;
        break;
      case UNPAID_OPERATION_CHECK_SUCCESS:
        draft.unpaidOperationCheckResult.isLoading = false;
        draft.unpaidOperationCheckResult.isLoaded = true;
        draft.unpaidOperationCheckResult.isLoadFailed = false;
        draft.unpaidOperationCheckResult.result = action.res.data.result;
        break;
      case UNPAID_OPERATION_CHECK_FAILURE:
        draft.unpaidOperationCheckResult.isLoading = false;
        draft.unpaidOperationCheckResult.isLoaded = false;
        draft.unpaidOperationCheckResult.isLoadFailed = true;
        draft.unpaidOperationCheckResult.result = -1;
        break;
      case UNPAID_OPERATION_CHECK_INITIAL:
        draft.unpaidOperationCheckResult.isLoading = false;
        draft.unpaidOperationCheckResult.isLoaded = false;
        draft.unpaidOperationCheckResult.isLoadFailed = false;
        draft.unpaidOperationCheckResult.result = -1;
        break;
      default:
        break;
    }
  });
};

export default unpaidOperationCheckReducer;
