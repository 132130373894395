import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { DeactivatedText, Layout } from './CreditcardSwitching.style';
import MyPageLayout from '../../components/Layouts/MyPage/MyPageLayout.index';

import CustomButton from '../../components/Common/Button/style';
import CustomButtonLabel from '../../components/Common/Button/Label/index';

import FW from '../../config/word.json';
import FS from '../../config/sentence.json';

import useAuthorizationNormal from '../../hooks/Auth/useAuthorizationNormal';

import { RootState } from '../../store/reducers';
import { PAYMENT_REQUEST, CUSTOMER_ID_REQUEST, CUSTOMER_ID_INITIAL } from '../../store/actions';
import { paymentStateType } from '../../store/reducers/payment/paymentReducer';
import { customerIdStateType } from '../../store/reducers/customerId/customerIdReducer';
import { getCreditChangeCheckType } from '../../store/reducers/creditChangeCheck/creditChangeCheckReducer';

/**
 * クレジットカード切替画面
 * @returns クレジットカード切替のコンポーネント
 */
const CreditCardSwitching: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // クレカ切替操作日チェックAPIのステータス
  const { getCreditChangeCheckResult } = useSelector<RootState, getCreditChangeCheckType>(
    (state) => state.creditChangeCheck,
  );
  // web決済APIのステータス
  const { isPaymentLoaded, paymentDatas } = useSelector<RootState, paymentStateType>((state) => state.payment);
  // 顧客ID払出APIのステータス
  const { isCustomerIdLoaded, customerIdData } = useSelector<RootState, customerIdStateType>(
    (state) => state.customerId,
  );

  // ログイン認証を設定する
  useAuthorizationNormal();

  /**
   * 次へボタンを押下時の処理
   */
  const onClickGo = () => {
    dispatch({
      type: CUSTOMER_ID_REQUEST,
      history,
    });
  };

  /**
   * 画面描画時の処理
   * 顧客ID払出APIが成功して以降からの処理下の処理を通る
   * 顧客ID払出API → 顧客IDを返却 → web決済API
   */
  useEffect(() => {
    if (isCustomerIdLoaded) {
      if (customerIdData !== null) {
        dispatch({
          type: CUSTOMER_ID_INITIAL,
          history,
        });
        let receiptNumber = sessionStorage.getItem('memberID') ?? '';
        for (let i = receiptNumber.length; i < 9; i += 1) {
          receiptNumber = `0${receiptNumber}`;
        }
        const shopId = sessionStorage.getItem('shopId');
        const amount = 0;
        const paymentType = 'creditcard';
        sessionStorage.setItem('paymentType', paymentType);
        dispatch({
          type: PAYMENT_REQUEST,
          paymentType,
          customerId: customerIdData.customer_id,
          receiptNumber,
          shopId,
          amount,
          history,
        });
      }
    }
  }, [isCustomerIdLoaded, customerIdData, dispatch, history]);

  if (isPaymentLoaded) {
    if (paymentDatas !== null) {
      const html = `<html><head><meta name="robots" content="none"><meta charset="SHIFT_JIS"></head><body>${decodeURIComponent(
        paymentDatas.html,
      )}</body><script>document.forms[0].submit();</script></html>`;
      const x = window.open('', '_self');
      if (x != null) {
        x.document.open();
        x.document.write(html);
        x.document.close();
      }
    }
  }

  return (
    <>
      <MyPageLayout>
        {getCreditChangeCheckResult.result === -1 ? (
          <></>
        ) : getCreditChangeCheckResult.result === 0 ? (
          <>
            <Layout>
              <div>
                <p>
                  {FS.F_S_CREDITCARDSWITCHING_TEXT_1}
                  <br />
                  {FS.F_S_CREDITCARDSWITCHING_TEXT_2}
                </p>
                <CustomButton type="button" move="go" location="left" onClick={onClickGo}>
                  <CustomButtonLabel label={FW.F_W_GO_NEXT} arrowLocation="right" />
                </CustomButton>
              </div>
              <DeactivatedText>{FS.F_S_CREDITCARDSWITCHING_FOR_DEACTIVATED}</DeactivatedText>
            </Layout>
          </>
        ) : (
          <Layout>
            <div>
              {getCreditChangeCheckResult.result === 1 ? (
                <>
                  <span>
                    <p>{FS.F_S_CREDIT_CARD_ERROR_MESSAGE_1_1}</p>
                    <p>{FS.F_S_CREDIT_CARD_ERROR_MESSAGE_1_2}</p>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <p>{FS.F_S_CREDIT_CARD_ERROR_MESSAGE_2_1}</p>
                    <p>{FS.F_S_CREDIT_CARD_ERROR_MESSAGE_2_2}</p>
                  </span>
                </>
              )}
            </div>
          </Layout>
        )}
      </MyPageLayout>
    </>
  );
};

export default CreditCardSwitching;
