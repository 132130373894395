import produce from 'immer';
import { AnyAction } from 'redux';
import { COMMON_FAILURE } from '../../actions';

// タイプを設定
export type commonApiStateType = {
  apiStatus: commonApiStatusType;
};

type commonApiStatusType = {
  status: string;
  statusText: string;
};

// ステイタスを初期化
const initialState: commonApiStateType = {
  apiStatus: {
    status: '',
    statusText: '',
  },
};

// APIの結果によってステイタスが更新される処理
const commonReducer = (state = initialState, action: AnyAction): commonApiStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case COMMON_FAILURE:
        dataState.apiStatus = action.error;
        break;
      default:
        break;
    }
  });
};

export default commonReducer;
