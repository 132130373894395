import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { paymentActionType } from '../sagas/payment/paymentSaga';

import { API_BASE_ADDRESS } from '../api';

// APIの住所を設定します。
const paymentServices = (action: paymentActionType): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };
  if (action.paymentType === 'creditcard') {
    return {
      postPayment: () =>
        axios.post(
          `${API_BASE_ADDRESS}/web-payment?customer_id=${action.customerId}&receipt_number=${action.receiptNumber}&shop_id=${action.shopId}
&amount=${action.amount}
&success_url=${API_BASE_ADDRESS}/web-payinfo-pagecon/success
&cancel_url=${API_BASE_ADDRESS}/web-payinfo-pagecon/failure
&error_url=${API_BASE_ADDRESS}/web-payinfo-pagecon/failure
&pagecon_url=${API_BASE_ADDRESS}/web-payinfo-pagecon`,
          null,
          config,
        ),
    };
  }
  return {
    postPayment: () =>
      axios.post(
        `${API_BASE_ADDRESS}/web-payment?customer_id=${action.customerId}&receipt_number=${action.receiptNumber}&shop_id=${action.shopId}
&amount=${action.amount}&unpaid_id=${action.unpaidId}
&success_url=${API_BASE_ADDRESS}/web-unpaid-pagecon/success
&cancel_url=${API_BASE_ADDRESS}/web-unpaid-pagecon/failure
&error_url=${API_BASE_ADDRESS}/web-unpaid-pagecon/failure
&pagecon_url=${API_BASE_ADDRESS}/web-unpaid-pagecon`,
        null,
        config,
      ),
  };
};

export default paymentServices;
