import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import {
  GET_REFERRAL_CODE_REQUEST,
  GET_REFERRAL_CODE_SUCCESS,
  GET_REFERRAL_CODE_FAILURE,
  COMMON_FAILURE,
} from '../../actions';
import { referralCodeService } from '../../services';
import { historyType } from '../../../utils/commonType';

export type getReferralCodeAction = {
  type: typeof GET_REFERRAL_CODE_REQUEST;
  memberID: string;
  history: historyType;
};

function* getReferralCode(action: getReferralCodeAction): Generator<unknown> {
  try {
    const res: any = yield call(referralCodeService(action).getReferralCode);
    yield put({
      type: GET_REFERRAL_CODE_SUCCESS,
      data: res.data,
    });
    if (res.data.result === 0) {
      sessionStorage.setItem('introductionCode', res.data.campaign_code);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: GET_REFERRAL_CODE_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchGetReferralCode() {
  yield takeLatest(GET_REFERRAL_CODE_REQUEST, getReferralCode);
}
export default function* referralCodeSaga(): Generator<unknown> {
  yield all([fork(watchGetReferralCode)]);
}
