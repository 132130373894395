import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';

import { Form, Para, FormLayout, LoginTabs, LoginTab } from './Login.style';
import LoginLayout from '../../components/Layouts/Login/LoginLayout.index';
import LoginLogo from '../../components/Common/Logo/Login/index';

import useInput from '../../hooks/useInput';
import useAuthorizationNormal from '../../hooks/Auth/useAuthorizationNormal';

import FS from '../../config/sentence.json';
import FW from '../../config/word.json';

import { LOG_IN_INITIAL, LOG_IN_REQUEST, PUT_MAIL_ADDRESS_INITIAL } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { postLoginType } from '../../store/reducers/login/loginReducer';

/**
 * ログイン画面
 * @returns ログインのコンポーネント
 */
const Login: FC = () => {
  // ライブラリーを設定する
  const history = useHistory();
  const dispatch = useDispatch();
  // APIの処理のデータを取得する
  const { postLoginResult } = useSelector<RootState, postLoginType>((state) => state.login);

  const [errorLoginClass, setErrorLoginClass] = useState('');

  // ローカルの変数を設定する
  const [memberShip, onChangeMemberShip] = useInput('');
  const [mobileNumber, onChangeMbileNumber] = useInput('');
  const [birthday, onChangeBirthday] = useInput('');

  const [mailAddress, onChangeMailAddress] = useInput('');
  const [mailAddressPassWord, onChangeMailAddressPassWord] = useInput('');

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isMailAddressError, setIsMailAddressError] = useState(false);
  const [mailAddressErrorMessage, setMailAddressErrorMessage] = useState('');

  // ログイン認証を設定する
  useAuthorizationNormal();

  /**
   * 画面描画時の処理
   * ログインAPIが成功してからの処理
   */
  useEffect(() => {
    sessionStorage.clear();
    if (postLoginResult.isLoaded) {
      if (postLoginResult.result === -1) {
        sessionStorage.clear();
      } else if (postLoginResult.result === 0) {
        sessionStorage.setItem('memberID', postLoginResult.member_id.toString());
        sessionStorage.setItem('introductionCode', postLoginResult.introduction_code);
        sessionStorage.setItem('member_info', '{"result": true}');
        sessionStorage.setItem('typeCode', postLoginResult.type_code.toString());
        sessionStorage.setItem('price', postLoginResult.price.toString());
        sessionStorage.setItem('isActive', postLoginResult.is_active.toString());
        sessionStorage.setItem('businessType', postLoginResult.business_type.toString());
        history.push('/home');
      } else {
        sessionStorage.clear();
        history.push('/');
      }
    }
  }, [
    postLoginResult.isLoaded,
    postLoginResult.result,
    postLoginResult.member_id,
    postLoginResult.introduction_code,
    postLoginResult.type_code,
    postLoginResult.is_active,
    postLoginResult.business_type,
    history,
    postLoginResult.price,
  ]);

  /**
   * ログインボタンを押下時の処理
   */
  const onFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setIsError(false);
      setErrorMessage('');
      setIsMailAddressError(false);
      setMailAddressErrorMessage('');
      setErrorLoginClass('');
      dispatch({
        type: LOG_IN_INITIAL,
      });
      dispatch({
        type: PUT_MAIL_ADDRESS_INITIAL,
      });
      const REG_NUMBER = /^[0-9]+$/;
      // バリデーションチェックを行う
      // 携帯電話の正規表現

      if (REG_NUMBER.test(memberShip)) {
        if (REG_NUMBER.test(mobileNumber)) {
          // 日付の正規表現, 日付のformatをチェックする
          if (birthday.length === 8 && REG_NUMBER.test(birthday) && moment(birthday, 'YYYYMMDD').isValid()) {
            setErrorLoginClass('0');
            dispatch({
              type: LOG_IN_REQUEST,
              memberShip,
              mobileNumber,
              birthday,
              loginClass: 0,
              history,
            });
          } else {
            setIsError(true);
            setErrorMessage(FS.F_S_BIRTHDAY_ERROR_MESSAGE);
          }
        } else {
          setIsError(true);
          setErrorMessage(FS.F_S_MOBILE_ERROR_MESSAGE);
        }
      } else {
        setIsError(true);
        setErrorMessage(FS.F_S_MEMBERSHIP_ERROR_MESSAGE);
      }
    },
    [memberShip, mobileNumber, birthday, dispatch, history],
  );

  /**
   * メールアドレスでログインボタンを押下時の処理
   */
  const onFormSubmitMail = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setIsError(false);
      setErrorMessage('');
      setIsMailAddressError(false);
      setMailAddressErrorMessage('');
      setErrorLoginClass('');
      dispatch({
        type: LOG_IN_INITIAL,
      });
      dispatch({
        type: PUT_MAIL_ADDRESS_INITIAL,
      });

      const REG_EMAIL =
        /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // バリデーションチェックの処理を行う
      if (!REG_EMAIL.test(mailAddress)) {
        setIsMailAddressError(true);
        setMailAddressErrorMessage(FS.F_S_EMAIL_ERROR_MESSAGE);
      } else {
        setErrorLoginClass('1');
        dispatch({
          type: LOG_IN_REQUEST,
          mail_address: mailAddress,
          password: mailAddressPassWord,
          loginClass: 1,
          history,
        });
      }
    },
    [dispatch, mailAddress, mailAddressPassWord, history],
  );

  return (
    <>
      <LoginLayout>
        <LoginLogo />
        <Para>{FW.F_W_MY_PAGE}</Para>
        <FormLayout>
          <LoginTabs defaultActiveKey="membership">
            <LoginTab eventKey="membership" title={FW.F_W_LOGIN_MEMBERSHIP}>
              <Form onSubmit={onFormSubmit}>
                <div>
                  <input
                    type="tel"
                    value={memberShip}
                    onChange={onChangeMemberShip}
                    maxLength={10}
                    placeholder={FW.F_W_MEMBERSHIP_NUMBER_HALF_WIDTH}
                    required
                    aria-label="memberShip"
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    value={mobileNumber}
                    onChange={onChangeMbileNumber}
                    maxLength={11}
                    placeholder={FW.F_W_MOBILE_PHONE_NUMBER}
                    required
                    aria-label="mobileNumber"
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    value={birthday}
                    onChange={onChangeBirthday}
                    maxLength={8}
                    placeholder={FW.F_W_DATE_BIRTH_NUMBER}
                    required
                    aria-label="birthday"
                  />
                </div>

                <div>
                  <input type="submit" value="ログイン" aria-label="submit" />
                </div>
                {isError && <p>{errorMessage}</p>}
                {errorMessage.length > 0 ? (
                  <></>
                ) : postLoginResult.logInFailureMessage.length > 0 && errorLoginClass === '0' ? (
                  <p>{postLoginResult.logInFailureMessage}</p>
                ) : (
                  <></>
                )}
              </Form>
            </LoginTab>
            <LoginTab eventKey="email" title={FW.F_W_LOGIN_EMAIL_ADDRESS}>
              <Form onSubmit={onFormSubmitMail}>
                <div>
                  <input
                    type="email"
                    value={mailAddress}
                    onChange={onChangeMailAddress}
                    placeholder={FW.F_W_MAIL_ADDRESS_PLACEHOLDER}
                    maxLength={60}
                    required
                    aria-label="email"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    value={mailAddressPassWord}
                    onChange={onChangeMailAddressPassWord}
                    placeholder={FW.F_W_MAIL_ADDRESS_PASSWORD_PLACEHOLDER}
                    maxLength={64}
                    required
                    aria-label="email-password"
                  />
                </div>
                <p>{FS.F_S_LOGIN_EMAIL_TEXT}</p>
                <div>
                  <input type="submit" value="ログイン" aria-label="submit" />
                </div>
                {isMailAddressError && <p>{mailAddressErrorMessage}</p>}
                {errorMessage.length > 0 ? (
                  <></>
                ) : postLoginResult.logInMailFailureMessage.length > 0 && errorLoginClass === '1' ? (
                  <p>{postLoginResult.logInMailFailureMessage}</p>
                ) : (
                  <></>
                )}
              </Form>
            </LoginTab>
          </LoginTabs>
        </FormLayout>
      </LoginLayout>
    </>
  );
};

export default memo(Login);
