import React, { FC, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import {
  HomeLayout,
  HomeModal,
  HomeModalErrorMessage,
  HomeButton,
  HomeReferenceText,
  HomeRow,
  HomeCol,
  HomeModalHeader,
  HomeModalBody,
  HomeModalButton,
  HomeModalBodyLayout,
  HomeModalBodyText,
  HomeModalInputGroup,
  HomeModalInput,
  HomeModalErrorMessageText,
  HomeColTitle,
  HomeModalButtonSpinner,
} from './Home.style';

import MyPageLayout from '../../components/Layouts/MyPage/MyPageLayout.index';

import useAuthorizationMailAddress from '../../hooks/Auth/useAuthorizationMailAddress';
import useWindowSize from '../../hooks/useWindowSize';

import {
  GET_PASSWORD_STATUS_REQUEST,
  HOME_REQUEST,
  POST_SEND_MAIL_REQUEST,
  PUT_MAIL_ADDRESS_REQUEST,
  PUT_PASSWORD_REQUEST,
} from '../../store/actions';
import { RootState } from '../../store/reducers';
import { homeStateType, optionType } from '../../store/reducers/home/homeReducer';
import { getPasswordStatusType } from '../../store/reducers/password/getPasswordStatusReducer';
import { putMailAddressType } from '../../store/reducers/mail/putMailAddressReducer';
import { putPasswordType } from '../../store/reducers/password/putPasswordReducer';
import { postSendMailType } from '../../store/reducers/mail/postSendMailReducer';

import FW from '../../config/word.json';
import FS from '../../config/sentence.json';

/**
 * ホーム画面
 * @returns ホームのコンポーネント
 */
const Home: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const getIsMobile = () => window.innerWidth < 576;
  const isMobile = useWindowSize(getIsMobile);

  // 契約情報取得APIのステータス
  const { isHomeLoaded, homeDatas } = useSelector<RootState, homeStateType>((state) => state.home);
  // マイページパスワード設定状態チェックAPIのステータス
  const { getPasswordStatusResult } = useSelector<RootState, getPasswordStatusType>(
    (state) => state.password.getPasswordStatus,
  );
  // パスワード変更APIのステータス
  const { putPasswordResult } = useSelector<RootState, putPasswordType>((state) => state.password.putPassword);
  // メールアドレスAPIのステータス
  const { putMailAddressResult } = useSelector<RootState, putMailAddressType>((state) => state.mail.putMailAddress);
  // メールアドレス変更メール送信APIのステータス
  const { postSendMailResult } = useSelector<RootState, postSendMailType>((state) => state.mail.postSendMail);

  // モーダル(true: 開く, false:閉じる)
  const [isModalOpen, setIsModalOpen] = useState(false);
  // モーダルタイプ
  const [modalType, setModalType] = useState('email');
  // モーダル用のエラーメッセージ
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  // メールアドレス
  const [mailAddress, setMailAddress] = useState('');
  // パスワード
  const [password, setPassword] = useState('');
  // パスワード確認
  const [passwordConfirm, setPasswordConfirm] = useState('');

  // モーダルのボタンと入力欄の制御
  const [isModalOnChangeButtonControl, setIsModalOnChangeButtonControl] = useState(false);

  // ログイン認証を設定する
  useAuthorizationMailAddress();

  /**
   * 画面描画時の処理
   * ・セッションストレージと契約情報APIのステータスがなかったら、契約情報取得APIを呼出す処理
   * → (契約情報APIのステータスがない時 === リロードをした時）
   * ・クエリストリングのパラメタの値がある時に、メールアドレス変更APIを呼び出す
   */
  useEffect(() => {
    const query = new URLSearchParams(search);
    if (sessionStorage.getItem('memberID') && homeDatas.member_id === 0) {
      dispatch({
        type: HOME_REQUEST,
        memberID: sessionStorage.getItem('memberID'),
        history,
      });
    } else if (query.get('code')) {
      // 下記のセッションストレージは、ログインを判断するセッションストレージ
      sessionStorage.setItem('member_info', '{"result": true}');
      dispatch({
        type: PUT_MAIL_ADDRESS_REQUEST,
        code: query.get('code'),
        history,
      });
    } else {
      // 何もしない（この処理を通ると、ログインページに遷移される）
    }
  }, [dispatch, history, search, homeDatas.member_id]);
  // → useEffectのdepsの「search」の情報が更新されて、このuseEffectが再実行される(この処理を理解するためは、useEffectを理解しないといけない)

  /**
   * 画面描画時の処理
   * 契約情報取得APIが成功の場合、マイページパスワード設定状態チェックAPIを呼び出す
   */
  useEffect(() => {
    if (isHomeLoaded) {
      dispatch({
        type: GET_PASSWORD_STATUS_REQUEST,
        memberID: sessionStorage.getItem('memberID'),
        history,
      });
      // HOME画面ロードして、店舗IDと会員番号を保存する
      sessionStorage.setItem('shopID', homeDatas.shop_id.toString());
      sessionStorage.setItem('member_id', homeDatas.member_id.toString());
    }
  }, [isHomeLoaded, dispatch, history, homeDatas.shop_id, homeDatas.member_id]);

  /**
   * 画面描画時の処理
   * マイページパスワード設定状態チェックAPIを成功したら、パスワード欄にパスワード設定状態チェックする
   * 入力したパスワードのステータスを初期化する
   */
  useEffect(() => {
    if (getPasswordStatusResult.isLoaded) {
      setMailAddress(homeDatas.personal_info.mail_address);
      setPassword('');
      setPasswordConfirm('');
    }
  }, [getPasswordStatusResult.isLoaded, homeDatas.personal_info.mail_address]);

  /**
   * 画面描画時の処理
   * ログインAPIとメールアドレス変更APIが成功であり、APIの処理結果が正常だったら、契約情報APIを呼び出す。
   */
  useEffect(() => {
    if (putMailAddressResult.isLoaded) {
      if (putMailAddressResult.result !== -1) {
        history.push('/email-address-result');
      }
    }
  }, [putMailAddressResult.isLoaded, putMailAddressResult.result, history]);

  /**
   * 画面描画時の処理
   * メールアドレス変更メール送信APIが成功だったら、下記の処理をする
   */
  useEffect(() => {
    if (postSendMailResult) {
      if (postSendMailResult.result === -1) {
        // 初期化の場合、メッセージを表示しない
        setModalErrorMessage('');
      } else if (postSendMailResult.result === 0) {
        // メールアドレス変更メール送信APIの処理結果(result)が「成功」の場合、メッセージを表示する
        setModalErrorMessage(FS.F_S_POST_MAIL_SEND_RESULT_SUCCESS);
        setIsModalOnChangeButtonControl(true);
      } else if (postSendMailResult.result === 2) {
        // メールアドレス変更メール送信APIの処理結果(result)が「メールアドレスの重複」の場合、メッセージを表示する
        setModalErrorMessage(FS.F_S_POST_MAIL_SEND_RESULT_FAILURE_DUPLICATE);
        setIsModalOnChangeButtonControl(false);
      } else {
        // メールアドレス変更メール送信APIの処理結果(result)が「失敗」の場合、メッセージを表示する
        setModalErrorMessage(FS.F_S_POST_MAIL_SEND_RESULT_FAILURE);
        setIsModalOnChangeButtonControl(true);
      }
    }
  }, [postSendMailResult, postSendMailResult.result]);

  /**
   * 画面描画時の処理
   * 1．パスワード変更が成功の場合
   * 1-1．パスワード変更が成功したら、契約情報取得APIを呼び出す
   * 1-2．ポップアップを閉じる
   *
   * 2．パスワード変更が失敗の場合
   * 2-1. ポップアップが閉じないで、ポップアップの内容だけ変更する
   */
  useEffect(() => {
    if (putPasswordResult.isLoaded) {
      if (putPasswordResult.result === -1) {
        // 初期値なので、何もしない
        setModalErrorMessage('');
      } else if (putPasswordResult.result === 0) {
        // APIの処理結果(result)が「成功」の場合
        const memberID = sessionStorage.getItem('memberID');
        dispatch({
          type: HOME_REQUEST,
          memberID,
          history,
        });
        setModalErrorMessage(FS.F_S_PUT_PASSWORD_RESULT_SUCCESS);
        setIsModalOnChangeButtonControl(true);
      } else if (putPasswordResult.result === 2) {
        // APIの処理結果(result)が「パスワードがメールアドレスまたは携帯電話番号と等しい」場合
        setModalErrorMessage(FS.F_S_PUT_PASSWORD_RESULT_FAILURE_2);
        setIsModalOnChangeButtonControl(false);
      } else {
        // APIの処理結果(result)が「失敗」の場合
        setModalErrorMessage(FS.F_S_PUT_PASSWORD_RESULT_FAILURE);
        setIsModalOnChangeButtonControl(true);
      }
    }
  }, [putPasswordResult.isLoaded, putPasswordResult.result, dispatch, history]);

  /**
   * メールアドレスの変更するボタンを押下時の処理
   */
  const onClickModalEmail = useCallback(() => {
    setModalType('email');
    setMailAddress(homeDatas.personal_info.mail_address);
    setIsModalOpen(true);
    setIsModalOnChangeButtonControl(false);
    setModalErrorMessage('');
  }, [homeDatas.personal_info.mail_address]);

  /**
   * メールアドレスを入力時の処理
   */
  const onChangeMailAddress = useCallback((eve: React.ChangeEvent<HTMLInputElement>) => {
    setModalErrorMessage('');
    setMailAddress(eve.target.value);
  }, []);

  /**
   * メールアドレスのポップアップの変更するボタンを押下時の処理
   */
  const onClickMailAddressUpdate = useCallback(() => {
    const MAIL_ADDRESS_REGEX =
      /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // バリデーションチェックの処理を行う
    if (mailAddress.length === 0 || mailAddress.trim().length === 0) {
      // 何も入力してなかったら、エラーメッセージを常時する
      setModalErrorMessage(FS.F_S_HOME_MAIL_ADDRESS_NO_INPUT);
    } else if (!MAIL_ADDRESS_REGEX.test(mailAddress)) {
      // メールアドレスが正規表現の条件と異なったら、エラー
      setModalErrorMessage(FS.F_S_HOME_MAIL_ADDRESS_ILLEGAL);
    } else {
      dispatch({
        type: POST_SEND_MAIL_REQUEST,
        mailAddress,
        memberID: sessionStorage.getItem('memberID'),
        history,
      });
    }
  }, [mailAddress, dispatch, history]);

  /**
   * パスワードの変更するボタンを押下時の処理
   */
  const onClickModalPassword = useCallback(() => {
    setModalType('password');
    // ここで、パスワードとパスワード確認のAPIを入力する
    setPassword('');
    setPasswordConfirm('');
    setIsModalOpen(true);
    setIsModalOnChangeButtonControl(false);
    setModalErrorMessage('');
  }, []);

  /**
   * パスワードのポップアップの変更するボタンを押下時の処理
   */
  const onClickEditPassword = useCallback(() => {
    // 半角英字：OK
    // 半角数字：OK
    // 半角記号：OK「!"#$%&'()=^~|@`{;+:*},.<>/?_-\[]」
    // スペースキー：NG（半角、全角）
    const PASSWORD_REGEX = /^[0-9a-zA-Z!"#$%&'()=^~//[|@`{;+:*},-.\]\\<>/?_]+$/i;
    // バリデーションチェックの処理を行う
    if (password.trim() === '' || passwordConfirm.trim() === '') {
      // 「パスワード・パスワード確認」文字が空欄だったら、エラー
      setModalErrorMessage(FS.F_S_HOME_PASSWORD_NOT_INPUT);
    } else if (password.length <= 7) {
      // 「パスワード」が7文字以下なら、エラー
      setModalErrorMessage(FS.F_S_HOME_PASSWORD_LENGTH);
    } else if (!PASSWORD_REGEX.test(password)) {
      // 「パスワード・パスワード確認」文字に「スペース」が含まれているなら、エラー
      setModalErrorMessage(FS.F_S_HOME_PASSWORD_ILLEGAL);
    } else if (password !== passwordConfirm) {
      // 「パスワード・パスワード確認」が不一致なら、エラー
      setModalErrorMessage(FS.F_S_HOME_PASSWORD_NOT_MATCH);
    } else {
      dispatch({
        type: PUT_PASSWORD_REQUEST,
        password,
        memberID: sessionStorage.getItem('memberID'),
        history,
      });
    }
  }, [password, passwordConfirm, dispatch, history]);

  /**
   * パスワードを入力時の処理
   */
  const onChangePassword = useCallback((eve: React.ChangeEvent<HTMLInputElement>) => {
    setModalErrorMessage('');
    setPassword(eve.target.value);
  }, []);

  /**
   * パスワード確認を入力時の処理
   */
  const onChangePasswordConfirm = useCallback((eve: React.ChangeEvent<HTMLInputElement>) => {
    setModalErrorMessage('');
    setPasswordConfirm(eve.target.value);
  }, []);

  /**
   * モダールが閉じた時の処理
   */
  const onHideModalClose = useCallback(() => {
    setIsModalOpen(false);
    setModalErrorMessage('');
  }, []);

  return (
    <>
      <MyPageLayout>
        {isHomeLoaded ? (
          <HomeLayout>
            {homeDatas.result === 0 || homeDatas.result === 1 ? (
              <>
                <HomeRow>
                  <HomeCol xs={12} sm={4}>
                    <HomeColTitle>{FW.F_W_MEMBER_NAME}</HomeColTitle>
                  </HomeCol>
                  <HomeCol xs={12} sm={8}>
                    <p>
                      {homeDatas.personal_info.last_name} {homeDatas.personal_info.first_name} {FW.F_W_MR}
                    </p>
                  </HomeCol>
                </HomeRow>
                <HomeRow>
                  <HomeCol xs={12} sm={4}>
                    <HomeColTitle>{FW.F_W_MEMBER_NO}</HomeColTitle>
                  </HomeCol>
                  <HomeCol xs={12} sm={8}>
                    <HomeColTitle>{homeDatas.member_id}</HomeColTitle>
                  </HomeCol>
                </HomeRow>
                <HomeRow>
                  <HomeCol xs={12} sm={4}>
                    <HomeColTitle>{FW.F_W_START_DATE}</HomeColTitle>
                  </HomeCol>
                  <HomeCol xs={12} sm={8}>
                    {homeDatas.result === 0 ? (
                      homeDatas.start_date !== null ? (
                        <>
                          <p>
                            {parseInt(homeDatas.start_date.substring(0, 4), 10)}
                            {FW.F_W_YEAR}
                          </p>
                          <p>
                            {parseInt(homeDatas.start_date.substring(4, 6), 10)}
                            {FW.F_W_MONTH}
                          </p>
                          <p>
                            {parseInt(homeDatas.start_date.substring(6, 8), 10)}
                            {FW.F_W_DAY}
                          </p>
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <p>{homeDatas.member_type_info.start_date}</p>
                    )}
                  </HomeCol>
                </HomeRow>
                <HomeRow>
                  <HomeCol xs={12} sm={4}>
                    <HomeColTitle>{FW.F_W_AFF_SHOP}</HomeColTitle>
                  </HomeCol>
                  <HomeCol xs={12} sm={8}>
                    <HomeColTitle>{homeDatas.shop_name}</HomeColTitle>
                  </HomeCol>
                </HomeRow>
                {/* 次のリリースの対応する(メールアドレス) */}
                <HomeRow>
                  <HomeCol xs={12} sm={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <HomeColTitle>{FW.F_W_EMAIL_ADDRESS}</HomeColTitle>
                  </HomeCol>
                  <HomeCol style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ wordBreak: 'break-all' }}>{homeDatas.personal_info.mail_address}</p>
                  </HomeCol>
                  <HomeCol>
                    <HomeButton onClick={onClickModalEmail}>{FW.F_W_GO_CHANGE}</HomeButton>
                  </HomeCol>
                </HomeRow>
                <HomeRow>
                  <HomeCol xs={12} sm={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <p>{FW.F_W_PASSWORD}</p>
                  </HomeCol>
                  {getPasswordStatusResult.isLoaded ? (
                    <>
                      {getPasswordStatusResult.result === -1 ? (
                        // マイページパスワード設定状態チェックの処理結果(result)が「初期値」の場合
                        // APIが失敗時のステータスも「－1」だが、その場合はエラー画面に遷移する
                        <>
                          <HomeCol style={{ display: 'flex', alignItems: 'center' }}>
                            <p className="d-sm-none mt-2" />
                            <p>{FS.F_S_PASSWORD_STATUS_RESULT_LOADING}</p>
                          </HomeCol>
                        </>
                      ) : getPasswordStatusResult.result === 0 ? (
                        // マイページパスワード設定状態チェックの処理結果(result)が「成功」の場合
                        <>
                          <HomeCol style={{ display: 'flex', alignItems: 'center' }}>
                            <p>
                              {getPasswordStatusResult.has_password ? (
                                // パスワード設定済み
                                <>
                                  {FW.F_W_PASSWORD}
                                  {FW.F_W_CONFIGURED}
                                </>
                              ) : (
                                // パスワード未設定
                                <>
                                  {FW.F_W_PASSWORD}
                                  {FS.F_S_HOME_EMAIL_TEXT_1}
                                  <br />
                                  {FS.F_S_HOME_EMAIL_TEXT_2}
                                </>
                              )}
                            </p>
                          </HomeCol>
                          <HomeCol>
                            <HomeButton onClick={onClickModalPassword}>{FW.F_W_GO_CHANGE}</HomeButton>
                          </HomeCol>
                        </>
                      ) : (
                        // マイページパスワード設定状態チェックの処理結果(result)が「失敗」の場合
                        <>
                          <HomeCol className="align-items-center">
                            <p>{FS.F_S_PASSWORD_STATUS_RESULT_FAIL}</p>
                          </HomeCol>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </HomeRow>
                <HomeRow>
                  <HomeCol xs={12} sm={4}>
                    <HomeColTitle>{FW.F_W_RECESS} </HomeColTitle>
                  </HomeCol>
                  <HomeCol xs={12} sm={8}>
                    {homeDatas &&
                      homeDatas.recesses.map((item: string) => {
                        if (item.length === 6 && parseInt(item, 10) > 0) {
                          return <p key={`recess-${item}`}>{parseInt(item.substr(4, 6), 10)}月&nbsp;</p>;
                        }
                        return <p key={`recess-${item}`}>{item}&nbsp;</p>;
                      })}
                  </HomeCol>
                </HomeRow>
                {homeDatas.result === 0 ? (
                  <HomeRow>
                    <HomeCol xs={12} sm={4}>
                      <HomeColTitle> {FW.F_W_CURR_CONTRACT}</HomeColTitle>
                    </HomeCol>
                    <HomeCol xs={12} sm={8}>
                      <p>
                        {homeDatas.member_type_info.member_type_name !== '' && (
                          <>
                            {homeDatas.member_type_info.member_type_name}
                            {homeDatas.member_type_info.free_cancel_text}
                            <br />
                          </>
                        )}
                        {homeDatas.options !== null &&
                          homeDatas.options.map((item: optionType) => {
                            return (
                              <React.Fragment key={`option-${item.option_id}`}>
                                {item.option_name}
                                {item.free_cancel_text}
                                <br />
                              </React.Fragment>
                            );
                          })}
                      </p>
                    </HomeCol>
                  </HomeRow>
                ) : (
                  <>{/* 契約中ではないなら、現在のご契約内容は表示しない */}</>
                )}
                {homeDatas.penalties != null && homeDatas.penalties.length > 0 ? (
                  <HomeRow>
                    <HomeCol xs={12} sm={4}>
                      <HomeColTitle>
                        {FW.F_W_CAMP_DEDIT1}
                        {isMobile ? <></> : <br />}
                        {FW.F_W_CAMP_DEDIT2}
                      </HomeColTitle>
                    </HomeCol>
                    <HomeCol xs={12} sm={8}>
                      <p>
                        {homeDatas.penalties !== null &&
                          homeDatas.penalties.map((item: string) => {
                            return (
                              <React.Fragment key={`penalty-${item}`}>
                                {item}
                                <br />
                              </React.Fragment>
                            );
                          })}
                      </p>
                    </HomeCol>
                  </HomeRow>
                ) : (
                  <>{/* 違約金期間がなかったら、キャンペーン解約金発生期間は表示しない */}</>
                )}
                <HomeReferenceText>{FS.F_S_CURRENT_CONTRACT_CONFIRM}</HomeReferenceText>
                <HomeReferenceText>{FS.F_S_CURRENT_CONTRACT_CANCEL_CONFIRM}</HomeReferenceText>
              </>
            ) : (
              <></>
            )}
          </HomeLayout>
        ) : (
          <></>
        )}
        <HomeModal show={isModalOpen} onHide={onHideModalClose}>
          <HomeModalHeader closeButton />
          {modalType === 'email' ? (
            <HomeModalBody>
              <HomeModalBodyLayout>
                {isModalOnChangeButtonControl ? (
                  <></>
                ) : (
                  <>
                    <HomeModalBodyText>{FW.F_W_EMAIL_ADDRESS}</HomeModalBodyText>
                    <HomeModalInputGroup className="mb-3">
                      <HomeModalInput type="text" value={mailAddress} onChange={onChangeMailAddress} maxLength={60} />
                    </HomeModalInputGroup>
                  </>
                )}

                {modalErrorMessage.length > 0 ? (
                  <HomeModalErrorMessage>
                    {modalErrorMessage.split('\n').map((value, index) => {
                      return (
                        <React.Fragment key={`text-${index.toString()}-${value}`}>
                          <HomeModalErrorMessageText>{value}</HomeModalErrorMessageText>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </HomeModalErrorMessage>
                ) : (
                  <></>
                )}
                {isModalOnChangeButtonControl ? (
                  <></>
                ) : (
                  <>
                    <HomeModalButton
                      onClick={onClickMailAddressUpdate}
                      disabled={homeDatas.personal_info.mail_address === mailAddress || postSendMailResult.isLoading}
                    >
                      {postSendMailResult.isLoading ? (
                        <>
                          <HomeModalButtonSpinner animation="border" variant="danger" />
                          {FW.F_W_LOADING}
                        </>
                      ) : (
                        <>{FW.F_W_GO_CHANGE}</>
                      )}
                    </HomeModalButton>
                  </>
                )}
              </HomeModalBodyLayout>
            </HomeModalBody>
          ) : modalType === 'password' ? (
            <HomeModalBody>
              <HomeModalBodyLayout>
                {isModalOnChangeButtonControl ? (
                  <></>
                ) : (
                  <>
                    <HomeModalBodyText>{FW.F_W_NEW_PASSWORD}</HomeModalBodyText>
                    <HomeModalInputGroup className="mb-3">
                      <HomeModalInput type="password" value={password} onChange={onChangePassword} maxLength={64} />
                    </HomeModalInputGroup>
                    <HomeModalBodyText>{FW.F_W_NEW_PASSWORD_CONFIRM}</HomeModalBodyText>
                    <HomeModalInputGroup className="mb-3">
                      <HomeModalInput
                        type="password"
                        value={passwordConfirm}
                        onChange={onChangePasswordConfirm}
                        maxLength={64}
                      />
                    </HomeModalInputGroup>
                    <HomeModalBodyText style={{ color: 'red' }}>{FS.F_S_HOME_PASSWORD_TEXT}</HomeModalBodyText>
                  </>
                )}

                {modalErrorMessage.length > 0 ? (
                  <HomeModalErrorMessage>
                    {modalErrorMessage.split('\n').map((value, index) => {
                      return (
                        <React.Fragment key={`text-${index.toString()}-${value}`}>
                          <HomeModalErrorMessageText>{value}</HomeModalErrorMessageText>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </HomeModalErrorMessage>
                ) : (
                  <></>
                )}
                {isModalOnChangeButtonControl ? (
                  <></>
                ) : (
                  <>
                    <HomeModalButton onClick={onClickEditPassword} disabled={putPasswordResult.isLoading}>
                      {putPasswordResult.isLoading ? (
                        <>
                          <HomeModalButtonSpinner animation="border" variant="danger" />
                          {FW.F_W_LOADING}
                        </>
                      ) : (
                        <>{FW.F_W_GO_CHANGE}</>
                      )}
                    </HomeModalButton>
                  </>
                )}
              </HomeModalBodyLayout>
            </HomeModalBody>
          ) : (
            <></>
          )}
        </HomeModal>
      </MyPageLayout>
    </>
  );
};

export default Home;
