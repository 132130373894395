import produce from 'immer';
import { AnyAction } from 'redux';
import { HOME_REQUEST, HOME_SUCCESS, HOME_FAILURE, HOME_INITIAL } from '../../actions';

// タイプを設定
export type homeStateType = {
  isHomeLoading: boolean;
  isHomeLoaded: boolean;
  homeFailureMessage: string;
  homeDatas: homeType;
};

export type optionType = {
  campaign_code: string;
  description: number;
  end_date: string;
  is_continuous_at_transfer: number;
  option_id: number;
  option_name: string;
  free_cancel_text: string;
  price: number;
  shop_id: number;
  start_date: string;
  tax_rate: number;
};

type homeType = {
  UID: string;
  member_id: number;
  member_type_info: memberType;
  options: [];
  outsourcing_code: string;
  personal_info: infoType;
  recesses: [];
  result: number;
  shop_id: number;
  shop_name: string;
  start_date: string;
  penalties: [];
};

type infoType = {
  birthday: string;
  address1: string;
  address2: string;
  building_name: string;
  emergency_number: string;
  emergency_relationship: string;
  first_name: string;
  first_name_ruby: string;
  gender: number;
  last_name: string;
  last_name_ruby: string;
  mail_address: string;
  mobile_number: string;
  parent_first_name: string;
  parent_last_name: string;
  phone_number: string;
  postal_code: string;
};

type memberType = {
  campaign_code: string;
  description: number;
  end_date: string;
  is_not_able_to_cancel_withdrawal: string;
  member_type_id: number;
  member_type_name: string;
  free_cancel_text: string;
  price: number;
  start_date: string;
  shop_id: number;
  tax_rate: number;
  type_code: number;
};

// ステイタスを初期化
const initialState: homeStateType = {
  isHomeLoading: false,
  isHomeLoaded: false,
  homeFailureMessage: '',
  homeDatas: {
    UID: '',
    member_id: 0,
    member_type_info: {
      campaign_code: '',
      description: 0,
      end_date: '',
      is_not_able_to_cancel_withdrawal: '',
      member_type_id: 0,
      member_type_name: '',
      free_cancel_text: '',
      price: 0,
      start_date: '',
      shop_id: 0,
      tax_rate: 0,
      type_code: 0,
    },
    options: [],
    outsourcing_code: '',
    personal_info: {
      birthday: '',
      address1: '',
      address2: '',
      building_name: '',
      emergency_number: '',
      emergency_relationship: '',
      first_name: '',
      first_name_ruby: '',
      gender: 0,
      last_name: '',
      last_name_ruby: '',
      mail_address: '',
      mobile_number: '',
      parent_first_name: '',
      parent_last_name: '',
      phone_number: '',
      postal_code: '',
    },
    recesses: [],
    result: 0,
    shop_id: 0,
    shop_name: '',
    start_date: '',
    penalties: [],
  },
};

// APIの結果によってステイタスが更新される処理
const homeReducer = (state = initialState, action: AnyAction): homeStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case HOME_REQUEST:
        dataState.isHomeLoading = true;
        dataState.isHomeLoaded = false;
        dataState.homeFailureMessage = '';
        dataState.homeDatas.UID = '';
        dataState.homeDatas.member_id = 0;
        dataState.homeDatas.options = [];
        dataState.homeDatas.outsourcing_code = '';
        dataState.homeDatas.personal_info.birthday = '';
        dataState.homeDatas.personal_info.address1 = '';
        dataState.homeDatas.personal_info.address2 = '';
        dataState.homeDatas.personal_info.building_name = '';
        dataState.homeDatas.personal_info.emergency_number = '';
        dataState.homeDatas.personal_info.emergency_relationship = '';
        dataState.homeDatas.personal_info.first_name = '';
        dataState.homeDatas.personal_info.first_name_ruby = '';
        dataState.homeDatas.personal_info.gender = 0;
        dataState.homeDatas.personal_info.last_name = '';
        dataState.homeDatas.personal_info.last_name_ruby = '';
        dataState.homeDatas.personal_info.mail_address = '';
        dataState.homeDatas.personal_info.mobile_number = '';
        dataState.homeDatas.personal_info.parent_first_name = '';
        dataState.homeDatas.personal_info.parent_last_name = '';
        dataState.homeDatas.personal_info.phone_number = '';
        dataState.homeDatas.personal_info.postal_code = '';
        dataState.homeDatas.start_date = '';
        dataState.homeDatas.recesses = [];
        dataState.homeDatas.result = 0;
        dataState.homeDatas.shop_id = 0;
        dataState.homeDatas.shop_name = '';
        dataState.homeDatas.penalties = [];
        break;
      case HOME_SUCCESS:
        dataState.isHomeLoading = false;
        dataState.isHomeLoaded = true;
        dataState.homeFailureMessage = action.res.message;
        dataState.homeDatas.UID = action.res.data.UID;
        dataState.homeDatas.member_id = action.res.data.member_id;
        dataState.homeDatas.member_type_info = action.res.data.member_type_info;
        dataState.homeDatas.options = action.res.data.options;
        dataState.homeDatas.outsourcing_code = action.res.data.outsourcing_code;
        dataState.homeDatas.personal_info = action.res.data.personal_info;
        dataState.homeDatas.recesses = action.res.data.recesses;
        dataState.homeDatas.result = action.res.data.result;
        dataState.homeDatas.shop_id = action.res.data.shop_id;
        dataState.homeDatas.shop_name = action.res.data.shop_name;
        dataState.homeDatas.start_date = action.res.data.start_date;
        dataState.homeDatas.penalties = action.res.data.penalties;
        break;
      case HOME_FAILURE:
        dataState.isHomeLoading = false;
        dataState.isHomeLoaded = false;
        dataState.homeFailureMessage = '';
        dataState.homeDatas.UID = '';
        dataState.homeDatas.member_id = 0;
        dataState.homeDatas.options = [];
        dataState.homeDatas.outsourcing_code = '';
        dataState.homeDatas.personal_info.birthday = '';
        dataState.homeDatas.personal_info.address1 = '';
        dataState.homeDatas.personal_info.address2 = '';
        dataState.homeDatas.personal_info.building_name = '';
        dataState.homeDatas.personal_info.emergency_number = '';
        dataState.homeDatas.personal_info.emergency_relationship = '';
        dataState.homeDatas.personal_info.first_name = '';
        dataState.homeDatas.personal_info.first_name_ruby = '';
        dataState.homeDatas.personal_info.gender = 0;
        dataState.homeDatas.personal_info.last_name = '';
        dataState.homeDatas.personal_info.last_name_ruby = '';
        dataState.homeDatas.personal_info.mail_address = '';
        dataState.homeDatas.personal_info.mobile_number = '';
        dataState.homeDatas.personal_info.parent_first_name = '';
        dataState.homeDatas.personal_info.parent_last_name = '';
        dataState.homeDatas.personal_info.phone_number = '';
        dataState.homeDatas.personal_info.postal_code = '';
        dataState.homeDatas.start_date = '';
        dataState.homeDatas.recesses = [];
        dataState.homeDatas.result = 0;
        dataState.homeDatas.shop_id = 0;
        dataState.homeDatas.shop_name = '';
        dataState.homeDatas.penalties = [];
        break;
      case HOME_INITIAL:
        dataState.isHomeLoading = false;
        dataState.isHomeLoaded = false;
        dataState.homeFailureMessage = '';
        dataState.homeDatas.UID = '';
        dataState.homeDatas.member_id = 0;
        dataState.homeDatas.options = [];
        dataState.homeDatas.outsourcing_code = '';
        dataState.homeDatas.personal_info.birthday = '';
        dataState.homeDatas.personal_info.address1 = '';
        dataState.homeDatas.personal_info.address2 = '';
        dataState.homeDatas.personal_info.building_name = '';
        dataState.homeDatas.personal_info.emergency_number = '';
        dataState.homeDatas.personal_info.emergency_relationship = '';
        dataState.homeDatas.personal_info.first_name = '';
        dataState.homeDatas.personal_info.first_name_ruby = '';
        dataState.homeDatas.personal_info.gender = 0;
        dataState.homeDatas.personal_info.last_name = '';
        dataState.homeDatas.personal_info.last_name_ruby = '';
        dataState.homeDatas.personal_info.mail_address = '';
        dataState.homeDatas.personal_info.mobile_number = '';
        dataState.homeDatas.personal_info.parent_first_name = '';
        dataState.homeDatas.personal_info.parent_last_name = '';
        dataState.homeDatas.personal_info.phone_number = '';
        dataState.homeDatas.personal_info.postal_code = '';
        dataState.homeDatas.start_date = '';
        dataState.homeDatas.recesses = [];
        dataState.homeDatas.result = 0;
        dataState.homeDatas.shop_id = 0;
        dataState.homeDatas.shop_name = '';
        dataState.homeDatas.penalties = [];
        break;
      default:
        break;
    }
  });
};

export default homeReducer;
