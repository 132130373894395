import produce from 'immer';
import { AnyAction } from 'redux';
import {
  GET_MEMBER_TRANSFER_STATUS_REQUEST,
  GET_MEMBER_TRANSFER_STATUS_SUCCESS,
  GET_MEMBER_TRANSFER_STATUS_FAILURE,
  GET_MEMBER_TRANSFER_STATUS_INITIAL,
} from '../../actions';

// タイプを設定
export type memberTransferStatusStateType = {
  isGetMemberTransferStatusLoading: boolean;
  isGetMemberTransferStatusLoaded: boolean;
  getMemberTransferStatusFailureMessage: string;
  memberTransferStatusDatas: any;
};

// ステイタスを初期化
const initialState: memberTransferStatusStateType = {
  isGetMemberTransferStatusLoading: false,
  isGetMemberTransferStatusLoaded: false,
  getMemberTransferStatusFailureMessage: '',
  memberTransferStatusDatas: [],
};

// APIの結果によってステイタスが更新される処理
const memberTransferStatusReducer = (state = initialState, action: AnyAction): memberTransferStatusStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case GET_MEMBER_TRANSFER_STATUS_REQUEST:
        dataState.isGetMemberTransferStatusLoading = true;
        dataState.isGetMemberTransferStatusLoaded = false;
        dataState.getMemberTransferStatusFailureMessage = '';
        dataState.memberTransferStatusDatas = [];
        break;
      case GET_MEMBER_TRANSFER_STATUS_SUCCESS:
        dataState.isGetMemberTransferStatusLoading = false;
        dataState.isGetMemberTransferStatusLoaded = true;
        dataState.getMemberTransferStatusFailureMessage = '';
        dataState.memberTransferStatusDatas = action.data;
        break;
      case GET_MEMBER_TRANSFER_STATUS_FAILURE:
        dataState.isGetMemberTransferStatusLoading = false;
        dataState.isGetMemberTransferStatusLoaded = false;
        dataState.getMemberTransferStatusFailureMessage = '';
        dataState.memberTransferStatusDatas = [];
        break;
      case GET_MEMBER_TRANSFER_STATUS_INITIAL:
        dataState.isGetMemberTransferStatusLoading = false;
        dataState.isGetMemberTransferStatusLoaded = false;
        dataState.getMemberTransferStatusFailureMessage = '';
        dataState.memberTransferStatusDatas = [];
        break;
      default:
        break;
    }
  });
};

export default memberTransferStatusReducer;
