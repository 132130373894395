import React, { FC } from 'react';
import { BackGround, Layout } from './LoginLayout.style';

const LoginLayout: FC = ({ children }) => {
  return (
    <>
      <BackGround>
        <Layout>{children}</Layout>
      </BackGround>
    </>
  );
};

export default LoginLayout;
