import React, { memo } from 'react';
import styled from '@emotion/styled';
import { RED, WHITE } from '../../../../styles/color';
import {
  MIRA_FONT_SIZE,
  FINTNESS_FONT_SIZE,
  MIRA_SMART_FONT_SIZE,
  FINTNESS_SMART_FONT_SIZE,
  MIRA_LOGO_IMG_PC_TABLET_HEIGHT,
  MIRA_LOGO_IMG_SMART_HEIGHT,
  MIRA_LOGO_IMG_MARGON_BOTTOM,
} from '../../../../styles/size';
import { SMART_SIZE } from '../../../../styles/common';

export const Title = styled.div`
  > img {
    height: ${MIRA_LOGO_IMG_PC_TABLET_HEIGHT};
    margin-bottom: ${MIRA_LOGO_IMG_MARGON_BOTTOM};
    ${SMART_SIZE} {
      height: ${MIRA_LOGO_IMG_SMART_HEIGHT};
    }
  }
  > span:first-of-type {
    color: ${RED};
    font-size: ${MIRA_FONT_SIZE};
    ${SMART_SIZE} {
      font-size: ${MIRA_SMART_FONT_SIZE};
    }
  }
  > span {
    color: ${WHITE};
    font-size: ${FINTNESS_FONT_SIZE};
    ${SMART_SIZE} {
      font-size: ${FINTNESS_SMART_FONT_SIZE};
    }
  }
`;

const LoginLogo = () => {
  return (
    <>
      <Title>
        <img src="/images/logo-white.svg" alt="" />
      </Title>
    </>
  );
};

export default memo(LoginLogo);
