import produce from 'immer';
import { AnyAction } from 'redux';
import {
  GET_RECESS_STATUS_REQUEST,
  GET_RECESS_STATUS_SUCCESS,
  GET_RECESS_STATUS_FAILURE,
  GET_RECESS_STATUS_INITIAL,
} from '../../actions';

// タイプを設定
export type recessStatusStateType = {
  isGetRecessStatusLoading: boolean;
  isGetRecessStatusLoaded: boolean;
  getRecessStatusFailureMessage: string;
  recessStatusDatas: any;
};

// ステイタスを初期化
const initialState: recessStatusStateType = {
  isGetRecessStatusLoading: false,
  isGetRecessStatusLoaded: false,
  getRecessStatusFailureMessage: '',
  recessStatusDatas: [],
};

// APIの結果によってステイタスが更新される処理
const recessStatusReducer = (state = initialState, action: AnyAction): recessStatusStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case GET_RECESS_STATUS_REQUEST:
        dataState.isGetRecessStatusLoading = true;
        dataState.isGetRecessStatusLoaded = false;
        dataState.getRecessStatusFailureMessage = '';
        dataState.recessStatusDatas = [];
        break;
      case GET_RECESS_STATUS_SUCCESS:
        dataState.isGetRecessStatusLoading = false;
        dataState.isGetRecessStatusLoaded = true;
        dataState.getRecessStatusFailureMessage = '';
        dataState.recessStatusDatas = action.data;
        break;
      case GET_RECESS_STATUS_FAILURE:
        dataState.isGetRecessStatusLoading = false;
        dataState.isGetRecessStatusLoaded = false;
        dataState.getRecessStatusFailureMessage = '';
        dataState.recessStatusDatas = [];
        break;
      case GET_RECESS_STATUS_INITIAL:
        dataState.isGetRecessStatusLoading = false;
        dataState.isGetRecessStatusLoaded = false;
        dataState.getRecessStatusFailureMessage = '';
        dataState.recessStatusDatas = [];
        break;
      default:
        break;
    }
  });
};

export default recessStatusReducer;
