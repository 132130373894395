import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { getMenuAction } from '../sagas/menu/menuSaga';
import { API_BASE_ADDRESS } from '../api';

// ユーザーメニューを取得します。
const menuServices = (action: getMenuAction): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
    params: { shop_id: action.shopId },
  };

  return {
    getMenu: () => axios.get(`${API_BASE_ADDRESS}/member/${action.memberId}/menu`, config),
  };
};

export default menuServices;
