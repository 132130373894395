import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Global } from '@emotion/react';
import { GlOBAL_RESET_CSS } from './styles/reset';
import { GlOBAL_COMMON_CSS } from './styles/common';

import LoginPage from './pages/Login/Login.index';
import HomePage from './pages/Home/Home.index';
import ReferralCodePage from './pages/ReferralCode/ReferralCode.index';
import CreditcardSwitchingPage from './pages/CreditcardSwitching/CreditcardSwitching.index';
import Unpaid from './pages/Unpaid/Unpaid.index';
import UnpaidPayment from './pages/Unpaid/Payment/Payment.index';
import Complete from './pages/Complete/Complete.index';

import Recess from './pages/Recess/Recess.index';
import Recess_Info from './pages/Recess/Rcess_Info/Recess.info';
import Month_start from './pages/Recess/Recess_Month_Start/Month.start';
import Month_end from './pages/Recess/Recess_Month_End/Month.end';
import Recess_agreement from './pages/Recess/Recess_Agreement/Recess.agreement';
import Recess_complete from './pages/Recess/Recess_Complete/Recess.complete';
import Recess_cancel_agreement from './pages/Recess/Recess_Cancel_Agreement/Recess.cancel.agreement';
import Recess_cancel_complete from './pages/Recess/Recess_Cancel_Complete/Recess.cancel.complete';

import ErrorSystem from './pages/Error/ErrorSystem';
import Error404 from './pages/Error/Error404';
import ErrorPayment from './pages/Error/ErrorPayment';
import ErrorMail from './pages/Error/ErrorMail';

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse" />
//   </div>
// );

const App: FC = () => {
  return (
    <>
      <Global styles={GlOBAL_RESET_CSS} />
      <Global styles={GlOBAL_COMMON_CSS} />

      <BrowserRouter>
        <Switch>
          <Route path="/" component={LoginPage} exact />
          <Route path="/home" component={HomePage} exact />

          <Route path="/referralCode" component={ReferralCodePage} exact />

          <Route path="/creditcardSwitching" component={CreditcardSwitchingPage} exact />
          <Route path="/creditcardSwitching/complete" component={Complete} exact />

          {/* 未納金お支払いの画面 */}
          <Route path="/unpaid" component={Unpaid} exact />
          <Route path="/unpaid/payment" component={UnpaidPayment} exact />
          <Route path="/unpaid/payment/complete" component={Complete} exact />

          <Route path="/systemError" component={ErrorSystem} />
          <Route path="/paymentError" component={ErrorPayment} />
          <Route path="/email-address-result" component={ErrorMail} />

          {/* 休会・休会取消の画面 */}
          <Route path="/recess" component={Recess} exact />
          {/* 休会ルールの画面 */}
          <Route path="/recess/recess-info" component={Recess_Info} exact />
          {/* 休会開始月選択の画面 */}
          <Route path="/recess/month-start" component={Month_start} exact />
          {/* 休会終了月選択の画面 */}
          <Route path="/recess/month-end" component={Month_end} exact />
          {/* 休会選択月確認の画面 */}
          <Route path="/recess/recess-aggrement" component={Recess_agreement} exact />
          {/* 休会完了の画面 */}
          <Route path="/recess/recess-complete" component={Recess_complete} exact />
          {/* 休会取消月確認の画面 */}
          <Route path="/recess/recess-cancel-agreement" component={Recess_cancel_agreement} exact />
          {/* 休会取消完了の画面 */}
          <Route path="/recess/recess-cancel-complete" component={Recess_cancel_complete} exact />

          <Route path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
