import produce from 'immer';
import { AnyAction } from 'redux';
import { LOG_IN_REQUEST, LOG_IN_SUCCESS, LOG_IN_FAILURE, LOG_IN_INITIAL } from '../../actions';

import FS from '../../../config/sentence.json';

// タイプを設定
export type postLoginType = {
  postLoginResult: {
    isLoading: boolean;
    isLoaded: boolean;
    logInFailureMessage: string;
    logInMailFailureMessage: string;
    result: number;
    member_id: string;
    introduction_code: string;
    type_code: number;
    is_active: boolean;
    business_type: number;
    price: number;
  };
};

// ステイタスを初期化
const initialState: postLoginType = {
  postLoginResult: {
    isLoading: false,
    isLoaded: false,
    logInFailureMessage: '',
    logInMailFailureMessage: '',
    result: -1,
    member_id: '',
    introduction_code: '',
    is_active: false,
    type_code: 0,
    business_type: 0,
    price: -1,
  },
};

// APIの結果によってステイタスが更新される処理
const loginReducer = (state = initialState, action: AnyAction): postLoginType => {
  return produce(state, (stateDraft) => {
    const draft = stateDraft;
    switch (action.type) {
      case LOG_IN_REQUEST:
        draft.postLoginResult.isLoading = true;
        draft.postLoginResult.isLoaded = false;
        draft.postLoginResult.logInFailureMessage = '';
        draft.postLoginResult.logInMailFailureMessage = '';
        draft.postLoginResult.introduction_code = '';
        draft.postLoginResult.member_id = '';
        draft.postLoginResult.result = -1;
        draft.postLoginResult.is_active = false;
        draft.postLoginResult.type_code = 0;
        draft.postLoginResult.business_type = 0;
        draft.postLoginResult.price = -1;
        break;
      case LOG_IN_SUCCESS:
        draft.postLoginResult.isLoading = false;
        draft.postLoginResult.isLoaded = true;
        draft.postLoginResult.logInFailureMessage =
          action.data.result === 1
            ? `${FS.F_S_LOGIN_FAILURE_MESSAGE}`
            : action.data.result === 2
            ? `${FS.F_S_LOGIN_FAILURE_RESULT_2}`
            : '';
        draft.postLoginResult.logInMailFailureMessage =
          action.data.result === 1
            ? `${FS.F_S_LOGIN_MAIL_FAILURE_MESSAGE}`
            : action.data.result === 2
            ? `${FS.F_S_LOGIN_FAILURE_RESULT_2}`
            : '';
        draft.postLoginResult.member_id = action.data.member_id;
        draft.postLoginResult.result = action.data.result;
        draft.postLoginResult.is_active = action.data.is_active;
        draft.postLoginResult.type_code = action.data.type_code;
        draft.postLoginResult.business_type = action.data.business_type;
        draft.postLoginResult.price = action.data.price;
        break;
      case LOG_IN_FAILURE:
        draft.postLoginResult.isLoading = false;
        draft.postLoginResult.isLoaded = false;
        draft.postLoginResult.logInFailureMessage = '';
        draft.postLoginResult.introduction_code = '';
        draft.postLoginResult.member_id = '';
        draft.postLoginResult.result = -1;
        draft.postLoginResult.is_active = false;
        draft.postLoginResult.type_code = 0;
        draft.postLoginResult.business_type = 0;
        draft.postLoginResult.price = -1;
        break;
      case LOG_IN_INITIAL:
        draft.postLoginResult.isLoading = false;
        draft.postLoginResult.isLoaded = false;
        draft.postLoginResult.logInFailureMessage = '';
        draft.postLoginResult.introduction_code = '';
        draft.postLoginResult.member_id = '';
        draft.postLoginResult.result = -1;
        draft.postLoginResult.is_active = false;
        draft.postLoginResult.type_code = 0;
        draft.postLoginResult.business_type = 0;
        draft.postLoginResult.price = -1;
        break;
      default:
        break;
    }
  });
};

export default loginReducer;
