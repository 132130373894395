import produce from 'immer';
import { AnyAction } from 'redux';
import {
  GET_MEMBER_MENU_REQUEST,
  GET_MEMBER_MENU_SUCCESS,
  GET_MEMBER_MENU_FAILURE,
  GET_MEMBER_MENU_INITIAL,
} from '../../actions';

// タイプを設定
export type menuStateType = {
  isGetMenuLoading: boolean;
  isGetMenuLoaded: boolean;
  menuData: any;
};

// ステイタスを初期化
const initialState: menuStateType = {
  isGetMenuLoading: false,
  isGetMenuLoaded: false,
  menuData: [],
};

// APIの結果によってステイタスが更新される処理
const menuReducer = (state = initialState, action: AnyAction): menuStateType => {
  return produce(state, (draft) => {
    const dataState = draft;
    switch (action.type) {
      case GET_MEMBER_MENU_REQUEST:
        dataState.isGetMenuLoading = true;
        dataState.isGetMenuLoaded = false;
        dataState.menuData = [];
        break;
      case GET_MEMBER_MENU_SUCCESS:
        dataState.isGetMenuLoading = false;
        dataState.isGetMenuLoaded = true;
        dataState.menuData = action.data;
        break;
      case GET_MEMBER_MENU_FAILURE:
        dataState.isGetMenuLoading = false;
        dataState.isGetMenuLoaded = false;
        dataState.menuData = [];
        break;
      case GET_MEMBER_MENU_INITIAL:
        dataState.isGetMenuLoading = false;
        dataState.isGetMenuLoaded = false;
        dataState.menuData = [];
        break;
      default:
        break;
    }
  });
};

export default menuReducer;
