import React, { FC } from 'react';
import Header from './Header/MyPageHeader.index';
import Footer from './Footer/MyPageFooter.index';
import { CommonBlockBox, CommonLayout, MyPageLayoutWrapper } from './MyPageLayout.style';

const MyPageLayout: FC = ({ children }) => {
  return (
    <>
      <MyPageLayoutWrapper>
        <Header />
        <CommonLayout>
          <CommonBlockBox>{children}</CommonBlockBox>
        </CommonLayout>
        <Footer />
      </MyPageLayoutWrapper>
    </>
  );
};

export default MyPageLayout;
