import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import {
  GET_MEMBER_MENU_REQUEST,
  GET_MEMBER_MENU_SUCCESS,
  GET_MEMBER_MENU_FAILURE,
  COMMON_FAILURE,
} from '../../actions';
import { menuService } from '../../services';
import { historyType } from '../../../utils/commonType';

export type getMenuAction = {
  type: typeof GET_MEMBER_MENU_REQUEST;
  memberId: string;
  shopId: string;
  history: historyType;
};

function* getMenu(action: getMenuAction): Generator<unknown> {
  try {
    const res: any = yield call(menuService(action).getMenu);
    yield put({
      type: GET_MEMBER_MENU_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: GET_MEMBER_MENU_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchMenu() {
  yield takeLatest(GET_MEMBER_MENU_REQUEST, getMenu);
}

export default function* menuSaga(): Generator<unknown> {
  yield all([fork(watchMenu)]);
}
