import styled from '@emotion/styled';
import { WHITE, RED } from '../../../styles/color';
import { TABLET_SIZE, SMART_SIZE } from '../../../styles/common';

const MonthInput = styled.label`
  position: relative;
  margin: 18px;
  width: 324px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0px 5px 8px #00000026;
  display: flex;
  align-items: center;
  opacity: 1;
  justify-content: center;
  cursor: pointer;
  border: 3px solid ${RED};
  background-color: ${WHITE};
  color: ${RED};
  & > input {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }
  & > img {
    width: 20px;
    height: 25px;
  }
  & span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    font-family: 'Noto Sans JP';
    margin-left: 7.5px;
  }
  ${TABLET_SIZE} {
    margin: 15px;
    width: 200px;
    height: 80px;
    border-radius: 40px;
    display: flex;
  }
  ${SMART_SIZE} {
    width: 280px;
    height: 80px;
    border-radius: 40px;
  }
`;

export default MonthInput;
