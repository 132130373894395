import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { PC_SIZE, SMART_SIZE, TABLET_SIZE } from '../../../../../styles/common';
import { BLACK, RED, WHITE, LIGHT_GRAY, LARGE_BUTTON_BOX_SHADOW_COLOR } from '../../../../../styles/color';
import {
  PC_TABLET_LINK_HEIGHT,
  MIRA_HEADER_PC_PADDING_TOP,
  MIRA_HEADER_PC_PADDING_BOTTOM,
  MIRA_HEADER_LINK_BORDER_RADIUS,
  MIRA_HEADER_LINK_PC_TABLET_LINE_HEIGHT,
  MIRA_HEADER_MAX_WIDTH,
  MIRA_HEADER_PC_GAP,
  MIRA_HEADER_PC_PADDING_LEFT,
  MIRA_HEADER_PC_PADDING_RIGHT,
  MIRA_HEADER_TABLET_PADDING_LEFT,
  MIRA_HEADER_TABLET_PADDING_RIGHT,
  MIRA_HEADER_TABLET_PADDING_TOP,
  MIRA_HEADER_TABLET_PADDING_BOTTOM,
  MIRA_HEADER_TABLET_GAP,
  MIRA_HEADER_TABLET_MARGIN_TOP,
  MIRA_HEADER_LINK_PC_TABLET_ACTIVE_PADDING_TOP,
  MIRA_HEADER_LINK_PC_TABLET_ACTIVE_PADDING_BOTTOM,
  MIRA_HEADER_LINK_PC_ACTIVE_PADDING_LEFT,
  MIRA_HEADER_LINK_PC_ACTIVE_PADDING_RIGHT,
  MIRA_HEADER_LINK_TABLET_ACTIVE_PADDING_LEFT,
  MIRA_HEADER_LINK_TABLET_ACTIVE_PADDING_RIGHT,
  MIRA_HEADER_BORDER_WIDTH,
  MIRA_HEADER_LINK_PC_TABLET_FONT_SIZE,
  MAIN_FULL_WIDTH,
  MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_TOP,
  MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_BOTTOM,
  MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_LEFT,
  MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_RIGHT,
  MIRA_HEADER_LINK_PC_ACTIVE_TABLET_MARGIN_LEFT,
  MIRA_HEADER_LINK_PC_ACTIVE_TABLET_MARGIN_RIGHT,
  MIRA_HOME_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_MAIN_TABLET_MAX_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE,
  MAIN_BUTTON_BORDER_WIDTH,
} from '../../../../../styles/size';

interface ButtonProps {
  isclickedactive: string;
}

export const HeaderLayout = styled.div`
  text-align: center;
  padding: ${MIRA_HEADER_PC_PADDING_TOP} ${MIRA_HEADER_PC_PADDING_BOTTOM};
  border-bottom: ${MIRA_HEADER_BORDER_WIDTH} solid ${LIGHT_GRAY};
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${BLACK};
    height: ${PC_TABLET_LINK_HEIGHT};
    text-decoration: none;
    border-radius: ${MIRA_HEADER_LINK_BORDER_RADIUS};
    font-size: ${MIRA_HEADER_LINK_PC_TABLET_FONT_SIZE};
    line-height: ${MIRA_HEADER_LINK_PC_TABLET_LINE_HEIGHT};
    /* : {
      background-color: ${RED};
      color: ${WHITE};
    } */
  }
  ${PC_SIZE} {
    // max-width: ${MIRA_HEADER_MAX_WIDTH};
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding-left: ${MIRA_HEADER_PC_PADDING_LEFT};
    padding-right: ${MIRA_HEADER_PC_PADDING_RIGHT};
    margin-right: auto;
    margin-left: auto;
    & > div:nth-of-type(2) {
      // width: ${MAIN_FULL_WIDTH};
      display: flex;
      align-items: baseline;
      justify-content: end;
      gap: ${MIRA_HEADER_PC_GAP};
    }
    /*a:last-of-type {
      display: none;
    }
    a:nth-of-type(4) {
      display: none;
    }*/
  }
  ${TABLET_SIZE} {
    padding: ${MIRA_HEADER_TABLET_PADDING_TOP} ${MIRA_HEADER_TABLET_PADDING_LEFT} ${MIRA_HEADER_TABLET_PADDING_BOTTOM}
      ${MIRA_HEADER_TABLET_PADDING_RIGHT};
    /*a:last-child {
      display: none;
    }
    a:nth-of-type(4) {
      display: none;
    }*/
  }
`;

export const CommonMenuLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${MIRA_HEADER_TABLET_GAP};
  margin-top: ${MIRA_HEADER_TABLET_MARGIN_TOP};
`;

export const MenuLink = styled(Link)<ButtonProps>`
  background-color: ${({ isclickedactive }) => (isclickedactive === 'active' ? `${RED} !important` : ``)};
  color: ${({ isclickedactive }) => (isclickedactive === 'active' ? `${WHITE} !important` : ``)};
  padding: ${({ isclickedactive }) =>
    isclickedactive === 'active'
      ? `${MIRA_HEADER_LINK_PC_TABLET_ACTIVE_PADDING_TOP}
      ${MIRA_HEADER_LINK_PC_ACTIVE_PADDING_LEFT}
      ${MIRA_HEADER_LINK_PC_TABLET_ACTIVE_PADDING_BOTTOM}
      ${MIRA_HEADER_LINK_PC_ACTIVE_PADDING_RIGHT}`
      : ``};
  margin: ${({ isclickedactive }) =>
    isclickedactive === 'active'
      ? `${MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_TOP} 
      ${MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_LEFT} 
      ${MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_BOTTOM}
      ${MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_RIGHT} 
      `
      : ``};

  ${TABLET_SIZE} {
    padding: ${({ isclickedactive }) =>
      isclickedactive === 'active'
        ? `${MIRA_HEADER_LINK_PC_TABLET_ACTIVE_PADDING_TOP}
         ${MIRA_HEADER_LINK_TABLET_ACTIVE_PADDING_LEFT}
          ${MIRA_HEADER_LINK_PC_TABLET_ACTIVE_PADDING_BOTTOM}
           ${MIRA_HEADER_LINK_TABLET_ACTIVE_PADDING_RIGHT}`
        : ``};
    margin: ${({ isclickedactive }) =>
      isclickedactive === 'active'
        ? `${MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_TOP} 
          ${MIRA_HEADER_LINK_PC_ACTIVE_TABLET_MARGIN_LEFT} 
          ${MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_BOTTOM}
          ${MIRA_HEADER_LINK_PC_ACTIVE_TABLET_MARGIN_RIGHT} 
          `
        : ``};
  }
`;

export const ReservationModal = styled(Modal)`
  padding-left: 40px !important;
  padding-right: 40px !important;
  & > div {
    max-width: ${MIRA_HOME_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH};
    display: flex;
    align-items: center;
    height: ${MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT};
    justify-content: center;
    & > div {
      height: 162px;
      width: 400px;
      border-radius: 30px;
    }
  }
  ${TABLET_SIZE} {
    & > div {
      max-width: ${MIRA_HOME_LAYOUT_MODAL_MAIN_TABLET_MAX_WIDTH};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  ${SMART_SIZE} {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: ${MAIN_FULL_WIDTH};
  }
`;

export const ReservationModalText = styled.p`
  margin: 0px 21px;
  margin-top: 5px;
`;

export const ReservationModalButton = styled.button`
  width: 150px;
  height: 50px;
  border: 3px solid ${RED};
  box-shadow: 0px 5px 8px ${LARGE_BUTTON_BOX_SHADOW_COLOR};
  font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE};
  font-weight: 700;
  line-height: 29px;
  background: ${WHITE};
  border-radius: 50px;
  color: ${RED};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 34px;

  &:disabled {
    opacity: 0.6 !important;
    background: ${WHITE} !important;
    color: ${RED} !important;
  }

  &:hover {
    background: ${RED};
    color: ${WHITE};
    border: ${MAIN_BUTTON_BORDER_WIDTH} solid ${RED};
  }

  ${SMART_SIZE} {
    width: ${MAIN_FULL_WIDTH};
  }
`;

export const ReservationModalButtonLayout = styled.div`
  display: flex;
`;
