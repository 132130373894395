import produce from 'immer';
import { AnyAction } from 'redux';
import {
  PUT_MAIL_ADDRESS_SUCCESS,
  PUT_MAIL_ADDRESS_FAILURE,
  PUT_MAIL_ADDRESS_REQUEST,
  PUT_MAIL_ADDRESS_INITIAL,
} from '../../actions';

export type putMailAddressType = {
  putMailAddressResult: {
    isLoading: boolean;
    isLoaded: boolean;
    result: number;
    member_id: number;
    mobile_number: string;
    birthday: string;
  };
};

const initialState: putMailAddressType = {
  putMailAddressResult: {
    isLoading: false,
    isLoaded: false,
    result: -1,
    member_id: 0,
    mobile_number: '',
    birthday: '',
  },
};

const putMailAddressReducer = (state = initialState, action: AnyAction): putMailAddressType => {
  return produce(state, (stateDraft) => {
    const draft = stateDraft;
    switch (action.type) {
      case PUT_MAIL_ADDRESS_REQUEST:
        draft.putMailAddressResult.isLoading = true;
        draft.putMailAddressResult.isLoaded = false;
        draft.putMailAddressResult.result = -1;
        draft.putMailAddressResult.member_id = 0;
        draft.putMailAddressResult.mobile_number = '';
        draft.putMailAddressResult.birthday = '';
        break;
      case PUT_MAIL_ADDRESS_SUCCESS:
        draft.putMailAddressResult.isLoading = false;
        draft.putMailAddressResult.isLoaded = true;
        draft.putMailAddressResult.result = action.data.result;
        draft.putMailAddressResult.member_id = action.data.member_id;
        draft.putMailAddressResult.mobile_number = action.data.mobile_number;
        draft.putMailAddressResult.birthday = action.data.birthday;
        break;
      case PUT_MAIL_ADDRESS_FAILURE:
        draft.putMailAddressResult.isLoading = false;
        draft.putMailAddressResult.isLoaded = false;
        draft.putMailAddressResult.result = -1;
        draft.putMailAddressResult.member_id = 0;
        draft.putMailAddressResult.mobile_number = '';
        draft.putMailAddressResult.birthday = '';
        break;
      case PUT_MAIL_ADDRESS_INITIAL:
        draft.putMailAddressResult.isLoading = false;
        draft.putMailAddressResult.isLoaded = false;
        draft.putMailAddressResult.result = -1;
        draft.putMailAddressResult.member_id = 0;
        draft.putMailAddressResult.mobile_number = '';
        draft.putMailAddressResult.birthday = '';
        break;
      default:
        break;
    }
  });
};

export default putMailAddressReducer;
