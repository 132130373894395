import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import { POST_SEND_MAIL_REQUEST, POST_SEND_MAIL_SUCCESS, POST_SEND_MAIL_FAILURE, COMMON_FAILURE } from '../../actions';
import { mailService } from '../../services';
import { historyType } from '../../../utils/commonType';

export type postSendMailStatusAction = {
  type: typeof POST_SEND_MAIL_REQUEST;
  memberId: string;
  mail_address: string;
  history: historyType;
};

function* postSendMailStatus(action: postSendMailStatusAction): Generator<unknown> {
  try {
    const res: any = yield call(mailService(action).postSendMail);
    yield put({
      type: POST_SEND_MAIL_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: POST_SEND_MAIL_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchPostSendMailStatus() {
  yield takeLatest(POST_SEND_MAIL_REQUEST, postSendMailStatus);
}
export default function* postSendMailStatusSaga(): Generator<unknown> {
  yield all([fork(watchPostSendMailStatus)]);
}
