import produce from 'immer';
import { AnyAction } from 'redux';
import { PUT_PASSWORD_SUCCESS, PUT_PASSWORD_FAILURE, PUT_PASSWORD_REQUEST, PUT_PASSWORD_INITIAL } from '../../actions';

export type putPasswordType = {
  putPasswordResult: {
    isLoading: boolean;
    isLoaded: boolean;
    result: number;
  };
};

const initialState: putPasswordType = {
  putPasswordResult: {
    isLoading: false,
    isLoaded: false,
    result: -1,
  },
};

const putPasswordReducer = (state = initialState, action: AnyAction): putPasswordType => {
  return produce(state, (stateDraft) => {
    const draft = stateDraft;
    switch (action.type) {
      case PUT_PASSWORD_REQUEST:
        draft.putPasswordResult.isLoading = true;
        draft.putPasswordResult.isLoaded = false;
        draft.putPasswordResult.result = -1;
        break;
      case PUT_PASSWORD_SUCCESS:
        draft.putPasswordResult.isLoading = false;
        draft.putPasswordResult.isLoaded = true;
        draft.putPasswordResult.result = action.data.result;
        break;
      case PUT_PASSWORD_FAILURE:
        draft.putPasswordResult.isLoading = false;
        draft.putPasswordResult.isLoaded = false;
        draft.putPasswordResult.result = -1;
        break;
      case PUT_PASSWORD_INITIAL:
        draft.putPasswordResult.isLoading = false;
        draft.putPasswordResult.isLoaded = false;
        draft.putPasswordResult.result = -1;
        break;
      default:
        break;
    }
  });
};

export default putPasswordReducer;
