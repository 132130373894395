import React, { FC, memo } from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';
import MenuBar from './MenuBar/MyPageMenuBar.index';

type Props = {
  children?: React.ReactNode;
};

const Footer: FC<Props> = ({ children }) => {
  const getIsMobile = () => window.innerWidth < 576;
  const isMobile = useWindowSize(getIsMobile);

  return <>{isMobile ? <MenuBar /> : <></>}</>;
};

export default memo(Footer);
