import styled from '@emotion/styled';
import {
  MIRA_UNPAID_LAYOUT_BUTTON_BORDER_RADIUS,
  MIRA_UNPAID_LAYOUT_BUTTON_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_HEIGHT,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BUTTON_PC_WIDTH,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLE_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_WIDTH,
  MIRA_UNPAID_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BUTTON_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM,
  MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH,
  MIRA_UNPAID_LAYOUT_TITLE_P_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TITLE_P_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TITLE_P_PC_TABLET_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH,
  MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TITLE_P_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_TITLE_P_SMART_MARGIN_TOP,
  MAIN_FULL_WIDTH,
  MIRA_UNPAID_LAYOUT_BOX_BORDER_RADIUS,
  MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_TOP,
  MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_BOTTOM,
  MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_TOP,
  MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_BOTTOM,
  MIRA_UNPAID_LAYOUT_PC_BOX_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TABLET_BOX_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SMART_BOX_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BOX_H3_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_H3_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_H3_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_PC_BOX_H3_TEXT_UNDERLINE_OFFSET,
  MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_H3_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_PC_BOX_SPAN_PC_TABLET_MARGIN_LEFT,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_SPAN_SMART_MARGIN_LEFT,
  MIRA_UNPAID_LAYOUT_BOX_UL_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BOX_UL_TABLET_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BOX_LI_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_LI_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_LI_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_LI_PC_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_BOX_LI_PADDING_LEFT,
  MIRA_UNPAID_LAYOUT_BOX_LI_TEXT_INDENT,
  MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SECTION_UL_PADDING_LEFT,
  MIRA_UNPAID_LAYOUT_SECTION_UL_PC_MARGIN_LEFT,
  MIRA_UNPAID_LAYOUT_SECTION_UL_TABLET_SMART_MARGIN_LEFT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_SECTION_LI_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_SECTION_LI_PADDING_LEFT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_TEXT_INDENT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMART_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PADDING_LEFT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TEXT_INDENT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_FONT_SIZE,
  MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LINE_HEIGHT,
  MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LETTER_SPACING,
  MIRA_UNPAID_LAYOUT_SECTION_UL_SMALL_PC_MARGIN_LEFT,
  MIRA_UNPAID_LAYOUT_SECTION_UL_SMALL_TABLET_SMART_MARGIN_LEFT,
  MIRA_UNPAID_LAYOUT_SECTION_UL_MARGIN_TOP,
  MIRA_UNPAID_LAYOUT_TITLE_P_PC_LINE_HEIGHT,
  ERROR_DESCRIPTION,
  ERROR_DESCRIPTION_MARGIN_BOTTOM,
  ERROR_SMART_DESCRIPTION,
} from '../../styles/size';
import { SMART_SIZE, TABLET_SIZE } from '../../styles/common';
import { BLACK, BOX_BACKGROUND_PINK, DESCRIPTION_TEXT_BLUE, RED } from '../../styles/color';

export const Layout = styled.div`
  margin-top: ${MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP};
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: ${MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM};
  & > div {
    & > div {
      max-width: ${MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH};
      margin-left: auto;
      margin-right: auto;
      & > h1 {
        font-size: ${MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE};
        line-height: ${MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING};
        color: ${BLACK};
        font-weight: 700;
      }
      & > p {
        font-size: ${MIRA_UNPAID_LAYOUT_TITLE_P_PC_FONT_SIZE};
        line-height: ${MIRA_UNPAID_LAYOUT_TITLE_P_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_UNPAID_LAYOUT_TITLE_P_PC_LETTER_SPACING};
        font-weight: 500;
        color: ${DESCRIPTION_TEXT_BLUE};
        margin-top: ${MIRA_UNPAID_LAYOUT_TITLE_P_PC_TABLET_MARGIN_TOP};
      }
      & > div {
        width: ${MAIN_FULL_WIDTH};
        display: inline-block;
        background: ${BOX_BACKGROUND_PINK};
        border-radius: ${MIRA_UNPAID_LAYOUT_BOX_BORDER_RADIUS};
        padding-top: ${MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_TOP};
        padding-bottom: ${MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_BOTTOM};
        margin-top: ${MIRA_UNPAID_LAYOUT_PC_BOX_MARGIN_TOP};
        & > h3 {
          font-size: ${MIRA_UNPAID_LAYOUT_BOX_H3_PC_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_BOX_H3_PC_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_H3_PC_LETTER_SPACING};
          text-decoration: underline;
          text-underline-offset: ${MIRA_UNPAID_LAYOUT_PC_BOX_H3_TEXT_UNDERLINE_OFFSET};
          font-weight: 700;
          display: inline-block;
        }
        & > span {
          font-size: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LETTER_SPACING};
          font-weight: 500;
          text-decoration: none;
          color: black;
          margin-left: ${MIRA_UNPAID_LAYOUT_PC_BOX_SPAN_PC_TABLET_MARGIN_LEFT};
          display: inline-block;
        }
      }
      & > ul {
        list-style: none;
        margin-top: ${MIRA_UNPAID_LAYOUT_BOX_UL_PC_MARGIN_TOP} !important;
        margin-left: 0;
        padding-left: 0;
        & > li {
          text-align: left;
          font-size: ${MIRA_UNPAID_LAYOUT_BOX_LI_PC_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_BOX_LI_PC_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_LI_PC_LETTER_SPACING};
          font-weight: 500;
          margin-top: ${MIRA_UNPAID_LAYOUT_BOX_LI_PC_MARGIN_TOP};
          padding-left: ${MIRA_UNPAID_LAYOUT_BOX_LI_PADDING_LEFT};
          text-indent: ${MIRA_UNPAID_LAYOUT_BOX_LI_TEXT_INDENT};
          & span {
            color: ${RED};
          }
        }
        & > li:before {
          content: '〇';
        }
      }
      & > section {
        & > ul {
          margin-top: ${MIRA_UNPAID_LAYOUT_SECTION_UL_MARGIN_TOP};
          list-style: none;
          padding-left: ${MIRA_UNPAID_LAYOUT_SECTION_UL_PADDING_LEFT};
          margin-left: ${MIRA_UNPAID_LAYOUT_SECTION_UL_PC_MARGIN_LEFT};
          & > li {
            text-align: left;
            font-size: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_LETTER_SPACING};
            font-weight: 500;
            padding-left: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PADDING_LEFT};
            text-indent: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TEXT_INDENT};
            & > span {
              color: ${RED};
            }
          }
          & > li:before {
            content: ' ※';
          }
        }
        & > div {
          padding: 0;
          display: flex;
          text-align: left;
          justify-content: start;
          & > div {
            margin: 0;
            background: none;
            color: ${RED};
            & > p {
              font-size: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_FONT_SIZE};
              line-height: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LINE_HEIGHT};
              letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LETTER_SPACING};
              font-weight: 500;
            }
          }
          & > div {
            & > ul {
              list-style: none;
              margin-left: ${MIRA_UNPAID_LAYOUT_SECTION_UL_SMALL_PC_MARGIN_LEFT};
              & > li {
                color: ${RED};
                font-size: ${MIRA_UNPAID_LAYOUT_SECTION_LI_PC_FONT_SIZE};
                line-height: ${MIRA_UNPAID_LAYOUT_SECTION_LI_PC_LINE_HEIGHT};
                letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_LI_PC_LETTER_SPACING};
                font-weight: 500;
                text-align: left;
                padding-left: ${MIRA_UNPAID_LAYOUT_SECTION_LI_PADDING_LEFT};
                text-indent: ${MIRA_UNPAID_LAYOUT_SECTION_LI_TEXT_INDENT};
              }
              & > li:before {
                content: '・';
              }
            }
          }
        }
      }
    }

    & > button {
      margin-top: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_MARGIN_TOP};
      width: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_WIDTH};
      height: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_HEIGHT};
      font-size: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_FONT_SIZE};
      line-height: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_LINE_HEIGHT};
      letter-spacing: ${MIRA_UNPAID_LAYOUT_BUTTON_PC_LETTER_SPACING};
      color: ${RED};
      font-weight: 700;
      border-radius: ${MIRA_UNPAID_LAYOUT_BUTTON_BORDER_RADIUS};
    }
  }
  ${TABLET_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP};
    display: flex;
    justify-content: center;
    text-align: center;
    & > div {
      & > div {
        max-width: ${MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH};
        margin-left: auto;
        margin-right: auto;
        & > h1 {
          font-size: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING};
          color: ${BLACK};
          font-weight: 700;
        }
        & p {
          font-size: ${MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LETTER_SPACING};
        }
        & > div {
          margin-top: ${MIRA_UNPAID_LAYOUT_TABLET_BOX_MARGIN_TOP};
          & > h3 {
            font-size: ${MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LETTER_SPACING};
            display: inline-block;
          }
          & > span {
            font-size: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LETTER_SPACING};
            display: inline-block;
          }
        }
        & > ul {
          list-style: none;
          margin-top: ${MIRA_UNPAID_LAYOUT_BOX_UL_TABLET_SMART_MARGIN_TOP} !important;
          & > li {
            font-size: ${MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LETTER_SPACING};
            margin-top: ${MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_MARGIN_TOP};
          }
        }
        & > section {
          & > ul {
            list-style: none;
            margin-left: ${MIRA_UNPAID_LAYOUT_SECTION_UL_TABLET_SMART_MARGIN_LEFT};
            & > li {
              font-size: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_FONT_SIZE};
              line-height: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_LINE_HEIGHT};
              letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_LETTER_SPACING};
            }
            & > li:before {
              content: ' ※';
            }
          }
          & > div {
            & > div > p {
              font-size: ${MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_FONT_SIZE};
              line-height: ${MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_LINE_HEIGHT};
              letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_LETTER_SPACING};
            }
            & > div {
              & > ul {
                margin-left: ${MIRA_UNPAID_LAYOUT_SECTION_UL_SMALL_TABLET_SMART_MARGIN_LEFT};
                & > li {
                  font-size: ${MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_FONT_SIZE};
                  line-height: ${MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_LINE_HEIGHT};
                  letter-spacing: ${MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_LETTER_SPACING};
                }
              }
            }
          }
        }
      }
      & > button {
        margin-top: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLE_MARGIN_TOP};
        width: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_WIDTH};
        height: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT};
        font-size: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE};
        line-height: ${MIRA_UNPAID_LAYOUT_BUTTON_LINE_HEIGHT};
        letter-spacing: ${MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_LETTER_SPACING};
        color: ${RED};
        font-weight: 700;
      }
    }
  }
  ${SMART_SIZE} {
    margin-top: ${MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP};
    & > div {
      & div {
        margin-left: auto;
        margin-right: auto;
        & > h1 {
          font-size: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING};
          color: ${BLACK};
          font-weight: 700;
        }
        & p {
          font-size: ${MIRA_UNPAID_LAYOUT_TITLE_P_SMART_FONT_SIZE};
          line-height: ${MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LETTER_SPACING};
          margin-top: ${MIRA_UNPAID_LAYOUT_TITLE_P_SMART_MARGIN_TOP};
        }
        & > div {
          display: grid;
          padding-top: ${MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_TOP};
          padding-bottom: ${MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_BOTTOM};
          margin-top: ${MIRA_UNPAID_LAYOUT_SMART_BOX_MARGIN_TOP};
          & > h3 {
            font-size: ${MIRA_UNPAID_LAYOUT_BOX_H3_SMART_FONT_SIZE};
            line-height: ${MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LINE_HEIGHT};
            letter-spacing: ${MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LETTER_SPACING};
          }
          & > span {
            margin-left: ${MIRA_UNPAID_LAYOUT_BOX_SPAN_SMART_MARGIN_LEFT};
          }
        }
        & > ul {
          list-style: none;
        }
        & > section {
          & > div {
            & > div {
              margin: 0;
              background: none;
              padding: 0;
            }
            & > div > ul {
              margin-top: 0 !important;
            }
            & > div > ul > li {
              margin-top: ${MIRA_UNPAID_LAYOUT_SECTION_LI_SMART_MARGIN_TOP};
            }
          }
        }
      }
      & > button {
        margin-top: ${MIRA_UNPAID_LAYOUT_BUTTON_SMART_MARGIN_TOP};
      }
    }
  }
`;

export const ErrorMessageDescription = styled.p`
  font-size: ${ERROR_DESCRIPTION};
  margin-bottom: ${ERROR_DESCRIPTION_MARGIN_BOTTOM};
  line-height: 1.5;
  white-space: pre-line;
  ${SMART_SIZE}: {
    font-size: ${ERROR_SMART_DESCRIPTION};
  }
`;
