import styled from '@emotion/styled';
import { SMART_SIZE } from '../../../styles/common';
import { WHITE } from '../../../styles/color';

const IMG_URL = '../../images/background.jpeg';

export const BackGround = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  ::before {
    content: '';
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${IMG_URL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
  }
`;

export const Layout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${WHITE};
  font-weight: 700;
  width: 100%;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  ${SMART_SIZE} {
    padding-left: 4%;
    padding-right: 4%;
  }
`;
