import React, { FC, useState, memo, useCallback } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ModalBody } from 'react-bootstrap';

import {
  CommonMenuLayout,
  HeaderLayout,
  MenuLink,
  ReservationModal,
  ReservationModalButton,
  ReservationModalButtonLayout,
  ReservationModalText,
} from './MyPagePCHeader.style';

import useWindowSize from '../../../../../hooks/useWindowSize';
import LogoTitle from '../../../../Common/Logo/MyPage';
import { SideBarItems } from '../../MyPageSideBarItems';

import {
  GET_CREDIT_CHANGE_CHECK_REQUEST,
  GET_MEMBER_MENU_INITIAL,
  GET_MEMBER_MENU_REQUEST,
  GET_MEMBER_TRANSFER_STATUS_INITIAL,
  GET_RECESS_STATUS_INITIAL,
  GET_SHOP_BUTTON_INITIAL,
  HOME_INITIAL,
  LOG_IN_INITIAL,
  UNPAID_OPERATION_CHECK_INITIAL,
} from '../../../../../store/actions';
import FS from '../../../../../config/sentence.json';

interface MenuProps {
  title: string;
  label: string;
  to: string;
  activeOnlyWhenExact: boolean;
  isCorporateMonthly: boolean;
  isCorporatePrivate: boolean;
  isActive: boolean;
  isBusinessType: boolean | undefined;
}

const CustomMenuLink = memo(
  ({
    title,
    to,
    activeOnlyWhenExact,
    label,
    isCorporateMonthly,
    isCorporatePrivate,
    isActive,
    isBusinessType,
  }: MenuProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const url = useLocation().pathname;
    const typeCode = sessionStorage.getItem('typeCode');
    const price = sessionStorage.getItem('price');
    const isSessionStorageActive = sessionStorage.getItem('isActive') ?? '';
    const businessType = sessionStorage.getItem('businessType') ?? '';

    const [isPCModal, setIsPCModal] = useState(false);

    const onHideModalClose = useCallback(() => {
      setIsPCModal(false);
    }, []);

    const onClickButtonModal = useCallback(
      (data: number) => () => {
        if (data === 0) {
          window.open('https://mirafitness.hacomono.jp/');
          setIsPCModal(false);
        } else {
          setIsPCModal(false);
        }
      },
      [],
    );

    // ログアウトのボタンを押下時にセッションストレージをクリアする
    const onClickMenuItem = (toLabel: string) => () => {
      if (toLabel === 'logout') {
        // ログアウトをクリックすると、セッションストレージをクリアする
        dispatch({
          type: LOG_IN_INITIAL,
        });
        dispatch({
          type: HOME_INITIAL,
        });
        sessionStorage.clear();
      } else if (toLabel === 'home') {
        dispatch({
          type: HOME_INITIAL,
        });
      } else if (toLabel === 'creditcardSwitching') {
        // クレカ切替操作日チェックAPIを呼出し
        dispatch({
          type: GET_CREDIT_CHANGE_CHECK_REQUEST,
          member_id: sessionStorage.getItem('memberID'),
          history,
        });
      } else if (toLabel === 'unpaid') {
        dispatch({
          type: UNPAID_OPERATION_CHECK_INITIAL,
        });
      } else if (toLabel === 'others') {
        setIsPCModal(true);
      } else if (toLabel === 'recess') {
        // 休会をクリックすると、セッションストレージをクリアする
        dispatch({
          type: GET_MEMBER_MENU_INITIAL,
        });
        dispatch({
          type: GET_SHOP_BUTTON_INITIAL,
        });
        dispatch({
          type: GET_RECESS_STATUS_INITIAL,
        });
        dispatch({
          type: GET_MEMBER_TRANSFER_STATUS_INITIAL,
        });
        // 会員メニューを取得する
        dispatch({
          type: GET_MEMBER_MENU_REQUEST,
          memberId: sessionStorage.getItem('memberID') ?? '',
          shopId: sessionStorage.getItem('shopID') ?? '',
          history,
        });
      }
    };

    return (
      <>
        {isPCModal ? (
          <>
            <ReservationModal show={isPCModal} onHide={onHideModalClose}>
              <ModalBody>
                <ReservationModalText>
                  {FS.F_S_UNPAID_RESERVATION_TEXT_1}
                  <br />
                  {FS.F_S_UNPAID_RESERVATION_TEXT_2}
                </ReservationModalText>
                <ReservationModalButtonLayout>
                  <ReservationModalButton type="button" onClick={onClickButtonModal(0)}>
                    はい
                  </ReservationModalButton>
                  <ReservationModalButton type="button" onClick={onClickButtonModal(1)}>
                    いいえ
                  </ReservationModalButton>
                </ReservationModalButtonLayout>
              </ModalBody>
            </ReservationModal>
          </>
        ) : (
          <></>
        )}
        {((typeCode === '60' || (typeCode === '70' && price === '0')) && isCorporateMonthly) ||
        (typeCode === '70' && price !== '0' && isCorporatePrivate) ||
        (isSessionStorageActive === 'false' && isCorporatePrivate) ||
        (businessType !== '2' && isBusinessType === undefined) ? (
          <></>
        ) : (
          <>
            <Route path={to} exact={activeOnlyWhenExact}>
              {({ match }) => {
                const active = url.includes(to) && to !== '/' ? 'active' : '';
                return (
                  <>
                    <MenuLink to={to} isclickedactive={active} onClick={onClickMenuItem(label)}>
                      {title}
                    </MenuLink>
                  </>
                );
              }}
            </Route>
          </>
        )}
      </>
    );
  },
);

const CommonMenu: FC = memo(() => {
  return (
    <>
      {SideBarItems.map((value) => {
        return (
          <CustomMenuLink
            key={value.title}
            activeOnlyWhenExact={value.exact}
            to={value.path}
            title={value.smartTitle ? value.smartTitle : value.title}
            label={value.label}
            isCorporateMonthly={value.isCorporateMonthly}
            isCorporatePrivate={value.isCorporatePrivate}
            isActive={value.isActive}
            isBusinessType={value.isBusinessType}
          />
        );
      })}
    </>
  );
});

/**
 * PC版とタブレット版のコンポーネント
 * @returns PC版とタブレット版のコンポーネント
 */
const PCHeader: FC = () => {
  const getTabletSize = () => window.innerWidth <= 991;
  const isTablet = useWindowSize(getTabletSize);

  return (
    <>
      <HeaderLayout>
        <LogoTitle />
        <div>
          {isTablet ? (
            <>
              <CommonMenuLayout>
                <CommonMenu />
              </CommonMenuLayout>
            </>
          ) : (
            <>
              <CommonMenu />
            </>
          )}
        </div>
      </HeaderLayout>
    </>
  );
};

export default memo(PCHeader);
