import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import MyPageLayout from '../../components/Layouts/MyPage/MyPageLayout.index';
import { Para } from '../Login/Login.style';
import CustomButton from '../../components/Common/Button/style';
import LoginLayout from '../../components/Layouts/Login/LoginLayout.index';
import LoginLogo from '../../components/Common/Logo/Login/index';
import { SMART_SIZE, TABLET_SIZE } from '../../styles/common';
import { BLACK } from '../../styles/color';
import {
  ERROR_LAYOUT_TOP,
  MAIN_FULL_WIDTH,
  ERROR_LAYOUT_LEFT,
  ERROR_LAYOUT_TRANSLATE_X,
  ERROR_LAYOUT_TRANSLATE_Y,
  ERROR_LAYOUT_BOX_PC_MAX_WIDTH,
  ERROR_LAYOUT_BOX_TABLET_SMART_MAX_WIDTH,
  ERROR_LAYOUT_BOX_PC_HEIGHT,
  ERROR_LAYOUT_BOX_TEXT_PC_FONT_SIZE,
  ERROR_LAYOUT_BOX_TEXT_TABLET_SMART_FONT_SIZE,
  ERROR_LAYOUT_BOX_TEXT_PC_MARGIN_BOTTOM,
  ERROR_LAYOUT_BOX_TEXT_TABLET_SMART_MARGIN_BOTTOM,
} from '../../styles/size';
import FS from '../../config/sentence.json';
import FW from '../../config/word.json';

const Layout = styled.div`
  position: absolute;
  top: ${ERROR_LAYOUT_TOP};
  left: ${ERROR_LAYOUT_LEFT};
  transform: translate(${ERROR_LAYOUT_TRANSLATE_X}, ${ERROR_LAYOUT_TRANSLATE_Y});
  font-weight: 700;
  width: ${MAIN_FULL_WIDTH};
  text-align: center;
`;
const Box = styled.div`
  max-width: ${ERROR_LAYOUT_BOX_PC_MAX_WIDTH};
  height: ${ERROR_LAYOUT_BOX_TABLET_SMART_MAX_WIDTH};
  background: white;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${BLACK};
  & > div {
    & > p {
      font-size: ${ERROR_LAYOUT_BOX_TEXT_PC_FONT_SIZE};
      margin-bottom: ${ERROR_LAYOUT_BOX_TEXT_PC_MARGIN_BOTTOM};
    }
    & > button {
      ${TABLET_SIZE} {
        width: ${MAIN_FULL_WIDTH};
      }
    }
  }
  ${SMART_SIZE} {
    max-width: ${ERROR_LAYOUT_BOX_TABLET_SMART_MAX_WIDTH};
    height: ${ERROR_LAYOUT_BOX_PC_HEIGHT};
    background: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${BLACK};
    & > div {
      & > p {
        font-size: ${ERROR_LAYOUT_BOX_TEXT_TABLET_SMART_FONT_SIZE};
        margin-bottom: ${ERROR_LAYOUT_BOX_TEXT_TABLET_SMART_MARGIN_BOTTOM};
      }
    }
  }
`;

const Title404 = styled.div`
  font-size: 58px;
  margin-bottom: 5px;
`;

const Description404 = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
`;

const Error404: FC = () => {
  const history = useHistory();
  const isLogin = JSON.parse(sessionStorage.getItem('member_info') || '{}');

  const onClickLoginBack = () => {
    if (sessionStorage.getItem('member_info')) {
      history.push('/home');
    } else {
      history.push('/');
    }
  };

  return (
    <>
      {isLogin.result ? (
        <>
          <MyPageLayout>
            <Layout>
              <Box>
                <div>
                  <Title404>{FW.F_W_404}</Title404>
                  <Description404>{FW.F_W_NOT_FOUND}</Description404>
                  <p>{FS.F_S_DISPLAY_ERROR_LOG_IN_404_PAGE}</p>
                  <CustomButton type="button" onClick={onClickLoginBack} move="go">
                    {FW.F_W_GO_HOME}
                  </CustomButton>
                </div>
              </Box>
            </Layout>
          </MyPageLayout>
        </>
      ) : (
        <LoginLayout>
          <LoginLogo />
          <Para>{FW.F_W_MY_PAGE}</Para>
          <Box>
            <div>
              <Title404>{FW.F_W_404}</Title404>
              <Description404>{FW.F_W_NOT_FOUND}</Description404>
              <p>{FS.F_S_DISPLAY_ERROR_LOG_OUT_404_PAGE}</p>
              <CustomButton type="button" onClick={onClickLoginBack} move="go">
                {FW.F_W_GO_BACK}
              </CustomButton>
            </div>
          </Box>
        </LoginLayout>
      )}
    </>
  );
};

export default Error404;
