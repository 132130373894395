import styled from '@emotion/styled';
import {
  MIRA_RECESS_LAYOUT_TEXT_PC_FONT_SIZE,
  MIRA_RECESS_LAYOUT_TEXT_PC_LETTER_SPACING,
  MIRA_RECESS_LAYOUT_TEXT_PC_LINE_HEIGHT,
  MIRA_RECESS_LAYOUT_TEXT_TABLET_FONT_SIZE,
  MIRA_RECESS_LAYOUT_TEXT_TABLET_LETTER_SPACING,
  MIRA_RECESS_LAYOUT_TEXT_TABLET_LINE_HEIGHT,
  MIRA_RECESS_LAYOUT_TEXT_SMART_LETTER_SPACING,
  MIRA_RECESS_LAYOUT_TEXT_SMART_LINE_HEIGHT,
  MIRA_RECESS_LAYOUT_TEXT_SMART_FONT_SIZE,
  MIRA_RECESS_LAYOUT_PC_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_TABLET_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_SMART_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_MARGIN_BOTTOM,
  MIRA_RECESS_LAYOUT_PC_MAX_WIDTH,
  MIRA_RECESS_LAYOUT_TABLET_SMART_MAX_WIDTH,
  MAIN_FULL_WIDTH,
  MIRA_RECESS_LAYOUT_BOX_PC_TABLET_PADDING_TOP,
  MIRA_RECESS_LAYOUT_BOX_PC_TABLET_PADDING_BOTTOM,
  MIRA_RECESS_LAYOUT_BOX_H3_PC_LINE_HEIGHT,
  MIRA_RECESS_LAYOUT_BOX_H3_PC_LETTER_SPACING,
  MIRA_RECESS_LAYOUT_PC_BOX_H3_TEXT_UNDERLINE_OFFSET,
  MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_FONT_SIZE,
  MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_LINE_HEIGHT,
  MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_LETTER_SPACING,
  MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_FONT_WEIGHT,
  MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_FONT_SIZE,
  MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_TABLET_COMPLETE_MESSAGE_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_SMART_COMPLETE_MESSAGE_MARGIN_TOP,
  MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT,
} from '../../../styles/size';
import { SMART_SIZE, TABLET_SIZE } from '../../../styles/common';
import { BLACK } from '../../../styles/color';

export const Layout = styled.div`
  margin-top: ${MIRA_RECESS_LAYOUT_PC_MARGIN_TOP};
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: ${MIRA_RECESS_LAYOUT_MARGIN_BOTTOM};
  & > div {
    max-width: ${MIRA_RECESS_LAYOUT_PC_MAX_WIDTH};
    margin-left: auto;
    margin-right: auto;
    & > h1 {
      font-size: ${MIRA_RECESS_LAYOUT_TEXT_PC_FONT_SIZE};
      line-height: ${MIRA_RECESS_LAYOUT_TEXT_PC_LINE_HEIGHT};
      letter-spacing: ${MIRA_RECESS_LAYOUT_TEXT_PC_LETTER_SPACING};
      color: ${BLACK};
      font-weight: ${MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT};
    }
    & > div {
      width: ${MAIN_FULL_WIDTH};
      display: flex;
      padding-top: ${MIRA_RECESS_LAYOUT_BOX_PC_TABLET_PADDING_TOP};
      padding-bottom: ${MIRA_RECESS_LAYOUT_BOX_PC_TABLET_PADDING_BOTTOM};
      margin-top: ${MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_MARGIN_TOP};
      & > span {
        font-size: ${MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_FONT_SIZE};
        line-height: ${MIRA_RECESS_LAYOUT_BOX_H3_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_RECESS_LAYOUT_BOX_H3_PC_LETTER_SPACING};
        text-underline-offset: ${MIRA_RECESS_LAYOUT_PC_BOX_H3_TEXT_UNDERLINE_OFFSET};
        font-weight: ${MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_FONT_WEIGHT};
        display: flex;
      }
    }
  }
  ${TABLET_SIZE} {
    margin-top: ${MIRA_RECESS_LAYOUT_TABLET_MARGIN_TOP};
    display: flex;
    justify-content: center;
    text-align: center;
    & > div {
      max-width: ${MIRA_RECESS_LAYOUT_TABLET_SMART_MAX_WIDTH};
      margin-left: auto;
      margin-right: auto;
      & > h1 {
        font-size: ${MIRA_RECESS_LAYOUT_TEXT_TABLET_FONT_SIZE};
        line-height: ${MIRA_RECESS_LAYOUT_TEXT_TABLET_LINE_HEIGHT};
        letter-spacing: ${MIRA_RECESS_LAYOUT_TEXT_TABLET_LETTER_SPACING};
      }
      & > div {
        margin-top: ${MIRA_RECESS_LAYOUT_TABLET_COMPLETE_MESSAGE_MARGIN_TOP};
        & > span {
          font-size: ${MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_FONT_SIZE};
          line-height: ${MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_LETTER_SPACING};
          display: inline-block;
        }
      }
    }
  }
  ${SMART_SIZE} {
    margin-top: ${MIRA_RECESS_LAYOUT_SMART_MARGIN_TOP};
    & div {
      margin-left: auto;
      margin-right: auto;
      & > h1 {
        font-size: ${MIRA_RECESS_LAYOUT_TEXT_SMART_FONT_SIZE};
        line-height: ${MIRA_RECESS_LAYOUT_TEXT_SMART_LINE_HEIGHT};
        letter-spacing: ${MIRA_RECESS_LAYOUT_TEXT_SMART_LETTER_SPACING};
      }
      & > div {
        display: grid;
        margin-top: ${MIRA_RECESS_LAYOUT_SMART_COMPLETE_MESSAGE_MARGIN_TOP};
      }
    }
  }
`;
