import React, { FC } from 'react';

import MyPageLayout from '../../../components/Layouts/MyPage/MyPageLayout.index';

import { Layout } from '../Recess_Complete/Recess.complete.style';

import FS from '../../../config/sentence.json';

import useAuthorizationNormal from '../../../hooks/Auth/useAuthorizationNormal';

/**
 * 休会取消完了画面
 * @returns 休会取消完了画面のHTML
 */
const Month_end: FC = () => {
  // ログイン認証を設定する
  useAuthorizationNormal();

  return (
    <>
      <MyPageLayout>
        <Layout>
          <div>
            <h1>{FS.F_S_CANCEL_RECESS_COMPLETE_TEXT_1}</h1>
            <div>
              <span>
                {FS.F_S_CANCEL_RECESS_COMPLETE_TEXT_2}
                <br />
                {FS.F_S_CANCEL_RECESS_COMPLETE_TEXT_3}
              </span>
            </div>
          </div>
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Month_end;
