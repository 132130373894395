import { combineReducers } from 'redux';
import getPasswordStatusReducer from './getPasswordStatusReducer';
import putPasswordReducer from './putPasswordReducer';

const index = combineReducers({
  getPasswordStatus: getPasswordStatusReducer,
  putPassword: putPasswordReducer,
});

export default index;
