import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { API_BASE_ADDRESS } from '../api';

// APIの住所を設定します。
const customerIdServices = (): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
    // params: { receipt_number: receiptNumber },
  };
  return {
    getCustomerId: () => axios.get(`${API_BASE_ADDRESS}/customer-id`, config),
  };
};

export default customerIdServices;
