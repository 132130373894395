import produce from 'immer';
import { AnyAction } from 'redux';
import {
  GET_CREDIT_CHANGE_CHECK_SUCCESS,
  GET_CREDIT_CHANGE_CHECK_FAILURE,
  GET_CREDIT_CHANGE_CHECK_REQUEST,
  GET_CREDIT_CHANGE_CHECK_INITIAL,
} from '../../actions';

// タイプを設定
export type getCreditChangeCheckType = {
  getCreditChangeCheckResult: {
    isLoading: boolean;
    isLoaded: boolean;
    result: number;
  };
};

// ステイタスを初期化
const initialState: getCreditChangeCheckType = {
  getCreditChangeCheckResult: {
    isLoading: false,
    isLoaded: false,
    result: -1,
  },
};

// APIの結果によってステイタスが更新される処理
const customerIdReducer = (state = initialState, action: AnyAction): getCreditChangeCheckType => {
  return produce(state, (stateDraft) => {
    const draft = stateDraft;
    switch (action.type) {
      case GET_CREDIT_CHANGE_CHECK_REQUEST:
        draft.getCreditChangeCheckResult.isLoading = true;
        draft.getCreditChangeCheckResult.isLoaded = false;
        draft.getCreditChangeCheckResult.result = -1;
        break;
      case GET_CREDIT_CHANGE_CHECK_SUCCESS:
        draft.getCreditChangeCheckResult.isLoading = false;
        draft.getCreditChangeCheckResult.isLoaded = true;
        draft.getCreditChangeCheckResult.result = action.data.result;
        break;
      case GET_CREDIT_CHANGE_CHECK_FAILURE:
        draft.getCreditChangeCheckResult.isLoading = false;
        draft.getCreditChangeCheckResult.isLoaded = false;
        draft.getCreditChangeCheckResult.result = -1;
        break;
      case GET_CREDIT_CHANGE_CHECK_INITIAL:
        draft.getCreditChangeCheckResult.isLoading = false;
        draft.getCreditChangeCheckResult.isLoaded = false;
        draft.getCreditChangeCheckResult.result = -1;
        break;
      default:
        break;
    }
  });
};

export default customerIdReducer;
