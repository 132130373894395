import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';
import customerIdServices from '../../services/customerIdService ';
import { CUSTOMER_ID_REQUEST, CUSTOMER_ID_SUCCESS, CUSTOMER_ID_FAILURE, COMMON_FAILURE } from '../../actions';

export type customerIdActionType = {
  type: typeof CUSTOMER_ID_REQUEST;
  history: string[];
};

function* getCustomerId(action: customerIdActionType): Generator<unknown> {
  try {
    const res = yield call(customerIdServices().getCustomerId);
    yield put({
      type: CUSTOMER_ID_SUCCESS,
      res,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: CUSTOMER_ID_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchGetCustomerId() {
  yield takeLatest(CUSTOMER_ID_REQUEST, getCustomerId);
}
export default function* customerIdSaga(): Generator<unknown> {
  yield all([fork(watchGetCustomerId)]);
}
