import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { getReferralCodeAction } from '../sagas/referralCode/referralCodeSaga';

import { API_BASE_ADDRESS } from '../api';

// APIの住所を設定します。

const referralCodeService = (action: getReferralCodeAction): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
    params: { member_id: action.memberID },
  };
  return {
    getReferralCode: () => axios.get(`${API_BASE_ADDRESS}/code`, config),
  };
};

export default referralCodeService;
