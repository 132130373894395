import React, { FC, memo } from 'react';
import MyPageLogo from '../../../../Common/Logo/MyPage';
import { Layout } from './MyPageMobileHeader.style';

const MobileHeader: FC = () => {
  return (
    <>
      <div>
        <Layout>
          <MyPageLogo />
        </Layout>
      </div>
    </>
  );
};

export default memo(MobileHeader);
