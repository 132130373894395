/* **********************************************
 * 共通
 ************************************************* */
export const MIRA_PC_LOGO_FONT_SIZE = '43px' as const;
export const FINTNESS_PC_LOGO_FONT_SIZE = '32px' as const;
export const MIRA_TABLET_SMART_LOGO_FONT_SIZE = '29px' as const;
export const FINTNESS_TABLET_SMART_LOGO_FONT_SIZE = '22px' as const;
export const MAIN_LAYOUT_MIN_HEIGHT = '100vh' as const;
export const MAIN_LAYOUT_MIN_HEIGHT_MINUS = '134px' as const;
export const MAIN_LAYOUT_PADDING_BOTTOM = '130px' as const;
export const MAIN_PC_TABLET_LAYOUT_MARGIN_LEFT = '40px' as const;
export const MAIN_PC_TABLET_LAYOUT_MARGIN_RIGHT = '40px' as const;
export const MAIN_SMART_LAYOUT_MARGIN_LEFT = '4%' as const;
export const MAIN_SMART_LAYOUT_MARGIN_RIGHT = '4%' as const;
export const MAIN_BUTTON_MIN_WIDTH = '110px' as const;
export const MAIN_BUTTON_HEIGHT = '45px' as const;
export const MAIN_BUTTON_BORDER_WIDTH = '3px' as const;
export const MAIN_BUTTON_BOX_SHADOW_TOP = '0' as const;
export const MAIN_BUTTON_BOX_SHADOW_RIGHT_LEFT = '4px' as const;
export const MAIN_BUTTON_BOX_SHADOW_BOTTOM = '6px' as const;
export const MAIN_BUTTON_BORDER_RADIUS = '24px' as const;
export const MAIN_BUTTON_FONT_SIZE = '16px' as const;
export const MAIN_BUTTON_LINE_HEIGHT = '30px' as const;
export const MAIN_FULL_WIDTH = '100%' as const;

/* **********************************************
 * ログイン
 ************************************************* */
export const MIRA_FONT_SIZE = '55px' as const;
export const MIRA_SMART_FONT_SIZE = '48px' as const;
export const FINTNESS_FONT_SIZE = '43px' as const;
export const FINTNESS_SMART_FONT_SIZE = '37px' as const;
export const MIRA_LOGO_IMG_PC_TABLET_HEIGHT = '55px' as const;
export const MIRA_LOGO_IMG_SMART_HEIGHT = '48px' as const;
export const MIRA_LOGO_IMG_MARGON_BOTTOM = '20px' as const;

// 文章のサイズ
export const TITLE_FONT_SIZE = '24px' as const;
export const DESCRIPTION_FONT_SIZE = '14px' as const;
export const PC_DESCRIPTION_FONT_SIZE = '24px' as const;
export const TABLET_DESCRIPTION_FONT_SIZE = '15px' as const;
export const SAMART_DESCRIPTION_FONT_SIZE = '12px' as const;

// エラー時のサイズ
export const ERROR_TITLE = '35px' as const;
export const ERROR_DESCRIPTION = '24px' as const;
export const ERROR_SMART_DESCRIPTION = '20px' as const;
export const NOT_FOUND_DESCRIPTION = '14px' as const;
export const LAYOUT_TOP = '50%' as const;
export const LAYOUT_LEFT = '50%' as const;
export const LAYOUT_TRANSFORM = '-50%' as const;
export const LAYOUT_WIDTH = '100%' as const;
export const ERROR_CODE_MARGIN_BOTTOM = '15px' as const;
export const ERROR_DESCRIPTION_MARGIN_BOTTOM = '30px' as const;

// 404 error
export const ERROR_LAYOUT_TOP = '50%' as const;
export const ERROR_LAYOUT_LEFT = '50%' as const;
export const ERROR_LAYOUT_TRANSLATE_X = '-50%' as const;
export const ERROR_LAYOUT_TRANSLATE_Y = '-50%' as const;
export const ERROR_LAYOUT_BOX_PC_MAX_WIDTH = '435px' as const;
export const ERROR_LAYOUT_BOX_TABLET_SMART_MAX_WIDTH = '300px' as const;

export const ERROR_LAYOUT_BOX_PC_HEIGHT = '250px' as const;
export const ERROR_LAYOUT_BOX_TABLET_SMART_HEIGHT = '200px' as const;

export const ERROR_LAYOUT_BOX_TEXT_PC_FONT_SIZE = '23px' as const;
export const ERROR_LAYOUT_BOX_TEXT_TABLET_SMART_FONT_SIZE = '20px' as const;

export const ERROR_LAYOUT_BOX_TEXT_PC_MARGIN_BOTTOM = '50px' as const;
export const ERROR_LAYOUT_BOX_TEXT_TABLET_SMART_MARGIN_BOTTOM = '40px' as const;

// テーブルサイズ
export const SMART_TABLE_SIZE = '90vw' as const;
export const PC_TABLET_TABLE_SIZE = '423px' as const;

// LOGIN
export const FORM_PC_WIDTH = '530px' as const;
export const FORM_PC_HEIGHT = '418px' as const;
export const FORM_TABLET_WIDTH = '530px' as const;
export const FORM_TABLET_HEIGHT = '418px' as const;
export const FORM_TABLET_MIN_WIDTH = '530px' as const;
export const FORM_SMART_HEIGHT = '418px' as const;
export const FORM_SMART_MAX_WIDTH = '410px' as const;
export const FORM_SMART_MIN_WIDTH = '330px' as const;
export const FORM_PC_BORDER_RADIUS = '7px' as const;
export const INPUT_BUTTON_PC_TABLET_SMART_MARGIN_TOP = '30px' as const;
export const INPUT_BUTTON_PC_TABLET_LINE_HEIGHT = '24px' as const;
export const INPUT_BUTTON_PC_TABLET_FONT_SIZE = '16px' as const;
export const FORMTITLE_PC_HEIGHT = '60px' as const;
export const FORMTITLE_SMART_HEIGHT = '418px' as const;
export const FORM_TITLE_PC_TABLET_MARGIN_TOP = '-1px' as const;
export const FORM_TITLE_PC_TABLET_MARGIN_LEFT = '-1px' as const;
export const FORM_TITLE_PC_TABLET_MARGIN_RIGHT = '-1px' as const;
export const FORM_TITLE_CODE_PC_TABLET_MARGIN_TOP = '-1px' as const;
export const FORM_TITLE_CODE_PC_TABLET_MARGIN_LEFT = '-1px' as const;
export const FORM_TITLE_CODE_PC_TABLET_MARGIN_RIGHT = '-1px' as const;
export const FORM_TITLE_CODE_PC_TABLET_WIDTH = '100%' as const;
export const FORM_TITLE_CODE_PC_TABLET_TITLE_LINE_HEIGHT = '24px' as const;
export const FORM_TITLE_CODE_PC_TABLET_DIV_WIDTH = '50%' as const;
export const FORM_TITLE_EMAIL_PC_TABLET_MARGIN_TOP = '-1px' as const;
export const FORM_TITLE_EMAIL_PC_TABLET_MARGIN_LEFT = '-1px' as const;
export const FORM_TITLE_EMAIL_PC_TABLET_MARGIN_RIGHT = '-1px' as const;
export const FORM_TITLE_EMAIL_PC_TABLET_WIDTH = '100%' as const;
export const FORM_TITLE_EMAIL_PC_TABLET_TITLE_LINE_HEIGHT = '24px' as const;
export const FORM_TITLE_EMAIL_PC_TABLET_DIV_WIDTH = '50%' as const;
export const FORM_TITLE_PC_TABLET_TITLE_LINE_HEIGHT = '24px' as const;
export const INPUT_PC_TABLET_SMART_MAX_WIDTH = '330px' as const;
export const INPUT_PC_WIDTH = '100%' as const;
export const INPUT_PC_HEIGHT = '50px' as const;
export const INPUT_SMART_HEIGHT = '50px' as const;
export const INPUT_BUTTON_PC_TABLET_SMART_WIDTH = '250px' as const;
export const INPUT_BUTTON_PC_TABLET_SMART_HEIGHT = '50px' as const;
export const FORMLAYOUT_PC_TABLET_TITLE_BORDER_RADIUS = '10px' as const;
export const FORMLAYOUT_TABLET_SMART_WIDTH = '100%' as const;
export const PARA_PC_TABLET_TITLE_FONT_SIZE = '26px' as const;
export const PARA_SMART_TITLE_FONT_SIZE = '22px' as const;
export const FORM_TITLE_PC_TABLET_TITLE_FONT_SIZE = '16px' as const;
export const FORM_TITLE_SMART_TITLE_FONT_SIZE = '14px' as const;
export const PARA_PC_TABLET_TITLE_LINE_SIZE = '38px' as const;
export const PARA_SMART_TITLE_LINE_SIZE = '33px' as const;
export const PARA_PC_TABLET_TITLE_LETTER_SPACE_SIZE = '1.3px' as const;
export const PARA_SMART_TITLE_LETTER_SPACE_SIZE = '1.1px' as const;
export const FORM_TITLE_SMART_TITLE_LINE_SIZE = '20px' as const;
export const FORM_TITLE_SMART_TITLE_LETTER_SPACE_SIZE = '1.1px' as const;
export const FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS = '10px' as const;
export const FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS_RIGHT = '0px' as const;
export const FORM_TITLE_PC_TABLET_TITLE_BORDER_RADIUS_LEFT = '0px' as const;
export const FORM_TITLE_FONT_SIZE = '16px' as const;
export const FORM_TITLE_LINE_SIZE = '24px' as const;
export const FORM_TITLE_LETTER_SPACE_SIZE = '0px' as const;
export const FORM_TITLE_BORDER_RADIUS = '25px' as const;
export const FORM_TITLE_SMART_TITLE_MIN_WIDTH = '50%' as const;
export const FORM_TITLE_SMART_TITLE_MAX_WIDTH = '330px' as const;
export const INPUT_VALIDATION_MESSAGE_PC_TABLET_FONT_SIZE = '14px' as const;
export const INPUT_VALIDATION_MESSAGE_SMART_FONT_SIZE = '12px' as const;
export const INPUT_VALIDATION_MESSAGE_SMART_LINE_HEIGHT = '15px' as const;
export const INPUT_VALIDATION_MESSAGE_SMART_LINE_SPACING = '0.7px' as const;
export const INPUT_VALIDATION_MESSAGE_SMART_HEIGHT = '60px' as const;
export const FORM_TITLE_SMART_TITLE_MIN_WIDTH_PLUS_ONE = '1px' as const;
export const FORM_TITLE_CODE_BORDER_BOTTOM = '1px' as const;
export const FORM_TITLE_BACKGROUND_PADDING = '0%' as const;
export const FORM_TITLE_PC_TABLET_SMART_TOP = '50px' as const;
export const FORM_TITLE_PC_TABLET_SMART_MARGIN_BOTTOM = '10px' as const;
export const FORM_PC_PADDING_TOP = '5px' as const;
export const FORM_PC_PADDING_LEFT = '18px' as const;
export const FORM_PC_PADDING_BOTTOM = '5px' as const;
export const FORM_PC_PADDING_RIGHT = '18px' as const;
export const INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_TOP = '0px' as const;
export const INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_LEFT = '5px' as const;
export const INPUT_BUTTON_PC_TABLET_SMART_BOX_SHADOW_BOTTOM = '8px' as const;

// MENU
export const PC_TABLET_LINK_HEIGHT = '40px' as const;
export const MIRA_HEADER_BORDER_WIDTH = '1px' as const;
export const MIRA_HEADER_LOGO_PC_HEIGHT = '43px' as const;
export const MIRA_HEADER_LOGO_TABLET_SMART_HEIGHT = '29px' as const;
export const MIRA_HEADER_LINK_BORDER_RADIUS = '30px' as const;
export const MIRA_HEADER_LINK_PC_TABLET_FONT_SIZE = '16px' as const;
export const MIRA_HEADER_LINK_PC_TABLET_LINE_HEIGHT = '24px' as const;
export const MIRA_HEADER_MAX_WIDTH = '1200px' as const;
export const MIRA_HEADER_PC_GAP = '55px' as const;
export const MIRA_HEADER_PC_PADDING_LEFT = '40px' as const;
export const MIRA_HEADER_PC_PADDING_RIGHT = '65px' as const;
export const MIRA_HEADER_PC_PADDING_TOP = '22px' as const;
export const MIRA_HEADER_PC_PADDING_BOTTOM = '23px' as const;
export const MIRA_HEADER_TABLET_PADDING_LEFT = '0px' as const;
export const MIRA_HEADER_TABLET_PADDING_RIGHT = '0px' as const;
export const MIRA_HEADER_TABLET_PADDING_TOP = '17px' as const;
export const MIRA_HEADER_TABLET_PADDING_BOTTOM = '7px' as const;
export const MIRA_HEADER_TABLET_GAP = '35px' as const;
export const MIRA_HEADER_TABLET_MARGIN_TOP = '13px' as const;
export const MIRA_HEADER_LINK_PC_TABLET_ACTIVE_PADDING_TOP = '8px' as const;
export const MIRA_HEADER_LINK_PC_TABLET_ACTIVE_PADDING_BOTTOM = '8px' as const;
export const MIRA_HEADER_LINK_PC_ACTIVE_PADDING_LEFT = '25px' as const;
export const MIRA_HEADER_LINK_PC_ACTIVE_PADDING_RIGHT = '25px' as const;
export const MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_LEFT = '-25px' as const;
export const MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_RIGHT = '-25px' as const;
export const MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_TOP = '0' as const;
export const MIRA_HEADER_LINK_PC_ACTIVE_PC_MARGIN_BOTTOM = '0' as const;

export const MIRA_HEADER_LINK_PC_ACTIVE_PADDING_RIGHT_NON_ACTIVE = '30px' as const;

export const MIRA_HEADER_LINK_TABLET_ACTIVE_PADDING_LEFT = '15px' as const;
export const MIRA_HEADER_LINK_TABLET_ACTIVE_PADDING_RIGHT = '15px' as const;
export const MIRA_HEADER_LINK_PC_ACTIVE_TABLET_MARGIN_LEFT = '-15px' as const;
export const MIRA_HEADER_LINK_PC_ACTIVE_TABLET_MARGIN_RIGHT = '-15px' as const;
export const MIRA_SMART_MENU_BOTTOM = '0' as const;
export const MIRA_SMART_MENU_MARGIN_TOP = '0px' as const;
export const MIRA_SMART_MENU_MARGIN_LEFT = '0' as const;
export const MIRA_SMART_MENU_MARGIN_RIGHT = '0' as const;
export const MIRA_SMART_MENU_MARGIN_BOTTOM = '10px' as const;
export const MIRA_SMART_MENU_PADDING_TOP_BOTTOM = '10px' as const;
export const MIRA_SMART_MENU_MIN_WIDTH = '62.5px' as const;
export const MIRA_SMART_MENU_PADDING_LEFT_RIGHT = '5px' as const;
export const MIRA_SMART_MENU_ICON_WIDTH = '30px' as const;
export const MIRA_SMART_MENU_ICON_HEIGHT = '30px' as const;
export const MIRA_SMART_MENU_LINK_FONT_SIZE = '10px' as const;
export const MIRA_SMART_MENU_LINK_MARGIN_TOP = '2px' as const;
export const MIRA_SMART_MENU_LINK_LINE_HEIGHT = '12px' as const;
export const MIRA_SMART_SUB_ITEM_LEFT = '0' as const;
export const MIRA_SMART_SUB_ITEM_TOP = '0' as const;
export const MIRA_SMART_SUB_ITEM_BOTTOM = '0' as const;
export const MIRA_SMART_TAB_ITEM_PADDING_TOP_BOTTOM = '26.5px' as const;
export const MIRA_SMART_TAB_ITEM_PADDING_LEFT_RIGHT = '41px' as const;
export const MIRA_SMART_TAB_ITEM_MARGIN_RIGHT = '15px' as const;
export const MIRA_SMART_TAB_ITEM_CLOSE_ICON_Z_INDEX = '100' as const;
export const MIRA_SMART_TAB_ITEM_CLOSE_ICON_RIGHT = '25px' as const;
export const MIRA_SMART_TAB_ITEM_CLOSE_FONT_SIZE = '40px' as const;
export const MIRA_SMART_TAB_ITEM_CLOSE_TOP = '-57px' as const;

export const MIRA_SMART_TAB_ITEM_FORM_INPUT_MARGIN_RIGHT = '6px' as const;
export const MIRA_SMART_TAB_ITEM_FORM_INPUT_MARGIN_TOP = '0px' as const;
export const MIRA_SMART_TAB_ITEM_FORM_INPUT_WIDTH = '22px' as const;
export const MIRA_SMART_TAB_ITEM_FORM_INPUT_HEIGHT = '22px' as const;
export const MIRA_SMART_SCROLLBAR_HEIGHT = '5px' as const;
export const MIRA_SMART_SCROLLBAR_BORDER_RADIUS = '10px' as const;
export const MIRA_SMART_SUBMENU_MIN_WIDTH = '75px' as const;
export const MIRA_SMART_SUBMENU_BACKGROIND_OPACITY = '0.8' as const;
export const MIRA_SMART_SUBMENU_INPUT_BOX_SHADOW_RIGHT = '0' as const;
export const MIRA_SMART_SUBMENU_INPUT_BOX_SHADOW_BOTTOM = '0' as const;
export const MIRA_SMART_SUBMENU_INPUT_BOX_SHADOW_TOP_RIGHT = '5px' as const;
export const MIRA_SMART_SUBMENU_INPUT_BORDER_WIDTH = '1px' as const;

export const MIRA_SMART_HEADER_PADDING_TOP_BOTTOM = '15px' as const;
export const MIRA_SMART_HEADER_PADDING_LEFT_RIGHT = '0px' as const;
export const MIRA_SMART_HEADER_BORDER_WIDTH = '1px' as const;

// Home
export const MIRA_HOME_LAYOUT_SMART_PADDING_BOTTOM = '114.5px' as const;
export const MIRA_HOME_LAYOUT_MAX_WIDTH = '720px' as const;
export const MIRA_HOME_LAYOUT_PC_TABLET_MIN_HEIGT = '62px' as const;
export const MIRA_HOME_LAYOUT_BORDER_WIDTH = '1px' as const;
export const MIRA_HOME_LAYOUT_LETTER_lINE_HEIGHT = '22px' as const;
export const MIRA_HOME_LAYOUT_PC_TABLET_LETTER_FONT_SIZE = '18px' as const;
export const MIRA_HOME_LAYOUT_LETTER_PC_TABLET_SPAN_FONT_SIZE = '14px' as const;
export const MIRA_HOME_LAYOUT_BUTTON_MARGIN_BOTTOM = '8.5px' as const;
export const MIRA_HOME_LAYOUT_FIRST_COLUMN_PC_TABLET_MAX_WIDTH = '175.5px' as const;
export const MIRA_HOME_LAYOUT_FIRST_COLUMN_PC_TABLET_FONT_SIZE = '16px' as const;
export const MIRA_HOME_LAYOUT_LAST_COLUMN_MAX_WIDTH = '120px' as const;
export const MIRA_HOME_LAYOUT_FIRST_COLUMN_SMART_FONT_SIZE = '14px' as const;
export const MIRA_HOME_LAYOUT_LETTER_SMART_SPAN_FONT_SIZE = '13px' as const;
export const MIRA_HOME_LAYOUT_SMALL_TEXT_FONT_SIZE = '14px' as const;

export const MIRA_HOME_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH = '800px' as const;
export const MIRA_HOME_LAYOUT_MODAL_MAIN_TABLET_MAX_WIDTH = '477px' as const;
export const MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT = '100vh' as const;
export const MIRA_HOME_LAYOUT_MODAL_MAIN_MARGIN_BOTTOM = '150px' as const;
export const MIRA_HOME_LAYOUT_MODAL_PC_CONTENT_HEIGHT = '468px' as const;
export const MIRA_HOME_LAYOUT_MODAL_TABLET_SMART_CONTENT_HEIGHT = '450px' as const;
export const MIRA_HOME_LAYOUT_MODAL_HEADER_HEIGHT = '50px' as const;
export const MIRA_HOME_LAYOUT_MODAL_DELETE_BUTTON_FONT_SIZE = '55px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_PC_TEXT_FONT_SIZE = '16px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_SMART_TEXT_FONT_SIZE = '14px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_SMART_TEXT_LINE_HEIGHT = '14px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_WIDTH = '280px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_WIDTH = '255px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_MAX_WIDTH = '255px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_HEIGHT = '70px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_HEIGHT = '50px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE = '20px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_FONT_SIZE = '14px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_PC_WIDTH = '600px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_WIDTH = '407px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_MAX_WIDTH = '345px' as const;

export const MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_HEIGHT = '48px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_PC_FONT_SIZE = '18px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_TABLET_SMART_FONT_SIZE = '16px' as const;
export const MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_FONT_WEIGHT = '500' as const;
export const MIRA_HOME_LAYOUT_SMART_HEIGHT = '100vh' as const;

// ReferralCode
export const MIRA_REFERRALCODE_LAYOUT_PC_TABLET_MARGIN_TOP = '70px' as const;
export const MIRA_REFERRALCODE_LAYOUT_SMART_MARGIN_TOP = '40px' as const;
export const MIRA_REFERRALCODE_LAYOUT_PC_MARGIN_TOP_MINUS = '100px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TABLET_MARGIN_TOP_MINUS = '79.5px' as const;
export const MIRA_REFERRALCODE_LAYOUT_SMART_HEIGHT = '100vh' as const;
export const MIRA_REFERRALCODE_LAYOUT_SMART_HEIGHT_MINUS = '125px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_PC_PADDING_TOP = '16px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_PADDING_TOP = '14px' as const;

export const MIRA_REFERRALCODE_LAYOUT_TEXT__PC_LETTER_SPACING = '0.9px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT__TABLET_SMART_LETTER_SPACING = '0.7px' as const;

export const MIRA_REFERRALCODE_LAYOUT_TEXT_PC_FONT_SIZE = '18px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_FONT_SIZE = '14px' as const;

export const MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_PC_FONT_SIZE = '30px' as const;
export const MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_TABLET_SMART_FONT_SIZE = '24px' as const;

export const MIRA_REFERRALCODE_LAYOUT_TEXT_PC_LINE_HEIGHT = '27px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_LINE_HEIGHT = '20px' as const;

export const MIRA_REFERRALCODE_LAYOUT_TEXT_MARGIN_TOP = '4px' as const;

export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_FONT_SIZE = '30px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_SMART_FONT_SIZE = '24px' as const;

export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_LINE_HEIGHT = '30px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_LINE_HEIGHT = '24px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_SMART_LINE_HEIGHT = '36px' as const;

export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_LETTER_SPACING = '1.5px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_SMART_LETTER_SPACING = '1.2px' as const;

export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_MARGIN_TOP = '54px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_MARGIN_TOP = '44px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_H1_SMART_MARGIN_TOP = '49px' as const;

export const MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_PC_LINE_HEIGHT = '30px' as const;
export const MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_TABLET_LINE_HEIGHT = '24px' as const;
export const MIRA_REFERRALCODE_LAYOUT_TEXT_ERROR_SMART_LINE_HEIGHT = '36px' as const;

export const MIRA_REFERRALCODE_ERROR_TEXT_PADDING_BOTTOM = '15px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_MAX_WIDTH = '600px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_MAX_WIDTH = '529px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_BORDER_RADIUS = '10px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_PADDING_TOP = '32px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_PADDING_TOP = '25px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_LETTER_SPACING = '1.3px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_LETTER_SPACING = '1px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_FONT_SIZE = '26px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_FONT_SIZE = '20px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_LINE_HEIGHT = '38px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_LINE_HEIGHT = '29px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_FONT_SIZE = '40px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_FONT_SIZE = '32px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_LINE_HEIGHT = '40px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_LINE_HEIGHT = '32px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_LETTER_SPACING = '2px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_LETTER_SPACING = '1.6px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_H1_PADDING_TOP = '7px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_H1_PADDING_BOTTOM = '13px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_H1_PADDING_TOP = '9px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_H1_PADDING_BOTTOM = '15px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_MARGIN_TOP = '15px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_MARGIN_BOTTOM = '0' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_MARGIN_LEFT = '50px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_MARGIN_RIGHT = '50px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_MARGIN_LEFT = '30px' as const;
export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_MARGIN_RIGHT = '30px' as const;

export const MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_BORDER_RADIUS = '10px' as const;

export const MIRA_REFERRALCODE_CARDS_PC_MAX_WIDTH = '992px' as const;
export const MIRA_REFERRALCODE_CARDS_TABLET_SMART_MAX_WIDTH = '720px' as const;

export const MIRA_REFERRALCODE_CARDS_MARGIN_BOTTOM = '150px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_PC_FONT_SIZE = '26px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_TABLET_SIZE = '20px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_SMART_SIZE = '18px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_PC_LINE_HEIGHT = '38px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_TABLET_LINE_HEIGHT = '29px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_SMART_LINE_HEIGHT = '27px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_PC_LETTER_SPACING = '1.3px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_TABLET_LETTER_SPACING = '1px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_SMART_LETTER_SPACING = '0.9px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_PC_TEXT_UNDERLINE_OFFSET = '1.5px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_BORDER_RADIUS = '27px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_PC_MARGIN_TOP = '40px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_TABLET_SMART_MARGIN_TOP = '35px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_TOP = '7px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_BOTTOM = '9px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_LEFT = '27px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_RIGHT = '27px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_TOP = '6px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_BOTTOM = '10px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_LEFT = '27px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_RIGHT = '27px' as const;

export const MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_TOP = '8px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_BOTTOM = '5px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_LEFT = '5px' as const;
export const MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_RIGHT = '5px' as const;

export const MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_FONT_SIZE = '14px' as const;
export const MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_LINE_HEIGHT = '21px' as const;
export const MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_LETTER_SPACING = '0.42px' as const;

export const MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_INTEND = '-1em' as const;
export const MIRA_REFERRALCODE_CARDS_DESCRIPTION_PADDING_LEFT = '1em' as const;

export const MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_PC_MARGIN_TOP = '18px' as const;
export const MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_TABLET_SMART_MARGIN_TOP = '8px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_CONTAINER_GAP = '18px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_PC_PADDING_TOP = '25px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_TABLET_SMART_PADDING_TOP = '20px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_PC_WIDTH = '487px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_BORDER_RADIUS = '10px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_PC_MARGIN_TOP = '20px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_TABLET_SMART_MARGIN_TOP = '16px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H3_PC_MARGIN_LEFT = '19px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H3_PC_MARGIN_RIGHT = '19px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_MARGIN_LEFT = '15.5px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_MARGIN_RIGHT = '15.5px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H3_HEIGHT = '40px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H3_BORDER_RADIUS = '20px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H3_PC_FONT_SIZE = '21px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_FONT_SIZE = '16px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H3_PC_LINE_HEIGHT = '31px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_LINE_HEIGHT = '24px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H3_PC_LETTER_SPACING = '1.05px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_LETTER_SPACING = '0.8px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H1_PC_FONT_SIZE = '25px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_FONT_SIZE = '20px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H1_PC_LINE_HEIGHT = '34px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_LINE_HEIGHT = '26px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H1_PC_LETTER_SPACING = '1.3px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_LETTER_SPACING = '1px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H1_PC_MARGIN_TOP = '9px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_MARGIN_TOP = '7px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_H1_MARGIN_BOTTOM = '3px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_P_FONT_SIZE = '14px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_P_PC_LINE_HEIGHT = '21px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_P_TABLTE_SMART_LINE_HEIGHT = '15px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_P_LETTER_SPACING = '0.7px' as const;

export const MIRA_REFERRALCODE_CARDS_BOX_P_PC_MARGIN_TOP = '20px' as const;
export const MIRA_REFERRALCODE_CARDS_BOX_P_TABLTE_SMART_MARGIN_TOP = '18px' as const;

// CreditCardSwitching
export const MIRA_CREDITCARDSWITCHING_LAYOUT_SMART_ERROR_HEIGHT_MINUS = '135px' as const;
export const MIRA_CREDITCARDSWITCHING_LAYOUT_SMART_IPHONE_ERROR_HEIGHT_MINUS = '145px' as const;
export const MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_PC_FONT_SIZE = '30px' as const;
export const MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_TABLET_SMART_FONT_SIZE = '24px' as const;
export const MIRA_CREDITCARDSWITCHING_LAYOUT_TEXT_TABLET_SMART_MIN_FONT_SIZE = '20px' as const;

export const MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_WIDTH = '280px' as const;
export const MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_WIDTH = '255px' as const;

export const MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_HEIGHT = '70px' as const;
export const MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT = '50px' as const;

export const MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_PC_FONT_SIZE = '20px' as const;
export const MIRA_CREDITCARDSWITCHING_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE = '14px' as const;

export const MIRA_CREDITCARDSWITCHING_LAYOUT_ERROR_TEXT_PC_TABLET_FONT_SIZE = '24px' as const;
export const MIRA_CREDITCARDSWITCHING_LAYOUT_ERROR_TEXT_SMART_FONT_SIZE = '20px' as const;

// CreditCardSwitching, Unpaid Complete
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_PC_TABLET_MARGIN_TOP = '33.33vh' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_PC_MARGIN_TOP_MINUS = '100px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TABLET_MARGIN_TOP_MINUS = '79.5px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_SMART_HEIGHT = '100vh' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_SMART_HEIGHT_MINUS = '124px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_FONT_SIZE = '30px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_FONT_SIZE = '24px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_FONT_SIZE = '22px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_LINE_HEIGHT = '45px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_LINE_HEIGHT = '36px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_LINE_HEIGHT = '33px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_LETTER_SPACING = '1.5px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_SMART_LETTER_SPACING = '0px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_PC_MARGIN_BOTTOM = '30px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_TABLET_MARGIN_BOTTOM = '24px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_TITLE_SMART_MARGIN_BOTTOM = '28px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_FONT_SIZE = '18px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_FONT_SIZE = '14px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_LINE_HEIGHT = '27px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_LINE_HEIGHT = '21px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_PC_LETTER_SPACING = '0.9px' as const;
export const MIRA_CREDITCARDSWITCHING_COMPLATE_LAYOUT_DESCRIPTION_TABLET_SMART_LETTER_SPACING = '0.7px' as const;

//  unpaid
export const MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP = '71px' as const;
export const MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP = '43px' as const;
export const MIRA_UNPAID_LAYOUT_SMART_MARGIN_TOP = '38px' as const;

export const MIRA_UNPAID_LAYOUT_MARGIN_BOTTOM = '150px' as const;

export const MIRA_UNPAID_LAYOUT_PC_MARGIN_TOP_MINUS = '100px' as const;
export const MIRA_UNPAID_LAYOUT_TABLET_MARGIN_TOP_MINUS = '79.5px' as const;
export const MIRA_UNPAID_LAYOUT_SMART_HEIGHT = '100vh' as const;
export const MIRA_UNPAID_LAYOUT_SMART_HEIGHT_MINUS = '200px' as const;
export const MIRA_UNPAID_LAYOUT_TEXT_PC_FONT_SIZE = '30px' as const;
export const MIRA_UNPAID_LAYOUT_TEXT_TABLET_FONT_SIZE = '24px' as const;
export const MIRA_UNPAID_LAYOUT_TEXT_SMART_FONT_SIZE = '23px' as const;

export const MIRA_UNPAID_LAYOUT_TEXT_PC_LINE_HEIGHT = '45px' as const;
export const MIRA_UNPAID_LAYOUT_TEXT_TABLET_LINE_HEIGHT = '36px' as const;
export const MIRA_UNPAID_LAYOUT_TEXT_SMART_LINE_HEIGHT = '34px' as const;

export const MIRA_UNPAID_LAYOUT_TEXT_PC_LETTER_SPACING = '1.5px' as const;
export const MIRA_UNPAID_LAYOUT_TEXT_TABLET_LETTER_SPACING = '1.2px' as const;
export const MIRA_UNPAID_LAYOUT_TEXT_SMART_LETTER_SPACING = '1.15px' as const;

export const MIRA_UNPAID_LAYOUT_BUTTON_PC_MARGIN_TOP = '60px' as const;
export const MIRA_UNPAID_LAYOUT_BUTTON_TABLE_MARGIN_TOP = '50px' as const;
export const MIRA_UNPAID_LAYOUT_BUTTON_SMART_MARGIN_TOP = '60px' as const;

export const MIRA_UNPAID_LAYOUT_BUTTON_PC_WIDTH = '280px' as const;
export const MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_WIDTH = '255px' as const;

export const MIRA_UNPAID_LAYOUT_BUTTON_PC_HEIGHT = '70px' as const;
export const MIRA_UNPAID_LAYOUT_BUTTON_TABLTE_SMART_HEIGHT = '50px' as const;

export const MIRA_UNPAID_LAYOUT_BUTTON_PC_FONT_SIZE = '20px' as const;
export const MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_FONT_SIZE = '14px' as const;

export const MIRA_UNPAID_LAYOUT_BUTTON_PC_LINE_HEIGHT = '29px' as const;
export const MIRA_UNPAID_LAYOUT_BUTTON_LINE_HEIGHT = '20px' as const;

export const MIRA_UNPAID_LAYOUT_BUTTON_PC_LETTER_SPACING = '1px' as const;
export const MIRA_UNPAID_LAYOUT_BUTTON_TABLET_SMART_LETTER_SPACING = '0.7px' as const;

export const MIRA_UNPAID_LAYOUT_BUTTON_BORDER_RADIUS = '40px' as const;
export const MIRA_UNPAID_LAYOUT_BUTTON_SMART_BOTTOM = '89px' as const;

export const MIRA_UNPAID_LAYOUT_PC_MAX_WIDTH = '992px' as const;
export const MIRA_UNPAID_LAYOUT_TABLET_SMART_MAX_WIDTH = '824px' as const;

export const MIRA_UNPAID_LAYOUT_TITLE_P_PC_FONT_SIZE = '21px' as const;
export const MIRA_UNPAID_LAYOUT_TITLE_P_PC_LINE_HEIGHT = '31px' as const;
export const MIRA_UNPAID_LAYOUT_TITLE_P_PC_LETTER_SPACING = '1.05px' as const;
export const MIRA_UNPAID_LAYOUT_TITLE_P_PC_TABLET_MARGIN_TOP = '5px' as const;

export const MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_FONT_SIZE = '17px' as const;
export const MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LINE_HEIGHT = '25px' as const;
export const MIRA_UNPAID_LAYOUT_TITLE_P_TABLET_LETTER_SPACING = '0.85px' as const;

export const MIRA_UNPAID_LAYOUT_TITLE_P_SMART_FONT_SIZE = '16px' as const;
export const MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LINE_HEIGHT = '24px' as const;
export const MIRA_UNPAID_LAYOUT_TITLE_P_SMART_LETTER_SPACING = '0.8px' as const;
export const MIRA_UNPAID_LAYOUT_TITLE_P_SMART_MARGIN_TOP = '8px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_BORDER_RADIUS = '27px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_TOP = '7px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_PC_TABLET_PADDING_BOTTOM = '9px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_TOP = '24px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_SMART_PADDING_BOTTOM = '24px' as const;

export const MIRA_UNPAID_LAYOUT_PC_BOX_MARGIN_TOP = '65px' as const;
export const MIRA_UNPAID_LAYOUT_TABLET_BOX_MARGIN_TOP = '55px' as const;
export const MIRA_UNPAID_LAYOUT_SMART_BOX_MARGIN_TOP = '57px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_H3_PC_FONT_SIZE = '26px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_H3_PC_LINE_HEIGHT = '38px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_H3_PC_LETTER_SPACING = '1.3px' as const;
export const MIRA_UNPAID_LAYOUT_PC_BOX_H3_TEXT_UNDERLINE_OFFSET = '1.5px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_FONT_SIZE = '20px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LINE_HEIGHT = '29px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_H3_TABLET_LETTER_SPACING = '1px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_H3_SMART_FONT_SIZE = '17px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LINE_HEIGHT = '25px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_H3_SMART_LETTER_SPACING = '0.8px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_FONT_SIZE = '16px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LINE_HEIGHT = '24px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_SPAN_PC_LETTER_SPACING = '0.8px' as const;
export const MIRA_UNPAID_LAYOUT_PC_BOX_SPAN_PC_TABLET_MARGIN_LEFT = '12px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_FONT_SIZE = '14px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LINE_HEIGHT = '20px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_SPAN_TABLET_SMART_LETTER_SPACING = '0.7px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_SPAN_SMART_MARGIN_LEFT = '0px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_UL_PC_MARGIN_TOP = '20px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_UL_TABLET_SMART_MARGIN_TOP = '27px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_LI_PC_FONT_SIZE = '21px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_LI_PC_LINE_HEIGHT = '31px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_LI_PC_LETTER_SPACING = '0.63px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_LI_PC_MARGIN_TOP = '15px' as const;

export const MIRA_UNPAID_LAYOUT_BOX_LI_PADDING_LEFT = '1em' as const;
export const MIRA_UNPAID_LAYOUT_BOX_LI_TEXT_INDENT = '-1em' as const;

export const MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_FONT_SIZE = '16px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LINE_HEIGHT = '24px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_LETTER_SPACING = '0.48px' as const;
export const MIRA_UNPAID_LAYOUT_BOX_LI_TABLET_SMART_MARGIN_TOP = '10px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_UL_MARGIN_TOP = '8px' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_UL_PADDING_LEFT = '0' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_UL_PC_MARGIN_LEFT = '17px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_UL_TABLET_SMART_MARGIN_LEFT = '12px' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_LI_PC_FONT_SIZE = '21px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_PC_LINE_HEIGHT = '31px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_PC_LETTER_SPACING = '0.63px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_PADDING_LEFT = '1em' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_TEXT_INDENT = '-1em' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_FONT_SIZE = '16px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_LINE_HEIGHT = '24px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_TABLET_SMART_LETTER_SPACING = '0.48px' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_FONT_SIZE = '16px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_LINE_HEIGHT = '24px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_P_TABLET_SMART_LETTER_SPACING = '0.48px' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_FONT_SIZE = '21px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LINE_HEIGHT = '31px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_P_PC_SMART_LETTER_SPACING = '0.63px' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMART_MARGIN_TOP = '8px' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_FONT_SIZE = '15px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_LINE_HEIGHT = '22px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PC_LETTER_SPACING = '0.45px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_PADDING_LEFT = '1em' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TEXT_INDENT = '-1em' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_FONT_SIZE = '14px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_LINE_HEIGHT = '21px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_LI_SMALL_TABLET_SMART_LETTER_SPACING = '0.42px' as const;

export const MIRA_UNPAID_LAYOUT_SECTION_UL_SMALL_PC_MARGIN_LEFT = '17px' as const;
export const MIRA_UNPAID_LAYOUT_SECTION_UL_SMALL_TABLET_SMART_MARGIN_LEFT = '12px' as const;

// Payment
export const MIRA_PAYMENT_LAYOUT_PC_TITLE_MARGIN_TOP = '71px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TITLE_MARGIN_BOTTOM = '33px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_TITLE_MARGIN_TOP = '45px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_TITLE_MARGIN_BOTTOM = '35px' as const;
export const MIRA_PAYMENT_LAYOUT_SMART_TITLE_MARGIN_TOP = '37px' as const;
export const MIRA_PAYMENT_LAYOUT_SMART_TITLE_MARGIN_BOTTOM = '36px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT = '30px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT_HEIGHT = '45px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TITLE_FONT_SPACING = '1.5px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT = '24px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT_HEIGHT = '36px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_TITLE_FONT_SPACING = '1.2px' as const;

export const MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT = '16px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT_HEIGHT = '29px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_HISTORY_FONT_SPACING = '0.8px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT = '14px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_HISTORY_FONT_HEIGHT = '25px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_HISTORY_FONT_SPACING = '0.7px' as const;

export const MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT = '18px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT_HEIGHT = '29px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_SUBTOTAL_FONT_SPACING = '0.9px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_FONT = '16px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_FONT_HEIGHT = '26px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SUBTOTAL_FONT_SPACING = '0.8px' as const;

export const MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT = '24px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT_HEIGHT = '36px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TOTAL_FONT_SPACING = '1.2px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTAL_FONT = '22px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTAL_FONT_HEIGHT = '33px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_TOTAL_FONT_SPACING = '1.1px' as const;

export const MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT = '14px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_HEIGHT = '20px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TABLET_EXPLAN_FONT_SPACING = '0.7px' as const;
export const MIRA_PAYMENT_LAYOUT_SMART_TOTAL_FONT = '13px' as const;
export const MIRA_PAYMENT_LAYOUT_SMART_TOTAL_FONT_HEIGHT = '19px' as const;

export const MIRA_PAYMENT_LAYOUT_HISTORY_END_BORDER = '1px' as const;
export const MIRA_PAYMENT_LAYOUT_TOTAL_END_BORDER = '3px' as const;
export const MIRA_PAYMENT_LAYOUT_SMART_FONT_SPACING = '0px' as const;

export const MIRA_PAYMENT_LAYOUT_PC_TABLET_WIDTH = '600px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_MARGIN_BOTTOM = '150px' as const;

export const MIRA_PAYMENT_LAYOUT_PC_EXPLAN_MARGIN_TOP = '30px' as const;

export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTAL_MARGIN_BOTTOM = '42.66px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_EXPLAN_MARGIN_TOP = '28px' as const;
export const MIRA_PAYMENT_LAYOUT_EXPLAN_MARGIN_BOTTOM = '10px' as const;

export const MIRA_PAYMENT_LAYOUT_PC_BUTTON_MARGIN_TOP = '60px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_BUTTON_MARGIN_TOP = '50px' as const;
export const MIRA_PAYMENT_LAYOUT_SMART_BUTTON_MARGIN_BOTTOM = '90px' as const;
export const MIRA_PAYMENT_LAYOUT_SMART_BUTTON_IPHONE_MARGIN_BOTTOM = '100px' as const;

export const MIRA_PAYMENT_LAYOUT_PC_SUBTOTALROW_HEIGHT = '80px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_SUBTOTALROW_HEIGHT = '74.13px' as const;
export const MIRA_PAYMENT_LAYOUT_PC_TOTALROW_HEIGHT = '55px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLET_SMART_TOTALROW_HEIGHT = '51.38px' as const;
export const MIRA_PAYMENT_LAYOUT_SUBTOTALrOW_PADDING_TOP = '8px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLE_FIRST_COL_WIDTH = '18px' as const;
export const MIRA_PAYMENT_LAYOUT_TABLE_FIRST_COL_TOP = '-5px' as const;

// 休会
export const MIRA_RECESS_LAYOUT_TITLE_FONT_WEIGHT = '700' as const;
export const MIRA_RECESS_LAYOUT_BODY_FONT_WEIGHT = '500' as const;

export const MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_FONT_SIZE = '21px' as const;
export const MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_FONT_WEIGHT = '500' as const;
export const MIRA_RECESS_LAYOUT_PC_COMPLETE_MESSAGE_MARGIN_TOP = '20px' as const;
export const MIRA_RECESS_LAYOUT_TABLET_COMPLETE_MESSAGE_MARGIN_TOP = '15px' as const;
export const MIRA_RECESS_LAYOUT_SMART_COMPLETE_MESSAGE_MARGIN_TOP = '10px' as const;

export const MIRA_RECESS_LAYOUT_PC_MARGIN_TOP = '71px' as const;
export const MIRA_RECESS_LAYOUT_TABLET_MARGIN_TOP = '43px' as const;
export const MIRA_RECESS_LAYOUT_SMART_MARGIN_TOP = '38px' as const;

export const MIRA_RECESS_LAYOUT_TEXT_PC_LINE_HEIGHT = '45px' as const;
export const MIRA_RECESS_LAYOUT_TEXT_TABLET_LINE_HEIGHT = '36px' as const;
export const MIRA_RECESS_LAYOUT_TEXT_SMART_LINE_HEIGHT = '34px' as const;

export const MIRA_RECESS_LAYOUT_TEXT_PC_LETTER_SPACING = '1.5px' as const;
export const MIRA_RECESS_LAYOUT_TEXT_TABLET_LETTER_SPACING = '1.2px' as const;
export const MIRA_RECESS_LAYOUT_TEXT_SMART_LETTER_SPACING = '1.15px' as const;

export const MIRA_RECESS_LAYOUT_SELECTION_PC_TABLET_MARGIN_TOP = '80px' as const;
export const MIRA_RECESS_LAYOUT_SELECTION_SMART_MARGIN_TOP = '50px' as const;

export const MIRA_RECESS_LAYOUT_SELECTION_DIV_PC_TABLET_MARGIN = '50px' as const;
export const MIRA_RECESS_LAYOUT_SELECTIONDIV_SMART_MARGIN = '30px' as const;

export const MIRA_RECESS_LAYOUT_BOX_PC_TABLET_PADDING_TOP = '7px' as const;

export const MIRA_RECESS_LAYOUT_MARGIN_BOTTOM = '150px' as const;

export const MIRA_RECESS_LAYOUT_PC_MAX_WIDTH = '992px' as const;
export const MIRA_RECESS_LAYOUT_TABLET_SMART_MAX_WIDTH = '824px' as const;

export const MIRA_RECESS_LAYOUT_TEXT_PC_FONT_SIZE = '30px' as const;
export const MIRA_RECESS_LAYOUT_TEXT_TABLET_FONT_SIZE = '24px' as const;
export const MIRA_RECESS_LAYOUT_TEXT_SMART_FONT_SIZE = '23px' as const;

export const MIRA_RECESS_LAYOUT_BOX_PC_TABLET_PADDING_BOTTOM = '9px' as const;

export const MIRA_RECESS_LAYOUT_BOX_H3_PC_LINE_HEIGHT = '38px' as const;
export const MIRA_RECESS_LAYOUT_BOX_H3_PC_LETTER_SPACING = '1.3px' as const;
export const MIRA_RECESS_LAYOUT_PC_BOX_H3_TEXT_UNDERLINE_OFFSET = '1.5px' as const;

export const MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_FONT_SIZE = '14px' as const;
export const MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_LINE_HEIGHT = '20px' as const;
export const MIRA_RECESS_LAYOUT_BOX_SPAN_TABLET_SMART_LETTER_SPACING = '0.7px' as const;

export const MIRA_RECESS_LAYOUT_MODAL_PC_CONTENT_HEIGHT = '250px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_TABLET_SMART_CONTENT_HEIGHT = '200px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH = '500px' as const;

export const MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_WIDTH = '250px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_WIDTH = '230px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_HEIGHT = '60px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_HEIGHT = '55px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_MAX_WIDTH = '230px' as const;

export const MIRA_RECESS_LAYOUT_MODAL_MESSAGE_PC_LAYOUT_HEIGHT = '120px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_MESSAGE_TABLE_SMART_LAYOUT_HEIGHT = '80px' as const;

export const MIRA_RECESS_LAYOUT_MODAL_MESSAGE_PC_FONT_SIZE = '22px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_MESSAGE_TABLET_FONT_SIZE = '18px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_MESSAGE_SMART_FONT_SIZE = '16px' as const;

export const MIRA_RECESS_LAYOUT_MODAL_MESSAGE_LAYOUT_PADDING = '30px' as const;
export const MIRA_RECESS_LAYOUT_MODAL_MESSAGE_LAYOUT_PADDING_TOP = '40px' as const;

export const MIRA_RECESS_LAYOUT_MODAL_LAYOUT_BORDER_RADIUS = '30px' as const;

export const MIRA_RECESS_LAYOUT_BUTTON_SMART_BOTTOM = '89px' as const;
export const MIRA_RECESS_LAYOUT_BUTTON_IPHONE_BOTTOM = '99px' as const;
