import styled from '@emotion/styled';
import { Col, Modal, Row, InputGroup, FormControl, Spinner } from 'react-bootstrap';

import { SMART_SIZE, TABLET_SIZE } from '../../styles/common';
import {
  MIRA_HOME_LAYOUT_SMALL_TEXT_FONT_SIZE,
  MIRA_HOME_LAYOUT_MAX_WIDTH,
  MIRA_HOME_LAYOUT_PC_TABLET_MIN_HEIGT,
  MIRA_HOME_LAYOUT_LETTER_lINE_HEIGHT,
  MIRA_HOME_LAYOUT_PC_TABLET_LETTER_FONT_SIZE,
  MIRA_HOME_LAYOUT_LETTER_PC_TABLET_SPAN_FONT_SIZE,
  MAIN_BUTTON_MIN_WIDTH,
  MAIN_BUTTON_HEIGHT,
  MAIN_BUTTON_BORDER_WIDTH,
  MAIN_BUTTON_BOX_SHADOW_TOP,
  MAIN_BUTTON_BOX_SHADOW_RIGHT_LEFT,
  MAIN_BUTTON_BOX_SHADOW_BOTTOM,
  MAIN_BUTTON_BORDER_RADIUS,
  MAIN_BUTTON_FONT_SIZE,
  MAIN_BUTTON_LINE_HEIGHT,
  MIRA_HOME_LAYOUT_BUTTON_MARGIN_BOTTOM,
  MIRA_HOME_LAYOUT_FIRST_COLUMN_PC_TABLET_MAX_WIDTH,
  MAIN_FULL_WIDTH,
  MIRA_HOME_LAYOUT_FIRST_COLUMN_PC_TABLET_FONT_SIZE,
  MIRA_HOME_LAYOUT_LAST_COLUMN_MAX_WIDTH,
  MIRA_HOME_LAYOUT_FIRST_COLUMN_SMART_FONT_SIZE,
  MIRA_HOME_LAYOUT_LETTER_SMART_SPAN_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_MAIN_TABLET_MAX_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_PC_CONTENT_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_TABLET_SMART_CONTENT_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_HEADER_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_DELETE_BUTTON_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_BODY_PC_TEXT_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_SMART_TEXT_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_SMART_TEXT_LINE_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_MAX_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_BODY_PC_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_MAX_WIDTH,
  MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_HEIGHT,
  MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_PC_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_TABLET_SMART_FONT_SIZE,
  MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_FONT_WEIGHT,
  MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_WIDTH,
} from '../../styles/size';
import { WHITE, RED, LARGE_BUTTON_BOX_SHADOW_COLOR, LIGHT_GRAY_BORDER, BLACK } from '../../styles/color';

export const HomeLayout = styled.div`
  margin-top: 120px;
  max-width: ${MIRA_HOME_LAYOUT_MAX_WIDTH};
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 150px;

  ${TABLET_SIZE} {
    margin-top: 151px;
  }
  ${SMART_SIZE} {
    margin-top: auto;
  }
`;

export const HomeRow = styled(Row)`
  border-bottom: 1px solid ${LIGHT_GRAY_BORDER};
  text-align: left;
  min-height: ${MIRA_HOME_LAYOUT_PC_TABLET_MIN_HEIGT};
  padding-top: 31.5px;

  ${SMART_SIZE} {
    display: block;
    text-align: left;
    padding-top: 34.5px;
  }
`;

export const HomeCol = styled(Col)`
  & > p {
    line-height: ${MIRA_HOME_LAYOUT_LETTER_lINE_HEIGHT};
    padding-bottom: 8.5px;
    font-size: ${MIRA_HOME_LAYOUT_PC_TABLET_LETTER_FONT_SIZE};
    letter-spacing: 0.9px;
    color: ${BLACK};
    display: inline-block;
    & > span {
      font-size: ${MIRA_HOME_LAYOUT_LETTER_PC_TABLET_SPAN_FONT_SIZE};
      line-height: 16px;
    }
  }

  &:first-of-type {
    font-weight: 700;
    max-width: ${MIRA_HOME_LAYOUT_FIRST_COLUMN_PC_TABLET_MAX_WIDTH};
    ${SMART_SIZE} {
      max-width: ${MAIN_FULL_WIDTH};
    }
    & > p {
      font-size: ${MIRA_HOME_LAYOUT_FIRST_COLUMN_PC_TABLET_FONT_SIZE};
    }
  }
  &:nth-of-type(2) {
    word-break: break-all;
    ${SMART_SIZE} {
      word-break: initial;
    }
  }
  &:nth-of-type(3) {
    max-width: ${MIRA_HOME_LAYOUT_LAST_COLUMN_MAX_WIDTH};
    position: relative;
  }

  ${SMART_SIZE} {
    display: flex;
    align-items: end;
    width: ${MAIN_FULL_WIDTH};
    & > p {
      font-size: ${MIRA_HOME_LAYOUT_FIRST_COLUMN_PC_TABLET_FONT_SIZE};
      & > span {
        font-size: ${MIRA_HOME_LAYOUT_LETTER_SMART_SPAN_FONT_SIZE};
      }
    }

    &:nth-of-type(3) {
      justify-content: flex-start;
      max-width: ${MIRA_HOME_LAYOUT_LAST_COLUMN_MAX_WIDTH};
    }
    &:first-of-type {
      font-weight: 700;
      & > p {
        font-size: ${MIRA_HOME_LAYOUT_FIRST_COLUMN_SMART_FONT_SIZE};
      }
    }
  }
`;

export const HomeColTitle = styled.p`
  line-height: 22px;
  padding-bottom: 8.5px;
  font-size: 16px;
  letter-spacing: 0.9px;
  color: #000;
  display: inline-block;
  ${SMART_SIZE} {
    font-size: 14px;
  }
`;

export const HomeReferenceText = styled.p`
  text-align: left;
  font-size: ${MIRA_HOME_LAYOUT_SMALL_TEXT_FONT_SIZE};
  padding-top: 11.5px;
  font-weight: bold;
  color: red;
`;

export const HomeButton = styled.button`
  min-width: ${MAIN_BUTTON_MIN_WIDTH};
  height: ${MAIN_BUTTON_HEIGHT};
  border: 3px solid ${RED};
  background: ${WHITE};
  box-shadow: ${MAIN_BUTTON_BOX_SHADOW_TOP} ${MAIN_BUTTON_BOX_SHADOW_RIGHT_LEFT} ${MAIN_BUTTON_BOX_SHADOW_BOTTOM}
    #00000026;
  border-radius: ${MAIN_BUTTON_BORDER_RADIUS};
  color: ${RED};
  text-align: center;
  font-size: ${MAIN_BUTTON_FONT_SIZE};
  line-height: ${MAIN_BUTTON_LINE_HEIGHT};
  font-weight: 700;
  margin-bottom: ${MIRA_HOME_LAYOUT_BUTTON_MARGIN_BOTTOM};
  ${SMART_SIZE} {
    position: initial;
    top: 0px;
  }

  &:hover {
    background: ${RED};
    color: ${WHITE};
    border: ${MAIN_BUTTON_BORDER_WIDTH} solid ${RED};
  }
  &:focus {
    border: ${MAIN_BUTTON_BORDER_WIDTH} solid ${RED};
    background: ${WHITE};
    box-shadow: 0 4 6 ${LARGE_BUTTON_BOX_SHADOW_COLOR};
    color: ${RED};
  }
  &:focus:hover {
    background: ${RED};
    color: ${WHITE};
    border: 3px solid ${RED};
  }
`;

export const HomeModal = styled(Modal)`
  padding-left: 40px !important;
  padding-right: 40px !important;
  & > div {
    max-width: ${MIRA_HOME_LAYOUT_MODAL_MAIN_PC_MAX_WIDTH};
    display: flex;
    align-items: center;
    height: ${MIRA_HOME_LAYOUT_MODAL_MAIN_HEIGHT};
    justify-content: center;
    & > div {
      height: ${MIRA_HOME_LAYOUT_MODAL_PC_CONTENT_HEIGHT};
      border-radius: 30px;
    }
  }
  ${TABLET_SIZE} {
    & > div {
      max-width: ${MIRA_HOME_LAYOUT_MODAL_MAIN_TABLET_MAX_WIDTH};
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        height: ${MIRA_HOME_LAYOUT_MODAL_TABLET_SMART_CONTENT_HEIGHT};
      }
    }
  }
  ${SMART_SIZE} {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: ${MAIN_FULL_WIDTH};
  }
`;

export const HomeModalHeader = styled(Modal.Header)`
  &:first-of-type {
    display: flex;
    justify-content: flex-end;
    border: none;
    padding: 15px;
    height: ${MIRA_HOME_LAYOUT_MODAL_HEADER_HEIGHT};
    & > button {
      margin-top: 15px;
      border: none;
      box-sizing: content-box;
      background-color: transparent;
      & > span:first-of-type {
        font-size: ${MIRA_HOME_LAYOUT_MODAL_DELETE_BUTTON_FONT_SIZE};
        z-index: 999;
      }
      & > span:nth-of-type(2) {
        display: none;
      }
    }
  }
  ${TABLET_SIZE} {
    &:first-of-type {
      display: flex;
      justify-content: flex-end;
      border: none;
      & > button {
        border: none;
        box-sizing: content-box;
        background-color: transparent;
        z-index: 999;
        & > span:nth-of-type(2) {
          display: none;
        }
      }
    }
    & > div {
      & > div:last-of-type {
        text-align: left;
        & > p {
          font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_SMART_TEXT_FONT_SIZE};
          line-height: 14px;
          font-weight: 700;
        }
        & > button {
          max-width: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_MAX_WIDTH};
          width: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_WIDTH};
          height: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_HEIGHT};
          font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_FONT_SIZE};
          background: ${WHITE};
          color: ${RED};
          text-align: center;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        & > button:hover {
          background: ${RED};
          color: ${WHITE};
          border: 3px solid ${RED};
        }
        & > button:focus {
          border: 3px solid ${RED};
          background: ${WHITE};
          box-shadow: ${MAIN_BUTTON_BOX_SHADOW_TOP} ${MAIN_BUTTON_BOX_SHADOW_RIGHT_LEFT}
            ${MAIN_BUTTON_BOX_SHADOW_BOTTOM} #00000026;
          color: ${RED};
        }
      }
    }
    & > div:nth-of-type(2) {
      max-width: ${MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_MAX_WIDTH};
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        width: 100%;
        height: auto;
        & > div {
          & > input {
            font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_TABLET_SMART_FONT_SIZE};
          }
        }
      }
    }
  }
  ${SMART_SIZE} {
    &:first-of-type {
      display: flex;
      justify-content: flex-end;
      border: none;
      & > button {
        border: none;
        box-sizing: content-box;
        background-color: transparent;
        z-index: 999;
        & > span:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
`;

export const HomeModalBody = styled(Modal.Body)`
  &:last-of-type {
    margin-left: auto;
    margin-right: auto;
  }
  &:nth-of-type(2) {
    width: ${MIRA_HOME_LAYOUT_MODAL_BODY_PC_WIDTH};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
  }

  ${TABLET_SIZE} {
    &:first-of-type {
      display: flex;
      justify-content: flex-end;
      border: none;
      & > button {
        border: none;
        box-sizing: content-box;
        background-color: transparent;
        z-index: 999;
        & > span:nth-of-type(2) {
          display: none;
        }
      }
    }
    &:nth-of-type(2) {
      width: ${MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_WIDTH};
    }
    & > div {
      & > div:last-of-type {
        text-align: left;
        & > p {
          font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_SMART_TEXT_FONT_SIZE};
          line-height: ${MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_SMART_TEXT_LINE_HEIGHT};
          font-weight: 700;
        }
        & > button {
          max-width: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_MAX_WIDTH};
          width: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_WIDTH};
          height: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_HEIGHT};
          font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_FONT_SIZE};
          background: ${WHITE};
          color: ${RED};
          text-align: center;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        & > button:hover {
          background: ${RED};
          color: ${WHITE};
          border: 3px solid ${RED};
        }
        & > button:focus {
          border: 3px solid ${RED};
          background: ${WHITE};
          box-shadow: ${MAIN_BUTTON_BOX_SHADOW_TOP} ${MAIN_BUTTON_BOX_SHADOW_RIGHT_LEFT}
            ${MAIN_BUTTON_BOX_SHADOW_BOTTOM} #00000026;
          color: ${RED};
        }
      }
    }
    & > div:nth-of-type(2) {
      max-width: ${MIRA_HOME_LAYOUT_MODAL_BODY_TABLET_MAX_WIDTH};
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        width: 100%;
        height: auto;
        & > div {
          & > input {
            font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_TABLET_SMART_FONT_SIZE};
          }
        }
      }
    }
  }
  ${SMART_SIZE} {
    &:last-of-type {
      & > div {
        text-align: left;
        & > p {
          font-weight: 700;
        }
      }
    }
    &:nth-of-type(2) {
      width: ${MAIN_FULL_WIDTH};
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        width: ${MAIN_FULL_WIDTH};
        height: auto;
      }
    }
  }
`;

export const HomeModalBodyLayout = styled.div`
  width: ${MAIN_FULL_WIDTH};
  height: auto;
  text-align: left;
`;

export const HomeModalInputGroup = styled(InputGroup)`
  height: ${MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_HEIGHT};
  border: 1px solid #c9caca;
  background: ${WHITE};
  & > input {
    font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_PC_FONT_SIZE};
    color: ${BLACK};
    letter-spacing: 0.9px;
    font-weight: ${MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_FONT_WEIGHT};
    border-radius: 0px;
  }
`;

export const HomeModalInput = styled(FormControl)`
  font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_PC_FONT_SIZE};
  color: ${BLACK};
  letter-spacing: 0.9px;
  font-weight: ${MIRA_HOME_LAYOUT_MODAL_BODY_INPUT_FONT_WEIGHT};
  border-radius: 0px;
`;

export const HomeModalBodyText = styled.p`
  font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_PC_TEXT_FONT_SIZE};
  line-height: 16px;
  font-weight: 700;
  padding-bottom: 11px;
`;

export const HomeModalButton = styled.button`
  width: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_WIDTH};
  height: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_HEIGHT};
  border: 3px solid ${RED};
  box-shadow: 0px 5px 8px ${LARGE_BUTTON_BOX_SHADOW_COLOR};
  font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_PC_FONT_SIZE};
  font-weight: 700;
  line-height: 29px;
  background: ${WHITE};
  border-radius: 50px;
  color: ${RED};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 34px;

  &:disabled {
    opacity: 0.6 !important;
    background: ${WHITE} !important;
    color: ${RED} !important;
  }

  &:hover {
    background: ${RED};
    color: ${WHITE};
    border: ${MAIN_BUTTON_BORDER_WIDTH} solid ${RED};
  }
  ${TABLET_SIZE} {
    max-width: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_MAX_WIDTH};
    width: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_WIDTH};
    height: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_HEIGHT};
    font-size: ${MIRA_HOME_LAYOUT_MODAL_BODY_LARGE_BUTTON_TABLET_SMART_FONT_SIZE};
  }

  ${SMART_SIZE} {
    width: ${MAIN_FULL_WIDTH};
  }
`;

export const HomeModalButtonSpinner = styled(Spinner)`
  margin-right: 8px;
`;

export const HomeModalErrorMessage = styled.p`
  text-align: left;
  color: ${RED};
  letter-spacing: -0.1px;
`;

export const HomeModalErrorMessageText = styled.span`
  display: block;
  margin-bottom: -10px;
  font-size: 22px;

  ${TABLET_SIZE} {
    font-size: 18px;
  }
  ${SMART_SIZE} {
    font-size: 16px;
  }
`;
