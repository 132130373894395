import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Layout, Title, Description } from './Complete.style';

import useWindowSize from '../../hooks/useWindowSize';
import useAuthorizationReload from '../../hooks/Auth/useAuthorizationReload';

import MyPageLayout from '../../components/Layouts/MyPage/MyPageLayout.index';

import FW from '../../config/word.json';
import FS from '../../config/sentence.json';

const Complete: FC = () => {
  // モバイルのサイズを取得する
  const getIsMobile = () => window.innerWidth < 576;
  // ライブラリーを設定する
  const history = useHistory();
  const searchString = useLocation().search;
  const isMobile = useWindowSize(getIsMobile);

  const [name, setName] = useState('null');

  const pageName = history.location.pathname.split('/')[1];

  /**
   * 説明：1．SBPSページから戻った時に、全てのセッションストレージがクリアされる
   *      2．全てのセッションストレージがクリアされたら、ログアウトの状態になる
   *      3．SBPSから取得したデータに、URLを取得する
   *      4．URLで、memberIdとmemberInfoの情報を取得する処理を実施する
   *    → memberIdとmemberInfoの情報がなかったら、ホーム画面に遷移させる
   *    → memberIdとmemberInfoの情報があったら、memberIDとmember_infoのセッションストレージを設定する
   */
  useEffect(() => {
    const query = new URLSearchParams(searchString);
    const memberId = query.get('memberID') || '';
    const memberInfo = query.get('member_info') || '';
    if (!(memberId || memberInfo)) {
      history.push('/home');
    } else {
      sessionStorage.setItem('memberID', memberId);
      sessionStorage.setItem('member_info', memberInfo);
    }
  }, []);

  // ログイン認証を設定する
  useAuthorizationReload();

  useEffect(() => {
    switch (pageName) {
      case 'creditcardSwitching':
        setName(FW.F_W_CREDIT_CARD_SWITCHING);
        break;
      case 'unpaid':
        setName(FW.F_W_UNPAID_NAME);
        break;
      default:
        break;
    }
  }, [pageName]);

  return (
    <>
      <MyPageLayout>
        <Layout>
          <div>
            <Title>
              {name}
              {FS.F_S_COMPLETED_TITLE}
            </Title>
            <Description>
              {isMobile ? (
                <div>
                  {FS.F_S_CREDITCARDSWITCHING_COMPLETED_MOBILE_1} <br />
                  {FS.F_S_CREDITCARDSWITCHING_COMPLETED_MOBILE_2} <br />
                  {FS.F_S_CREDITCARDSWITCHING_THANKFUL_FOR_REGISTER}
                </div>
              ) : (
                <div>
                  {FS.F_S_CREDITCARDSWITCHING_MAIL_COMPLETED} <br />
                  {FS.F_S_CREDITCARDSWITCHING_THANKFUL_FOR_REGISTER} <br />
                </div>
              )}
            </Description>
          </div>
        </Layout>
      </MyPageLayout>
    </>
  );
};

export default Complete;
