import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import { postRecessCancelAction } from '../sagas/recess/recessCancelSaga';
import { API_BASE_ADDRESS } from '../api';

// 休会取消を取得実行します。
const recessCancelService = (action: postRecessCancelAction): any => {
  const config = {
    headers: {
      'X-Request-ID': uuidV4(),
    },
  };

  const postData = {
    member_id: action.memberId,
    shop_id: action.shopId,
  };

  return {
    postRecessCancel: () => axios.post(`${API_BASE_ADDRESS}/member/recess-cancel`, postData, config),
  };
};

export default recessCancelService;
