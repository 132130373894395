import styled from '@emotion/styled';

import { SMART_SIZE, TABLET_SIZE } from '../../styles/common';
import {
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_LETTER_SPACING,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_LETTER_SPACING,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_MAX_WIDTH,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_MAX_WIDTH,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_LETTER_SPACING,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_PADDING_TOP,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_LETTER_SPACING,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_PADDING_TOP,
  MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_PC_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_TEXT_ERROR_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_TABLET_SMART_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_TEXT_PC_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_TEXT_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_TEXT__PC_LETTER_SPACING,
  MIRA_REFERRALCODE_LAYOUT_TEXT__TABLET_SMART_LETTER_SPACING,
  MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_TABLET_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_PC_TABLET_MARGIN_TOP,
  MIRA_REFERRALCODE_LAYOUT_TEXT_MARGIN_TOP,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_SMART_FONT_SIZE,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_LAYOUT_SMART_MARGIN_TOP,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_LETTER_SPACING,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_SMART_LETTER_SPACING,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_MARGIN_TOP,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_MARGIN_TOP,
  MIRA_REFERRALCODE_LAYOUT_TEXT_H1_SMART_MARGIN_TOP,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_BORDER_RADIUS,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_H1_PADDING_TOP,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_H1_PADDING_BOTTOM,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_MARGIN_TOP,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_MARGIN_BOTTOM,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_MARGIN_LEFT,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_MARGIN_RIGHT,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_MARGIN_LEFT,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_MARGIN_RIGHT,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_BORDER_RADIUS,
  MIRA_REFERRALCODE_CARDS_PC_MAX_WIDTH,
  MIRA_REFERRALCODE_CARDS_TABLET_SMART_MAX_WIDTH,
  MIRA_REFERRALCODE_CARDS_MARGIN_BOTTOM,
  MIRA_REFERRALCODE_CARDS_H3_PC_FONT_SIZE,
  MIRA_REFERRALCODE_CARDS_H3_TABLET_SIZE,
  MIRA_REFERRALCODE_CARDS_H3_SMART_SIZE,
  MIRA_REFERRALCODE_CARDS_H3_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_H3_TABLET_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_H3_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_H3_PC_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_H3_SMART_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_H3_TABLET_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_H3_BORDER_RADIUS,
  MIRA_REFERRALCODE_CARDS_H3_PC_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_H3_TABLET_SMART_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_TOP,
  MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_LEFT,
  MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_BOTTOM,
  MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_RIGHT,
  MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_TOP,
  MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_LEFT,
  MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_BOTTOM,
  MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_RIGHT,
  MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_TOP,
  MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_LEFT,
  MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_BOTTOM,
  MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_RIGHT,
  MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_FONT_SIZE,
  MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_PC_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_TABLET_SMART_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_BOX_CONTAINER_GAP,
  MIRA_REFERRALCODE_CARDS_BOX_PC_PADDING_TOP,
  MIRA_REFERRALCODE_CARDS_BOX_TABLET_SMART_PADDING_TOP,
  MAIN_FULL_WIDTH,
  MIRA_REFERRALCODE_CARDS_BOX_PC_WIDTH,
  MIRA_REFERRALCODE_CARDS_BOX_BORDER_RADIUS,
  MIRA_REFERRALCODE_CARDS_BOX_PC_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_BOX_TABLET_SMART_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_BOX_H3_PC_MARGIN_LEFT,
  MIRA_REFERRALCODE_CARDS_BOX_H3_PC_MARGIN_RIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_MARGIN_LEFT,
  MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_MARGIN_RIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_H3_HEIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_H3_BORDER_RADIUS,
  MIRA_REFERRALCODE_CARDS_BOX_H3_PC_FONT_SIZE,
  MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_FONT_SIZE,
  MIRA_REFERRALCODE_CARDS_BOX_H3_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_H3_PC_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_BOX_H1_PC_FONT_SIZE,
  MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_FONT_SIZE,
  MIRA_REFERRALCODE_CARDS_BOX_H1_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_H1_PC_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_BOX_H1_PC_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_BOX_H1_MARGIN_BOTTOM,
  MIRA_REFERRALCODE_CARDS_BOX_P_FONT_SIZE,
  MIRA_REFERRALCODE_CARDS_BOX_P_PC_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_P_TABLTE_SMART_LINE_HEIGHT,
  MIRA_REFERRALCODE_CARDS_BOX_P_LETTER_SPACING,
  MIRA_REFERRALCODE_CARDS_BOX_P_PC_MARGIN_TOP,
  MIRA_REFERRALCODE_CARDS_BOX_P_TABLTE_SMART_MARGIN_TOP,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_H1_PADDING_TOP,
  MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_H1_PADDING_BOTTOM,
  MIRA_REFERRALCODE_CARDS_H3_PC_TEXT_UNDERLINE_OFFSET,
  MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_INTEND,
  MIRA_REFERRALCODE_CARDS_DESCRIPTION_PADDING_LEFT,
} from '../../styles/size';
import { BLACK, RED, BOX_BACKGROUND_PINK, WHITE, DESCRIPTION_TEXT_BLUE } from '../../styles/color';

export const Layout = styled.div`
  margin-top: ${MIRA_REFERRALCODE_LAYOUT_PC_TABLET_MARGIN_TOP};
  display: flex;
  justify-content: center;
  text-align: center;
  & > div {
    & > p {
      text-align: center;
      letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_TEXT__PC_LETTER_SPACING};
      font-size: ${MIRA_REFERRALCODE_LAYOUT_TEXT_PC_FONT_SIZE};
      line-height: ${MIRA_REFERRALCODE_LAYOUT_TEXT_PC_LINE_HEIGHT};
      color: ${DESCRIPTION_TEXT_BLUE};
      font-weight: 500;
      margin-top: ${MIRA_REFERRALCODE_LAYOUT_TEXT_MARGIN_TOP};
    }
    & > h1 {
      font-size: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_FONT_SIZE};
      line-height: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_LINE_HEIGHT};
      letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_LETTER_SPACING};
      font-weight: 700;
      margin-top: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_PC_MARGIN_TOP};
      & > span {
        color: ${RED};
      }
    }
    & > div {
      background: ${BOX_BACKGROUND_PINK};
      max-width: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_MAX_WIDTH};
      padding-bottom: 25px;
      border-radius: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_BORDER_RADIUS};
      margin: auto;
      & > p {
        padding-top: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_PADDING_TOP};
        font-weight: 700;
        letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_LETTER_SPACING};
        font-size: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_FONT_SIZE};
        line-height: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_PC_LINE_HEIGHT};
      }
      & > h1 {
        font-size: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_FONT_SIZE};
        line-height: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_LINE_HEIGHT};
        background: ${WHITE};
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_H1_PADDING_TOP};
        padding-bottom: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_PC_H1_PADDING_BOTTOM};
        margin-top: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_MARGIN_TOP};
        margin-bottom: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_MARGIN_BOTTOM};
        margin-left: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_MARGIN_LEFT};
        margin-right: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_MARGIN_RIGHT};
        border-radius: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_BORDER_RADIUS};
        color: ${RED};
        font-weight: 700;
        letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_PC_LETTER_SPACING};
      }
    }
  }
  & > span > p {
    text-align: center;
    color: ${BLACK};
    font-size: ${MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_PC_FONT_SIZE};
    line-height: ${MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_PC_LINE_HEIGHT};
    font-weight: 700;
  }
  ${TABLET_SIZE} {
    & > div {
      & > p {
        letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_TEXT__TABLET_SMART_LETTER_SPACING};
        font-size: ${MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_FONT_SIZE};
        line-height: ${MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_LINE_HEIGHT};
      }
      & > h1 {
        font-size: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_SMART_FONT_SIZE};
        line-height: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_LINE_HEIGHT};
        letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_SMART_LETTER_SPACING};
        margin-top: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_TABLET_MARGIN_TOP};
      }
      & > div {
        max-width: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_MAX_WIDTH};
        & > p {
          padding-top: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_PADDING_TOP};
          letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_LETTER_SPACING};
          font-size: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_FONT_SIZE};
          line-height: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_LINE_HEIGHT};
        }
        & > h1 {
          font-size: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_FONT_SIZE};
          line-height: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_LETTER_SPACING};
          background: ${WHITE};
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_MARGIN_LEFT};
          margin-right: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_MARGIN_RIGHT};
          color: ${RED};
          padding-top: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_H1_PADDING_TOP};
          padding-bottom: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_H1_PADDING_BOTTOM};
        }
      }
    }
    & > span > p {
      text-align: center;
      color: ${BLACK};
      font-size: ${MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_TABLET_SMART_FONT_SIZE};
      line-height: ${MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_TABLET_LINE_HEIGHT};
      font-weight: 700;
    }
  }
  ${SMART_SIZE} {
    margin-top: ${MIRA_REFERRALCODE_LAYOUT_SMART_MARGIN_TOP};
    display: block;
    margin-left: auto;
    margin-right: auto;
    & > div {
      & > p {
        letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_TEXT__TABLET_SMART_LETTER_SPACING};
        font-size: ${MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_FONT_SIZE};
        line-height: ${MIRA_REFERRALCODE_LAYOUT_TEXT_TABLET_SMART_LINE_HEIGHT};
      }
      & > h1 {
        line-height: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_SMART_LINE_HEIGHT};
        margin-top: ${MIRA_REFERRALCODE_LAYOUT_TEXT_H1_SMART_MARGIN_TOP};
      }
      & > div {
        max-width: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TABLET_SMART_MAX_WIDTH};
        & > p {
          padding-top: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_PADDING_TOP};
          letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_LETTER_SPACING};
          font-size: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_FONT_SIZE};
          line-height: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_TEXT_TABLET_SMART_LINE_HEIGHT};
        }
        & > h1 {
          font-size: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_FONT_SIZE};
          line-height: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_REFERRALCODE_LAYOUT_BACKGROUND_BLOCK_H1_TABLET_SMART_LETTER_SPACING};
        }
      }
    }
    & > span > p {
      font-size: ${MIRA_REFERRALCODE_LAYOUT_ERROR_TEXT_TABLET_SMART_FONT_SIZE};
      line-height: ${MIRA_REFERRALCODE_LAYOUT_TEXT_ERROR_SMART_LINE_HEIGHT};
    }
  }
`;
export const Cards = styled.div`
  max-width: ${MIRA_REFERRALCODE_CARDS_PC_MAX_WIDTH};
  margin: 0 auto ${MIRA_REFERRALCODE_CARDS_MARGIN_BOTTOM} auto;
  & > h3 {
    font-size: ${MIRA_REFERRALCODE_CARDS_H3_PC_FONT_SIZE};
    line-height: ${MIRA_REFERRALCODE_CARDS_H3_PC_LINE_HEIGHT};
    letter-spacing: ${MIRA_REFERRALCODE_CARDS_H3_PC_LETTER_SPACING};
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: ${MIRA_REFERRALCODE_CARDS_H3_PC_TEXT_UNDERLINE_OFFSET};
    border-radius: ${MIRA_REFERRALCODE_CARDS_H3_BORDER_RADIUS};
    background: ${BOX_BACKGROUND_PINK};
    margin-top: ${MIRA_REFERRALCODE_CARDS_H3_PC_MARGIN_TOP};
    padding: ${MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_TOP} ${MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_LEFT}
      ${MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_BOTTOM} ${MIRA_REFERRALCODE_CARDS_H3_PC_PADDING_RIGHT};
  }
  & > ul {
    margin-top: ${MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_PC_MARGIN_TOP};
    list-style: none;
    & > li {
      font-size: ${MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_FONT_SIZE};
      line-height: ${MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_LINE_HEIGHT};
      letter-spacing: ${MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_LETTER_SPACING};
      text-align: left;
      font-weight: 500;
      text-indent: ${MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_INTEND};
      padding-left: ${MIRA_REFERRALCODE_CARDS_DESCRIPTION_PADDING_LEFT};
      & span {
        color: ${RED};
      }
    }
    li:before {
      content: '〇';
    }
  }
  & div {
    display: flex;
    justify-content: center;
    gap: ${MIRA_REFERRALCODE_CARDS_BOX_CONTAINER_GAP};
    & div {
      display: block;
      padding-top: ${MIRA_REFERRALCODE_CARDS_BOX_PC_PADDING_TOP};
      border: 1px solid ${BLACK};
      width: ${MIRA_REFERRALCODE_CARDS_BOX_PC_WIDTH};
      border-radius: ${MIRA_REFERRALCODE_CARDS_BOX_BORDER_RADIUS};
      margin-top: ${MIRA_REFERRALCODE_CARDS_BOX_PC_MARGIN_TOP};
      & h3 {
        margin-left: ${MIRA_REFERRALCODE_CARDS_BOX_H3_PC_MARGIN_LEFT};
        margin-right: ${MIRA_REFERRALCODE_CARDS_BOX_H3_PC_MARGIN_RIGHT};
        height: ${MIRA_REFERRALCODE_CARDS_BOX_H3_HEIGHT};
        border-radius: ${MIRA_REFERRALCODE_CARDS_BOX_H3_BORDER_RADIUS};
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${BLACK};
        color: ${WHITE};
        font-size: ${MIRA_REFERRALCODE_CARDS_BOX_H3_PC_FONT_SIZE};
        line-height: ${MIRA_REFERRALCODE_CARDS_BOX_H3_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_REFERRALCODE_CARDS_BOX_H3_PC_LETTER_SPACING};
        font-weight: 700;
      }
      & h1 {
        font-size: ${MIRA_REFERRALCODE_CARDS_BOX_H1_PC_FONT_SIZE};
        line-height: ${MIRA_REFERRALCODE_CARDS_BOX_H1_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_REFERRALCODE_CARDS_BOX_H1_PC_LETTER_SPACING};
        font-weight: 700;
        color: ${RED};
        margin-top: ${MIRA_REFERRALCODE_CARDS_BOX_H1_PC_MARGIN_TOP};
        margin-bottom: ${MIRA_REFERRALCODE_CARDS_BOX_H1_MARGIN_BOTTOM};
      }
      & p {
        font-size: ${MIRA_REFERRALCODE_CARDS_BOX_P_FONT_SIZE};
        line-height: ${MIRA_REFERRALCODE_CARDS_BOX_P_PC_LINE_HEIGHT};
        letter-spacing: ${MIRA_REFERRALCODE_CARDS_BOX_P_LETTER_SPACING};
        font-weight: 500;
        margin-bottom: ${MIRA_REFERRALCODE_CARDS_BOX_P_PC_MARGIN_TOP};
      }
    }
  }
  ${TABLET_SIZE} {
    max-width: ${MIRA_REFERRALCODE_CARDS_TABLET_SMART_MAX_WIDTH};
    & > h3 {
      font-size: ${MIRA_REFERRALCODE_CARDS_H3_TABLET_SIZE};
      line-height: ${MIRA_REFERRALCODE_CARDS_H3_TABLET_LINE_HEIGHT};
      letter-spacing: ${MIRA_REFERRALCODE_CARDS_H3_TABLET_LETTER_SPACING};
      margin-top: ${MIRA_REFERRALCODE_CARDS_H3_TABLET_SMART_MARGIN_TOP};
      padding: ${MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_TOP} ${MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_LEFT}
        ${MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_BOTTOM} ${MIRA_REFERRALCODE_CARDS_H3_TABLET_PADDING_RIGHT};
    }
    & > p {
      margin-top: ${MIRA_REFERRALCODE_CARDS_DESCRIPTION_TEXT_TABLET_SMART_MARGIN_TOP};
    }
    & div {
      display: block;
      & div {
        padding-top: ${MIRA_REFERRALCODE_CARDS_BOX_TABLET_SMART_PADDING_TOP};
        width: ${MAIN_FULL_WIDTH};
        margin-top: ${MIRA_REFERRALCODE_CARDS_BOX_TABLET_SMART_MARGIN_TOP};
        & h3 {
          margin-left: ${MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_MARGIN_LEFT};
          margin-right: ${MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_MARGIN_RIGHT};
          font-size: ${MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_FONT_SIZE};
          line-height: ${MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_REFERRALCODE_CARDS_BOX_H3_TABLET_SMART_LETTER_SPACING};
        }
        & h1 {
          font-size: ${MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_FONT_SIZE};
          line-height: ${MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_LINE_HEIGHT};
          letter-spacing: ${MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_LETTER_SPACING};
          margin-top: ${MIRA_REFERRALCODE_CARDS_BOX_H1_TABLTE_SMART_MARGIN_TOP};
        }
        & p {
          line-height: ${MIRA_REFERRALCODE_CARDS_BOX_P_TABLTE_SMART_LINE_HEIGHT};
          margin-bottom: ${MIRA_REFERRALCODE_CARDS_BOX_P_TABLTE_SMART_MARGIN_TOP};
        }
      }
    }
  }
  ${SMART_SIZE} {
    & > h3 {
      font-size: ${MIRA_REFERRALCODE_CARDS_H3_SMART_SIZE};
      line-height: ${MIRA_REFERRALCODE_CARDS_H3_SMART_LINE_HEIGHT};
      letter-spacing: ${MIRA_REFERRALCODE_CARDS_H3_SMART_LETTER_SPACING};
      padding: ${MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_TOP} ${MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_LEFT}
        ${MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_BOTTOM} ${MIRA_REFERRALCODE_CARDS_H3_SMART_PADDING_RIGHT};
    }
  }
`;
