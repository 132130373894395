import styled from '@emotion/styled';
import { WHITE, RED, RED_DISABLED, LIGHT_GRAY } from '../../../styles/color';
import { TABLET_SIZE, SMART_SIZE } from '../../../styles/common';

interface IProps {
  location?: 'left' | 'right' | '';
  move: 'back' | 'go' | '';
}

const CustomButton = styled.button<IProps>`
  position: relative;
  box-shadow: 0px 5px 8px #00000026;
  width: 280px;
  height: 70px;
  border-radius: 40px;
  background-color: ${WHITE};
  /* color: ${({ move }) => (move === 'go' ? `${RED}` : ``)}; */
  color: ${({ move }) => (move === 'back' ? `${LIGHT_GRAY}` : move === 'go' ? `${RED}` : ``)};
  border: 3px solid ${({ move }) => (move === 'back' ? `${LIGHT_GRAY}` : move === 'go' ? `${RED}` : ``)};
  margin-right: ${({ location }) => (location === 'left' ? `20px` : ``)};
  margin-left: ${({ location }) => (location === 'right' ? `20px` : ``)};
  cursor: pointer;
  :hover {
    background-color: ${({ move }) => (move === 'back' ? `${LIGHT_GRAY}` : move === 'go' ? `${RED}` : ``)};
    color: ${WHITE};
  }
  :disabled {
    color: ${RED_DISABLED};
    border: 3px solid ${RED_DISABLED};
    :hover {
      background-color: ${WHITE};
      color: ${RED_DISABLED};
    }
  }
  & > span {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  ${TABLET_SIZE} {
    margin-right: ${({ location }) => (location === 'left' ? `9.5px` : ``)};
    margin-left: ${({ location }) => (location === 'right' ? `9.5px` : ``)};
    width: 45%;
    max-width: 255px;
    height: 50px;
    & > span {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.7px;
      font-weight: 700;
    }
  }
  ${SMART_SIZE} {
    margin-right: ${({ location }) => (location === 'left' ? `9.5px` : ``)};
    margin-left: ${({ location }) => (location === 'right' ? `9.5px` : ``)};
    width: 45%;
    height: 50px;
    & > span {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.7px;
      font-weight: 700;
    }
  }
`;

export default CustomButton;
