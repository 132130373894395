import { all, call, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { fork, put } from 'redux-saga/effects';

import {
  POST_RECESS_CANCEL_REQUEST,
  POST_RECESS_CANCEL_SUCCESS,
  POST_RECESS_CANCEL_FAILURE,
  COMMON_FAILURE,
} from '../../actions';
import { recessCancelService } from '../../services';
import { historyType } from '../../../utils/commonType';

export type postRecessCancelAction = {
  type: typeof POST_RECESS_CANCEL_REQUEST;
  memberId: string;
  shopId: string;
  history: historyType;
};

function* postRecessCancel(action: postRecessCancelAction): Generator<unknown> {
  try {
    const res: any = yield call(recessCancelService(action).postRecessCancel);
    yield put({
      type: POST_RECESS_CANCEL_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put({
        type: POST_RECESS_CANCEL_FAILURE,
        error: error.response,
      });
      yield put({
        type: COMMON_FAILURE,
        error: error.response,
      });
    }
    action.history.push('/systemError');
  }
}

function* watchPostRecessCancel() {
  yield takeLatest(POST_RECESS_CANCEL_REQUEST, postRecessCancel);
}

export default function* recessCancelSaga(): Generator<unknown> {
  yield all([fork(watchPostRecessCancel)]);
}
